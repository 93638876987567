import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs, { Dayjs } from 'dayjs'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import {
	NetcurioTooltip,
	NetcurioAutocomplete,
	NetcurioNumericField,
	NetcurioDatePicker,
	NetcurioIconButton,
	NetcurioIcons
} from '@netcurio/frontend-components'
import { ItemRFQProps, MeasurementUnits, ProductRFQ } from '@netcurio/frontend-common'
import { PRODUCT_NAME_CODE } from '../queries'
import { connection } from '../../../utilities/connection'
import { useDebounce } from '../../../utilities/useDebounce'
import { expiredToken } from '../../../utilities/expiredToken'
import { showErrorComponent } from '../../../utilities/errorCode'
import { ErrorModal } from '../../newRFQ/modals/ErrorModal/ErrorModal'
import classNames from 'classnames'
import styles from './item.module.scss'

export const Item = ({
	selectedProductItem,
	itemPosition,
	handleItemFieldChange,
	deleteItem,
	measurementUnits,
	selectedSupplier,
	itemDataObject,
	clearUnit,
	validateItem,
	disabled,
	lengthItems
}: ItemRFQProps): JSX.Element => {
	const { t } = useTranslation()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const minDate = dayjs()
	const productMinLength = 3

	const [errorCode, setErrorCode] = useState('')
	const [product, setProduct] = useState<ProductRFQ | null>(null)
	const [productWasSelectedFromOption, setProductWasSelectedFromOption] = useState<boolean>(false)
	const [productInput, setProductInput] = useState('')
	const [productOptions, setProductOptions] = useState<Array<ProductRFQ>>([])
	const [productOptionsLoading, setProductOptionsLoading] = useState(false)
	const [amount, setAmount] = useState<string>(itemDataObject.amount)
	const [measureU, setMeasureU] = useState<MeasurementUnits>(itemDataObject.unit)
	const [measureInput, setMeasureInput] = useState<string>('')
	const [date, setDate] = useState<dayjs.Dayjs | null>(null)

	const debouncedProductInput = useDebounce<string>(productInput, 500)

	useEffect(() => {
		if (itemDataObject.unit) {
			setMeasureU(
				itemDataObject.unit.code
					? measurementUnits.find((mu) => mu.code === itemDataObject.unit.code)
					: undefined
			)
			setMeasureInput(itemDataObject.unit.code ? itemDataObject.unit.code : '')
		} else {
			setMeasureInput('')
		}
	}, [itemDataObject.unit])

	useEffect(() => {
		if (debouncedProductInput.length >= productMinLength) {
			setProductOptionsLoading(true)
			client
				.query({
					query: PRODUCT_NAME_CODE,
					variables: {
						searchText: debouncedProductInput.toLowerCase(),
						supplier: selectedSupplier.supplier_rfc
					}
				})
				.then((result: ApolloQueryResult<{ ProductsByNameOrCode: Array<ProductRFQ> }>) => {
					setProductOptions(result.data.ProductsByNameOrCode)
				})
				.catch((error) => {
					console.error(error)
					const errorCode = showErrorComponent(error)
					if (!expiredToken(errorCode)) {
						setErrorCode(errorCode)
					}
				})
				.finally(() => setProductOptionsLoading(false))
		} else {
			setProductOptions([])
		}
	}, [client, debouncedProductInput, selectedSupplier.supplier_rfc])

	useEffect(() => {
		if (!measureU && typeof itemDataObject.unit === 'string') {
			const tempCode: string = itemDataObject.unit
			setMeasureU(measurementUnits.find((mu) => mu.code === tempCode))
			setMeasureInput(itemDataObject.unit)
		} else if (!measureU && typeof itemDataObject.unit === 'object') {
			if (itemDataObject.unit && itemDataObject.unit.code) {
				setMeasureU(measurementUnits.find((mu) => mu.code === itemDataObject.unit.code))
				setMeasureInput(itemDataObject.unit.code)
			}
		}
		if ((!measureU && itemDataObject.unit === undefined) || '') {
			clearUnit(itemPosition)
		}
	}, [itemDataObject.unit, itemPosition, measureU])

	const removeItem = () => {
		deleteItem(parseInt(itemPosition))
		if (lengthItems === 1 && parseInt(itemPosition) === 0) {
			setProductInput('')
			setProduct(null)
			setAmount('')
			setMeasureU(undefined)
			setMeasureInput('')
			setDate(null)
			setProductWasSelectedFromOption(false)
		}
	}
	const getOptionLabel = (option: ProductRFQ) => {
		if (typeof option === 'object' && !option?.id) {
			return `${option.description}`
		} else if (typeof option === 'string') {
			return option
		} else {
			return `${option.id} - ${option.description}`
		}
	}
	const handleProductSelect = (product: ProductRFQ) => {
		if (!product) {
			setMeasureU(null)
			setMeasureInput('')
			selectedProductItem(product, itemPosition, product?.unit)
			setProductWasSelectedFromOption(false)
		} else if (typeof product == 'object') {
			setProduct(product)
			if (typeof product?.unit === 'string') {
				const tempCode: string = product?.unit
				setMeasureU(measurementUnits.find((mu) => mu.code === tempCode))
				handleUMChange(product?.unit)
			}
			if (typeof product?.unit === 'object') {
				setMeasureU(measurementUnits.find((mu) => mu.code === product?.unit.code))
				handleUMChange(product?.unit.code)
			}
			selectedProductItem(product, itemPosition, product?.unit)
			handleItemFieldChange({ product: product }, itemPosition, 'product')
			setProductWasSelectedFromOption(true)
		} else if (typeof product == 'string' && !productWasSelectedFromOption) {
			const tempProduct = {
				currency: 'MXN',
				description: product,
				supplier: {
					rfc: selectedSupplier.supplier_rfc
				},
				id: undefined,
				tax: 0.16,
				ean: undefined,
				unit: measureU ? measureU : null,
				unit_price: undefined
			}
			setProduct(tempProduct)
			selectedProductItem(product, itemPosition, measureU)
			handleItemFieldChange({ product: product }, itemPosition, 'product')
		}
	}
	const handleNetAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const amount = event.target.value
		setAmount(amount)
		handleItemFieldChange({ amount }, itemPosition, 'amount')
	}
	const getQuantityLabel = (option: MeasurementUnits) => {
		return option.code
	}
	const handleOnSelectMeasure = (measureU: MeasurementUnits) => {
		const measureSelected = measureU || undefined
		setMeasureU(measureSelected)
		selectedProductItem(product, itemPosition, measureSelected)
	}
	const isMeasureEqualToValue = (option: MeasurementUnits, value: string) => {
		return option.code === value
	}
	const isOptionEqualToProduct = (option: ProductRFQ, value: ProductRFQ) => {
		return option.id === value?.id
	}
	const renderMeasureOptions = (props, option: MeasurementUnits) => {
		return <li {...props}>{`${option.code} - ${option.description}`}</li>
	}
	const handleOnChangeDate = (date: Dayjs) => {
		setDate(date)
		handleItemFieldChange({ requiredDate: date }, itemPosition, 'requiredDate')
	}
	const handleUMChange = (value: string): void => {
		if (value !== measureInput && value !== '') {
			setMeasureInput(value)
		} else if (measureInput.length <= 1 && value !== measureInput) {
			setMeasureInput(value)
		}
	}

	return (
		<div className={styles.itemNewRfq}>
			<NetcurioTooltip title={t('positionText')}>
				<div className={classNames(styles.resizePositionRfq, styles.onlyReadFieldsRfq)}>
					{parseInt(itemPosition) + 1}
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={product?.description}>
				<div className={styles.resizeProductNewRfq}>
					<NetcurioAutocomplete
						height="smaller"
						placeholder={t('selectProductNewRFQ')}
						options={productOptions}
						getOptionLabel={getOptionLabel}
						isOptionEqualToValue={isOptionEqualToProduct}
						variant="outlined"
						value={product}
						onSelectValue={handleProductSelect}
						inputValue={productInput}
						onInputValueChange={setProductInput}
						loading={productOptionsLoading}
						minLength={productMinLength}
						disabled={disabled}
						error={!!validateItem.validateProduct}
						fullWidth
						freeSolo
						autoSelect
					/>
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={t('quantity')}>
				<div className={styles.quantity}>
					<NetcurioNumericField
						fullWidth
						size="small"
						placeholder={t('placeholderAmount')}
						value={amount}
						decimalScale={2}
						onChange={handleNetAmountChange}
						disabled={disabled}
						error={!!validateItem.validateAmount}
					/>
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={t('unitMeasureText')}>
				<div className={styles.resizeUmNewRfq}>
					<NetcurioAutocomplete
						variant="outlined"
						height="smaller"
						placeholder={t('UM')}
						options={measurementUnits}
						getOptionLabel={getQuantityLabel}
						value={measureU}
						inputValue={measureInput}
						onSelectValue={handleOnSelectMeasure}
						onInputValueChange={handleUMChange}
						isOptionEqualToValue={isMeasureEqualToValue}
						renderOption={renderMeasureOptions}
						disabled={disabled}
						error={!!validateItem.validateUnit}
						fullWidth
						forcePopupIcon={false}
					/>
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={t('requiredDeliveryDateText')}>
				<div className={styles.containerCalendarNewRfq}>
					<NetcurioDatePicker
						height="smaller"
						className={classNames(styles.calendarNewRfq, {
							[styles.calendarError]: !!validateItem?.validateRequiredDate
						})}
						label={t('requiredDateText')}
						format="DD/MM/YY"
						value={date}
						onChange={handleOnChangeDate}
						minDate={minDate}
						disabled={disabled}
					/>
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={t('delete')} placement={'top'}>
				<div>
					<NetcurioIconButton
						disabled={disabled}
						restingIcon={<NetcurioIcons.CancelOutlined />}
						hoverIcon={<NetcurioIcons.Cancel />}
						onClick={removeItem}
						className={disabled ? null : styles.colorRed}
					/>
				</div>
			</NetcurioTooltip>
			<ErrorModal open={!!errorCode} errorCode={errorCode} />
		</div>
	)
}
