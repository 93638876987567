export default {
	PO: 'PO',
	IN: 'IN',
	GR: 'GR',
	PC: 'PC',
	RFQ: 'RFQ',
	USERS: 'USERS',
	PRODUCTS: 'PRODUCTS',
	CONSUMPTIONS: 'CONSUMPTIONS',
	REQUEST_FOR_CREDIT_MEMOS: 'REQUEST_FOR_CREDIT_MEMOS',
	CREDIT_MEMOS: 'CREDIT_MEMOS',
	COMPANIES: 'COMPANIES',
	TICKETS: 'TICKETS',
	ENROLLMENTS: 'ENROLLMENTS'
}
