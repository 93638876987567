import React from 'react'

interface ConditionalRender {
	condition: boolean
	children: React.ReactNode
	fallback?: React.ReactNode
}

export const ConditionalRender: (props: ConditionalRender) => JSX.Element = ({
	condition,
	children,
	fallback = null
}) => {
	return <>{condition ? children : fallback}</>
}
