import { dateFormatter, parseTimestampToTimeZone, URLS } from '@netcurio/frontend-common'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Constants from '../../../../../utilities/constants'
import styles from '../../../adminConsole.module.scss'
import { RelatedCompanyRow } from './types'

export const TableRow: FC<RelatedCompanyRow> = ({
	rfc,
	name,
	created_at,
	clickable = false,
	customer_reference
}) => {
	return (
		<Link
			className={`${styles.containerColumnCompanyProductList} ${clickable ? styles.clickable : ''} ${
				styles[rfc]
			}`}
			to={
				clickable
					? `${URLS.ADMINISTRATION}/${Constants.ADMIN.ADMIN_TABS.RELATED_COMPANIES_TAB}/${rfc}`
					: '#'
			}
		>
			<p className={`${styles.textColumnMainAdminCompanyList} ${styles.companyName}`}>{name}</p>
			<p className={`${styles.textColumnMainAdminCompanyList} ${styles.companyRfc}`}>{rfc}</p>
			<p className={`${styles.textColumnMainAdminCompanyList} ${styles.companyRfc}`}>
				{customer_reference ?? '-'}
			</p>
			<p className={`${styles.textColumnMainAdminCompanyList} ${styles.companyDays}`}>
				{dateFormatter.format(parseTimestampToTimeZone(created_at))}
			</p>
		</Link>
	)
}
