import gql from 'graphql-tag'

export const GENERATE_RESET_PASSWORD_TOKEN = gql`
	mutation generateResetPasswordToken($email: ID!) {
		generateResetPasswordToken(email: $email)
	}
`

export const USER_BY_RECOVERY_PASSWORD_TOKEN = gql`
	query UserByRecoveryPasswordToken($recovery_password_token: ID!) {
		UserByRecoveryPasswordToken(recovery_password_token: $recovery_password_token) {
			email
		}
	}
`

export const INVALIDATE_RESET_PASSWORD_TOKEN = gql`
	mutation invalidateResetPasswordToken($email: ID!, $recovery_password_token: ID!) {
		invalidateResetPasswordToken(email: $email, recovery_password_token: $recovery_password_token) {
			code
			message
		}
	}
`
