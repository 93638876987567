import {
	NetcurioAlert,
	NetcurioGrid,
	NetcurioTextField,
	SectionData,
	Severity
} from '@netcurio/frontend-components'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './RegisterCompanyModal.module.scss'
import { ShowRegisterCompanyContext } from './ShowRegisterCompanyContext'

export const RegisterCompanyForm = () => {
	const { t } = useTranslation()
	const { companyForm, errorsForm, setcompanyForm, serverErrorMessage } =
		useContext(ShowRegisterCompanyContext)

	return (
		<div>
			{serverErrorMessage.code !== 'RFC_VALIDATION' && serverErrorMessage.message && (
				<NetcurioAlert severity={Severity.Error}>{t(serverErrorMessage.message)}</NetcurioAlert>
			)}
			<SectionData title={'companyGeneralDataTitle'}>
				<NetcurioGrid container alignContent="flex-start" justifyContent="flex-start">
					<NetcurioGrid item xs={6} className={classNames([styles.alignmentCompanyModal])}>
						<NetcurioTextField
							size="small"
							fullWidth
							variant="outlined"
							label={t('rfcNewCompany')}
							placeholder={t('rfcPlcNewCompany')}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setcompanyForm({ ...companyForm, rfc: e.target.value })
							}
							error={
								serverErrorMessage.code === 'RFC_VALIDATION'
									? true
									: errorsForm['rfcError']['isValid']
							}
							helperText={
								errorsForm['rfcError']['isValid']
									? t(errorsForm['rfcError']['message'])
									: serverErrorMessage.code === 'RFC_VALIDATION' &&
										serverErrorMessage.message
							}
							maxLength={13}
							minLength={12}
						/>
					</NetcurioGrid>
					<NetcurioGrid item xs={6} className={classNames([styles.alignmentCompanyModal])}>
						<NetcurioTextField
							size="small"
							fullWidth
							variant="outlined"
							label={t('nameNewCompany')}
							placeholder={t('namePlcNewCompany')}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setcompanyForm({ ...companyForm, companyName: e.target.value })
							}
							error={errorsForm['companyNameError']['isValid']}
							helperText={
								errorsForm['companyNameError']['isValid'] &&
								t(errorsForm['companyNameError']['message'])
							}
						/>
					</NetcurioGrid>
					<NetcurioGrid item xs={12} className={classNames([styles.alignmentCompanyModal])}>
						<NetcurioTextField
							size="small"
							fullWidth
							variant="outlined"
							label={t('addressInputNewCompany')}
							placeholder={t('addressPlcNewCompany')}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setcompanyForm({ ...companyForm, address: e.target.value })
							}
							error={errorsForm['addressError']['isValid']}
							helperText={
								errorsForm['addressError']['isValid'] &&
								t(errorsForm['addressError']['message'])
							}
						/>
					</NetcurioGrid>
					<NetcurioGrid item xs={6} className={classNames([styles.alignmentCompanyModal])}>
						<NetcurioTextField
							size="small"
							fullWidth
							variant="outlined"
							label={t('tokenNewCompany')}
							placeholder={t('tokenPlcNewCompany')}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setcompanyForm({ ...companyForm, tokenState: e.target.value })
							}
							error={errorsForm['tokenStateError']['isValid']}
							helperText={
								errorsForm['tokenStateError']['isValid'] &&
								t(errorsForm['tokenStateError']['message'])
							}
							maxLength={3}
						/>
					</NetcurioGrid>
					<NetcurioGrid item xs={6} className={classNames([styles.alignmentCompanyModal])}>
						<NetcurioTextField
							size="small"
							fullWidth
							variant="outlined"
							label={t('zipcodeNewCompany')}
							placeholder={t('zipcodePlcNewCompany')}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setcompanyForm({ ...companyForm, zipCode: e.target.value })
							}
							error={errorsForm['zipCodeError']['isValid']}
							helperText={
								errorsForm['zipCodeError']['isValid'] &&
								t(errorsForm['zipCodeError']['message'])
							}
							maxLength={5}
						/>
					</NetcurioGrid>
					<NetcurioGrid item xs={6} className={classNames([styles.alignmentCompanyModal])}>
						<NetcurioTextField
							size="small"
							fullWidth
							variant="outlined"
							label={t('industryNewCompany')}
							placeholder={t('industryPlcNewCompany')}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setcompanyForm({ ...companyForm, industry: e.target.value })
							}
							error={errorsForm['industryError']['isValid']}
							helperText={
								errorsForm['industryError']['isValid'] &&
								t(errorsForm['industryError']['message'])
							}
						/>
					</NetcurioGrid>
					<NetcurioGrid item xs={6} className={classNames([styles.alignmentCompanyModal])}>
						<NetcurioTextField
							disabled
							fullWidth
							variant="outlined"
							size="small"
							label={t('creationDateNewCompany')}
							value={companyForm.creationDate}
						/>
					</NetcurioGrid>
				</NetcurioGrid>
				<NetcurioGrid container alignContent="flex-start" justifyContent="flex-start"></NetcurioGrid>
			</SectionData>

			<SectionData title={'userGeneralDataTitle'}>
				<NetcurioGrid container alignContent="flex-start" justifyContent="flex-start">
					<NetcurioGrid item xs={6} className={classNames([styles.alignmentCompanyModal])}>
						<NetcurioTextField
							size="small"
							fullWidth
							variant="outlined"
							label={t('userNameNewCompany')}
							placeholder={t('userNamePlcNewCompany')}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setcompanyForm({ ...companyForm, userFirstName: e.target.value })
							}
							error={errorsForm['userFirstNameError']['isValid']}
							helperText={
								errorsForm['userFirstNameError']['isValid'] &&
								t(errorsForm['userFirstNameError']['message'])
							}
						/>
					</NetcurioGrid>
					<NetcurioGrid item xs={6} className={classNames([styles.alignmentCompanyModal])}>
						<NetcurioTextField
							size="small"
							fullWidth
							variant="outlined"
							label={t('userLastNewCompany')}
							placeholder={t('userLastPlcNewCompany')}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setcompanyForm({ ...companyForm, userLastName: e.target.value })
							}
							error={errorsForm['userLastNameError']['isValid']}
							helperText={
								errorsForm['userLastNameError']['isValid'] &&
								t(errorsForm['userLastNameError']['message'])
							}
						/>
					</NetcurioGrid>
					<NetcurioGrid item xs={6} className={classNames([styles.alignmentCompanyModal])}>
						<NetcurioTextField
							size="small"
							label={t('emailNewCompany')}
							placeholder={t('emailPlcNewCompany')}
							fullWidth
							variant="outlined"
							type="email"
							onChange={(e) => setcompanyForm({ ...companyForm, email: e.target.value })}
							error={errorsForm['emailError']['isValid']}
							helperText={
								errorsForm['emailError']['isValid'] && t(errorsForm['emailError']['message'])
							}
						/>
					</NetcurioGrid>
				</NetcurioGrid>
			</SectionData>
		</div>
	)
}
