import {
	FileResult,
	FileUpload,
	NetcurioButton,
	NetcurioDialog,
	PairFilesIcon,
	ProcessFileUpload,
	Severity,
	ValidationFiles
} from '@netcurio/frontend-components'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface AttachDocumentsModalProps {
	open: boolean
	close(): void
	uuid: string
	getInvoiceInfo(): void
}
interface UploadedLinkedFile {
	file: { description: string; name: string; url: string }
	uuid: string
	errorMessage?: number
}

export const AttachDocumentsModal = ({ open, close, uuid, getInvoiceInfo }: AttachDocumentsModalProps) => {
	const { SELECT, UPLOADING, FINISHED } = ProcessFileUpload
	const { Info, Success } = Severity
	const options = {
		0: { icon: PairFilesIcon.Success, errorText: '' },
		1: { icon: PairFilesIcon.Warning, errorText: 'No se pudo cargar el archivo' },
		2: { icon: PairFilesIcon.Warning, errorText: 'No se pudo adjuntar el archivo' }
	}
	const { t } = useTranslation()

	const [stopUpload, setStopUpload] = useState(false)
	const [canUpload, setCanUpload] = useState(false)
	const [process, setProcess] = useState<ProcessFileUpload>(SELECT)
	const [filesToUpload, setFilesToUpload] = useState<Array<File>>([])
	const [filesResult, setFilesResult] = useState<Array<FileResult>>([])
	const [hideUploadedFiles, setHideUploadedFiles] = useState(false)

	useEffect(() => {
		return () => {
			setProcess(SELECT)
			setFilesToUpload([])
			setStopUpload(false)
		}
	}, [open, SELECT])

	useEffect(() => {
		let controller: AbortController
		const fetchData = async () => {
			const data: UploadedLinkedFile[] | void = await attachDocument()
			if (!stopUpload) {
				if (data) {
					setFilesResult((prevFilesResult) =>
						prevFilesResult.map((file) => {
							const item = data.find((entry) => entry.file.description === file.files[0].name)
							const resultMessageAccessor = item.file.name ? 0 : item.errorMessage
							return {
								...file,
								...options[resultMessageAccessor],
								isLoading: false
							}
						})
					)
					getInvoiceInfo()
					setHideUploadedFiles(true)
					setProcess(FINISHED)
				}
			}
		}
		if (process === UPLOADING) {
			setHideUploadedFiles(false)
			controller = new AbortController()
			fetchData().catch((err) => {
				throw err
			})
		}
		return () => {
			controller?.abort()
		}
	}, [process, stopUpload, UPLOADING, FINISHED])

	useEffect(() => {
		if (process === UPLOADING) {
			setCanUpload(false)
			const filesResult = filesToUpload.map((file) => ({ files: [file], isLoading: true }))
			setFilesResult(filesResult)
		}
	}, [process, filesToUpload, UPLOADING])

	const filesUploaded = (filesResult: Array<FileResult>): ValidationFiles => {
		const total = filesResult.length
		const updated = filesResult.filter((file) => file.icon === PairFilesIcon.Success).length
		return {
			severity: total === updated ? Success : Info,
			message: t('totalUploadedDocuments', {
				uploadedDocuments: updated,
				documents: total
			})
		}
	}

	const getAuthToken = async () => {
		const user = await Auth.currentAuthenticatedUser()
		return user.signInUserSession.idToken.jwtToken
	}

	const validateFilesResult = [filesUploaded]

	const processData = () => setProcess(UPLOADING)

	const updateFilesToUpload = (fileList: FileList) =>
		setFilesToUpload((state) => {
			for (const file of Array.from(fileList)) {
				const noDuplicateFile = state.findIndex((f) => f.name === file.name) === -1
				if (noDuplicateFile) state.push(file)
			}

			state.sort((fileA, fileB) => {
				if (fileA.name > fileB.name) return 1
				if (fileA.name < fileB.name) return -1
				return 0
			})

			return [...state]
		})

	const deleteFile = (index: React.Key) => setFilesToUpload((state) => state.filter((_, i) => i !== index))

	const attachDocument = async (): Promise<UploadedLinkedFile[] | void> => {
		const formData = new FormData()
		filesToUpload.forEach((file) => {
			formData.append('file', file, file.name)
		})

		const token = await getAuthToken()

		const headers = {
			Accept: 'application/json',
			Authorization: 'Bearer ' + token
		}

		const response = await fetch('/api/v1/files', {
			method: 'POST',
			headers,
			body: formData
		})

		if (response.ok) {
			const uploadedFiles = await response.json()
			const results = await linkUploadedFiles(uploadedFiles.files)
			return results
		} else {
			const results = []
			filesToUpload.forEach((file) => {
				results.push({
					file,
					errorMessage: 1
				})
			})
			return results
		}
	}

	const linkUploadedFiles = async (uploadedFiles): Promise<UploadedLinkedFile[] | void> => {
		const token = await getAuthToken()
		const headers = {
			'Content-Type': 'application/json; charset=UTF-8',
			Accept: 'application/json',
			Authorization: 'Bearer ' + token
		}
		const results = []

		for (const file of uploadedFiles) {
			const response = await fetch(`api/v1/invoices/${uuid}/files`, {
				method: 'POST',
				headers,
				body: JSON.stringify(file)
			})

			if (response.ok) {
				const parsedData = await response.json()
				results.push(parsedData)
			} else {
				results.push({
					file: { description: file.description },
					errorMessage: 2
				})
			}
		}
		return results
	}
	const closeModal = () => {
		setStopUpload(true)
		setFilesToUpload([])
		setProcess(SELECT)
		close()
	}

	return (
		<NetcurioDialog
			open={open}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={closeModal}>
						{t('cancelButton')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={processData} disabled={!canUpload}>
						{t('attach')}
					</NetcurioButton>
				</>
			}
			titleText={t('attachDocumentsTitle')}
			minWidth={'96rem'}
			maxWidth={'96rem'}
		>
			<FileUpload
				fileUploadDescription={t('attachToInvoiceMessages')}
				waitUploadFilesText={t('documentsBeingProcessed')}
				acceptMultipleFiles={true}
				process={process}
				filesToUpload={filesToUpload}
				filesResult={filesResult}
				validateFilesResult={validateFilesResult}
				setCanUpload={setCanUpload}
				updateFilesToUpload={updateFilesToUpload}
				deleteFile={deleteFile}
				hideUploadedFiles={hideUploadedFiles}
			/>
		</NetcurioDialog>
	)
}
