import { Roles } from '@netcurio/frontend-common'
import { FileViewerModal, useNetcurioLoader } from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useEnvironment } from '../../../../../hooks/useEnvironment'
import { ErrorWrapper, FileViewerObject } from '../../../../../types'
import { connection } from '../../../../../utilities/connection'
import Constants from '../../../../../utilities/constants'
import { showErrorComponent } from '../../../../../utilities/errorCode'
import { expiredToken } from '../../../../../utilities/expiredToken'
import UserInformation from '../../../../../utilities/userInformation'
import { ErrorModal } from '../Modals/ErrorModal/ErrorModal'
import CompanyFiles from './companyFiles'
import { CustomersSuppliers } from './queries'
import styles from './relatedCompanyDetail.module.scss'
import TrackInformation from './trackInformation'

export interface RelatedCompanyInformation {
	payment_days: number
	name: string
	rfc: string
	address_line_1?: string
	country_code?: string
	state_code?: string
	postal_code?: string
	industry_description?: string
	created_at: string
}

export interface FileInformation {
	[index: string]: {
		column: string
		uuid?: string
	}
}

export default function RelatedCompanyDetail(): JSX.Element {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const { environment } = useEnvironment()
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }

	const { COMPANIES_FOLDER } = Constants.FOLDER
	const { t } = useTranslation()
	const { relatedCompany } = useParams() as { relatedCompany: string }
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const userRole = UserInformation.getCompanyRole()
	let isMounted = true

	const [companyInformation, setCompanyInformation] = useState<RelatedCompanyInformation | undefined>()
	const [errorMessage, setErrorMessage] = useState<ErrorWrapper | undefined>()
	const [files, setFiles] = useState<FileInformation>({
		charter: { column: 'INCORPORATION_ACT' },
		registration: { column: 'RFC_REGISTRATION' },
		attorney: { column: 'ATTORNEY' },
		compliance: { column: 'COMPLIANCE' },
		ine: { column: 'ID_CARD' },
		address: { column: 'PROOF_ADDRESS' },
		account: { column: 'BANK_STATEMENT' },
		services: { column: 'SPECIALTY_SERVICES' },
		bankForm: { column: 'SIGNED_BANK_DATA_FORM' },
		supplierDataFormat: { column: 'SUPPLIER_DATA_FORMAT' },
		priceList: { column: 'QUOTATION_PRICE_LIST' },
		operationNotice: { column: 'OPERATION_NOTICE' },
		cofeprisHealthRecords: { column: 'COFEPRIS_HEALTH_RECORDS' },
		authorizedDistributionLetter: { column: 'AUTHORIZED_DISTRIBUTION_LETTER' },
		healthLicenseControlledMedicine: { column: 'HEALTH_LICENSE_CONTROLLED_MEDICINE' },
		noticeFromHealthManager: { column: 'NOTICE_HEALTH_MANAGER' },
		other: { column: 'OTHER' }
	})
	const [fileViewer, setFileViewer] = useState<FileViewerObject>({
		open: false,
		titleText: t('documentText'),
		fileSrc: ''
	})

	useEffect(() => {
		getRelatedCompany()
		return () => {
			isMounted = false
		}
	}, [])

	function getRelatedCompany() {
		showLoadingSpinner()

		client
			.query({
				query: CustomersSuppliers,
				variables: {
					filter: [
						{
							column_name: userRole === Roles.CUSTOMER ? 'supplier_rfc' : 'customer_rfc',
							type: 'wildcard',
							value: relatedCompany
						}
					]
				}
			})
			.then((result) => {
				if (isMounted) {
					const data = result.data.CustomersSuppliers.list[0]
					if (!data) {
						hideLoadingSpinner()
						return setErrorMessage({
							message: 'relatedCompanyNotFound'
						})
					}
					setCompanyInformation({
						payment_days: data.payment_days,
						rfc: relatedCompany,
						name: data.company.name,
						address_line_1: data.company.address_line_1,
						country_code: data.company.country_code,
						state_code: data.company.state_code,
						postal_code: data.company.postal_code,
						industry_description: data.company.industry_description,
						created_at: data.company.created_at
					})

					const newFilesState = { ...files }
					for (const key in newFilesState) {
						const document = data.company.supportingDocuments.filter(
							(doc: any) => doc.document_type === newFilesState[key].column
						)[0]
						if (document) newFilesState[key].uuid = document.link
					}
					setFiles(newFilesState)
					hideLoadingSpinner()
				}
			})
			.catch((error) => {
				if (isMounted) {
					console.error(error)
					const errorCode = showErrorComponent(error)
					if (!expiredToken(errorCode)) {
						setErrorMessage({
							code: showErrorComponent(error),
							message: error.message
						})
					}
					hideLoadingSpinner()
				}
			})
	}

	function setFile(uuid: string) {
		const fileName = `${uuid.toLowerCase()}.pdf`
		const fileSrc = `${S3_BUCKET}${COMPANIES_FOLDER}/${fileName}`
		setFileViewer((state) => ({ ...state, fileSrc, open: true }))
	}

	const closePDFModal = () => setFileViewer((state) => ({ ...state, open: false }))

	return (
		<div className={styles.relatedCompanyTopCornersRadius}>
			<div className={styles.fixSpaceTopHeaderEnrollment} />
			<div className={styles.relatedCompanyDetailMainContainer}>
				<div className={styles.displayFlex}>
					<p className={styles.titleHeaderRelatedCompanyDetail}>
						{`${t(userRole === Roles.CUSTOMER ? 'supplier_PO' : 'customer_text')} ${
							companyInformation?.name || ''
						}`}
					</p>
				</div>
			</div>
			<div className={`${styles.containerTrackRelatedCompanyDetail} ${styles.grayColorText}`}>
				<TrackInformation companyInformation={companyInformation} />
			</div>
			<div className={styles.fixSpaceBackgroundGray} />
			<CompanyFiles filesInformation={files} setUUIDFile={setFile} />
			<FileViewerModal onClose={closePDFModal} {...fileViewer} />
			<ErrorModal
				open={!!errorMessage?.code}
				errorCode={errorMessage?.code}
				errorGraphQlMessage={errorMessage?.message}
			/>
		</div>
	)
}
