import { NetcurioButton, NetcurioDialog, Severity } from '@netcurio/frontend-components'
import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory } from 'react-router-dom'
import { acceptActionErrorModal, getErrorCodeModal } from '../../../utilities/getErrorCodeModal'

interface CatchedErrorModalNewEnrollmentProps {
	open: boolean
	errorCode: string
	errorGraphQlMessage?: string
	closeModalNoPriority?(): void
}

export const CatchedErrorModalNewEnrollment: FC<CatchedErrorModalNewEnrollmentProps> = ({
	open,
	errorCode,
	errorGraphQlMessage,
	closeModalNoPriority
}): ReactElement => {
	const { t } = useTranslation()
	const history: RouterChildContext['router']['history'] = useHistory()
	const { title, message, idCode, isUserBlocked } = getErrorCodeModal({ errorCode })
	const hasPriorityErrorCode: boolean = [
		'UNEXPECTED_ERROR',
		'NO_DATA_FOUND',
		'INVALID_TOKEN',
		'ERROR',
		'NETWORK_ERROR',
		'not authenticated'
	].includes(errorCode)

	return (
		<NetcurioDialog
			open={open}
			titleText={title}
			headerTitleSeverity={Severity.Error}
			actionButtons={
				<NetcurioButton
					variant="contained"
					onClick={() => {
						if (!isUserBlocked) {
							if (hasPriorityErrorCode) {
								history.goBack()
							} else {
								closeModalNoPriority()
							}
						} else {
							acceptActionErrorModal(history)
						}
					}}
				>
					{t('acceptTextButton')}
				</NetcurioButton>
			}
		>
			<p>
				{isUserBlocked || hasPriorityErrorCode
					? t(message, { idCode: idCode ?? ' ' })
					: errorGraphQlMessage}
			</p>
		</NetcurioDialog>
	)
}
