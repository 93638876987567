import classNames from 'classnames'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioTooltip } from '../NetcurioTooltip/NetcurioTooltip'
import styles from './TotalContainerBar.module.scss'

type fields = unknown
export interface TotalConatinerBarProps<T extends fields> {
	title: string
	fields: T
	total?: T
	isBgDark?: boolean
	showTotalLine?: boolean
}
/**
 * TotalContainerBar
 * @param title <string>: The translation to set in table's header.
 * @param fields <object>: This object will be mapped, the key will be used on the left side like translation and the value will be set on the right side.
 * @param total <object>: Like in fields this will map, the key will be used on the left side like translation and the value will be set on the right side.
 * @param isBgDark <boolean>: In case the Background of the title sections is needed in a darker accent color
 * @param showTotalLine <boolean>: In case the total line isn't needed
 * @returns component
 */
export function TotalContainerBar<T extends fields>({
	title,
	fields,
	total,
	isBgDark = false,
	showTotalLine = true
}: TotalConatinerBarProps<T>): ReactElement {
	const { t } = useTranslation()
	return (
		<div className={styles.mainContainer}>
			<div
				className={classNames(styles.header, {
					[styles.darkBg]: isBgDark
				})}
			>
				<p className={styles.headerTotalContainerBar}>{t(title)}</p>
			</div>
			{Object.keys(fields).map((key, index) => {
				if (
					fields[key] === 0 ||
					(typeof fields[key] === 'string' &&
						fields[key] == '$0.00' &&
						key !== 'pendingAmortization')
				) {
					return <div key={index}></div>
				} else {
					return (
						<div className={styles.containerFields} key={index}>
							<p className={styles.fieldKey}>{t(key)}</p>
							{key === 'pendingAmortization' && fields[key] === '-' ? (
								<NetcurioTooltip title={t('invoices.noAdvancements')} placement={'top'}>
									<p className={styles.fieldValue}>{fields[key]}</p>
								</NetcurioTooltip>
							) : (
								<p className={styles.fieldValue}>{fields[key]}</p>
							)}
						</div>
					)
				}
			})}
			{total
				? Object.keys(total).map((key, index) => {
						return (
							<div
								className={classNames(styles.containerFields, {
									[styles.borderTop]: showTotalLine
								})}
								key={index}
							>
								<p className={styles.fieldKey}>{t(key)}</p>
								<p className={styles.fieldValue}>{total[key]}</p>
							</div>
						)
					})
				: null}
		</div>
	)
}
