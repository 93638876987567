import gql from 'graphql-tag'

export const AUTHORIZE_INVOICE_MANUALLY = gql`
	mutation authorizeInvoiceManually(
		$uuid: ID!
		$override_message: String!
		$cost_center_key: String
		$expense_type_key: String
		$expense_subtype_key: String
		$bank_reference: String
		$payment_date: Date
	) {
		authorizeInvoiceManually(
			uuid: $uuid
			override_message: $override_message
			cost_center_key: $cost_center_key
			expense_type_key: $expense_type_key
			expense_subtype_key: $expense_subtype_key
			bank_reference: $bank_reference
			payment_date: $payment_date
		) {
			code
			message
		}
	}
`
