import React, { ReactElement } from 'react'
import { t } from 'i18next'
import { parseTimestampToTimeZone, dateFormatter, Status } from '@netcurio/frontend-common'
import { PurchaseOrderItem } from '../../../../types'
import Constants from '../../../../utilities/constants'
import Formatter from '../../../../utilities/formatter'
import classNames from 'classnames'
import styles from './pageManager.module.scss'

interface TableRowProps {
	item: PurchaseOrderItem
	currency: string
}
export const TableRow = ({ item, currency }: TableRowProps): ReactElement => {
	const confirmedAmount = () => {
		const { status, confirmed_amount, unit } = item
		if (
			(status as Status).key === Constants.STATUS.REJECTED ||
			(status as Status).key === Constants.STATUS.NEW
		) {
			const statusConfirmedAmount =
				(status as Status).key === Constants.STATUS.REJECTED
					? Constants.STATUS.REJECTED
					: 'pendingText'
			return (
				<div className={styles.bgGrayPoPdfRow}>
					<p>{t(statusConfirmedAmount)}</p>
				</div>
			)
		} else {
			return (
				<div className={styles.bgGrayPoPdfRow}>
					<p>
						{confirmed_amount} {unit}
					</p>
				</div>
			)
		}
	}
	const {
		position,
		code,
		name,
		requested_amount,
		unit,
		required_delivery_date,
		delivery_proposal_date,
		tax,
		unit_price,
		net_value,
		storage_location
	} = item
	const netValue = net_value ? Formatter.currency.format(net_value) : Constants.SYMBOL.HYPHEN,
		storageLocation = storage_location ? storage_location : 'NAtext'
	return (
		<div className={styles.poPdfRow}>
			<p className={styles.positionHeader}>{position}</p>
			<div
				className={classNames(
					styles.descriptionHeader,
					styles.bgGrayPoPdfRow,
					styles.poPdfDescriptionRow
				)}
			>
				<p className={styles.poPdfCodeRow}>
					<span className={styles.poPdfCodeTextRow}>{`${t('codePoPdf')} `}</span>
					{code}
				</p>
				<p className={styles.poPdfNameRow}>{name}</p>
			</div>
			<div className={styles.storageLocationHeader}>
				<p>{t(storageLocation)}</p>
			</div>
			<div className={classNames(styles.poPdfQuantitiesHeader, styles.bgGrayPoPdfRow)}>
				<p className={styles.poPdfCodeTextRow}>{t('quantityRequestedPoPdf')}</p>
				<p>
					{requested_amount} {unit}
				</p>
				<p className={classNames(styles.poPdfCodeTextRow, styles.poPdfQuantityConfirmedRow)}>
					{t('quantityConfirmedPoPdf')}
				</p>
				{confirmedAmount()}
			</div>
			<div className={styles.requiredDateHeader}>
				<p>{dateFormatter.format(parseTimestampToTimeZone(required_delivery_date))}</p>
			</div>
			<div className={classNames(styles.deliveredDateHeader, styles.bgGrayPoPdfRow)}>
				<p>{dateFormatter.format(parseTimestampToTimeZone(delivery_proposal_date))}</p>
			</div>
			<p className={styles.ivaHeader}>{Formatter.percent.format(tax)}</p>
			<p className={classNames(styles.unitPriceRow, styles.bgGrayPoPdfRow)}>
				{Formatter.currency.format(unit_price)}
			</p>
			<p className={styles.netTotalRow}>{netValue + ' ' + Formatter.nullValueFormatting(currency)}</p>
		</div>
	)
}
