import { Theme, ThemeOptions } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
	ColorBaseWhite,
	MdRefTypefaceFont,
	MdSysLabelMediumSize,
	NetcurioRefActionCDisabled,
	NetcurioRefErrorAMain,
	NetcurioRefPrimaryBDark,
	NetcurioRefPrimaryCLight,
	NetcurioRefWarningCLight
} from '@netcurio/frontend-design-tokens'
import { Dayjs } from 'dayjs'
import 'dayjs/locale/es'
import { useEffect, useState } from 'react'

const heights = {
	smaller: '3.219rem',
	small: '4rem',
	medium: '5.6rem'
}

export const NetcurioDatePickerThemeOptions: ThemeOptions = {
	components: {
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					fontSize: '2.4rem'
				}
			}
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					'&.MuiPickersDay-root': {
						fontSize: '1.2rem'
					}
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				formControl: {
					fontSize: MdSysLabelMediumSize,
					backgroundColor: ColorBaseWhite,
					borderColor: NetcurioRefPrimaryCLight
				},
				input: {
					fontFamily: MdRefTypefaceFont,
					fontSize: MdSysLabelMediumSize,
					borderColor: NetcurioRefPrimaryCLight
				}
			}
		}
	}
}

interface NetcurioDatePickerProps {
	label?: string
	value?: Dayjs
	onChange?: (date: Dayjs, context?: { validationError: string | null }) => void
	onAccept?: (date: Dayjs) => void
	shouldRespectLeadingZeros?: boolean
	format?: string
	sx?: SxProps<Theme>
	className?: string
	minDate?: Dayjs
	maxDate?: Dayjs
	defaultValue?: Dayjs
	height?: 'small' | 'medium' | 'smaller'
	disabled?: boolean
	error?: boolean
	warning?: boolean
	fullWidth?: boolean
	closeOnSelect?: boolean
	formatDensity?: 'dense' | 'spacious'
}

/**
 * NetcurioDatePicker
 * @param label <string> The label content.
 * @param value <Dayjs> The selected value. Used when the component is controlled.
 * @param onChange <(date: Dayjs) => void> Callback fired when the value changes.
 * @param onAccept <(date: Dayjs) => void> Callback fired when the value is accepted.
 * @param shouldRespectLeadingZeros <boolean> When shouldRespectLeadingZeros={true}, the field will add an invisible character on the sections containing a single digit to make sure onChange is fired.
 * @param format <string> Format of the date when rendered in the input(s). Defaults to localized format based on the used views.
 * @param sx <SxProps<Theme>> The system prop that allows defining system overrides as well as additional CSS styles.
 * @param className <string> Class name applied to the root element.
 * @param minDate <Dayjs> Minimal selectable date.
 * @param maxDate <Dayjs> Maximal selectable date.
 * @param defaultValue <Dayjs> The default value. Used when the component is not controlled.
 * @param height <'smaller' | 'small' | 'medium'>: The height of the component.
 * @param error
 @returns component
 */
export const NetcurioDatePicker = ({
	height = 'smaller',
	fullWidth = false,
	error,
	warning,
	...rest
}: NetcurioDatePickerProps) => {
	const [sxValue, setSxValue] = useState<SxProps<Theme>>({})

	useEffect(() => {
		if (error) {
			setSxValue({
				width: fullWidth ? '100% !important' : 'auto',
				'& .MuiInputLabel-root': {
					height: heights[height] ? heights[height] : '',
					color: NetcurioRefErrorAMain
				},
				'& .MuiOutlinedInput-root': {
					height: heights[height] ? heights[height] : '',
					'& fieldset': {
						borderColor: NetcurioRefErrorAMain
					},
					'&.Mui-error:after': {
						borderBottomColor: NetcurioRefErrorAMain
					}
				},
				/* Labels Section */
				'& .MuiFormLabel-root': {
					fontSize: '1.4rem',
					zIndex: 1,
					position: 'absolute',
					marginTop: '-0.6rem'
				},
				'& .MuiInputLabel-outlined': {
					top: '-0.2rem'
				},
				'& .MuiInputLabel-standard': {
					top: '0.2rem'
				},
				'& .MuiInputLabel-filled': {
					top: '-1.2rem'
				},
				/* Shrinked Animated Label Section */
				'& .MuiInputLabel-shrink': {
					position: 'absolute',
					marginTop: '0.4rem',
					marginLeft: '0.2rem'
				}
			})
		} else if (warning) {
			setSxValue({
				width: fullWidth ? '100% !important' : 'auto',
				'& .MuiInputLabel-root': {
					height: heights[height] ? heights[height] : '',
					color: NetcurioRefWarningCLight
				},
				'& .MuiOutlinedInput-root': {
					height: heights[height] ? heights[height] : '',
					'& fieldset': {
						borderColor: NetcurioRefWarningCLight
					},
					'&.Mui-error:after': {
						borderBottomColor: NetcurioRefWarningCLight
					}
				},
				/* Labels Section */
				'& .MuiFormLabel-root': {
					fontSize: '1.4rem',
					zIndex: 1,
					position: 'absolute',
					marginTop: '-0.6rem'
				},
				'& .MuiInputLabel-outlined': {
					top: '-0.2rem'
				},
				'& .MuiInputLabel-standard': {
					top: '0.2rem'
				},
				'& .MuiInputLabel-filled': {
					top: '-1.2rem'
				},
				/* Shrinked Animated Label Section */
				'& .MuiInputLabel-shrink': {
					position: 'absolute',
					marginTop: '0.4rem',
					marginLeft: '0.2rem'
				}
			})
		} else {
			setSxValue({
				width: fullWidth ? '100% !important' : 'auto',
				'& .MuiInputBase-root': {
					height: heights[height] ? heights[height] : '',
					borderColor: NetcurioRefPrimaryCLight
				},
				'& .MuiTextField-root': {
					height: heights[height] ? heights[height] : ''
				},
				'& .MuiAutocomplete-input': {
					position: 'absolute',
					width: '90% !important',
					top: 0
				},
				'& .MuiInputBase-input': {
					maxHeight: heights[height] ? heights[height] : ''
				},
				'& .MuiOutlinedInput-root': {
					height: heights[height] ? heights[height] : '',
					'& fieldset': {
						paddingLeft: '1.2rem',
						paddingRight: '1.2rem',
						borderColor: NetcurioRefPrimaryCLight
					},
					'&:hover fieldset': {
						borderColor: NetcurioRefPrimaryBDark
					}
				},
				'& .MuiStandardInput-root': {
					height: heights[height] ? heights[height] : '',
					'& fieldset': {
						paddingLeft: '1.2rem',
						paddingRight: '1.2rem'
					}
				},
				'& .MuiFilledInput-root': {
					height: heights[height] ? heights[height] : '',
					borderBottom: '1px solid transparent',
					'& fieldset': {
						paddingLeft: '1.2rem',
						paddingRight: '1.2rem'
					}
				},
				/* Labels Section */
				'& .MuiFormLabel-root': {
					zIndex: 1,
					position: 'absolute',
					fontSize: '1.4rem',
					marginTop: '-0.6rem',
					maxWidth: '85%'
				},
				'& .MuiInputLabel-outlined': {
					top: '-0.2rem'
				},
				'& .MuiInputLabel-standard': {
					top: '0.2rem'
				},
				'& .MuiInputLabel-filled': {
					top: '-1.2rem'
				},
				/* Shrinked Animated Label Section */
				'& .MuiInputLabel-shrink': {
					position: 'absolute',
					marginTop: '0.4rem',
					marginLeft: '0.2rem'
				},
				/* Disabled Inputs Section */
				'& .MuiInputBase-input.Mui-disabled': {
					WebkitTextFillColor: NetcurioRefActionCDisabled,
					borderColor: NetcurioRefActionCDisabled
				},
				'& .MuiOutlinedInput-root.Mui-disabled': {
					'& fieldset': {
						borderColor: NetcurioRefActionCDisabled
					}
				},
				/* Adornment */
				'& .MuiAutocomplete-endAdornment': {
					zIndex: 2,
					position: 'absolute !important',
					right: 0,
					top: 'calc(50% - 1.2rem)'
				},
				'& .MuiCircularProgress-root': {
					position: 'absolute !important',
					right: '2.6rem',
					top: 'calc(50% - 1rem)'
				}
			})
		}
	}, [error])

	return (
		<DatePicker
			slotProps={{
				layout: {
					sx: {
						'.MuiPickersCalendarHeader-labelContainer': {
							fontSize: '1.6rem'
						},
						'.MuiPickersYear-yearButton': {
							fontSize: '1.6rem'
						}
					}
				}
			}}
			sx={sxValue}
			{...rest}
		/>
	)
}
