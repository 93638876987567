import React from 'react'
import { useTranslation } from 'react-i18next'
import { Severity, NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'

interface ConfirmNewTicketModalProps {
	open: boolean
	close: () => void
	accept: () => void
	title?: string
	bodyText: string
	textButton: string
	showInput?: boolean
	headerTitleSeverity?: Severity
}

/**
 * ConfirmNewTicketModal
 * @description Helps to display a message when user try to save the changes to ticket permissions
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param accept <function>: this function handles any action on the parent when accept action is triggered
 * @param title <string>: is a text that describe the tittle of modal
 * @param bodyText <string>: is a text that text of body of modal
 * @param textButton <string>: is a text that describe the action of the button
 * @param headerTitleSeverity <Severity>: is a type of message on the modal
 * @returns Modal component
 */
export const ConfirmNewTicketModal = ({
	open,
	close,
	accept,
	title,
	bodyText,
	textButton,
	headerTitleSeverity
}: ConfirmNewTicketModalProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioDialog
			open={open}
			titleText={t(title)}
			headerTitleSeverity={headerTitleSeverity}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={close}>
						{t('cancelButton')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={accept}>
						{t(textButton)}
					</NetcurioButton>
				</>
			}
		>
			<label>{t(bodyText)}</label>
		</NetcurioDialog>
	)
}
