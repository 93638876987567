import {
	NetcurioButton,
	NetcurioDialog,
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableContainer
} from '@netcurio/frontend-components'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { AttachedDocumentRow } from './AttachedDocumentRow'
import styles from './AttachedDocumentsModal.module.scss'
import { File } from '../../types/File'

interface AttachedDocumentsModalProps {
	open: boolean
	attachedDocuments: File[]
	onClose(): void
	folderUUID: string
	getInvoiceInfo: () => void
}

export const AttachedDocumentsModal = ({
	open,
	attachedDocuments,
	onClose,
	folderUUID,
	getInvoiceInfo
}: AttachedDocumentsModalProps) => {
	useEffect(() => {
		if (attachedDocuments?.length === 0) {
			onClose()
		}
	}, [attachedDocuments])

	return (
		<NetcurioDialog
			open={open}
			actionButtons={
				<NetcurioButton variant="contained" onClick={onClose}>
					{t('closeText')}
				</NetcurioButton>
			}
			titleText={t('attachedDocumentsTitle')}
			minWidth={'96rem'}
			maxWidth={'96rem'}
		>
			{attachedDocuments?.length > 0 ? (
				<NetcurioTableContainer maxHeight={'55rem'}>
					<NetcurioTable stickyHeader aria-label="scrollable table" size={'small'}>
						{/* TODO: This section will be implemented further on when downloading all */}
						<NetcurioTableBody>
							{attachedDocuments?.map((file) => (
								<AttachedDocumentRow
									key={file.name}
									file={file}
									folderUUID={folderUUID}
									getInvoiceInfo={getInvoiceInfo}
								/>
							))}
						</NetcurioTableBody>
					</NetcurioTable>
				</NetcurioTableContainer>
			) : (
				<div className={styles.emptyDocuments}>
					<p>{t('emptyDocuments')}</p>
				</div>
			)}
		</NetcurioDialog>
	)
}
