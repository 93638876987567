import { Modal } from '@netcurio/frontend-common'
import Constants from '../../../../../utilities/constants'
import styles from '../../../adminConsole.module.scss'
import { RelatedCompaniesData } from './types'

export const relatedCompaniesListDefaultValues: RelatedCompaniesData = {
	textHeader: styles.headerTableDefault,
	dataFilters: {},
	dataFiltersArray: [],
	dataCustomerSupplier: {},
	textFilter: 'display-none',
	statusFilter: 'display-none',
	numericFilter: 'display-none',
	dateFilter: 'display-none',
	actualField: undefined,
	fieldList: undefined,
	initialSort: undefined,
	actualFilterData: '',
	dataStatusFilter: '',
	minAmount: '',
	maxAmount: '',
	filterValue: undefined,
	filterContainerBar: false,
	deleteRange: false,
	minRange: undefined,
	maxRange: undefined,
	initDate: undefined,
	finalDate: undefined,
	initRange: undefined,
	finalRange: undefined,
	initDateErrorText: undefined,
	finalDateErrorText: undefined,
	orderList: Constants.ORDER.ASC,
	limitRows: undefined,
	rowsActual: undefined,
	totalRows: undefined
}

export const modalDefaultValues: Modal = {
	errorCode: undefined
}
