import { NetcurioButton, NetcurioDialog, useNetcurioLoader } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../hooks/useClient'
import { getUrlParameter } from '../../../utilities/getUrlParameter'
import { CANCEL_CREDIT_MEMO } from '../queries'

interface CancelCreditMemoModalProps {
	open: boolean
	onSuccess(): void
	onClose(): void
	handleError(error: Error): void
}

export const CancelCreditMemoModal = ({
	open,
	onSuccess,
	onClose,
	handleError
}: CancelCreditMemoModalProps) => {
	const { t } = useTranslation()

	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const client = useClient()
	const creditMemoId = getUrlParameter('creditmemo')

	const cancelCreditMemo = (): void => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: CANCEL_CREDIT_MEMO,
				variables: {
					uuid: creditMemoId
				}
			})
			.then(() => {
				onSuccess()
			})
			.catch(handleError)
			.finally(() => {
				onClose()
				hideLoadingSpinner()
			})
	}

	return (
		<NetcurioDialog
			open={open}
			titleText={t('cancelCreditMemoTitle')}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={cancelCreditMemo}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('cancelCreditMemoQuestion')}</p>
		</NetcurioDialog>
	)
}
