import { Roles } from '@netcurio/frontend-common'
import userInformation from '../utilities/userInformation'

export const useUserRole = () => {
	const userRole = userInformation.getCompanyRole()

	return {
		userRole,
		isUserSupplier: userRole === Roles.SUPPLIER,
		isUserCustomer: userRole === Roles.CUSTOMER,
		isUserCustomerNoAdmins: userRole === Roles.CUSTOMER_NO_ADMINS
	}
}
