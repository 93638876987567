import gql from 'graphql-tag'

export const GOODS_RECEIPT_DETAILS = gql`
	query GoodsReceipt($id: ID!) {
		GoodsReceipt(id: $id) {
			id
			created_at
			customer_reference
			customer {
				rfc
			}
			created_by {
				name
				lastname
				email
			}
			purchase_order {
				id
				customer {
					rfc
				}
				branch_office
				delivery_address_line_1
				delivery_address_line_2
				delivery_address_country
				delivery_address_state
				delivery_address_postal_code
				supplier {
					name
					rfc
				}
				customer_reference
			}
			items {
				position
				product_description
				quantity
				unit
				purchase_order_item {
					storage_location
				}
				batches {
					id
					batch
					quantity
					goods_receipt
					customer
					goods_receipt_position
				}
			}
		}
	}
`
