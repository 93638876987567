import React, { ReactElement } from 'react'
import { IMainTable } from './types'
import HeaderTable from './headerTable'
import TableRow from './tableRow'
import styles from './styles.module.scss'

const mainTable = ({ informationTable }: IMainTable): ReactElement => {
	return (
		<div className={styles.mainTableComplement}>
			<HeaderTable />
			<div className={styles.mainTableComplementRows}>
				{Object.keys(informationTable).map((key) => (
					<TableRow key={key} informationTable={informationTable[key]} />
				))}
			</div>
		</div>
	)
}
export default mainTable
