import Alert from '@mui/material/Alert'
import { ThemeOptions } from '@mui/material/styles'
import React, { ReactNode } from 'react'
import styles from './NetcurioAlert.module.scss'

export enum Severity {
	Success = 'success',
	Info = 'info',
	Warning = 'warning',
	Error = 'error'
}

export const NetcurioAlertThemeOptions: ThemeOptions = {
	components: {
		MuiAlert: {
			styleOverrides: {
				message: {
					width: '100%'
				}
			}
		}
	}
}

interface NetcurioAlertProps {
	severity?: Severity
	children: ReactNode
	onClose?: (event: React.SyntheticEvent) => void
	elevation?: number
	messagePadding?: string
	icon?: React.ReactNode
}

/**
 * NetcurioAlertbox
 * @param severity <'Success' | 'Info' | 'Warning' | 'Error'>: The variant to use.
 * @param children <ReactNode>: Children elements of the Alert.
 * @param onClose <void>: Action when the close icon is clicked.
 * @param elevation <number>: How much box shadow should be shown arount the alert. @default 0.
 * @param messagePadding <string>: Padding of the content inside the alert. @default 8px.
 * @param icon <boolean>: If `false`, the icon isn't displayed in the alert. It can receive an icon.
 @returns component
 */
export const NetcurioAlert = ({
	severity,
	children,
	onClose,
	elevation = 0,
	messagePadding,
	icon,
	...rest
}: NetcurioAlertProps) => {
	return (
		<Alert
			severity={severity}
			onClose={onClose}
			elevation={elevation}
			sx={{
				'& .MuiAlert-message': {
					padding: messagePadding ?? '8px'
				}
			}}
			icon={icon}
			{...rest}
		>
			<span className={styles.netcurioAlert}>{children}</span>
		</Alert>
	)
}
