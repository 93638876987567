import { Roles, tableFillSelectionFilter } from '@netcurio/frontend-common'
import * as i18next from 'i18next'
import Constants from '../../utilities/constants'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './paymentComplementList.module.scss'

export function objectHeaderArray(userRole: string): Array<tableFillHeader> {
	const serieFolio: tableFillHeader = new tableFillHeader(
		i18next.t('registerIdentifier'),
		styles.headerSerieFolioContainer,
		'',
		'serie' + 'folio',
		'text'
	)
	const date: tableFillHeader = new tableFillHeader(
		i18next.t('emissionDate'),
		styles.headerDateContainer,
		'',
		'date',
		'date'
	)
	const customer: tableFillHeader = new tableFillHeader(
		i18next.t('buyer'),
		styles.headerCustomerContainer,
		'',
		'receiver',
		'text'
	)
	const supplier: tableFillHeader = new tableFillHeader(
		i18next.t('seller'),
		styles.headerSupplierContainer,
		'',
		'sender',
		'text'
	)
	const createdAt: tableFillHeader = new tableFillHeader(
		i18next.t('uploadDate'),
		styles.headerCreatedAtContainer,
		'',
		'created_at',
		'createdDate'
	)
	const statusPaymentComplementColumn: tableFillHeader = new tableFillHeader(
		i18next.t('actualState'),
		styles.headerStatusContainer,
		'',
		'status',
		'status'
	)
	return [
		serieFolio,
		statusPaymentComplementColumn,
		date,
		userRole === Roles.CUSTOMER ? supplier : customer,
		createdAt
	]
}
export function objectStatusFilterArray(): Array<tableFillSelectionFilter> {
	const accountedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.PAYMENT_COMPLEMENT_STATUS.ACCOUNTED,
		undefined,
		'MALE'
	)
	const cancelledStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.PAYMENT_COMPLEMENT_STATUS.VOIDED,
		undefined,
		'MALE'
	)
	const halfStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.PAYMENT_COMPLEMENT_STATUS.HALF
	)

	return [accountedStatusOption, cancelledStatusOption, halfStatusOption]
}
