import React from 'react'
import styles from './TermsAndConditions.module.scss'

export const TermsAndConditions = () => {
	return (
		<div id={'textTerms'} className={styles.containerTextTerms}>
			<h3>1. Obligatoriedad, consentimiento y aceptación de los presentes términos y condiciones.</h3>
			<div>
				La utilización del PORTAL atribuye a la persona que ingresa a la misma la condición de USUARIO
				del servicio, quien al ingresar al SITIO acepta el contenido de los presentes términos y
				condiciones y las demás políticas de NETCURIO, así como su expresa aceptación plena y sin
				excepción de los derechos y obligaciones derivados de todos y cada uno de los términos y
				condiciones contenidos en este documento mismos que son no negociables y deberán ser
				observados por todo persona que ingrese al portal en todo momento. En tal virtud, es
				indispensable que el USUARIO y las personas que ingresan al mismo lean con detenimiento el
				contenido de estos términos y condiciones, así como las políticas de NETCURIO, ya que al
				ingresar, acceder, navegar, utilizar o registrarse como USUARIO reconoce que ha leído y está
				de acuerdo con los presentes términos y condiciones por lo que le serán aplicables las
				obligaciones aquí contenidas, y dicho consentimiento tendrá los mismos efectos jurídicos como
				el consentimiento otorgado a través de firma autógrafa, siendo admisible como prueba en juicio
				en los términos de la legislación aplicable.
			</div>
			<h3>2. Objeto</h3>
			<div>
				El Portal es un canal automático de interacción con proveedores, para publicar y notificar de
				forma eficiente las nuevas órdenes de compra que se generen, así como la recepción ordenada y
				eficiente de facturas; disminuyendo los tiempos de interacción Cliente-Proveedor. NETCURIO
				otorga una licencia no exclusiva e intransferible al USUARIO del uso del portal para el
				servicio de recepción de facturas en línea, por tanto no puede realizar copias o revelar
				contenido del PORTAL, asimismo no debe permitir el acceso de terceros. En caso de que el
				USUARIO incurra en violación a esta condición por causas atribuibles al mismo, se someterá a
				todas las sanciones y obligaciones previstas en la Ley de la Propiedad Industrial, así como la
				cancelación inmediata del servicio, con independencias de las acciones legales que pudieran
				derivar del incumplimiento por parte del USUARIO.
			</div>
			<h3>3. Vigencia.</h3>
			<div>
				Los presentes términos y condiciones estarán vigentes a partir de su fecha de publicación y
				aceptación y serán aplicables los de la versión de actualización más reciente. Es
				responsabilidad del USUARIO cerciorarse si han sido actualizados. NETCURIO se reserva el
				derecho de modificar en cualquier momento y sin previo aviso, los presentes Términos y
				Condiciones, la configuración y el contenido del servicio y/o del PORTAL, mismos que el USARIO
				desde este momento acepta en su plenitud dichas modificaciones. Los derechos y obligaciones
				concedidos al USUARIO por los presentes términos y condiciones se extienden a cualquier
				actualización o nueva versión que sustituya o complemente al PORTAL, salvo que la
				actualización o nueva versión cuente con términos y condiciones propias.
			</div>
			<h3>4. Descripción del servicio y su funcionamiento.</h3>
			<div>
				El USUARIO tendrán acceso rápido y eficiente a la información de las facturas y órdenes de
				compra; incrementando el control y trazabilidad durante todo el proceso. Direccionamiento
				automático de documentos para los usuarios correspondientes. Asegurando que las facturas
				recibidas para pago a proveedores cumplan con todas las disposiciones fiscales y comerciales.
				Recepción de facturas en línea utilizando los distintos métodos de carga de documentos
				ofrecidos por el sistema. Únicamente las personas que cumplan con los requisitos de activación
				podrán utilizar los Servicios proporcionados por el Portal. El PORTAL procesará las facturas a
				partir de la información ingresada por el CLIENTE, por tal motivo el USUARIO es el único
				responsable por los datos que ingrese para dicho fin. Los Servicios incluyen la confirmación
				de órdenes de compra con sus respectivas cantidades, aceptación total, parcial o rechazo de
				las mismas. La carga de facturas electrónicas mediante archivos pdf y xml, mismos que deben
				ser cargados de forma obligatoria para que pueda procesarse el documento. La realización de
				validaciones de integridad de la información recibida y validación de datos fiscales.
			</div>
			<h3>5. Requerimientos técnicos</h3>
			<div>
				Para un uso adecuado del sistema es necesario contar con un equipo con conexión a Internet,
				que tenga instalado alguno de los siguientes navegadores:
				<ul>
					<li>Google Chrome versión 49 o superior</li>
					<li>Mozilla FireFox versión 50 o superior </li>
					<li>Apple Safari versión 9.1 o superior</li>
					<li>Microsoft Internet Explorer versión 10 o superior </li>
				</ul>
				Asimismo, se requiere instalación del software adecuado para visualización de archivos PDF
				como son: Adobe Acrobat
			</div>
			<h3>6. Condiciones de uso.</h3>
			<div>
				El USUARIO es el único autorizado para accesar al PORTAL, para lo cual debe contar con
				registro previo, proporcionando al efecto un nombre de usuario y contraseña para el uso del
				mismo. Por el sólo hecho de ingresar al PORTAL y registrarse, el USUARIO se compromete a
				utilizar el servicio conforme a las finalidades y requerimientos previstos en los presentes
				términos y condiciones, prohibiéndose de manera enunciativa más no limitativa, lo siguiente:
				<ol type="a">
					<li>
						Cualquier copia, reproducción, transformación, difusión, distribución, transmisión,
						publicación, exhibición, comunicación pública, representación total o parcial del
						Portal.
					</li>
					<li>
						Utilizar la aplicación/página Web con cualquier fin lesivo a los intereses de
						terceros, o que en sí mismos puedan dañar, inutilizar, sobrecargar, o deteriorar de
						cualquier forma los servicios, los equipos de otros usuarios.
					</li>
					<li>
						Llevar a cabo cualquier tipo de actuación que tengan como finalidad la modificación,
						ajuste, alteración o daños a la aplicación/página Web.
					</li>
					<li>
						Tratar datos o información obtenidos de manera ilícita o contraria a la ley aplicable.
					</li>
					<li>
						Subir datos que puedan contener Virus, o cualquier otro elemento susceptible de causar
						daños o alteraciones en perjuicio de la aplicación/página Web.
					</li>
					<li>
						Proporcionar información respecto del contenido y funcionamiento de la aplicación a
						terceros.
					</li>
					<li>
						En general, cualquier actividad contraria a la ley, la moral o las buenas costumbres.
					</li>
				</ol>
			</div>
			<h3>7. Datos personales y aviso de privacidad.</h3>
			<div>
				El USUARIO otorga su autorización para proporcionar datos personales que serán utilizados
				únicamente con la finalidad de la prestación del servicio y fines relativos al mismo. Bajo
				ninguna circunstancia NETCURIO brindará datos específicos del USUARIO a otras entidades, salvo
				que exista una orden o resolución de alguna autoridad competente para ello. De la misma manera
				el USUARIO acepta y reconoce que ha leído y comprende el alcance del aviso de privacidad de
				NETCURIO mismo que se encuentra disponible en la siguiente liga (
				<a href="https://netcurio.com/legal.html" target="_blank" rel="noreferrer">
					POLITICA DE PRIVACIDAD
				</a>
				) , por lo que la información será utilizada únicamente como se describe en el mismo. Los
				datos proporcionados serán tratados de conformidad con la Ley Federal de Protección de Datos
				Personales en Posesión de los Particulares y su Reglamento y únicamente personas autorizadas
				tendrán acceso a sus datos. NETCURIO podrá utilizar la información del USUARIO para llevar a
				cabo sondeos y estudios de mercado respecto de los aspectos comerciales relacionados con el
				servicio proporcionado, y en su caso, utilizar los resultados de los mismos antes terceros.
			</div>
			<h3> 8. Manejo de la información</h3>
			<div>
				NETCURIO no será responsable por cualquier problema con el manejo de la información ingresada
				por el usuario, ni por cualquier pérdida, alteración o daño de la misma. El uso del portal no
				exime al USUARIO de la obligación de enviar o presentar sus facturas por otro medio alterno en
				caso de serle requerido por el personal del cliente Asimismo, el ingreso de una factura el
				portal no constituye una aceptación o reconocimiento de obligación alguna por parte de
				NETCURIO o CLIENTE.
			</div>
			<h3>9. Licitud de las actividades.</h3>
			<div>
				Al utilizar el Servicio proporcionados por el PORTAL, el USUARIO acepta que todas sus
				actividades que lleva a cabo son lícitas por lo que abstendrá de utilizar los Servicios para
				llevar a cabo prácticas comerciales desleales o actividades que infrinjan los derechos
				intelectuales de terceros o cualquier otra actividad similar, en tal virtud acepta y se obliga
				a cumplir con todos los permisos de operación, autorizaciones, dadas de alta ante autoridades
				competentes, leyes de privacidad, leyes para la prevención de operaciones con recursos de
				procedencia ilícita incluyendo pero no limitando y cualquier otro requisito, acuerdo o trámite
				que impongan autoridades como el Servicio de Administración Tributaria y Procuraduría Federal
				del Consumidor. Al utilizar nuestros Servicios te obligas a mantenernos a salvo e
				indemnizarnos por cualquier disputa con autoridades o particulares ya sea por incumplir los
				presentes Términos y Condiciones o cualquier ley estatal o federal.
			</div>
			<h3> 10. Cuotas.</h3>
			<div>
				El USUARIO al utilizar los Servicios acepta y se obliga a pagar como contraprestación por el
				uso y goce de la Cuenta y demás Servicios proporcionados por el PORTAL las comisiones
				indicadas en nuestra página de comisiones más el Impuesto al Valor Agregado. NETCURIO se
				reserva el derecho para modificar las comisiones sin previo aviso. El USUARIO deberá estar al
				corriente con el pago de sus cuotas, en caso contrario NETCURIO se reserva el derecho de
				interrumpir el servicio sin previo aviso y sin responsabilidad por cualquier afectación que
				esto implique al USUARIO.
			</div>
			<h3> 11. Pago de facturas y condiciones comerciales.</h3>
			<div>
				NETCURIO no será responsable del pago de las facturas ingresadas en el PORTAL, siendo esta una
				relación comercial exclusiva entre el USUARIO y el PAGADOR, en este caso el CLIENTE. Asimismo,
				en caso de existir un rechazo, corrección, ajuste o disputa referente a cualquier factura
				ingresada en el PORTAL, el mismo deberá ser gestionado directamente entre CLIENTE y el USUARIO
			</div>
			<h3>12. Tecnología.</h3>
			<div>
				Los Servicios prestados por NETCURIO utilizan tecnologías que fueron desarrolladas con
				diversas técnicas de seguridad lo que se traduce en el hecho de la información proporcionada
				por el USUARIO está segura y a salvo. En algunos casos al utilizar los Servicios el USUARIO
				acepta se podrá solicitar la instalación y/o utilización de sistemas de información
				específicos, por lo que NETCURIO se reserva el derecho para solicitar al USUARIO descargas,
				actualizaciones, protocolos de seguridad y/o certificaciones para utilizar los Servicios.
			</div>
			<h3>13. Propiedad Intelectual.</h3>
			<div>
				La totalidad de los Servicios prestados en el PORTAL y demás contenidos incluyendo estos
				Términos y Condiciones son propiedad de NETCURIO; por lo que el USUARIO al ingresar al Sitio,
				registrarse como Usuario y activar su cuenta, acepta expresamente respetar los derechos de
				propiedad intelectual de NETCURIO. De la misma manera el USUARIO no se le otorga derecho
				alguno para promocionarse como afiliado, socio consejero, accionista, agente o mandatario de
				NETCURIO; así como tampoco le confiere derechos para utilizar la marca.
			</div>
			<h3>14. Alcance de la relación.</h3>
			<div>
				La relación entre NETCURIO y el USUARIO se limita a lo descrito en los presentes Términos y
				Condiciones por lo que entre ellos no existe ninguna otra relación de sociedad o vínculo.
			</div>
			<h3> 15. Legislación aplicable.</h3>
			<div>
				Los presentes Términos y Condiciones se encuentran sujetos a las leyes mexicanas
				particularmente al Código Civil Federal. Los USUARIOS, sin importar sus domicilios presentes o
				futuros, por el simple hecho de aceptar estos Términos y Condiciones se sujetan a la
				jurisdicción y competencia de los tribunales competentes de la Ciudad de México.
			</div>
		</div>
	)
}
