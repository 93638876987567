export default {
	PURCHASE_ORDERS: 'purchaseOrdersTooltip',
	INVOICES: 'invoicesTooltip',
	GOOD_RECEIPTS: 'goodsReceiptTooltip',
	PAYMENT_COMPLEMENTS: 'complementTooltip',
	DASHBOARDS: 'dashboardsTooltip',
	REPORTS: 'reportsText',
	REQUEST_FOR_QUOTATION: 'rfqMenuTooltip',
	CONSUMPTION: 'consignmentTooltip',
	CREDIT_MEMO: 'creditMemoTooltip',
	FOR_CREDIT_MEMO: 'requestForCreditMemoTooltip'
}
