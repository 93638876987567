import React, { FC } from 'react'
import {
	NetcurioTable,
	NetcurioTableContainer,
	NetcurioTableRow,
	NetcurioTableHead,
	NetcurioTableCell,
	NetcurioTableBody,
	NetcurioButton,
	NetcurioIcons
} from '@netcurio/frontend-components'
import { useTranslation } from 'react-i18next'
import { RegisterBatch } from '../../../../../types'
import { ItemsBatch } from './ItemsBatch'
import styles from '../../NewGoodsReceipt/tableEntriesRecord.module.scss'

interface ListRegisterBatchProps {
	unit: string
	pendingDelivery: number
	missingFields: boolean
	batchItems: Array<RegisterBatch>
	handleItemFieldChange(input: string, value: string, position: number): void
	removeItem(position: number): void
	addBatchItem(): void
}

export const ListRegisterBatch: FC<ListRegisterBatchProps> = ({
	unit,
	missingFields,
	batchItems,
	handleItemFieldChange,
	removeItem,
	addBatchItem,
	pendingDelivery
}) => {
	const { t } = useTranslation()

	return (
		<>
			<NetcurioTableContainer minHeight="100%">
				<NetcurioTable size="small">
					<colgroup>
						<col style={{ width: '30%' }} />
						<col style={{ width: '50%' }} />
						<col style={{ width: '20%' }} />
					</colgroup>
					<NetcurioTableHead>
						<NetcurioTableRow isDetailHeader>
							<NetcurioTableCell align="center">
								<p className={styles.tableTitle}>{t('quantity')}</p>
							</NetcurioTableCell>
							<NetcurioTableCell align="center">
								<p className={styles.tableTitle}>{t('batchText')}</p>
							</NetcurioTableCell>
							<NetcurioTableCell align="center"></NetcurioTableCell>
						</NetcurioTableRow>
					</NetcurioTableHead>
					<NetcurioTableBody>
						{batchItems.map((item: RegisterBatch, index: number) => (
							<ItemsBatch
								key={index}
								unit={unit}
								batchItem={item}
								itemPosition={index}
								handleItemFieldChange={handleItemFieldChange}
								removeItem={removeItem}
								pendingDelivery={pendingDelivery}
							/>
						))}
					</NetcurioTableBody>
				</NetcurioTable>
			</NetcurioTableContainer>
			{missingFields && <p className={styles.missingFields}>{t('missingFields')}</p>}
			<NetcurioButton
				variant="text"
				size="large"
				startIcon={<NetcurioIcons.AddCircleOutline className={styles.registerBatchModalAddIcon} />}
				onClick={addBatchItem}
			>
				<p className={styles.registerBatchModalTextButtonAddBatch}>{t('addBatch')}</p>
			</NetcurioButton>
		</>
	)
}
