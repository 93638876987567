import React from 'react'
import { NetcurioButton, NetcurioIcons, UploadDocument } from '@netcurio/frontend-components'
import { Translation } from 'react-i18next'
import { InformationFile } from '../../types'
import Constants from '../../utilities/constants'
import styles from './newEnrollment.module.scss'

interface Props {
	enrollmentFiles: InformationFile
	addFile: (text: string) => void
	setSelectedFile: (file: File) => void
	newFile?: File
}

export default function UploadFile({ enrollmentFiles, addFile, setSelectedFile, newFile }: Props) {
	return (
		<Translation>
			{(t) => (
				<div className={styles.itemCreationSizeNewEnrollment}>
					<div className={styles.headerNewEnrollment}>
						<p className={styles.alignTitleDocuments}>{t('fileUploadTitle')}</p>
					</div>
					<div className={styles.companyDocumentsContent}>
						{Object.keys(enrollmentFiles).map(
							(key) =>
								enrollmentFiles[key].uuid && (
									<div className={styles.enrollmentDocumentsRow} key={key}>
										<span>{t(`${key}NewCompany`)}</span>
										<NetcurioButton
											variant={'outlined'}
											size={'small'}
											sx={{
												height: '3rem',
												margin: '0.5rem 0 0 0.5rem',
												border: 'solid 0.1rem'
											}}
											onClick={() => addFile(enrollmentFiles[key].uuid ? enrollmentFiles[key].uuid : '')}
											endIcon={<NetcurioIcons.VisibilityOutlined />}
										>
											<p>{t('viewDocument')}</p>
										</NetcurioButton>
									</div>
								)
						)}
						<div className={'company-documents-upload-row'}>
							<UploadDocument
								id={'newFileField'}
								label={t('newFileEnrollment')}
								accept={Constants.MIME_TYPES.PDF}
								fileName={newFile?.name || ''}
								onFileChange={(file: File | undefined) => {
                                    if (file)
									setSelectedFile(file)
								}}
							/>
						</div>
					</div>
				</div>
			)}
		</Translation>
	)
}
