import { Filter, getCurrentUser, Roles, URLS } from '@netcurio/frontend-common'
import { Header, useNetcurioLoader } from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { Dayjs } from 'dayjs'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AddButton } from '../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../components/HeaderButtons/ExportButton'
import { NewPaymentComplementModal } from '../../components/NewPaymentComplementModal/NewPaymentComplementModal'
import { connection } from '../../utilities/connection'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { downloadExcelFile } from '../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../utilities/file-handling/file-descriptor'
import listHelper from '../../utilities/listHelper'
import UserInformation from '../../utilities/userInformation'
import { CatchedErrorModalPaymentComplementList } from './Modals/CatchedErrorModalPaymentComplementList'
import { PaymentComplementTable } from './paymentComplementTable'
import * as queries from './queries'
import { objectStatusFilterArray } from './tableParameterArray'
import {
	TypesDataPaymentComplement,
	TypesFilterToApply,
	TypesModal,
	TypesPaymentComplement,
	TypesPaymentComplementList,
	TypesUpdateFilter
} from './types'

export const PaymentComplementList = (): ReactElement => {
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const history = useHistory<string>()
	const initialSortField = 'created_at'
	const initialSortOrder = 'DESC'
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [userRole, setUserRole] = useState<Roles>()

	const [paymentComplement, setPaymentComplement] = useState<TypesPaymentComplementList>({
		dataComplement: {},
		dataFiltersArray: [],
		deleteRange: false,
		filterContainerBar: false,
		positionYScroll: 0,
		rowsActual: undefined,
		totalRows: undefined,
		limitRows: undefined,
		orderList: undefined,
		fieldList: undefined,
		actualFilterData: initialSortField,
		dataFilters: {},
		newPaymentComplementModal: false,
		filtersOfTypeStatus: {
			status: {
				list: objectStatusFilterArray(),
				numSelectOptions: 3,
				dataFilter: ''
			}
		}
	})
	const [modal, setModal] = useState<TypesModal>({
		errorModal: false,
		errorCode: undefined
	})
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [stopPagination, setStopPagination] = useState<boolean>(true)
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	useEffect(() => {
		setUserRole(UserInformation.getCompanyRole() as Roles)
		const finalHeight: number = window.innerHeight - 95
		const numberRows: number = Math.round(finalHeight / 45) + 2
		setPaymentComplement((prevState) => ({
			...prevState,
			limitRows: numberRows,
			rowsActual: numberRows
		}))
		showLoadingSpinner()
	}, [])

	useEffect(() => {
		if (paymentComplement.limitRows !== undefined) {
			filteringPaymentComplement(0, initialSortField, initialSortOrder, initialSortField)
		}
	}, [paymentComplement.limitRows])

	useEffect(() => {
		if (activePagination && stopPagination) {
			setActivePagination(false)
			setStopPagination(false)
			setShowBarLoader(true)
			filteringPaymentComplement(
				paymentComplement.rowsActual,
				'',
				'',
				'',
				undefined,
				undefined,
				undefined,
				'none'
			)
		}
	}, [activePagination, stopPagination])

	const hideModal = () =>
		setPaymentComplement((prevState) => ({
			...prevState,
			newPaymentComplementModal: false
		}))

	const paymentComplementCreatedNotification = async (UUID: string) => {
		await client.mutate({
			mutation: queries.SEND_NEW_PAYMENT_COMPLEMENT_EMAIL,
			variables: {
				uuid: UUID
			}
		})
	}
	const redirectToDetail = (UUID: string) => {
		window.sessionStorage.setItem('paymentComplementRegistered', 'true')
		paymentComplementCreatedNotification(UUID)
		history.push(`${URLS.COMPLEMENT_DETAIL}?paymentComplement=${UUID}`)
	}

	const downloadReportExcel = () => {
		const currentUser = getCurrentUser()

		if (Object.keys(paymentComplement.dataComplement).length > 0) {
			showLoadingSpinner()
			const copyFilterToApply: Array<Filter> = JSON.parse(
				JSON.stringify(paymentComplement.dataFiltersArray)
			)
			copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
			const body: string = JSON.stringify({
				companyFilter: currentUser ? currentUser.company.rfc : undefined,
				filters: copyFilterToApply,
				sort_field: paymentComplement.fieldList,
				sort_order: paymentComplement.orderList
			})
			listHelper
				.generateReportList(body, 'payment-complements')
				.then(async (res) => {
					if (res.ok) {
						res.json().then((responseJson: FileDescriptor) => {
							downloadExcelFile(responseJson)
						})
						hideLoadingSpinner()
					} else {
						const error: Error = await res.json()
						handleError(error)
					}
				})
				.catch(handleError)
		}
	}

	const closeFilterContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringPaymentComplement, initialSortField)
		setPaymentComplement((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) => {
		listHelper.deleteFilterActive(
			indexObject,
			paymentComplement.dataFilters,
			closeFilterContainerBar,
			filteringPaymentComplement
		)
		setPaymentComplement((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}
	const filteringPaymentComplement = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter?: string,
		initRange?: number | Dayjs,
		finalRange?: number | Dayjs,
		filterRemove?: string
	) => {
		let fieldListData: string = paymentComplement.fieldList,
			orderListData: string = paymentComplement.orderList,
			columnFilterActual: string = paymentComplement.actualFilterData,
			currentList: TypesDataPaymentComplement = {
				...paymentComplement.dataComplement
			}
		if (sortOrder !== '') {
			orderListData = sortOrder
			setPaymentComplement((prevState) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortField) {
			setPaymentComplement((prevState) => ({
				...prevState,
				fieldList: sortField
			}))
			fieldListData = sortField
		}
		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setPaymentComplement((prevState) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}
		const valueFilterActual: string = valueFilter
		let typeFilterActual: string, initRangeActual: number | Dayjs, finalRangeActual: number | Dayjs
		switch (columnFilterActual) {
			case 'seriefolio':
			case 'receiver':
			case 'sender':
				typeFilterActual = 'wildcard'
				break
			case 'status':
				typeFilterActual = 'exact_match'
				break
			case 'date':
			case 'created_at':
				typeFilterActual = 'date'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
		}

		if (skip > 0) {
			const rowsActual: number = paymentComplement.rowsActual + paymentComplement.limitRows
			setPaymentComplement((prevState) => ({
				...prevState,
				rowsActual: rowsActual
			}))
		} else {
			setPaymentComplement((prevState) => ({
				...prevState,
				rowsActual: prevState.limitRows,
				dataComplement: {}
			}))
			currentList = {}
		}
		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField
		)
		queryPaymentComplement(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number | Dayjs,
		finalRangeActual: number | Dayjs,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		const resultFilterToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			paymentComplement.dataFilters,
			paymentComplement.filtersOfTypeStatus,
			{}
		) as TypesFilterToApply
		const objUpdate: TypesUpdateFilter = resultFilterToApply.objectForStateUpdate
		setPaymentComplement((prevState) => ({
			...prevState,
			dataFilters: objUpdate.dataFilters,
			dataFiltersArray: objUpdate.dataFiltersArray,
			deleteRange: objUpdate.deleteRange,
			filterContainerBar: objUpdate.filterContainerBar
		}))
		if (objUpdate.filtersOfTypeStatus)
			setPaymentComplement((prevState) => ({
				...prevState,
				filtersOfTypeStatus: objUpdate.filtersOfTypeStatus
			}))
		return resultFilterToApply.filterToApply
	}

	const queryPaymentComplement = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: TypesDataPaymentComplement
	) => {
		const copyFilterToApply: Array<Filter> = JSON.parse(JSON.stringify(filterToApply))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query:
					userRole === Roles.CUSTOMER
						? queries.PAYMENT_COMPLEMENT_CUSTOMER
						: queries.PAYMENT_COMPLEMENT_SUPPLIER,
				variables: {
					limit: paymentComplement.limitRows,
					skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyFilterToApply
				}
			})
			.then((result) => {
				let interCount: number = skip
				const data: TypesDataPaymentComplement = { ...currentList },
					obj: Array<TypesPaymentComplement> = result.data.PaymentComplements.list
				for (const keyName in obj) {
					interCount++
					data[interCount] = obj[keyName]
				}
				setPaymentComplement((prevState) => ({
					...prevState,
					totalRows: result.data.PaymentComplements.total,
					filtersOfTypeStatus: {
						...prevState.filtersOfTypeStatus,
						status: {
							...prevState.filtersOfTypeStatus.status,
							dataFilter: prevState.filtersOfTypeStatus.status.dataFilter || ''
						}
					},
					dataComplement: data
				}))
				setStopPagination(true)
				hideLoadingSpinner()
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		hideLoadingSpinner()
		const errorCode: string = showErrorComponent(error)
		setShowBarLoader(false)
		setStopPagination(true)
		if (!expiredToken(errorCode)) {
			setPaymentComplement((prevState) => ({
				...prevState,
				dataComplement: {},
				filtersOfTypeStatus: {
					...prevState.filtersOfTypeStatus,
					status: {
						...prevState.filtersOfTypeStatus.status,
						dataFilter: ''
					}
				}
			}))
			setModal({
				errorModal: true,
				errorCode
			})
		}
	}

	return (
		<>
			<Header>
				<div>
					{userRole === Roles.SUPPLIER && (
						<AddButton
							onClick={() =>
								setPaymentComplement((prevState) => ({
									...prevState,
									newPaymentComplementModal: true
								}))
							}
							translationKey="newComplement"
						/>
					)}
				</div>
				<div>
					<ExportButton onClick={() => downloadReportExcel()} />
				</div>
			</Header>
			<PaymentComplementTable
				closeFilterContainerBar={closeFilterContainerBar}
				dataComplement={paymentComplement.dataComplement}
				dataFiltersArray={paymentComplement.dataFiltersArray}
				deleteFilterActive={deleteFilterActive}
				deleteRange={paymentComplement.deleteRange}
				filterContainerBar={paymentComplement.filterContainerBar}
				filteringPaymentComplement={filteringPaymentComplement}
				positionYScroll={paymentComplement.positionYScroll}
				rowsActual={paymentComplement.rowsActual}
				totalRows={paymentComplement.totalRows}
				userRole={userRole}
				setActivePagination={setActivePagination}
				filtersOfTypeStatus={paymentComplement.filtersOfTypeStatus}
				showBarLoader={showBarLoader}
				setShowBarLoader={setShowBarLoader}
			/>
			<NewPaymentComplementModal
				open={paymentComplement.newPaymentComplementModal}
				onClose={hideModal}
				redirectToDetail={redirectToDetail}
			/>
			<CatchedErrorModalPaymentComplementList open={!!modal.errorCode} errorCode={modal.errorCode} />
		</>
	)
}
