export default {
	PURCHASE_ORDERS: 'PURCHASE_ORDERS',
	INVOICES: 'INVOICES',
	GOOD_RECEIPTS: 'GOOD_RECEIPTS',
	PAYMENT_COMPLEMENTS: 'PAYMENT_COMPLEMENTS',
	DASHBOARDS: 'DASHBOARDS',
	REPORTS: 'REPORTS',
	REQUEST_FOR_QUOTATION: 'REQUEST_FOR_QUOTATION',
	CONSUMPTION: 'CONSUMPTION',
	CREDIT_MEMO: 'CREDIT_MEMO',
	FOR_CREDIT_MEMO: 'FOR_CREDIT_MEMO'
}
