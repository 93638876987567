import React, { ReactElement, useEffect, useState } from 'react'
import { CommentsSection, CommentSectionVariant, CommentSectionSize } from '@netcurio/frontend-components'

//utilities and types
import { ReviewButtons } from './reviewButtons'
import { Comment } from '@netcurio/frontend-common'
import { LateralColumnProps } from './types'

//queries
import { CREATE_TICKET_COMMENT, TICKET_COMMENTS } from './queries'

const LateralColumn = ({
	ticketDetail,
	client,
	handleError,
	ticketId,
	sender,
	actionButton
}: LateralColumnProps): ReactElement => {
	const [ticketComments, setTicketComments] = useState<Comment[]>([])
	const [showReviewButton, setShowReviewButton] = useState<boolean>(true)

	useEffect(() => {
		getComments()
	}, [])

	const getComments = () => {
		client
			.query({
				query: TICKET_COMMENTS,
				variables: {
					id: ticketId,
					sender: sender
				},
				fetchPolicy: 'no-cache'
			})
			.then((result) => {
				if (result.data) {
					setTicketComments(result.data.Ticket.comments)
				}
			})
			.catch(handleError)
	}

	const addNewComment = (newCommentData: Comment) => {
		const newComment = {
			ticket: ticketId,
			sender: sender,
			text: newCommentData.text
		}
		client
			.mutate({
				mutation: CREATE_TICKET_COMMENT,
				variables: newComment
			})
			.then(() => {
				getComments()
			})
			.catch(handleError)
	}

	const handleSizeChange = (commentsSectionSize: CommentSectionSize): void => {
		setShowReviewButton(commentsSectionSize !== CommentSectionSize.Extended)
	}

	return (
		<div className="ticket-lateral-column-container">
			<CommentsSection
				comments={ticketComments}
				variant={CommentSectionVariant.WithBody}
				onAddComment={addNewComment}
				onSizeChange={handleSizeChange}
			/>
			{showReviewButton && <ReviewButtons actionButton={actionButton} ticketDetail={ticketDetail} />}
		</div>
	)
}

export { LateralColumn }
