import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { NetcurioButton, NetcurioIcons, NetcurioTooltip, NetcurioAutocomplete } from '@netcurio/frontend-components'
import { parseTimestampToTimeZone, dateFormatter, URLS } from '@netcurio/frontend-common'
import { PaymentComplement } from '../../../../types'
import { defaultPaymentSelected } from '../utilities/defaultValues'
import Formatter from '../../../../utilities/formatter'
import cancelsvg from '@netcurio/frontend-assets/src/icons/cancel.svg'
import paymentsComplementsvg from '@netcurio/frontend-assets/src/icons/complement.svg'
import classNames from 'classnames'
import styles from './PaymentComplementInfo.module.scss'

interface PaymentComplementInfoTable {
	paymentSelected: PaymentComplement
	setPaymentSelected: Dispatch<SetStateAction<PaymentComplement>>
	paymentComplementInfo: Array<PaymentComplement>
}

export function PaymentComplementInfoTable({
	paymentSelected,
	setPaymentSelected,
	paymentComplementInfo
}: PaymentComplementInfoTable) {
	const history = useHistory<string>()
	const { t } = useTranslation()
	const [suggestions, setSuggestions] = useState<Array<PaymentComplement>>([])
	const [selectorDisabled, setSelectorDisabled] = useState<boolean>(true)

	useEffect(() => {
		const results = paymentComplementInfo.filter((complement) => {
			return complement?.serie?.toLowerCase().includes('')
		})
		setSelectorDisabled(results.length < 2)
		setSuggestions(results)
	}, [])

	function onListHeaderClick() {
		setPaymentSelected(defaultPaymentSelected)
	}

	function itemTemplate(item: PaymentComplement) {
		return item.serie + '-' + item.folio + ' - ' + item.uuid
	}

	function onSelect(e: PaymentComplement) {
		if (e) setPaymentSelected(e)
	}

	return (
		<section className={styles.paymentComplementTable}>
			<header className={styles.headerContainer}>
				<div className={styles.headerText}>
					<img
						src={paymentsComplementsvg}
						className={styles.iconPaymentComplement}
						alt={t('paymentComplementText')}
					/>
					<span>{t('paymentComplementText')}</span>
				</div>
				<img
					alt={t('closeText')}
					src={cancelsvg}
					className={styles.iconClose}
					onClick={onListHeaderClick}
				/>
			</header>
			<div className={styles.contentContainer}>
				<div className={styles.selectContainer}>
					{t('paymentComplementsRelated')}
					<NetcurioAutocomplete
						size="small"
						height="smaller"
						variant={'outlined'}
						options={suggestions}
						onSelectValue={onSelect}
						value={paymentSelected}
						getOptionLabel={itemTemplate}
						disabled={selectorDisabled}
						fullWidth
						disableClearable
					/>
				</div>
				<div className={styles.redirectContainer}>
					{t('paymentsRelated')}
					<NetcurioButton
						variant="outlined"
						onClick={() =>
							history.push(
								`${URLS.COMPLEMENT_DETAIL}?paymentComplement=${paymentSelected.uuid}`
							)
						}
						endIcon={<NetcurioIcons.ArrowCircleRight />}
					>
						{t('goToComplementText')}
					</NetcurioButton>
				</div>
				{paymentSelected.payments.map((payment, i) => (
					<div key={i} className={styles.paymentCard}>
						<div className={classNames(styles.paymentRow, styles.descriptionRow)}>
							<NetcurioTooltip title={t('PCPositionTooltip')} placement={'top'}>
								<p>{t('positionText')}</p>
							</NetcurioTooltip>
							<NetcurioTooltip title={t('PCDateTooltip')} placement={'top'}>
								<p>{t('paidText')}</p>
							</NetcurioTooltip>
							<NetcurioTooltip title={t('PCAmountTooltip')} placement={'top'}>
								<p>{t('total_amount')}</p>
							</NetcurioTooltip>
							<NetcurioTooltip title={t('PCCurrencyTooltip')} placement={'top'}>
								<p>{t('currencyText')}</p>
							</NetcurioTooltip>
						</div>
						<div className={classNames(styles.paymentRow, styles.descriptionRow)}>
							<p>{payment.position}</p>
							<p>{dateFormatter.format(parseTimestampToTimeZone(payment.date))}</p>
							<p>{Formatter.currency.format(payment.amount)}</p>
							<p>{payment.currency}</p>
						</div>
						<div className={classNames(styles.paymentRow, styles.totalRow)}>
							<p>{t('amountPaid')}</p>
							<p>{t('currentBalance')}</p>
						</div>
						{payment.items.map((item, i) => (
							<div
								key={i}
								className={classNames(styles.paymentRow, styles.totalRow, styles.relatedRow)}
							>
								<p>
									{isNaN(Number(item.paid_amount))
										? t('NAtext')
										: Formatter.currency.format(Number(item.paid_amount))}
								</p>
								<p>
									{isNaN(Number(item.pending_balance))
										? t('NAtext')
										: Formatter.currency.format(Number(item.pending_balance))}
								</p>
							</div>
						))}
					</div>
				))}
			</div>
		</section>
	)
}
