import React, { ReactNode } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { ThemeOptions } from '@mui/material/styles'

export const NetcurioStepperThemeOptions: ThemeOptions = {
	components: {
		MuiStepLabel: {
			styleOverrides: {
				label: {
					fontSize: '1.4rem'
				}
			}
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					width: '2.4rem',
					height: '2.4rem'
				},
				text: {
					fontSize: '1.2rem'
				}
			}
		}
	}
}

interface NetcurioStepperProps {
	activeStep?: number
	children: ReactNode
}

/**
 * NetcurioStepper
 * @param activeStep <number>: Set the active step (zero based index). Set to -1 to disable all the steps. @default 0
 * @returns component
 */
export const NetcurioStepper = ({ children, ...rest }: NetcurioStepperProps) => {
	return <Stepper {...rest}>{children}</Stepper>
}

interface NetcurioStepProps {
	completed?: boolean
	active?: boolean
	disabled?: boolean
	expanded?: boolean
	index?: number
	last?: boolean
	children: ReactNode
}

/**
 * NetcurioStep
 * @param completed <boolean>: Mark the step as completed. Is passed to child components.
 * @param active <boolean>: Sets the step as active. Is passed to child components.
 * @param disabled <boolean>: If `true`, the step is disabled.
 * @param expanded <boolean>: Expand the step. @default false
 * @param index <number>: The position of the step.
 * @param last <boolean>: If `true`, the Step is displayed as rendered last.
 * @param children <ReactNode>: Should be `NetcurioStep` sub-components such as `NetcurioStepLabel`, `NetcurioStepContent`.
 * @returns component
 */
export const NetcurioStep = ({ children, ...rest }: NetcurioStepProps) => {
	return <Step {...rest}>{children}</Step>
}

interface NetcurioStepLabelProps {
	error?: boolean
	icon?: React.ReactNode
	optional?: React.ReactNode
	StepIconComponent?: React.ElementType
	children: ReactNode
}

/**
 * NetcurioStep
 * @param error <boolean>: If `true`, the step is marked as failed.
 * @default false
 * @param icon <ReactNode>: Override the default label of the step icon.
 * @param optional <ReactNode>: The optional node to display.
 * @param StepIconComponent <ElementType>: The component to render in place of the [`StepIcon`](/material-ui/api/step-icon/).
 * @param children <ReactNode>: In most cases will simply be a string containing a title for the label.
 * @returns component
 */
export const NetcurioStepLabel = ({ children, ...rest }: NetcurioStepLabelProps) => {
	return <StepLabel {...rest}>{children}</StepLabel>
}
