import { Company } from '@netcurio/frontend-common'

const Tickets = ({
	new_ticket_notification,
	review_ticket_notification,
	closed_ticket_notification,
	reopened_ticket_notification
}: Company) => {
	return {
		title: 'ticketTitle',
		checkBoxRows: {
			new_ticket_notification: {
				name: 'ticketNew',
				value: new_ticket_notification
			},
			review_ticket_notification: {
				name: 'ticketReview',
				value: review_ticket_notification
			},
			closed_ticket_notification: {
				name: 'ticketClosed',
				value: closed_ticket_notification
			},
			reopened_ticket_notification: {
				name: 'ticketReopened',
				value: reopened_ticket_notification
			}
		}
	}
}

export const notificationSupplier = (company: Company) => {
	return {
		PO: {
			title: 'purchaseOrdersTooltip',
			checkBoxRows: {
				new_po_notification: {
					name: 'newPO',
					value: company.new_po_notification
				},
				deleted_po_notification: {
					name: 'POdeleted',
					value: company.deleted_po_notification
				},
				update_po_notification: {
					name: 'updatedPOws',
					value: company.update_po_notification
				}
			}
		},
		RFQ: {
			title: 'rfqMenuTooltip',
			checkBoxRows: {
				new_rfq_notification: {
					name: 'newRFQText',
					value: company.new_rfq_notification
				},
				closed_rfq_notification: {
					name: 'rfqClose',
					value: company.closed_rfq_notification
				}
			}
		},
		GR: {
			title: 'delivery',
			checkBoxRows: {
				new_gr_notification: {
					name: 'newMerchandiseEntry',
					value: company.new_gr_notification
				}
			}
		},
		Invoices: {
			title: 'invoicesTooltip',
			checkBoxRows: {
				paid_inv_notification: {
					name: 'invoicePaid',
					value: company.paid_inv_notification
				},
				canceled_inv_notification: {
					name: 'invoiceCanceled',
					value: company.canceled_inv_notification
				},
				scheduled_inv_notification: {
					name: 'invoiceScheduled',
					value: company.scheduled_inv_notification
				}
			}
		},
		Consignment: {
			title: 'CONSIGNMENT',
			checkBoxRows: {
				new_consumption_notification: {
					name: 'newConsumption',
					value: company.new_consumption_notification
				}
			}
		},
		Enrollments: {
			title: 'enrollmentText',
			checkBoxRows: {
				enrollment_approve_notification: {
					name: 'enrollmentApproved',
					value: company.enrollment_approve_notification
				},
				enrollment_review_notification: {
					name: 'enrollmentInReview',
					value: company.enrollment_review_notification
				},
				enrollment_reject_notification: {
					name: 'enrollmentRejected',
					value: company.enrollment_reject_notification
				}
			}
		},
		CreditsMemo: {
			title: 'creditMemos.title',
			checkBoxRows: {
				new_rfcm_notification: {
					name: 'newRFCM',
					value: company.new_rfcm_notification
				}
			}
		},
		Tickets: Tickets(company)
	}
}

export const notificationCustomer = (company: Company) => {
	return {
		PO: {
			title: 'purchaseOrdersTooltip',
			checkBoxRows: {
				confirmed_po_notification: {
					name: 'poConfirmed',
					value: company.confirmed_po_notification
				},
				rejected_po_notification: {
					name: 'poRejected',
					value: company.rejected_po_notification
				}
			}
		},
		Invoices: {
			title: 'invoicesTooltip',
			checkBoxRows: {
				scheduled_inv_notification: {
					name: 'invoiceScheduled',
					value: company.scheduled_inv_notification
				}
			}
		},
		PaymentComplements: {
			title: 'paymentComplement',
			checkBoxRows: {
				new_pc_notification: {
					name: 'newPaymentComplement',
					value: company.new_pc_notification
				},
				void_pc_notification: {
					name: 'voidedPaymentComplement',
					value: company.void_pc_notification
				}
			}
		},
		RFQ: {
			title: 'rfqMenuTooltip',
			checkBoxRows: {
				new_q_notification: {
					name: 'newQuotation',
					value: company.new_q_notification
				},
				rejected_rfq_notification: {
					name: 'rejectedRFQ',
					value: company.rejected_rfq_notification
				}
			}
		},
		Enrollments: {
			title: 'enrollmentText',
			checkBoxRows: {
				enrollment_new_notification: {
					name: 'newEnrollment',
					value: company.enrollment_new_notification
				}
			}
		},
		CreditsMemo: {
			title: 'creditMemos.title',
			checkBoxRows: {
				accepted_rfcm_notification: {
					name: 'rfcmAccepted',
					value: company.accepted_rfcm_notification
				},
				rejected_rfcm_notification: {
					name: 'rfcmRejected',
					value: company.rejected_rfcm_notification
				},
				accounted_cm_notification: {
					name: 'accountedCM',
					value: company.accounted_cm_notification
				}
			}
		},
		Tickets: Tickets(company)
	}
}
