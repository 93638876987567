import React from 'react'
import { useTranslation } from 'react-i18next'
import { Invoice } from '../../types'
import { ConditionalRender } from '../ConditionalRender/ConditionalRender'
import styles from './AssociatedDocumentViewer.module.scss'
import { AssociateDocumentItem } from './AssociateDocumentItem'
import { useAssociatedDocuments } from './useAssociatedDocuments'
interface AssociatedDocumentViewerProps {
	referenceType: string
	documentReferenceId: string
	customerRFC: string
	customerReference: string
	parentStatus?: string
	invoice?: Invoice
}

export const AssociatedDocumentViewer = ({
	referenceType,
	documentReferenceId,
	customerRFC,
	customerReference,
	parentStatus,
	invoice
}: AssociatedDocumentViewerProps) => {
	const { t } = useTranslation()

	const associatedDocuments = useAssociatedDocuments({
		documentReferenceId,
		customerRFC,
		customerReference,
		referenceType,
		parentStatus,
		invoice
	})

	return (
		<ConditionalRender condition={associatedDocuments.length > 0}>
			<div className={styles.associatedDocumentSection}>
				<div className={styles.header}>
					<p>{t('associatedTo')}</p>
				</div>
				<section className={styles['associated-document-container']}>
					{associatedDocuments.map((associatedDocument, index) => (
						<AssociateDocumentItem
							key={`associatedDocument-${index}`}
							label={associatedDocument.label}
							url={associatedDocument.url}
							tooltip={associatedDocument.tooltip}
							data={associatedDocument.data}
							disabled={associatedDocument.disabled}
						/>
					))}
				</section>
			</div>
		</ConditionalRender>
	)
}
