import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
	NetcurioNumericField,
	NetcurioTableCell,
	NetcurioTableRow,
	NetcurioTooltip
} from '@netcurio/frontend-components'
import { useTranslation } from 'react-i18next'
import { PurchaseOrderItem, RegisterBatch } from '../../../../../types'

import Formatter from '../../../../../utilities/formatter'
import Constants from '../../../../../utilities/constants'
import boxIconWhite from '@netcurio/frontend-assets/src/icons/box-white.svg'
import styles from '../tableEntriesRecord.module.scss'
import { RegisterBatchModal } from '../../Modals/RegisterBatchModal/RegisterBatchModal'

interface ItemEntriesRecordProps {
	dataPendingForGoodsReceipt: PurchaseOrderItem & { batches?: Array<RegisterBatch> }
	positionKey: number
	receivedAmount(valueReceivedAmount: string, indexPosition: number): void
	saveBatchItems(batchItems: any, position: number): void
	isFiltering: boolean
}

export const ItemEntriesRecord: FC<ItemEntriesRecordProps> = ({
	dataPendingForGoodsReceipt,
	positionKey,
	receivedAmount,
	saveBatchItems,
	isFiltering
}) => {
	const { t } = useTranslation()
	const [openNewBatchModal, setOpenNewBatchModal] = useState<boolean>(false)
	const [valueReceivedAmount, setValueReceivedAmount] = useState<string>('')
	const { position, name, code, confirmed_amount, pending_delivery, unit, storage_location } =
		dataPendingForGoodsReceipt

	useEffect(() => {
		if (isFiltering) {
			resetItem()
		}
	}, [isFiltering])

	const updateReceivedAmount = (valueReceivedAmount: string, batchItems: Array<RegisterBatch>) => {
		receivedAmount(valueReceivedAmount, positionKey)
		saveBatchItems(batchItems, positionKey)
		setValueReceivedAmount(valueReceivedAmount)
	}

	const resetItem = () => {
		setValueReceivedAmount('')
		receivedAmount('', positionKey)
	}
	return (
		<NetcurioTableRow isDetailRow>
			<NetcurioTableCell align="center">
				<p>{position}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="left" className={styles.descriptionColumn}>
				<NetcurioTooltip title={name} placement="right">
					<p className={styles.itemDescriptionOverflow}>
						{t('itemDescription')} {name}
					</p>
				</NetcurioTooltip>
				<p className={styles.itemCode}>
					{t('itemCode')} {code}
				</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				{t(Formatter.nullValueFormattingToNA(storage_location))}
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				{confirmed_amount ? confirmed_amount + ' ' + unit : t(Constants.SYMBOL.NA)}
			</NetcurioTableCell>
			<NetcurioTableCell align="center">{pending_delivery + ' ' + unit}</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<div className={styles.containerTableCell}>
					<div className={styles.wrapperTextField}>
						<NetcurioNumericField
							size="small"
							decimalScale={2}
							suffix={` ${unit}`}
							value={valueReceivedAmount}
							onChange={(event: ChangeEvent<HTMLInputElement>) => {
								receivedAmount(event.target.value, positionKey)
							}}
							maxLimit={pending_delivery}
							minLimit={0}
							disabled={
								dataPendingForGoodsReceipt?.batches &&
								dataPendingForGoodsReceipt?.batches.length > 0
							}
						/>
					</div>
				</div>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<div className={styles.containerTableCell}>
					<div className={styles.backgroundBatchIconNewGR}>
						<img
							src={boxIconWhite}
							alt={'Batch'}
							className={styles.batchIconNewGR}
							onClick={() => setOpenNewBatchModal(true)}
						/>
					</div>
				</div>
			</NetcurioTableCell>
			<RegisterBatchModal
				open={openNewBatchModal}
				dataPendingForGoodsReceipt={dataPendingForGoodsReceipt}
				updateReceivedAmount={updateReceivedAmount}
				closeNewBatchModal={() => setOpenNewBatchModal(false)}
				resetItem={resetItem}
			/>
		</NetcurioTableRow>
	)
}
