import { Severity, useNetcurioLoader, useNetcurioSnackbar } from '@netcurio/frontend-components'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { connection } from '../../../../utilities/connection'
import { showErrorComponent } from '../../../../utilities/errorCode'
import { expiredToken } from '../../../../utilities/expiredToken'
import { getUrlParameter } from '../../../../utilities/getUrlParameter'
import { CLOSE_REQUEST_FOR_QUOTATION } from '../../utilities/queries'

interface CloseRFQProps {
	closeRequest: boolean
	closeMainModal(): void
	addNewComment(): void
	resetBtnValue(): void
	resetDataPage(): void
}

/**
 * CloseRFQ
 * @description Helps the main modal to activate some actions(CloseRequestForQuotation) when the user is trying to close a RFQ
 * @param closeRequest <boolean>: If `true`, the function "closeRFQ" is triggered.
 * @param addNewComment <void>: Callback fired when the component requests to be closed.
 * @param closeMainModal <void>: Callback fired when the component requests to add a new comment into CommentSection.
 * @param resetBtnValue <void>: Callback fired to reset setCloseRFQ state in CloseRFQModal component.
 * @param resetDataPage <void>: Callback for to reset data page
 * @return component <ReactNode>
 **/

export const CloseRFQ = ({
	closeRequest,
	closeMainModal,
	addNewComment,
	resetBtnValue,
	resetDataPage
}: CloseRFQProps) => {
	const client = connection()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const { showSnackbar } = useNetcurioSnackbar()

	useEffect(() => {
		if (closeRequest) {
			closeRFQ()
			resetBtnValue()
		}
	}, [closeRequest])

	const closeRFQ = () => {
		showLoadingSpinner()
		const RFQ = getUrlParameter('rfq')
		const customer = getUrlParameter('customer')
		client
			.mutate({
				mutation: CLOSE_REQUEST_FOR_QUOTATION,
				variables: {
					id: RFQ,
					customer: customer
				}
			})
			.then(() => {
				addNewComment()
				sessionStorage.setItem('closeRFQ', 'true')
				resetDataPage()
				closeMainModal()
			})
			.catch((error) => {
				console.error(error)
				const idCode = showErrorComponent(error)
				if (!expiredToken(idCode)) {
					showSnackbar({
						message: t('errorCodeText', { idCode }),
						title: 'Error',
						severity: Severity.Error
					})
				}
				hideLoadingSpinner()
			})
	}

	return <p>{t('questionCloseRFQ')}</p>
}
