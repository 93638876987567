import gql from 'graphql-tag'

export const uploadDocument = gql`
	mutation uploadDocument($file: String!, $key: String!) {
		uploadDocument(file: $file, key: $key) {
			code
			message
		}
	}
`

export const CompanyDocuments = gql`
	query Company($rfc: ID!) {
		Company(rfc: $rfc) {
			supportingDocuments {
				document_type
				link
			}
		}
	}
`
