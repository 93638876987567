import { fillModal } from '../../components/dialogModal/fillModal'

export enum ModalTypes {
	error
}

export interface ModalData {
	fillModal: fillModal[]

	acceptActionModal?(value?: boolean): void
}

export interface DataHeader {
	receiver: Receiver
	category: Categories
	subject: string
}

export interface IHeaderInformationProps {
	dataHeader: DataHeader
	setDataHeader: React.Dispatch<React.SetStateAction<DataHeader>>
	handleError: (error: Error) => void
	description: string
	setDescription: (value: string) => void
	changeReceiver: () => void
	verifyReceiverReset: boolean

	setVerifyReceiverReset(value: boolean): void
}

export interface Receiver {
	rfc: string
	name: string
	label?: string
}

export interface Categories {
	code: string
	description: string
	label?: string
}

export interface DocumentCommentsProps {
	nameFile: string
	setFile: (file: File | undefined) => void
	setComment: (comment: string) => void
	disabledButtonSend: boolean
	actionButtonSend: () => void
}

export interface CommentSend {
	text?: string
}
