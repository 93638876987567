import { useEffect, useMemo, useState } from 'react'
import { CostCenter } from '../types/CostCenter'
import { ExpenseSubtype } from '../types/ExpenseSubtype'
import { ExpenseType } from '../types/ExpenseType'
import { connection } from '../utilities/connection'
import { COST_CENTERS } from '../views/invoices/graphql/cost_centers'
import { EXPENSE_SUBTYPES } from '../views/invoices/graphql/expense_subtypes'
import { EXPENSE_TYPES } from '../views/invoices/graphql/expense_types'
import { useCompanySettings } from './useCompanySettings'

interface Props {
	rfc: string | null
	selectedExpenseTypeKey?: string | null
}

interface State {
	costCenters: CostCenter[]
	expenseTypes: ExpenseType[]
	expenseSubtypes: ExpenseSubtype[]
}

export const useApprovalFields = ({ rfc, selectedExpenseTypeKey }: Props) => {
	const { companySettings } = useCompanySettings({ rfc })
	const [state, setState] = useState<State>({
		costCenters: [],
		expenseTypes: [],
		expenseSubtypes: []
	})

	const client = useMemo(() => connection(), [])
	const {
		cost_center_required: costCenterRequired = false,
		expense_type_required: expenseTypeRequired = false
	} = companySettings ?? {}

	useEffect(() => {
		const fetchData = async () => {
			const costCenterResponse = await client.query({
				query: COST_CENTERS,
				variables: {
					companyRfc: rfc
				}
			})

			const costCenters = costCenterResponse?.data?.getCostCenters ?? []

			const expenseTypesResponse = await client.query({
				query: EXPENSE_TYPES,
				variables: {
					companyRfc: rfc
				}
			})
			const expenseTypes = expenseTypesResponse?.data?.getExpenseTypes ?? []

			setState((prevState) => ({
				...prevState,
				costCenters,
				expenseTypes
			}))
		}

		if (rfc) {
			fetchData()
		}
	}, [client, rfc])

	useEffect(() => {
		const fetchExpenseSubtypes = async () => {
			const expenseSubtypesResponse = await client.query({
				query: EXPENSE_SUBTYPES,
				variables: {
					companyRfc: rfc,
					expenseTypeKey: selectedExpenseTypeKey
				}
			})
			setState((prevState) => ({
				...prevState,
				expenseSubtypes: expenseSubtypesResponse?.data?.getExpenseSubtypes ?? []
			}))
		}

		if (rfc && selectedExpenseTypeKey) {
			fetchExpenseSubtypes()
		}
	}, [selectedExpenseTypeKey, client, rfc])

	return {
		costCenterRequired,
		expenseTypeRequired,
		...state
	}
}
