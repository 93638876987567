import React from 'react'
import { useTranslation } from 'react-i18next'
import { Severity, NetcurioDialog, NetcurioButton, NetcurioTextField } from '@netcurio/frontend-components'
import classNames from 'classnames'
import styles from './inviteSupplierModal.module.scss'

interface InviteNewSupplierModalProps {
	open: boolean
	close: () => void
	accept: () => void
	title?: string
	bodyText: Array<string>
	textButton: string
	showInput?: boolean
	headerTitleSeverity?: Severity
	email: string
	setEmail: (e) => void
	isEmailError: boolean
}

/**
 * InviteNewSupplierModal
 * @description Helps add a new supplier user
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param accept <function>: this function handles any action on the parent when accept action is triggered
 * @param title <string>: is a text that describe the tittle of modal
 * @param bodyText <Array<string>>: is a text that text of body of modal
 * @param textButton <string>: is a text that describe the action of the button
 * @param headerTitleSeverity <Severity>: is a type of message on the modal
 * @param email <string>: is the value used to send the invitation to the new supplier
 * @param setEmail <function> this function handle the email value
 * @param isEmailError <boolean> tells if the sintax of the email has an error
 * @returns Modal component
 */
export const InviteNewSupplierModal = ({
	open,
	close,
	accept,
	title,
	bodyText,
	textButton,
	headerTitleSeverity,
	email,
	setEmail,
	isEmailError
}: InviteNewSupplierModalProps) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			open={open}
			titleText={t(title)}
			headerTitleSeverity={headerTitleSeverity}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={close}>
						{t('cancelButton')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={accept} disabled={!email}>
						{t(textButton)}
					</NetcurioButton>
				</>
			}
			contentFontSize="1.4rem"
		>
			<div className={styles.totalContainerNewSupplier}>
				<p className={classNames(styles.alignNameText, styles.textFormat)}>{t('userText')}</p>
				<div className={'containerInput'}>
					<NetcurioTextField
						type="email"
						fullWidth
						label={t('bussinessEmailText')}
						value={email}
						onChange={(e) => setEmail(e.currentTarget.value)}
						size="small"
						variant="outlined"
					/>
				</div>
				{isEmailError && <div className={styles.messageError}>{t('emailNotValidText')}</div>}

				<div className={'containerInput'}>
					<p className={classNames(styles.alignNameText, styles.textFormat)}>{t('role')}</p>
					<NetcurioTextField
						disabled
						fullWidth
						value={t('supplier_consumption')}
						size="small"
						variant="outlined"
					/>
				</div>
				<div className={classNames(styles.alignMessage, styles.textFormat)}>
					<p className={styles.description}> {`${t(bodyText[0])}. ${t(bodyText[1])}`}</p>
				</div>
			</div>
		</NetcurioDialog>
	)
}
