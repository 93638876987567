import React from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'

interface CancelNewEnrollmentModalProps {
	open: boolean
	cancel: () => void
	close: () => void
}

/**
 * CancelNewEnrollmentModal
 * @description Helps to display a message when user try to block another user
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param cancel <function>: this function handles any action on the parent when cancel action is triggered
 * @returns Modal component
 */
export const CancelNewEnrollmentModal = ({ open, cancel, close }: CancelNewEnrollmentModalProps) => {
	const { t } = useTranslation()

	const triggerCancel = () => {
		cancel()
	}

	const triggerClose = () => {
		close()
	}

	return (
		<>
			<NetcurioDialog
				open={open}
				onClose={triggerClose}
				titleText={t('cancelNewEnrollmentTitle')}
				actionButtons={
					<>
						<NetcurioButton variant="text" onClick={triggerClose}>
							{t('goBackText')}
						</NetcurioButton>
						<NetcurioButton variant="contained" onClick={triggerCancel}>
							{t('cancelButton')}
						</NetcurioButton>
					</>
				}
			>
				<p>{t('cancelNewEnrollmentText')}</p>
			</NetcurioDialog>
		</>
	)
}
