import {
	FreightAndTerms,
	InformationRFQItem,
	ItemQ,
	ItemQuotation,
	NewQuotation,
	RequestForQuotation
} from '../types'
import { Product } from '../../../types'
import { getCurrentUser } from '@netcurio/frontend-common'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import { connection } from '../../../utilities/connection'
import Queries from '../../../components/queries'
import { Dispatch, SetStateAction } from 'react'
import { QuotationItem } from '../types/QuotationItem'
import { defaultNewLineQuotationItem } from './defaultValues'
import Constants from '../../../utilities/constants'
import Formatter from '../../../utilities/formatter'
import cancelGray from '@netcurio/frontend-assets/src/icons/cancel-gray.svg'

const setCurrency = (newQuotation: NewQuotation): string => {
	let currency = ''
	const itemsQuotation: Array<ItemQuotation> = newQuotation.itemsQuotation
	if (itemsQuotation.length === 1) {
		if (itemsQuotation[0].itemsQ && newQuotation.currency !== itemsQuotation[0].currencyPosition) {
			currency = itemsQuotation[0].currencyPosition
		} else if (itemsQuotation[0].itemsQ && newQuotation.currency !== '') {
			currency = newQuotation.currency
		}
	} else if (itemsQuotation.length) {
		for (let i = 0; i < itemsQuotation.length; i++) {
			if (
				itemsQuotation[i].currencyPosition !== '' &&
				currency !== itemsQuotation[i].currencyPosition
			) {
				currency = itemsQuotation[i].currencyPosition
				break
			}
		}
	}
	return currency
}

const checkCurrencyList = (newQuotation: NewQuotation): boolean => {
	const items: Array<ItemQuotation> = newQuotation.itemsQuotation
	let currency = ''
	for (let i = 0; i < items.length; i++) {
		if (!items[i].sameCurrencyList) {
			return false
		} else {
			if (currency === '' && items[i].currencyPosition !== '') {
				currency = items[i].currencyPosition
			} else if (
				currency !== '' &&
				items[i].currencyPosition !== '' &&
				currency !== items[i].currencyPosition
			) {
				return false
			}
		}
	}
	return true
}

const getFullInformationProduct = (
	informationRFQItems: Array<InformationRFQItem>,
	setFullInformationProducts: Dispatch<SetStateAction<Array<Product>>>,
	handleError: (error: Error) => void
): void => {
	const client: DefaultClient<NormalizedCacheObject> = connection()
	const fullInformationProduct: Array<Product> = new Array(informationRFQItems.length)

	const promises: Array<Promise<void>> = informationRFQItems.map(
		(itemRFQ: InformationRFQItem, index: number) => {
			if (itemRFQ.product_code) {
				return client
					.query({
						query: Queries.PRODUCT_NAME_CODE,
						variables: {
							searchText: itemRFQ.product_code,
							supplier: getCurrentUser().company.rfc
						}
					})
					.then((result: ApolloQueryResult<{ ProductsByNameOrCode: Array<Product> }>) => {
						fullInformationProduct[index] = result.data.ProductsByNameOrCode[0]
					})
					.catch(handleError)
			}
		}
	)

	Promise.all(promises)
		.then(() => {
			setFullInformationProducts(fullInformationProduct)
		})
		.catch(handleError)
}

const getNetTotalQuotation = (
	newQuotation: NewQuotation,
	setNewQuotation: Dispatch<SetStateAction<NewQuotation>>
): void => {
	const netTotal: number = newQuotation.itemsQuotation.reduce(
		(accumulator: number, item: ItemQuotation) => accumulator + item.netTotal,
		0
	)
	setNewQuotation((prevState) => ({
		...prevState,
		netTotal
	}))
}

const createItemsQuotationToSave = (
	newQuotation: NewQuotation,
	informationRFQ: RequestForQuotation,
	settingValidateCatalogue: boolean
): Array<QuotationItem> => {
	const itemsQuotation: Array<QuotationItem> = []
	let newLine: QuotationItem = defaultNewLineQuotationItem
	let positionQuotation = 1
	newQuotation.itemsQuotation.forEach((itemRFQ: ItemQuotation, indexRFQ: number) => {
		if (itemRFQ.status === Constants.STATUS.CONFIRMED) {
			if (settingValidateCatalogue) {
				itemRFQ.itemsQ.forEach((itemQuotation: ItemQ) => {
					itemsQuotation.push({
						position: positionQuotation,
						status: itemRFQ.status,
						product_code: (itemQuotation.product as Product).id,
						product_description: (itemQuotation.product as Product).description,
						product_ean: (itemQuotation.product as Product).ean,
						quantity: parseFloat(itemQuotation.amount as string),
						unit: itemQuotation.unit,
						unit_price: parseFloat(itemQuotation.unitPrice as string),
						reference_price: parseFloat(itemQuotation.referencePrice as string),
						net_value: parseFloat(itemQuotation.netPrice as string),
						tax: parseFloat(itemQuotation.tax as string),
						delivery_proposal_date: Formatter.dateToSaveFormat(
							new Date(itemQuotation.requiredDate)
						),
						request_position: indexRFQ + 1,
						rejected_reason: undefined
					})
					positionQuotation++
				})
			} else {
				itemRFQ.itemsQ.forEach((itemQuotation: ItemQ, idItem: number) => {
					newLine = {
						position: positionQuotation,
						status: itemRFQ.status,
						product_code: undefined,
						product_description: undefined,
						product_ean: undefined,
						quantity: parseFloat(itemQuotation.amount as string),
						unit: itemQuotation.unit,
						unit_price: parseFloat(itemQuotation.unitPrice as string),
						reference_price: parseFloat(itemQuotation.referencePrice as string),
						net_value: parseFloat(itemQuotation.netPrice as string),
						tax: parseFloat(itemQuotation.tax as string),
						delivery_proposal_date: Formatter.dateToSaveFormat(
							new Date(itemQuotation.requiredDate)
						),
						request_position: indexRFQ + 1,
						rejected_reason: undefined
					}
					if (idItem === 0) {
						const itemRfq: InformationRFQItem = informationRFQ.items.find(
							(item: InformationRFQItem) => item.position === indexRFQ + 1
						)
						const compareProduct: string =
							(itemRfq.product_code ? itemRfq.product_code : 'N/A') +
							' - ' +
							itemRfq.product_description

						const newItemRfq: string =
							typeof itemQuotation.product === 'object'
								? itemQuotation.product?.id + ' - ' + itemQuotation.product?.description
								: itemQuotation.product
						if (newItemRfq === compareProduct) {
							newLine.product_code = itemRfq.product_code
							newLine.product_description = itemRfq.product_description
						} else {
							newLine.product_description = newItemRfq.replace('- ', '')
						}
					} else {
						newLine.product_description = itemQuotation.product as string
					}
					itemsQuotation.push(newLine)
					newLine = defaultNewLineQuotationItem
					positionQuotation++
				})
			}
		} else {
			itemsQuotation.push({
				position: positionQuotation,
				product_description: undefined,
				status: itemRFQ.status,
				request_position: indexRFQ + 1,
				rejected_reason: itemRFQ.rejectReason,
				delivery_proposal_date: undefined
			})
			positionQuotation++
		}
	})

	return itemsQuotation
}

const createDefaultItemQuotation = (indexPosition: number): ItemQuotation => {
	return {
		itemsQ: [
			{
				product: undefined,
				unitPrice: 'unitPrice',
				unitPriceString: 'unitPrice',
				referencePrice: '',
				unit: 'unitMeasure',
				tax: 'taxIVA',
				amount: '',
				requiredDate: undefined,
				netPrice: 'netValue',
				cancelImg: cancelGray,
				request_position: indexPosition,
				currency: ''
			}
		],
		netTotal: 0,
		rejectReason: undefined,
		status: Constants.STATUS.CONFIRMED,
		currencyPosition: '',
		sameCurrencyList: true,
		unitPrice: ''
	}
}

const generateItemsFreightAndTerms = (item: FreightAndTerms): FreightAndTerms => {
	return {
		code: item.code,
		description: item.description,
		label: item.code + ' - ' + item.description
	}
}

export {
	setCurrency,
	checkCurrencyList,
	getFullInformationProduct,
	getNetTotalQuotation,
	createItemsQuotationToSave,
	createDefaultItemQuotation,
	generateItemsFreightAndTerms
}
