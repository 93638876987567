import { TypesDataTable, tableFillSelectionFilter } from '@netcurio/frontend-common'
import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	ResultNotFound,
	TagsFilter,
	TextInputFilter,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { Dayjs } from 'dayjs'
import React, { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useState } from 'react'
import { HeaderTable } from '../../components/HeaderTable/HeaderTable'
import Constants from '../../utilities/constants'
import listHelper from '../../utilities/listHelper'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './requestForQuotationList.module.scss'
import { objectHeaderArray, objectStatusFilterArray } from './tableParameterArray'
import { TableRow } from './tableRow'
import { MainTableProps, StyleMainTable } from './types'

export const RequestForQuotationTable = ({
	userRole,
	dataRFQ,
	totalRows,
	rowsActual,
	deleteRange,
	filterContainerBar,
	dataFiltersArray,
	filtersOfTypeStatus,
	closeFilterContainerBar,
	deleteFilterActive,
	filteringRFQ,
	setActivePagination,
	showBarLoader,
	setShowBarLoader
}: MainTableProps): ReactElement => {
	const { showLoadingSpinner } = useNetcurioLoader()
	const [resultNotFound, setResultNotFound] = useState<boolean>(false)
	const [fillFields, setFillFields] = useState<Array<tableFillHeader>>([])
	const [styleMainTable, setStyleMainTable] = useState<StyleMainTable>({
		mainContainerHeight: undefined,
		mainTableHeight: undefined,
		activeHeight: true
	})
	const [dataTable, setDataTable] = useState<TypesDataTable>({
		textHeader: styles.textHeaderListRFQ,
		statusFilter: 'display-none',
		dateFilter: 'display-none',
		textFilter: 'display-none',
		actualField: 'date',
		initDate: undefined,
		finalDate: undefined,
		initDateErrorText: '',
		finalDateErrorText: '',
		fillStatusInputFilter: objectStatusFilterArray(),
		minAmount: undefined,
		maxAmount: undefined,
		filterValue: '',
		initRange: undefined,
		finalRange: undefined,
		minRange: undefined,
		maxRange: undefined,
		deleteRange: false
	})
	const heightRow = 40

	useEffect(() => {
		setShowBarLoader(false)
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: window.innerHeight - Constants.SIZE.MAIN_COMPONENTS_HEIGHT,
			mainContainerHeight: window.innerHeight - Constants.SIZE.HEADERS_HEIGHT
		}))
	}, [])

	useEffect(() => {
		setFillFields(objectHeaderArray(userRole))
	}, [userRole])

	useEffect(() => {
		if (totalRows && rowsActual) {
			const eventElement: Element = window.document.getElementsByClassName(styles.tableInformation)[0]
			if (eventElement) eventElement.addEventListener('scroll', handlePagination)
		}
		return () => {
			const eventElement: Element = window.document.getElementsByClassName(styles.tableInformation)[0]
			if (eventElement) eventElement.removeEventListener('scroll', handlePagination)
		}
	}, [totalRows, rowsActual])

	useEffect(() => {
		if (deleteRange && dataTable.deleteRange) {
			if (dataTable.actualField === 'date') {
				setDataTable((prevState) => ({
					...prevState,
					deleteRange: false,
					initRange: undefined,
					finalRange: undefined
				}))
			} else if (dataTable.actualField === 'total') {
				setDataTable((prevState) => ({
					...prevState,
					deleteRange: false,
					minRange: undefined,
					maxRange: undefined
				}))
			}
		}

		if (filtersOfTypeStatus.status.dataFilter === 'return') {
			if (dataTable.fillStatusInputFilter.length !== objectStatusFilterArray().length) {
				setDataTable((prevState) => ({
					...prevState,
					fillStatusInputFilter: objectStatusFilterArray()
				}))
			}
			if (dataTable.filterValue !== undefined) {
				setDataTable((prevState) => ({
					...prevState,
					filterValue: undefined
				}))
			}
		} else if (
			filtersOfTypeStatus.status.dataFilter !== '' &&
			filtersOfTypeStatus.status.dataFilter &&
			!dataTable.fillStatusInputFilter.some(
				(selectionFilter) =>
					selectionFilter.getSelectedParameter() === filtersOfTypeStatus.status.dataFilter
			)
		) {
			setDataTable((prevState) => ({
				...prevState,
				fillStatusInputFilter: [
					...prevState.fillStatusInputFilter,
					new tableFillSelectionFilter(filtersOfTypeStatus.status.dataFilter)
				]
			}))
		}

		if (filterContainerBar && styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				activeHeight: false,
				mainTableHeight: prevState.mainTableHeight - heightRow,
				mainContainerHeight: prevState.mainContainerHeight - heightRow
			}))
		} else if (!filterContainerBar && !styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				mainTableHeight: prevState.mainTableHeight + heightRow,
				activeHeight: true,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))
		}
		setResultNotFound(totalRows < 1)
		listHelper.updateHeaderBottomRadius(styles.tableHeaderClicked)
	}, [
		deleteRange,
		filtersOfTypeStatus.status.dataFilter,
		totalRows,
		filterContainerBar,
		dataTable.textHeader,
		dataTable.deleteRange,
		dataTable.actualField,
		styleMainTable.activeHeight
	])

	const handlePagination = () => {
		listHelper.handlePagination(
			styles.tableInformation,
			totalRows,
			rowsActual,
			undefined,
			setActivePagination,
			setShowBarLoader
		)
	}

	const showFilters = (field: string, fieldStyle: string) => {
		const filterActual: string = fieldStyle + 'Filter'
		let classActualField = 'display-none'
		let activeFilter: string

		switch (filterActual) {
			case 'dateFilter':
				classActualField = `RFQ-${fieldStyle}-filter`
				activeFilter = dataTable.dateFilter
				break
			case 'statusFilter':
				classActualField = `RFQ-${fieldStyle}-filter`
				activeFilter = dataTable.statusFilter
				break
			case 'textFilter':
				classActualField = 'display-block'
				activeFilter = dataTable.textFilter
				break
		}

		if (activeFilter === 'display-none') {
			const verifyMainContainer: boolean =
				styleMainTable.mainTableHeight >
				window.innerHeight - (dataTable.actualField === field || filterContainerBar ? 155 : 115)

			if (verifyMainContainer)
				setStyleMainTable((prevState) => ({
					...prevState,
					mainTableHeight: prevState.mainTableHeight - heightRow,
					mainContainerHeight: prevState.mainContainerHeight - heightRow
				}))

			setDataTable((prevState) => ({
				...prevState,
				initDate: undefined,
				finalDate: undefined,
				minAmount: undefined,
				maxAmount: undefined,
				filterValue: '',
				initRange: undefined,
				finalRage: undefined,
				minRange: undefined,
				maxRange: undefined,
				statusFilter: 'display-none',
				dateFilter: 'display-none',
				textFilter: 'display-none',
				actualField: field,
				initDateErrorText: '',
				finalDateErrorText: '',
				textHeader: styles.textHeaderListOnClick,
				[filterActual]: classActualField
			}))
		} else if (dataTable.actualField === field) {
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + heightRow,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))

			setDataTable((prevState) => ({
				...prevState,
				filterValue: '',
				textHeader: styles.textHeaderListRFQ,
				initRange: undefined,
				finalRage: undefined,
				[filterActual]: 'display-none'
			}))
		} else {
			setDataTable((prevState) => ({
				...prevState,
				actualField: field,
				filterValue: ''
			}))
		}
		listHelper.updateHeaderColors(fillFields, field, styles.tableHeaderClicked)
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		fieldValue: string,
		objectRange?: { [key: string]: number | Dayjs }
	) => {
		showLoadingSpinner()
		if (sortField !== '') {
			filteringRFQ(0, sortField, order, fieldFilter, undefined, undefined, undefined, undefined)
		} else {
			let initRange: number | Dayjs = dataTable.initRange
			let finalRange: number | Dayjs = dataTable.finalRange
			const minRange: number | Dayjs = dataTable.minRange
			const maxRange: number | Dayjs = dataTable.maxRange

			if (objectRange) {
				if (objectRange.initRange) initRange = objectRange.initRange
				if (objectRange.finalRange) finalRange = objectRange.finalRange
			}
			if (minRange) initRange = minRange
			if (maxRange) finalRange = maxRange
			filteringRFQ(0, sortField, order, fieldFilter, fieldValue, initRange, finalRange, undefined)
		}
		setDataTable((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const selectionFilter = (status: string, position: number) => {
		if (filtersOfTypeStatus.status.numSelectOptions < 5) {
			callDataList('', '', dataTable.actualField, status)
			dataTable.fillStatusInputFilter.splice(position, 1)
		}
	}

	const closeFilter = () => {
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: prevState.mainTableHeight + heightRow,
			mainContainerHeight: prevState.mainContainerHeight + heightRow
		}))
		setDataTable((prevState) => ({
			...prevState,
			statusFilter: 'display-none',
			dateFilter: 'display-none',
			textFilter: 'display-none',
			textHeader: styles.textHeaderListRFQ,
			filterValue: undefined,
			initRange: undefined,
			finalRange: undefined
		}))
	}

	const handleDateRangeFilterChange = (evt: Dayjs, field: string) => {
		const evtValue: Dayjs = evt
		const dateActual: string = field + 'Date'
		const rangeActual: string = field + 'Range'
		if (evtValue !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', dataTable.actualField, undefined, {
					[rangeActual]: evtValue
				})
				setDataTable((prevState) => ({
					...prevState,
					[dateActual]: evtValue,
					[rangeActual]: evtValue,
					[dateActual + 'StyleErrorText']: ''
				}))
				emptyFilterField()
			}
		}
	}

	const handleFilterKeyDown = (evt: KeyboardEvent<HTMLInputElement> | string) =>
		listHelper.handleFilterKeyDown(
			typeof evt === 'object' ? { ...evt, target: evt.target as HTMLInputElement } : evt,
			callDataList,
			emptyFilterField,
			dataTable.actualField,
			dataTable.filterValue,
			dataTable.minRange,
			dataTable.maxRange
		)

	const emptyFilterField = () =>
		setDataTable((prevState) => ({
			...prevState,
			filterValue: '',
			minAmount: undefined,
			maxAmount: undefined,
			initDate: undefined,
			finalDate: undefined
		}))

	const handleTextFilterChange = (evt: ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		const value: string = evt.target.value
		setDataTable((prevState) => ({
			...prevState,
			filterValue: value
		}))
	}
	const cleanFilter = (index) => {
		deleteFilterActive(index)
		setDataTable((prevState) => ({
			...prevState,
			initRange: undefined,
			finalRange: undefined
		}))
	}
	return (
		<div className={styles.marginsTable}>
			<div className={styles.headerMainTableRFQ}>
				{fillFields.map((item: tableFillHeader) => (
					<HeaderTable
						key={item.name}
						textHeader={dataTable.textHeader}
						showFilters={showFilters}
						dataMainHeaderTable={item}
					/>
				))}
			</div>
			<TagsFilter
				showTagsFilter={dataTable.statusFilter}
				actualField={dataTable.actualField}
				callDataList={callDataList}
				selectionFilter={selectionFilter}
				closeFilter={closeFilter}
				fillInputFilter={dataTable.fillStatusInputFilter}
			/>
			<DateInputFilter
				showDateFilter={dataTable.dateFilter}
				actualField={dataTable.actualField}
				initDate={dataTable.initDate}
				finalDate={dataTable.finalDate}
				callDataList={callDataList}
				initReferenceText="init"
				finReferenceText="final"
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			<TextInputFilter
				showTextFilter={dataTable.textFilter}
				actualField={dataTable.actualField}
				valueFilter={dataTable.filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			{filterContainerBar && (
				<ActiveFiltersBar
					objectType={Constants.LISTS.CREDIT_MEMOS}
					dataFiltersArray={dataFiltersArray}
					closeFilterContainerBar={closeFilterContainerBar}
					deleteFilterActive={cleanFilter}
				/>
			)}
			<div
				className={styles.tableInformationWhiteStyle}
				style={{ height: styleMainTable.mainContainerHeight }}
			>
				<div className={styles.tableInformation} style={{ height: styleMainTable.mainTableHeight }}>
					<ResultNotFound showNotFound={resultNotFound} />
					{Object.keys(dataRFQ).map((key) => (
						<TableRow key={key} dataRFQ={dataRFQ[parseInt(key)]} userRole={userRole} />
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
		</div>
	)
}
