import React from 'react'
import { t } from 'i18next'
import { NetcurioButton, NetcurioIcons } from '@netcurio/frontend-components'
import { ItemRFCM } from '../../types'
import { ValidationItemRFCM } from '../../types/ValidationItemRFCM'
import ItemRow from './itemRow'
import styles from './newRequestForCreditMemo.module.scss'

interface IItemListProps {
	missingFields: boolean
	subtotalExceeded: boolean
	itemDataList: Array<ItemRFCM>
	addItem: () => void
	handleItemFieldChange: (value: string, index: number, nameField: string) => void
	deleteItem: (itemIndex: number) => void
	validateItem: Array<ValidationItemRFCM>
	disabledRow: boolean
}

const ItemsList = ({
	missingFields,
	subtotalExceeded,
	itemDataList,
	addItem,
	handleItemFieldChange,
	deleteItem,
	validateItem,
	disabledRow
}: IItemListProps) => {
	return (
		<div className="items-list-container">
			<div className="items-list-container-header">
				<div className={styles.headerContainerRFCM}>
					<span className={styles.titleHeaderRFCM}>{t('conceptText')}</span>
				</div>
			</div>
			<div className={styles.containerListRFCM}>
				{itemDataList.map((item, index) => (
					<ItemRow
						key={index}
						itemIndex={index}
						itemPosition={index + 1}
						handleItemFieldChange={handleItemFieldChange}
						deleteItem={deleteItem}
						itemDataObject={item}
						validateItem={validateItem[index]}
						disabledRow={disabledRow}
					/>
				))}
				{subtotalExceeded && <p className="missing-fields">{t('invoiceSubtotalExceeded')}</p>}
				{missingFields && <p className="missing-fields">{t('missingFields')}</p>}
				<div className={styles.buttonAddItemContainer}>
					<NetcurioButton
						startIcon={<NetcurioIcons.AddCircleOutlineOutlined />}
						onClick={() => addItem()}
						variant="text"
						disabled={disabledRow}
					>
						{t('addConcept')}
					</NetcurioButton>
				</div>
			</div>
		</div>
	)
}

export default ItemsList
