import { gql } from 'graphql-tag'

export const TICKET_DETAIL = gql`
	query Ticket($id: ID!, $sender: ID!) {
		Ticket(id: $id, sender: $sender) {
			id
			sender {
				rfc
				name
			}
			receiver {
				rfc
				name
			}
			category {
				code
				description
			}
			status {
				key
				value
			}
			created_by {
				name
				lastname
				email
			}
			updated_by {
				name
				lastname
				email
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
			created_at
			updated_at
			date_review
			date_close
			subject
			description
			closing_reason
			document_creation
			document_revision
			can_review
			can_create
		}
	}
`

export const TICKET_COMMENTS = gql`
	query Ticket($id: ID!, $sender: ID!) {
		Ticket(id: $id, sender: $sender) {
			id
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`

export const CREATE_TICKET_COMMENT = gql`
	mutation createTicketComment($ticket: ID!, $sender: ID!, $text: String!) {
		createTicketComment(ticket: $ticket, sender: $sender, text: $text) {
			code
			message
		}
	}
`

export const TICKET_REVIEW = gql`
	mutation reviewTicket($id: ID!, $sender: ID!) {
		reviewTicket(id: $id, sender: $sender) {
			code
			message
		}
	}
`

export const ADD_REVISION_DOCUMENT = gql`
	mutation addRevisionDocument($id: ID!, $sender: ID!, $document_revision: String!) {
		addRevisionDocument(id: $id, sender: $sender, document_revision: $document_revision) {
			code
			message
		}
	}
`

export const CLOSE_TICKET = gql`
	mutation closeTicket($id: ID!, $sender: ID!, $closing_reason: String!) {
		closeTicket(id: $id, sender: $sender, closing_reason: $closing_reason) {
			code
			message
		}
	}
`

export const TICKET_REOPEN = gql`
	mutation reopenTicket($id: ID!, $sender: ID!) {
		reopenTicket(id: $id, sender: $sender) {
			code
			message
		}
	}
`
