import { Header } from '@netcurio/frontend-components'
import React from 'react'
import './styles.css'

export const Dashboard = () => {
	return (
		<div className={'container-dashboards'}>
			<Header />
			<div className={'screen-resize'}>
				<div>
					<div className={'bar-title-dashboards'}>{'dashboardsTooltip'}</div>
					<div className={'container-charts'}>
						<div className={'container-box-small'}></div>
					</div>
				</div>
			</div>
		</div>
	)
}
