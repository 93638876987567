export default {
	UPLOAD_TYPE: {
		PRODUCTS_CATALOG: 'PRODUCTS_CATALOG'
	},
	UPLOAD_FILES_RESPONSE: {
		SUCCESSFUL: 'successfulResponse',
		ERROR: 'errorResponse',
		UNKNOWN_ERROR: 'unknownError',
		INVALID_VERSION: 'INVALID_VERSION',
		INVALID_SIGNATURE: 'INVALID_SIGNATURE'
	},
	UPLOAD_FILES_ERRORS: {
		INCORRECT_FILE_STRUCTURE: 'INCORRECT_FILE_STRUCTURE',
		UPLOADING_STOPPED: 'UPLOADING_STOPPED'
	},
	EXPECTED_CFDI_TYPES: {
		INVOICE: 'I',
		CREDIT_MEMO: 'E',
		PAYMENT_COMPLEMENT: 'P'
	}
}
