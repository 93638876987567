import plusWhite from '@netcurio/frontend-assets/src/icons/plus-white.svg'
import { dateFormatter, getSerieFolio, parseTimestampToTimeZone, URLS } from '@netcurio/frontend-common'
import classNames from 'classnames'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NewCreditMemoModal } from '../../../components/NewCreditMemoModal/NewCreditMemoModal'
import { CreditMemo } from '../../../types'
import { ExtraRequestParams } from '../../../types/FileUpload'
import Constants from '../../../utilities/constants'
import Formatter from '../../../utilities/formatter'
import { getUrlParameter } from '../../../utilities/getUrlParameter'
import styles from '../requestForCreditMemoDetail.module.scss'

export default function CreditMemoListModule({
	creditMemos,
	showCreditMemoDetail,
	statusRFCM,
	readOnly
}: {
	creditMemos?: CreditMemo[]
	showCreditMemoDetail: (creditMemo: CreditMemo) => void
	statusRFCM: string
	readOnly?: boolean
}) {
	const extraRequestParams: ExtraRequestParams = {
		reference: getUrlParameter('requestforcreditmemo')
	}

	const { t } = useTranslation()

	const [openCreditMemoModal, setOpenCreditMemoModal] = useState(false)

	const openInvoiceModal = () => setOpenCreditMemoModal(true)
	const closeInvoiceModal = () => setOpenCreditMemoModal(false)

	function redirectToDetail(UUID: string) {
		sessionStorage.setItem('creditMemoRegistered', 'true')
		location.href = URLS.CREDIT_MEMO_DETAIL + '?creditmemo=' + UUID
	}

	function getCreditMemoList() {
		const rows: ReactElement[] = []

		if (creditMemos && creditMemos.length > 0)
			creditMemos.forEach((creditMemo) => {
				let containerMissingLink = ''
				if (creditMemo.status) {
					if (
						creditMemo.status.key === Constants.CREDIT_MEMO_STATUS.MISSING_LINK ||
						creditMemo.status.key === Constants.CREDIT_MEMO_STATUS.ERROR
					) {
						containerMissingLink = ' container-request-for-credit-memo-missing-link' // If not working use styles.containerMissingLink
					}
				}
				rows.push(
					<div
						key={creditMemo.uuid}
						className={classNames(styles.containerRowList, containerMissingLink)}
						onClick={() => showCreditMemoDetail(creditMemo)}
					>
						<p className={styles.informationRow}>
							{getSerieFolio(creditMemo.serie, creditMemo.folio)}
						</p>
						<p className={styles.informationRow}>
							{dateFormatter.format(parseTimestampToTimeZone(creditMemo.updated_at))}
						</p>
						<p className={styles.informationRow}>{Formatter.currency.format(creditMemo.total)}</p>
					</div>
				)
			})
		return <div>{rows}</div>
	}

	function showCreditMemoAddButton() {
		const disabled = statusRFCM !== Constants.REQUEST_FOR_CREDIT_MEMO_STATUS.ACCEPTED
		return (
			<div className={styles.centeredNewInvoiceBtn}>
				<button
					disabled={disabled}
					className={classNames(styles.btnAddNewCreditMemo, disabled)}
					onClick={openInvoiceModal}
				>
					<img src={plusWhite} className={styles.addNewInvoiceIcon} alt="add" />
					<div className={styles.newEntryBtnText}>{t('newCM')}</div>
				</button>
			</div>
		)
	}

	return (
		<div className={styles.listCreditMemoDetailContainer}>
			<div>
				<p className={styles.subtitleContainerListCreditMemo}>{t('creditMemosRelated')}</p>
			</div>
			{!readOnly && showCreditMemoAddButton()}
			<div className={styles.headersListPaymentsComplements}>
				<p className={styles.headerPaymentsComplements}>{t('serieFolio')}</p>
				<p className={styles.headerPaymentsComplements}>{t('dateText')}</p>
				<p className={styles.headerPaymentsComplements}>{t('total_amount')}</p>
			</div>
			<div
				className={classNames('container-rows-paymentComplements', {
					[styles.rfcmAssociationDocumentOpenWithButton]: !readOnly
				})}
			>
				{getCreditMemoList()}
			</div>

			{openCreditMemoModal && (
				<NewCreditMemoModal
					open
					onClose={closeInvoiceModal}
					redirectToDetail={redirectToDetail}
					extraRequestParams={extraRequestParams}
				/>
			)}
		</div>
	)
}
