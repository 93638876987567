import { URLS } from '@netcurio/frontend-common'
import {
	FilePairs,
	FileResult,
	FileUpload,
	NetcurioButton,
	NetcurioDialog,
	PairFilesIcon,
	ProcessFileUpload,
	Severity,
	ValidationFiles
} from '@netcurio/frontend-components'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import { Auth } from 'aws-amplify'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEnvironment } from '../../hooks/useEnvironment'
import { PaymentComplement } from '../../types'
import { ExtraRequestParams, UploadCFDIResponse } from '../../types/FileUpload'
import { connection, refreshToken } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { getCFDISATValidation, SATCFDIValidationResponse } from '../../utilities/getCFDISATValidation'
import { uploadCFDI } from '../../utilities/uploadCFDI'
import {
	validateFilesHavePairs,
	validateNewFiles,
	validateSizeOfFiles,
	validateTypeOfFile
} from '../../utilities/uploadFilesFuctions'
import styles from './NewPaymentComplementModal.module.scss'
import { COMPANY_SETTINGS, PAYMENT_COMPLEMENT_DETAILS, VOID_PAYMENT_COMPLEMENT } from './query'

interface NewPaymentComplementModalProps {
	open: boolean
	onClose: () => void
	redirectToDetail?: (detail: string) => void
	extraRequestParams?: ExtraRequestParams
}

/**
 * NewPaymentComplementModal
 * @param open <boolean>: If `true`, the component is shown.
 * @param onClose <void>: Callback fired when the component requests to be closed.
 * @param redirectToDetail <(detail: string) => void>: Function to execute a redirect
 * @param extraRequestParams <ExtraRequestParams>: Extra params for the request to the backend
 * @returns component
 */
export function NewPaymentComplementModal({
	open,
	onClose,
	redirectToDetail,
	extraRequestParams
}: NewPaymentComplementModalProps) {
	const { t } = useTranslation()
	const { SELECT, UPLOADING, FINISHED } = ProcessFileUpload
	const { PDF, XML } = Constants.MIME_TYPES
	const acceptFileTypes = `${PDF},${XML}`
	const client: DefaultClient<NormalizedCacheObject> = useMemo(
		(): DefaultClient<NormalizedCacheObject> => connection(),
		[]
	)
	const [canUpload, setCanUpload] = useState(false)
	const [stopUpload, setStopUpload] = useState(false)
	const [process, setProcess] = useState<ProcessFileUpload>(SELECT)
	const [filesToUpload, setFilesToUpload] = useState<Array<File>>([])
	const [filesResult, setFilesResult] = useState<Array<FileResult>>([])

	const filePairs: FilePairs = useMemo(() => {
		return filesToUpload.reduce((group, file) => {
			const nameTrimmed = file.name.substring(0, file.name.lastIndexOf('.'))
			group[nameTrimmed] = group[nameTrimmed] ?? { files: [], isLoading: false }
			group[nameTrimmed].files.push(file)
			return group
		}, {})
	}, [filesToUpload])

	const filesErrorCounts = useMemo(() => {
		const filesPairs = Object.values(filePairs).map((fp) => fp.files) as Array<Array<File>>
		const filesNoPairs = filesPairs.filter((files) => files.length < 2).flat()
		return filesNoPairs.reduce((counts, file) => ({ ...counts, [file.type]: counts[file.type] + 1 }), {
			[PDF]: 0,
			[XML]: 0
		})
	}, [filePairs, PDF, XML])

	const pairFilesUploaded = useMemo(
		() => filesResult.filter((file) => file.icon === PairFilesIcon.Success).length,
		[filesResult]
	)

	useEffect(() => {
		return () => {
			setProcess(SELECT)
			setFilesToUpload([])
			setStopUpload(false)
		}
	}, [open, SELECT])

	useEffect(() => setFilesResult(Object.values(filePairs)), [filePairs])

	const { environment, isSuccess } = useEnvironment()

	const typeOfFileValidation = (files: Array<File>) => validateTypeOfFile(files, acceptFileTypes)
	const filesHavePairs = () => validateFilesHavePairs(filesErrorCounts)
	const validateFilesToUpload = [typeOfFileValidation, validateSizeOfFiles, filesHavePairs]

	function filesUploaded(filesResult: Array<FileResult>): ValidationFiles {
		const total = filesResult.length
		const updated = pairFilesUploaded
		const CFDIName = t('paymentComplementName')
		return {
			severity: total === updated ? Severity.Success : Severity.Info,
			message: t('updatedBillsText', { updated, total, CFDIName })
		}
	}

	const validateFilesResult = [filesUploaded]

	const getToken = useCallback(async () => {
		return await Auth.currentAuthenticatedUser()
			.then((user) => user.signInUserSession.idToken.jwtToken)
			.catch(console.log)
	}, [])

	const submitFiles = useCallback(
		async (controller: AbortController, stopUpload: boolean) => {
			if (!stopUpload) {
				const { SUCCESSFUL, ERROR, UNKNOWN_ERROR } = Constants.UPLOAD_FILES.UPLOAD_FILES_RESPONSE
				const { UPLOADING_STOPPED } = Constants.UPLOAD_FILES.UPLOAD_FILES_ERRORS
				const { PAYMENT_COMPLEMENT: expectedCFDIType } = Constants.UPLOAD_FILES.EXPECTED_CFDI_TYPES

				refreshToken()
				const token = await getToken()
				for (const [fileName, filePairsObj] of Object.entries(filePairs)) {
					filePairs[fileName].isLoading = true
					setFilesResult(Object.values(filePairs))

					const uploadParams = { expectedCFDIType, extraRequestParams }
					const response: UploadCFDIResponse = await uploadCFDI(
						environment?.REST_API_URL || '',
						filePairsObj.files,
						token,
						uploadParams,
						controller
					)

					if (response[SUCCESSFUL]) {
						filePairs[fileName].UUID = response[SUCCESSFUL]
						const { data }: ApolloQueryResult<{ PaymentComplement: PaymentComplement }> =
							await client.query({
								query: PAYMENT_COMPLEMENT_DETAILS,
								variables: {
									uuid: filePairs[fileName].UUID
								}
							})
						const compSettings = await client.query({
							query: COMPANY_SETTINGS,
							variables: {
								rfc: data.PaymentComplement.receiver.rfc
							}
						})

						if (compSettings && compSettings.data?.CompanySettings?.validate_sat) {
							const { isValid, error, messageError }: SATCFDIValidationResponse =
								await getCFDISATValidation({
									uuid: filePairs[fileName].UUID,
									receiver: data.PaymentComplement.receiver.rfc,
									sender: data.PaymentComplement.sender.rfc,
									total: 0 //TODO: Validate if it's necessary to map the attribute total when uploading a payment complement
								})

							if (error) {
								filePairs[fileName].icon = PairFilesIcon.Warning
								filePairs[fileName].errorText = t('failedText')
							} else if (!isValid) {
								await client.mutate({
									mutation: VOID_PAYMENT_COMPLEMENT,
									variables: {
										uuid: filePairs[fileName].UUID,
										reason: messageError ?? 'Error de vigencia del CFDI en el SAT'
									}
								})
								filePairs[fileName].icon = PairFilesIcon.Warning
								filePairs[fileName].errorText = t('paymentComplementNotValidSat')
							} else {
								filePairs[fileName].icon = PairFilesIcon.Success
							}
						} else {
							filePairs[fileName].icon = PairFilesIcon.Success
						}
					} else if (response[ERROR] === '20') {
						filePairs[fileName].icon = PairFilesIcon.Stop
						filePairs[fileName].errorText = t(UPLOADING_STOPPED)
					} else {
						filePairs[fileName].icon = PairFilesIcon.Warning
						filePairs[fileName].errorText = t(response[ERROR] || response[UNKNOWN_ERROR])
					}
					filePairs[fileName].isLoading = false
					setFilesResult(Object.values(filePairs))
				}
				setProcess(FINISHED)
			}
		},
		[filePairs, extraRequestParams, getToken, t, FINISHED, isSuccess]
	)

	useEffect(() => {
		let controller: AbortController
		if (process === UPLOADING) {
			controller = new AbortController()
			submitFiles(controller, stopUpload)
		}
		return () => {
			controller?.abort()
		}
	}, [submitFiles, process, stopUpload, UPLOADING])

	const updateFilesToUpload = (fileList: FileList) =>
		setFilesToUpload((state) => validateNewFiles(fileList, state))

	const cancelUpload = () => setStopUpload(true)
	const processData = () => setProcess(UPLOADING)
	const deleteFile = (index: React.Key) => setFilesToUpload((state) => state.filter((_, i) => i !== index))
	function closeDialog() {
		onClose()
		if (
			redirectToDetail &&
			!extraRequestParams &&
			pairFilesUploaded === 1 &&
			pairFilesUploaded === filesResult.length
		) {
			redirectToDetail(filesResult[0].UUID)
		} else if (pairFilesUploaded > 0) {
			if (window.location.pathname === URLS.DASHBOARDS) location.href = URLS.COMPLEMENT_LIST
			else location.reload()
		}
	}

	const actionButtons = (
		<div className={styles.actionButtonsForDialog}>
			<NetcurioButton variant="text" onClick={closeDialog} disabled={process === UPLOADING}>
				{process === SELECT ? t('cancelButton') : t('closeText')}
			</NetcurioButton>
			{process === SELECT ? (
				<NetcurioButton variant="contained" onClick={processData} disabled={!canUpload}>
					{t('processText')}
				</NetcurioButton>
			) : process === FINISHED ? (
				<NetcurioButton onClick={processData} disabled={true}>
					{t('stopText')}
				</NetcurioButton>
			) : (
				<NetcurioButton variant="contained" onClick={cancelUpload} disabled={process !== UPLOADING}>
					{t('stopText')}
				</NetcurioButton>
			)}
		</div>
	)

	return (
		<NetcurioDialog
			open={open}
			actionButtons={actionButtons}
			titleText={t('uploadNewPaymentComplement')}
			minWidth="960px"
			maxWidth="960px"
		>
			<FileUpload
				fileUploadDescription={t('filePaymentComplementFormats')}
				dragAndDropText={t('dragAndDrop')}
				waitUploadFilesText={t('paymentComplementBeingProcessed')}
				acceptFileTypes={acceptFileTypes}
				acceptMultipleFiles={true}
				process={process}
				filesToUpload={filesToUpload}
				filesResult={filesResult}
				validateFilesToUpload={validateFilesToUpload}
				validateFilesResult={validateFilesResult}
				setCanUpload={setCanUpload}
				updateFilesToUpload={updateFilesToUpload}
				deleteFile={deleteFile}
			/>
		</NetcurioDialog>
	)
}
