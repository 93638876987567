import { useCallback, useEffect } from 'react'

export const useStorageListener = (key: string, callback: () => void) => {
	const handleStorageEvent = useCallback(
		(event) => {
			if (event.key === key) {
				callback()
			}
		},
		[key, callback]
	)

	useEffect(() => {
		window.addEventListener('storage', handleStorageEvent)

		return () => {
			window.removeEventListener('storage', handleStorageEvent)
		}
	}, [handleStorageEvent])
}
