import { t } from 'i18next'
import { ReactElement } from 'react'
import { NetcurioButton } from '../../NetcurioButton/NetcurioButton'
import { NetcurioDialog } from '../../NetcurioDialog/NetcurioDialog'

interface ChangeCompanyProps {
	open: boolean
	close(): void
	accept(): void
}

/**
 * @param open: <boolean> is a state if you can view modal
 * @param close: <function> to close the modal
 * @param accept <function> to do if agree the modal
 * @return component <ReactNode>
 */
export const ChangeCompanyHeaderModal = ({ open, close, accept }: ChangeCompanyProps): ReactElement => {
	return (
		<NetcurioDialog
			open={open}
			titleText={t('companyChange')}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={close}>
						{t('goBackText')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={accept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<div>
				<p>{t('changeCompanyQuestion')}</p>
			</div>
		</NetcurioDialog>
	)
}
