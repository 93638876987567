import { gql } from 'graphql-tag'

export const UPDATE_USER = gql`
	mutation updateUser(
		$name: String
		$lastname: String
		$phone_number: String
		$time_zone: String
		$default_company: String
		$homepage: String
	) {
		updateUser(
			name: $name
			lastname: $lastname
			phone_number: $phone_number
			time_zone: $time_zone
			default_company: $default_company
			homepage: $homepage
		) {
			code
			message
		}
	}
`
