import { gql } from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const REQUEST_FOR_QUOTATION_DETAIL: DocumentNode = gql`
	query RequestForQuotation($id: ID!, $customer: ID!) {
		RequestForQuotation(id: $id, customer: $customer) {
			id
			status {
				key
				value
			}
			created_at
			updated_at
			expiration_date
			date_rejected
			date_closed
			date_quoted
			created_by {
				email
				name
				lastname
			}
			supplier {
				rfc
				name
			}
			customer {
				rfc
				name
			}
			branch_office
			delivery_address_line_1
			delivery_address_line_2
			delivery_address_postal_code
			delivery_address_state
			delivery_address_country
			items {
				position
				product_description
				unit
				quantity
				required_delivery_date
				product_code
			}
			quotations {
				id
				total
				expiration_date
				hasExpired
				supplier {
					rfc
				}
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
			modified_by_supplier {
				email
				name
				lastname
			}
			customer_reference
			setting_validate_catalogue
			incoterm {
				code
				description
			}
			payment_term {
				code
				description
			}
			user_reference
			attachment
		}
	}
`

export const CREATE_RFQ_COMMENT: DocumentNode = gql`
	mutation createRFQComment($rfq: ID!, $customer: ID!, $text: String!) {
		createRFQComment(rfq: $rfq, customer: $customer, text: $text) {
			code
			message
		}
	}
`

export const REQUEST_FOR_QUOTATION_COMMENTS: DocumentNode = gql`
	query RequestForQuotation($id: ID!, $customer: ID!) {
		RequestForQuotation(id: $id, customer: $customer) {
			id
			customer {
				rfc
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`
export const QUOTATION: DocumentNode = gql`
	query Quotation($id: ID!, $supplier: ID!) {
		Quotation(id: $id, supplier: $supplier) {
			id
			total
			currency
			expiration_date
			hasExpired
			supplier {
				rfc
			}
			items {
				position
				status {
					key
				}
				product_code
				product_description
				quantity
				unit
				tax
				unit_price
				net_value
				rejected_reason {
					key
					value
				}
				delivery_proposal_date
				request_position {
					position
					product_description
					quantity
					unit
					required_delivery_date
				}
			}
			purchaseOrders {
				id
				customer {
					rfc
				}
			}
			incoterm {
				code
				description
			}
			payment_term {
				code
				description
			}
		}
	}
`

export const REJECT_REQUEST_FOR_QUOTATION: DocumentNode = gql`
	mutation rejectRequestForQuotation($id: ID!, $customer: ID!) {
		rejectRequestForQuotation(id: $id, customer: $customer) {
			code
			message
		}
	}
`

export const CLOSE_REQUEST_FOR_QUOTATION: DocumentNode = gql`
	mutation closeRequestForQuotation($id: ID!, $customer: ID!) {
		closeRequestForQuotation(id: $id, customer: $customer) {
			code
			message
		}
	}
`

export const QUOTATION_REASONS_REJECT: DocumentNode = gql`
	query QuotationRejectedReasons($language: ID) {
		QuotationRejectedReasons(language: $language) {
			value
			key
		}
	}
`

export const CREATE_QUOTATION: DocumentNode = gql`
	mutation createQuotation(
		$request: ID!
		$customer: ID!
		$total: Float!
		$currency: String!
		$expiration_date: Date!
		$incoterm: ID
		$payment_term: ID
		$items: [QuotationItemInput!]!
	) {
		createQuotation(
			request: $request
			customer: $customer
			total: $total
			currency: $currency
			expiration_date: $expiration_date
			incoterm: $incoterm
			payment_term: $payment_term
			items: $items
		)
	}
`

export const FREIGHT_CATALOGUE: DocumentNode = gql`
	query IncotermsByDescriptionOrCode($search_text: String!) {
		IncotermsByDescriptionOrCode(search_text: $search_text) {
			code
			description
		}
	}
`

export const PAYMENT_TERMS: DocumentNode = gql`
	query PaymentTermsByDescriptionOrCode($search_text: String!, $customer: ID!) {
		PaymentTermsByDescriptionOrCode(search_text: $search_text, customer: $customer) {
			code
			description
		}
	}
`

export const CURRENCIES_CATALOGUE: DocumentNode = gql`
	query Currencies {
		Currencies {
			code
			description
		}
	}
`
