import { Severity } from '@netcurio/frontend-components'

export enum TypesOfStatusMessages {
	Default = 'Default',
	PendingAssociate = 'PendingAssociate',
	EndingProduct = 'EndingProduct',
	SatValidation = 'SatValidation',
	SendAssociation = 'SendAssociation',
	PositionsMatch = 'PositionsMatch',
	SomePositionsDidMatch = 'SomePositionsDidMatch',
	QuantityExceeded = 'QuantityExceeded'
}

interface MessageProperties {
	message: string
	variant?: Severity
}

interface Message {
	[key: string]: MessageProperties
}

export const invoiceEditAlertMessages: Message = {
	Default: {
		message: ''
	},
	SatValidation: {
		message: '',
		variant: Severity.Error
	},
	PendingAssociate: {
		message: 'showMessagePendingAssociate',
		variant: Severity.Info
	},
	EndingProduct: {
		message: 'showMessagePendingProduct',
		variant: Severity.Info
	},
	SendAssociation: {
		message: 'sendAssociation',
		variant: Severity.Info
	},
	PositionsMatch: {
		message: 'invoices.positionsMatch',
		variant: Severity.Info
	},
	SomePositionsDidMatch: {
		message: 'invoices.somePositionsDidMatch',
		variant: Severity.Info
	},
	QuantityExceeded: {
		message: 'invoices.quantityExceeded',
		variant: Severity.Info
	}
}
