import { dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import {
	StatusBadges,
	StatusInformation,
	closedQuotation,
	newRequestQuotation,
	quotedQuotation,
	rejectedQuotation,
	statusLoading
} from '@netcurio/frontend-components'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Constants from '../../../utilities/constants'
import Formatter from '../../../utilities/formatter'
import styles from '../requestForQuotation.module.scss'
import { RequestForQuotation } from '../types'

interface TrackInformationHeaderProps {
	informationRFQ: RequestForQuotation
	showRejectRFQMessage: boolean
	showClosedRFQMessage: boolean
}

export const TrackInformationHeader: FC<TrackInformationHeaderProps> = ({
	informationRFQ,
	showClosedRFQMessage,
	showRejectRFQMessage
}) => {
	const { t } = useTranslation()

	const getStatuses = useCallback((): Array<StatusInformation> => {
		const statusText = informationRFQ?.status?.key ?? ''
		const createdQuotedDate = dateFormatter.format(parseTimestampToTimeZone(informationRFQ.created_at))
		const quotedDate = dateFormatter.format(parseTimestampToTimeZone(informationRFQ.date_quoted))
		const rejectedQuotedDate = dateFormatter.format(
			parseTimestampToTimeZone(informationRFQ.date_rejected)
		)
		const closedQuotedDate = dateFormatter.format(parseTimestampToTimeZone(informationRFQ.date_closed))
		const statuses = {
			[Constants.QUOTATION.NEW]: [newRequestQuotation(createdQuotedDate)],
			[Constants.QUOTATION.QUOTED]: [
				newRequestQuotation(createdQuotedDate),
				quotedQuotation(quotedDate)
			],
			[Constants.QUOTATION.REJECTED]: [
				newRequestQuotation(createdQuotedDate),
				rejectedQuotation(rejectedQuotedDate)
			],
			[Constants.QUOTATION.CLOSED]: informationRFQ.date_quoted
				? [
						newRequestQuotation(createdQuotedDate),
						quotedQuotation(quotedDate),
						closedQuotation(closedQuotedDate)
					]
				: [newRequestQuotation(createdQuotedDate), closedQuotation(closedQuotedDate)],
			DEFAULT: [statusLoading()]
		}
		return statuses[statusText] || statuses['DEFAULT']
	}, [informationRFQ])

	return (
		<div className={styles.containerRFQ}>
			<div className={styles.headerContainerRFQDetails}>
				<div className={styles.displayFlex}>
					<p className={styles.titleHeaderRFQDetails}>{t('titleDetailRFQ')}</p>
					<p className={styles.titleHeaderRFQDetailsId}>{Formatter.id(informationRFQ.id)}</p>
				</div>
			</div>
			<div className={styles.containerGreenSquares}>
				<div className={styles.containerSquareLineDescription}>
					<StatusBadges statusArray={getStatuses()} />
				</div>
				<div className={styles.alignStatusMessages}>
					{(showRejectRFQMessage || showClosedRFQMessage) && (
						<div className={styles.widthMessage}>
							<div className={styles.styleRfqContainer}>
								<p className={styles.messagesRFQ}>
									{t(showRejectRFQMessage ? 'rejectRFQText' : 'closeRFQText')}
								</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
