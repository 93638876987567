import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles'
import {
	MdRefTypefaceFont,
	NetcurioRefErrorAMain,
	NetcurioRefErrorBDark,
	NetcurioRefErrorCLight,
	NetcurioRefPrimaryAMain,
	NetcurioRefPrimaryBDark,
	NetcurioRefPrimaryCLight,
	NetcurioRefSecondaryAMain,
	NetcurioRefSecondaryBDark,
	NetcurioRefSecondaryCLight,
	NetcurioRefSuccessAMain,
	NetcurioRefSuccessBDark,
	NetcurioRefSuccessCLight,
	NetcurioRefWarningAMain,
	NetcurioRefWarningBDark,
	NetcurioRefWarningCLight
} from '@netcurio/frontend-design-tokens'
import { ReactNode } from 'react'
import { NetcurioAlertThemeOptions } from '../NetcurioAlert/NetcurioAlert'
import { NetcurioAutocompleteThemeOptions } from '../NetcurioAutocomplete/NetcurioAutocomplete'
import { NetcurioButtonThemeOptions } from '../NetcurioButton/NetcurioButton'
import { NetcurioCheckboxThemeOptions } from '../NetcurioCheckbox/NetcurioCheckbox'
import { NetcurioDatePickerThemeOptions } from '../NetcurioDatePicker/NetcurioDatePicker'
import { NetcurioGridThemeOptions } from '../NetcurioGrid/NetcurioGrid'
import { NetcurioIconsThemeOptions } from '../NetcurioIcons/NetcurioIcons'
import { NetcurioNumericFieldThemeOptions } from '../NetcurioNumericField/NetcurioNumericField'
import { NetcurioSelectThemeOptions } from '../NetcurioSelect/NetcurioSelect'
import { NetcurioStepperThemeOptions } from '../NetcurioStepper/NetcurioStepper'
import { NetcurioTableThemeOptions } from '../NetcurioTable/NetcurioTable'
import { NetcurioTextFieldThemeOptions } from '../NetcurioTextField/NetcurioTextField'
import { NetcurioTooltipThemeOptions } from '../NetcurioTooltip/NetcurioTooltip'

export const PaletteThemeOptions: ThemeOptions = {
	palette: {
		mode: 'light',
		primary: {
			main: NetcurioRefPrimaryAMain,
			light: NetcurioRefPrimaryCLight,
			dark: NetcurioRefPrimaryBDark
		},
		secondary: {
			main: NetcurioRefSecondaryAMain,
			light: NetcurioRefSecondaryCLight,
			dark: NetcurioRefSecondaryBDark
		},
		error: {
			main: NetcurioRefErrorAMain,
			light: NetcurioRefErrorCLight,
			dark: NetcurioRefErrorBDark
		},
		success: {
			main: NetcurioRefSuccessAMain,
			light: NetcurioRefSuccessCLight,
			dark: NetcurioRefSuccessBDark
		},
		warning: {
			main: NetcurioRefWarningAMain,
			light: NetcurioRefWarningCLight,
			dark: NetcurioRefWarningBDark
		}
	},
	typography: {
		fontFamily: MdRefTypefaceFont
	}
}

const theme = createTheme(
	NetcurioGridThemeOptions,
	NetcurioSelectThemeOptions,
	NetcurioButtonThemeOptions,
	NetcurioTextFieldThemeOptions,
	NetcurioAutocompleteThemeOptions,
	NetcurioNumericFieldThemeOptions,
	NetcurioTooltipThemeOptions,
	NetcurioStepperThemeOptions,
	NetcurioTableThemeOptions,
	NetcurioCheckboxThemeOptions,
	NetcurioAlertThemeOptions,
	NetcurioDatePickerThemeOptions,
	PaletteThemeOptions,
	NetcurioIconsThemeOptions
)

interface NetcurioThemeProps {
	children: ReactNode
}

export const NetcurioTheme = ({ children }: NetcurioThemeProps) => {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
