import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import React, { FormEvent, useEffect, useMemo, useState } from 'react'

import {
	dateFormatterLong,
	getCurrentUser,
	getSerieFolio,
	parseTimestampToTimeZone
} from '@netcurio/frontend-common'
import {
	NetcurioAutocomplete,
	NetcurioButton,
	NetcurioDialog,
	NetcurioTextField,
	NetcurioTooltip,
	Severity
} from '@netcurio/frontend-components'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import ComponentQueries from '../../components/queries'
import { InvoiceRfcm, InvoiceSuggestionRFCM, ModalRFCM, SupplierRfcm } from '../../types/RFCM'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import Formatter from '../../utilities/formatter'
import { useDebounce } from '../../utilities/useDebounce'
import { ErrorModal } from './Modals/ErrorModal/ErrorModal'
import styles from './newRequestForCreditMemo.module.scss'
import * as queries from './queries'

interface IHeaderInformationProps {
	resetChangeInvoice: (invoice: InvoiceRfcm) => void
	setSupplier: (selectedSupplier: SupplierRfcm) => void
	invoiceProps: InvoiceRfcm
	setDisabledRow: (value: boolean) => void
	reasonEmpty: boolean
	rfcmReason: string
	rfcmReasonChange: (e: FormEvent<HTMLInputElement>) => void
	resetRfcmReasonChange: (value: string) => void
	serieFolioUUID: string
	setSerieFolioUUID: (value: string) => void
	cleanInvoice: () => void
}

const HeaderInformation = ({
	resetChangeInvoice,
	setSupplier,
	invoiceProps,
	setDisabledRow,
	reasonEmpty,
	rfcmReason,
	rfcmReasonChange,
	resetRfcmReasonChange,
	serieFolioUUID,
	setSerieFolioUUID,
	cleanInvoice
}: IHeaderInformationProps) => {
	const user = getCurrentUser()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])

	const { t } = useTranslation()

	const [rfcSupplier, setRfcSupplier] = useState<string>('')
	const [invoiceSuggestions, setInvoiceSuggestions] = useState<Array<InvoiceSuggestionRFCM>>([])
	const [invoiceSuggestionsLoading, setInvoiceSuggestionsLoading] = useState(false)
	const [selectedInvoice, setSelectedInvoice] = useState<InvoiceSuggestionRFCM | null>(null)
	const [disabledInvoice, setDisabledInvoice] = useState(true)
	const [openRemoveSupplierAlert, setOpenRemoveSupplierAlert] = useState<boolean>(false)
	const [openRemoveInvoiceAlert, setOpenRemoveInvoiceAlert] = useState<boolean>(false)

	const [selectedSupplier, setSelectedSupplier] = useState<SupplierRfcm | null>(null)
	const [currentSupplierList, setCurrentSupplierList] = useState<Array<SupplierRfcm>>([])
	const [invoiceTotal, setInvoiceTotal] = useState<string>('priceInvoice')
	const [invoiceCurrency, setInvoiceCurrency] = useState<string>('currencyText')
	const [supplierListIsLoading, setSupplierListIsLoading] = useState(false)
	const [supplierInput, setSupplierInput] = useState('')
	const userName = user.name + ' ' + user.lastname

	const debouncedSupplierInput = useDebounce<string>(supplierInput, 500)
	const debouncedSerieFolioUUID = useDebounce<string>(serieFolioUUID, 500)

	const [stateModal, setStateModal] = useState<ModalRFCM>({
		cancelNewRFCMModal: false,
		saveRFCMModal: false,
		errorModal: false,
		errorCode: null
	})

	useEffect(() => setDisabledInvoice(!selectedSupplier), [selectedSupplier])

	useEffect(() => {
		if (reasonEmpty) {
			setInputRFCMReasonBorderColor('border-red-input')
		}
		if (invoiceProps && invoiceProps.uuid != '') {
			setInvoiceCurrency(invoiceProps.currency)
			setInvoiceTotal(Formatter.currency.format(invoiceProps.total))
			setDisabledRow(false)
		} else {
			setInvoiceCurrency('currencyText')
			setInvoiceTotal('priceInvoice')
			setDisabledRow(true)
		}
	})

	useEffect(() => {
		if (debouncedSupplierInput.length >= 3) {
			client
				.query({
					query: ComponentQueries.SUPPLIERS_BY_NAME_OR_CODE,
					variables: { search_text: debouncedSupplierInput.toLowerCase() }
				})
				.then((result) => {
					const allSuppliers = result.data.SuppliersByNameOrCode
					setCurrentSupplierList(allSuppliers)
				})
				.catch((error) => {
					console.error(error)
					const errorCode = showErrorComponent(error)
					if (!expiredToken(errorCode)) {
						setStateModal({
							...stateModal,
							errorModal: true,
							errorCode: errorCode
						})
					}
				})
				.finally(() => setSupplierListIsLoading(false))
		}
	}, [client, debouncedSupplierInput, stateModal])

	useEffect(() => {
		if (rfcSupplier && debouncedSerieFolioUUID.length >= 3) {
			setInvoiceSuggestionsLoading(true)
			client
				.query({
					query: queries.INVOICES_BY_UUID_OR_SERIE_FOLIO,
					variables: { searchText: debouncedSerieFolioUUID.toLowerCase(), sender: rfcSupplier }
				})
				.then((result) => {
					setInvoiceSuggestions(result.data.InvoicesByUUIDOrSerieFolio)
				})
				.catch((error) => {
					console.error(error)
					const errorCode = showErrorComponent(error)
					if (!expiredToken(errorCode)) {
						setStateModal((state) => ({
							...state,
							errorModal: true,
							errorCode: errorCode
						}))
					}
				})
				.finally(() => setInvoiceSuggestionsLoading(false))
		} else setInvoiceSuggestions([])
	}, [client, debouncedSerieFolioUUID, rfcSupplier])

	const selectInvoice = (value: InvoiceSuggestionRFCM) => {
		if (value === null && selectedInvoice && !openRemoveInvoiceAlert && !openRemoveSupplierAlert) {
			setOpenRemoveInvoiceAlert(true)
		} else {
			setSelectedInvoice(value)
			if (value !== null) {
				const invoiceSelected = value
				resetChangeInvoice(invoiceSelected)
			} else {
				setSerieFolioUUID('')
				resetChangeInvoice(null)
			}
		}
	}

	const selectSupplier = (supplier: SupplierRfcm) => {
		if (supplier === null && !openRemoveSupplierAlert && (serieFolioUUID || rfcmReason)) {
			setOpenRemoveSupplierAlert(true)
		} else {
			let supplierData = ''

			setSupplier(supplier)
			if (supplier !== null) {
				supplierData = supplier.rfc
			} else {
				selectInvoice(null)
				cleanInvoice()
				setOpenRemoveSupplierAlert(false)
			}
			resetRfcmReasonChange('')
			setRfcSupplier(supplierData)
			setSelectedSupplier(supplier)
			setOpenRemoveSupplierAlert(false)
		}
	}

	const handleRemoveSupplier = () => {
		selectSupplier(null)
		cleanInvoice()
		setOpenRemoveSupplierAlert(false)
	}

	const getSuppliersLabel = (supplier: SupplierRfcm) => `${supplier.name} - ${supplier.rfc}`
	const validateSuppliersValue = (option: SupplierRfcm, value: SupplierRfcm) => option.rfc === value.rfc
	function onSupplierInputValueChange(input: string) {
		setSupplierInput(input)
		setSupplierListIsLoading(input.length >= 3)
	}

	function getFormatSerieFolioUUID(invoice: InvoiceSuggestionRFCM) {
		let invoiceSerieFolioUUID = getSerieFolio(invoice.serie, invoice.folio)
		if (invoiceSerieFolioUUID === 'NA') {
			invoiceSerieFolioUUID = invoice.uuid
		} else {
			invoiceSerieFolioUUID += ' ' + Constants.SYMBOL.HYPHEN + ' ' + invoice.uuid
		}
		return invoiceSerieFolioUUID
	}
	const validateInvoiceValue = (option: InvoiceSuggestionRFCM, value: InvoiceSuggestionRFCM) =>
		option.uuid === value.uuid
	function onInvoiceInputValueChange(input: string) {
		setSerieFolioUUID(input)
		setInvoiceSuggestionsLoading(input.length >= 3)
	}

	return (
		<>
			<div className={styles.moduleContainer}>
				<div className={styles.headerContainerRFCM}>
					<div className={styles.titleHeaderRFCM}>{t('newRCMTitle')}</div>
				</div>
				<div className={styles.informationContainerHeaderRFCM}>
					<div className={styles.singleModuleRFCM}>
						<div className={styles.rightModuleRFCM}>
							<div className={styles.titleModuleRFCM}>{t('applicantInformation')}</div>
							<div className={classNames(styles.alignModulesRFCM, styles.marginBottomMedium)}>
								<div className={styles.singleModuleRFCM}>
									<div className={styles.titleFieldsRFCM}>{t('createByText')}</div>
									<div className={styles.readOnlyField}>{userName}</div>
								</div>
								<div className={styles.singleModuleRFCM}>
									<div className={styles.titleFieldsRFCM}>{t('creationDate')}</div>
									<div className={styles.readOnlyField}>
										{dateFormatterLong.format(parseTimestampToTimeZone(new Date()))}
									</div>
								</div>
								<div className={styles.singleModuleRFCM}>
									<div className={styles.titleFieldsRFCM}>{t('companyNameText')}</div>
									<div className={styles.readOnlyField}>{user.company.name}</div>
								</div>
								<div className={styles.singleModuleRFCM}>
									<div className={styles.titleFieldsRFCM}>{t('textRFC')}</div>
									<div className={styles.readOnlyField}>{user.company.rfc}</div>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.singleModuleRFCM}>
						<div className={styles.rightModuleRFCM}>
							<div className={styles.titleModuleRFCM}>{t('supplierInformation')}</div>
							<div className={styles.alignModulesRFCM}>
								<div className={classNames(styles.singleModuleRFCM, styles.suplierWidthRFCM)}>
									<div className={styles.titleFieldsRFCM}>{t('supplierText')}</div>
									<div className={styles.supplierSelectorFieldRFCM}>
										<NetcurioAutocomplete
											size="small"
											height="smaller"
											placeholder={t('selectSupplier')}
											variant="outlined"
											options={currentSupplierList}
											getOptionLabel={getSuppliersLabel}
											isOptionEqualToValue={validateSuppliersValue}
											value={selectedSupplier}
											onSelectValue={selectSupplier}
											inputValue={supplierInput}
											onInputValueChange={onSupplierInputValueChange}
											loading={supplierListIsLoading}
											minLength={3}
										/>
									</div>
								</div>
								<div className={classNames(styles.singleModuleRFCM, styles.padingSuplierRFC)}>
									<div className={styles.titleFieldsRFCM}>{t('supplierRfc')}</div>
									<div className={styles.readOnlyField}>{rfcSupplier}</div>
								</div>
							</div>
							<div className={classNames(styles.alignModulesRFCM, styles.resizeReasonRFCM)}>
								<div className={styles.singleModuleRFCM}>
									<div className={styles.titleFieldsRFCM}>{t('reasonRequest')}</div>
									<div>
										<NetcurioTextField
											fullWidth
											placeholder={t('reasonCreditMemo')}
											variant="outlined"
											size="small"
											value={rfcmReason}
											onChange={(e) => {
												rfcmReasonChange(e)
											}}
											maxLength={100}
										/>
									</div>
									{reasonEmpty && (
										<p className={'missing-fields margin-left-0'}>{t('missingFields')}</p>
									)}
								</div>
							</div>
							<div className={styles.alignCenteredModulesRFCM}>
								<div className={styles.alignModulesRFCM}>
									<div className={styles.singleModuleRFCM}>
										<div className={styles.titleFieldsRFCM}>{t('relatedInvoice')}</div>
										<NetcurioTooltip title={t('invoiceText')}>
											<div className={styles.supplierSelectorFieldRFCM}>
												<NetcurioAutocomplete
													size="small"
													height="smaller"
													placeholder={t('searchInvoice')}
													variant="outlined"
													options={invoiceSuggestions}
													getOptionLabel={getFormatSerieFolioUUID}
													isOptionEqualToValue={validateInvoiceValue}
													value={selectedInvoice}
													onSelectValue={selectInvoice}
													inputValue={serieFolioUUID}
													onInputValueChange={onInvoiceInputValueChange}
													loading={invoiceSuggestionsLoading}
													minLength={3}
													disabled={disabledInvoice}
												/>
											</div>
										</NetcurioTooltip>
									</div>
								</div>
								<div className={styles.alignModulesRFCM}>
									<div
										className={classNames(
											styles.singleModuleRFCM,
											styles.reducedInputRFCM
										)}
									>
										<div className={styles.titleFieldsRFCM}>{t('invoiceCurrency')}</div>
										<div className={styles.readOnlyField}>{t(invoiceCurrency)}</div>
									</div>
									<div
										className={classNames(
											styles.singleModuleRFCM,
											styles.reducedInputRFCM
										)}
									>
										<div className={styles.titleFieldsRFCM}>{t('invoiceAmount')}</div>
										<div className={styles.readOnlyField}>{t(invoiceTotal)}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NetcurioDialog
				onClose={() => setOpenRemoveSupplierAlert(false)}
				open={openRemoveSupplierAlert}
				titleText={t('deleteSupplierTitle')}
				headerTitleSeverity={Severity.Warning}
				actionButtons={
					<>
						<NetcurioButton onClick={() => setOpenRemoveSupplierAlert(false)}>
							{t('comeBackTextModal')}
						</NetcurioButton>
						<NetcurioButton variant="contained" onClick={handleRemoveSupplier}>
							{t('deleteText')}
						</NetcurioButton>
					</>
				}
			>
				{t('deleteSupplierQuestion')}
			</NetcurioDialog>
			<NetcurioDialog
				onClose={() => setOpenRemoveInvoiceAlert(false)}
				open={openRemoveInvoiceAlert}
				titleText={t('deleteInvoiceTitle')}
				headerTitleSeverity={Severity.Warning}
				actionButtons={
					<>
						<NetcurioButton onClick={() => setOpenRemoveInvoiceAlert(false)}>
							{t('comeBackTextModal')}
						</NetcurioButton>
						<NetcurioButton
							variant="contained"
							onClick={() => {
								selectInvoice(null)
								cleanInvoice()
								setOpenRemoveInvoiceAlert(false)
							}}
						>
							{t('deleteText')}
						</NetcurioButton>
					</>
				}
			>
				{t('deleteInvoiceQuestion')}
			</NetcurioDialog>
			<ErrorModal open={!!stateModal?.errorCode} errorCode={stateModal?.errorCode} />
		</>
	)
}
export default HeaderInformation
