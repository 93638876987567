import { tableFillHeader } from '../../../../../utilities/tableFillHeader'
import { tableFillSelectionFilter } from '@netcurio/frontend-common'
import Constants from '../../../../../utilities/constants'
import styles from '../../../adminConsole.module.scss'

export function fillFieldsHeaderTable(): Array<tableFillHeader> {
	const userColumn: tableFillHeader = new tableFillHeader(
		'userAdminHeader',
		styles.tableHeaderMediumSize,
		'',
		'email',
		'text'
	)
	const typeColumn: tableFillHeader = new tableFillHeader(
		'typeAdminHeader',
		styles.tableHeaderSmallSize,
		'',
		'is_admin',
		'type'
	)
	const nameColumn: tableFillHeader = new tableFillHeader(
		'nameAdminHeader',
		styles.tableHeaderMediumSize,
		'',
		'name_lastname',
		'text'
	)
	const statusColumn: tableFillHeader = new tableFillHeader(
		'statusAdminHeader',
		styles.tableHeaderxSmallSize,
		'',
		'status',
		'status'
	)
	const createdDateColumn: tableFillHeader = new tableFillHeader(
		'createdDateAdminHeader',
		styles.tableHeaderxSmallSize,
		'',
		'created_at',
		'date'
	)
	const createdByColumn: tableFillHeader = new tableFillHeader(
		'createdByAdminHeader',
		styles.tableHeaderMediumSize,
		'',
		'created_by',
		'text'
	)

	return [userColumn, typeColumn, nameColumn, statusColumn, createdDateColumn, createdByColumn]
}

export function objectStatusFilterArray(): Array<tableFillSelectionFilter> {
	const newStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.USER_STATUS.ACTIVE,
		'active-status-button'
	)
	const quotedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.USER_STATUS.INACTIVE,
		'inactive-status-button'
	)
	const rejectedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.USER_STATUS.BLOCKED,
		'blocked-rfq-status-button'
	)
	const closedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.USER_STATUS.UNSUBSCRIBED,
		'unsubscribed-status-button'
	)

	return [newStatusOption, quotedStatusOption, rejectedStatusOption, closedStatusOption]
}

export function objectTypeFilterArray(): Array<tableFillSelectionFilter> {
	const typeStandardOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.USER_TYPES.STANDARD,
		'user-standard-type-button'
	)
	const typeAdministratorOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.USER_TYPES.ADMINISTRATOR,
		'user-administrator-type-button'
	)

	return [typeStandardOption, typeAdministratorOption]
}
