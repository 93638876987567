import { Severity, ValidationFiles } from '@netcurio/frontend-components'
import Constants from './constants'
import { t } from 'i18next'

export function validateNewFiles(fileList: FileList, filesToUpload: Array<File>): Array<File> {
	for (const file of Array.from(fileList)) {
		const noDuplicateFile = filesToUpload.findIndex((f) => f.name === file.name) === -1
		if (noDuplicateFile) filesToUpload.push(file)
	}

	filesToUpload.sort((fileA, fileB) => {
		if (fileA.name > fileB.name) return 1
		if (fileA.name < fileB.name) return -1
		return 0
	})

	return [...filesToUpload]
}

export function validateSizeOfFiles(files: Array<File>): ValidationFiles {
	const { SIZE_MB, LIMIT_SIZE_FILES } = Constants.SIZE
	const filesSize = files.reduce((total, current) => total + current.size, 0)
	const filesSizeFixed = parseFloat((filesSize / SIZE_MB ** 2).toFixed(2))
	const sizeExceeded = filesSizeFixed > LIMIT_SIZE_FILES
	return sizeExceeded
		? { severity: Severity.Info, message: t('filesSizeTotalExceeded') }
		: { severity: Severity.Success, message: '' }
}

export function validateFilesHavePairs(filesErrorCounts: { [x: string]: number }): ValidationFiles {
	const { PDF, XML } = Constants.MIME_TYPES

	const filesHavePairs = { severity: Severity.Success, message: '' }
	if (Object.values(filesErrorCounts).some((error) => error > 0)) {
		filesHavePairs.severity = Severity.Info
		if (filesErrorCounts[PDF] === filesErrorCounts[XML]) {
			filesHavePairs.message = t('missingFilePairs')
		} else if (filesErrorCounts[PDF] > 0) {
			filesHavePairs.message = t('missingFileXML')
		} else if (filesErrorCounts[XML] > 0) {
			filesHavePairs.message = t('missingFilePDF')
		}
	}
	return filesHavePairs
}

export const validateTypeOfFile = (files: Array<File>, acceptFileTypes: string): ValidationFiles => {
	const fileTypeWrong = files.some((file) => !acceptFileTypes.includes(file.type))
	return fileTypeWrong
		? { severity: Severity.Info, message: t('messageInvalidFile') }
		: { severity: Severity.Success, message: '' }
}
