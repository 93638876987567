import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
interface RejectEnrollmentModalProps {
	open: boolean
	onClose(): void
	onReject(): void
}

/**
 * RejectEnrollmentModal
 * @description Helps to display a modal when the user is trying to confirm a PO
 * @param open <boolean>: If `true`, the component is shown
 * @param onClose <void>: Callback fired to close modal
 * @param onReject <void>: Callback fired when the user reject the action the modal
 * @return component <ReactNode>
 **/

export const RejectEnrollmentModal: FC<RejectEnrollmentModalProps> = ({ onReject, onClose, open }) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('titleRejectEnrollment')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onReject}>
						{t('rejectEnrollment')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('questionRejectEnrollment')}</p>
		</NetcurioDialog>
	)
}
