import { Environment } from './environment'

export const fetchEnvironment = async (): Promise<Environment> => {
	const headers = {
		Accept: 'application/json'
	}

	return fetch('/api/v1/environment', {
		method: 'GET',
		headers
	}).then((response) => response.json())
}
