import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog, Severity } from '@netcurio/frontend-components'

interface VoidInvoiceModalProps {
	onClose: () => void
	onAccept: () => void
	open: boolean
}

export const VoidInvoiceModal: FC<VoidInvoiceModalProps> = ({ onClose, onAccept, open }): ReactElement => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('voidInvoiceModalTitle')}
			open={open}
			headerTitleSeverity={Severity.Warning}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('voidInvoiceConfirmation')}</p>
		</NetcurioDialog>
	)
}
