import { NetcurioButton, NetcurioDialog, Severity } from '@netcurio/frontend-components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface SendInvoiceConfirmationProps {
	open: boolean
	onClose(): void
	onAccept(): void
}
export const SendInvoiceConfirmationModal: FC<SendInvoiceConfirmationProps> = ({
	open,
	onClose,
	onAccept
}: SendInvoiceConfirmationProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioDialog
			open={open}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={onClose}>
						{t('closeText')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('sendInvoice')}
					</NetcurioButton>
				</>
			}
			headerTitleSeverity={Severity.Info}
			titleText={t('titleSendInvoice')}
			minWidth={'60rem'}
			maxWidth={'60rem'}
		>
			<p>{t('sendInvoiceConfirmation')}</p>
		</NetcurioDialog>
	)
}
