import gql from 'graphql-tag'
export const MANUAL_INVOICE_AUTHORIZATION = gql`
	query ManualInvoiceAuthorization($uuid: ID!) {
		ManualInvoiceAuthorization(uuid: $uuid) {
			validations {
				accountingPeriodValidation {
					value
				}
				currencyValidation {
					value
					positions
				}
				priceValidation {
					value
					positions
				}
				taxValidation {
					value
					positions
				}
				quantityValidation {
					value
					positions
				}
				missingMerchandiseValidation {
					value
					positions
				}
				amountValidation {
					value
				}
			}
			overrideBy {
				email
				name
				lastname
			}
			overrideOn
			overrideMessage
		}
	}
`
