import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import {
	NetcurioButton,
	NetcurioDatePicker,
	NetcurioDialog,
	NetcurioGrid,
	NetcurioInputLabel
} from '@netcurio/frontend-components'
import { useTranslation } from 'react-i18next'
import { NetcurioTextField } from '@netcurio/frontend-components/src/NetcurioTextField/NetcurioTextField'
import { Dayjs } from 'dayjs'

interface RegisterPaymentModalProps {
	open: boolean
	onAccept(): void
	onClose(): void
	registerBankReference: string
	registerPaymentDate: Dayjs
	setRegisterPaymentDate: Dispatch<SetStateAction<Dayjs>>
	setRegisterBankReference: Dispatch<SetStateAction<string>>
}
/**
 * RegisterPaymentModal
 * @description Helps to display a modal when the user is trying to reject a RFQ
 * @param open <boolean>: If `true`, the component is shown
 * @param onClose <boolean>: If `false`, the component is hide
 * @param onAccept <void>: Callback fired when the user accept the action the modal
 * @param registerPaymentDate <Dayjs> is a value of datePicker
 * @param registerBankReference <string> is a value of the text input
 * @param setRegisterBankReference <Dispatch<SetStateAction<Dayjs>>> is a function that change value of registerBankReference
 * @param setRegisterPaymentDate <Dispatch<SetStateAction<string>>> is a function that change value of registerPaymentDate
 * @return component <ReactNode>
 **/
export const RegisterPaymentModal = ({
	open,
	onAccept,
	onClose,
	registerPaymentDate,
	registerBankReference,
	setRegisterPaymentDate,
	setRegisterBankReference
}: RegisterPaymentModalProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioDialog
			open={open}
			titleText={t('registerPayment')}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton
						variant="contained"
						disabled={!registerPaymentDate}
						onClick={() => {
							onAccept()
						}}
					>
						{t('registerCompanyButton')}
					</NetcurioButton>
				</>
			}
		>
			<NetcurioGrid container direction="column" width="100%">
				<NetcurioGrid item width="100%" textAlign="center">
					<p>{t('paymentText')}</p>
				</NetcurioGrid>
				<NetcurioGrid item marginY="1rem">
					<NetcurioInputLabel>{t('datePaymentText')}</NetcurioInputLabel>
					<NetcurioDatePicker
						height="smaller"
						value={registerPaymentDate}
						onChange={(value: Dayjs) => {
							setRegisterPaymentDate(value)
						}}
					/>
				</NetcurioGrid>
				<NetcurioGrid item marginY="1rem">
					<NetcurioInputLabel>{t('bankPaymentText')}</NetcurioInputLabel>
					<NetcurioTextField
						fullWidth
						id="standard-basic"
						variant="outlined"
						placeholder={t('optional')}
						value={registerBankReference}
						onChange={(event: ChangeEvent<HTMLInputElement>) => {
							setRegisterBankReference(event.target.value)
						}}
					/>
				</NetcurioGrid>
			</NetcurioGrid>
		</NetcurioDialog>
	)
}
