import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const INVOICES_DETAIL: DocumentNode = gql`
	query Invoice($uuid: ID!) {
		Invoice(uuid: $uuid) {
			uuid
			serie
			folio
			status {
				key
				value
			}
			sender {
				rfc
				name
			}
			receiver {
				rfc
				name
			}
			reference
			reference_type
			purchase_order {
				id
				customer {
					rfc
				}
				customer_reference
			}
			consumption {
				id
				customer {
					rfc
				}
				customer_reference
			}
			uploaded_by {
				email
				name
				lastname
			}
			modified_by_supplier {
				email
				name
				lastname
			}
			modified_by_customer {
				email
				name
				lastname
			}
			override_by {
				email
				name
				lastname
			}
			payment_method
			currency
			subtotal
			iva
			total
			date
			proposed_payment_date
			payment_date
			date_voided
			bank_reference
			updated_at
			created_at
			advance
			items {
				position
				concept
				quantity
				unit_price
				net_amount
				tax
				error
				purchase_order_item {
					code
					name
					unit_price
				}
				consumption_item {
					code
					description
					unit_price
				}
				reference_position
				error
				code
			}
			payment_complements {
				uuid
				serie
				folio
				date
				created_at
				payments {
					position
					date
					currency
					amount
					items {
						uuid
						paid_amount
						pending_balance
						invoice {
							uuid
							serie
							folio
						}
					}
				}
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
			invoice_documents {
				link
				document_type {
					code
					description
				}
			}
			error {
				key
				value
			}
		}
	}
`
