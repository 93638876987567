import { fillModal } from '../components/dialogModal/fillModal'

export const modalCompanyChange = () => {
	const modalCompanyChange = new fillModal(
		'changeCompany',
		'',
		'alertChangeCompanyTitle',
		'changeAction',
		'acceptTextButton',
		'',
		''
	)
	return [modalCompanyChange]
}
