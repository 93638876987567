import { NetcurioButton, NetcurioDialog, Severity } from '@netcurio/frontend-components'
import { t } from 'i18next'
import React from 'react'

interface DeleteDocumentModalProps {
	open: boolean
	onClose(): void
	deleteDocument(): void
}

export const DeleteDocumentModal = ({ open, onClose, deleteDocument }: DeleteDocumentModalProps) => {
	return (
		<NetcurioDialog
			open={open}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={onClose}>
						{t('cancelButton')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={deleteDocument}>
						{t('delete')}
					</NetcurioButton>
				</>
			}
			headerTitleSeverity={Severity.Warning}
			titleText={t('deleteDocumentsAlert')}
			minWidth={'60rem'}
			maxWidth={'60rem'}
		>
			<p>{t('deleteDocument')}</p>
		</NetcurioDialog>
	)
}
