import { getTimeZone } from '@netcurio/frontend-common'
import * as i18next from 'i18next'
import moment from 'moment-timezone'
import Constants from './constants'

const formatId = (id: unknown) => {
	return typeof id === 'string' ? id.replace(Constants.REGEX.LEADING_ZEROS, '') : id
}

const serieFolio = (serie: unknown, folio: unknown) => {
	let serieFolioValue = ''
	if (serie && typeof serie === 'string' && serie.trim().length !== 0) {
		serieFolioValue = serie
	}
	if (folio && typeof folio === 'string' && folio.trim().length !== 0) {
		if (serieFolioValue.length !== 0) {
			serieFolioValue = serieFolioValue + Constants.SYMBOL.HYPHEN + folio
		} else {
			serieFolioValue = folio
		}
	}
	return serieFolioValue.length !== 0 ? serieFolioValue : 'N/A'
}

const codeDescription = (code: string, description: string) => {
	let codeDescription = ''
	if (code && code.trim().length !== 0) {
		codeDescription = code
	}
	if (description && description.trim().length !== 0) {
		if (codeDescription.length !== 0) {
			codeDescription = codeDescription + ' ' + Constants.SYMBOL.HYPHEN + ' ' + description
		} else {
			codeDescription = description
		}
	}
	return codeDescription.length !== 0 ? codeDescription : 'N/A'
}

const initDateFormat = (initRangeActual: string): number | void => {
	if (initRangeActual) {
		const hour = ' 00:00:00'
		const initDate = changeDateOfTimezoneAndHour(new Date(initRangeActual), hour)
		return new Date(Date.parse(initDate + '+0000')).getTime()
	}
}

const finalDateFormat = (finalRangeActual: string): number | void => {
	if (finalRangeActual) {
		const hour = ' 23:59:59'
		const finalDate = changeDateOfTimezoneAndHour(new Date(finalRangeActual), hour)
		return new Date(Date.parse(finalDate + '+0000')).getTime()
	}
}

const dateToSaveFormat = (dateToSave: Date): number | void => {
	if (dateToSave) {
		const hour = ' 09:00:00'
		const dateSave = changeDateOfTimezoneAndHour(dateToSave, hour)
		return new Date(Date.parse(dateSave + '+0000')).getTime()
	}
}

const changeDateOfTimezoneAndHour = (date: Date, hour: string | number) => {
	const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
	const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
	const dateUpdate =
		date.getFullYear() + Constants.SYMBOL.HYPHEN + month + Constants.SYMBOL.HYPHEN + day + hour
	const userTimezone = getTimeZone()
	const timezone = userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone
	return moment.tz(dateUpdate, timezone).utc().format('YYYY-MM-DDTHH:mm')
}

const getDateForCalendar = (date: Date): Date => {
	return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)
}

const nullValueFormatting = (value: unknown) => {
	return value !== null && value !== undefined ? value : Constants.SYMBOL.HYPHEN
}

const nullValueFormattingToNA = (value: unknown) => {
	return value !== null && value !== undefined ? value : Constants.SYMBOL.NA
}

const hyphenDateFormat = (date: Date) => {
	const userTimezone = getTimeZone()
	const timezone = userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone
	return moment.tz(date, timezone).format('DD-MM-YYYY')
}

const getInvoiceIdRelated = (idRelated: string, customerReference: string) => {
	const naText = i18next.t(Constants.SYMBOL.NA)
	const formattedId = idRelated ? formatId(idRelated) : naText
	const formattedCustomerReference = customerReference ? formatId(customerReference) : naText

	return `${formattedId} ${Constants.SYMBOL.HYPHEN} ${formattedCustomerReference}`
}

export default {
	// TODO: REPLACE FROM COMMON
	date: new Intl.DateTimeFormat('es-MX', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		timeZone: 'UTC'
	}),
	// TODO: REPLACE FROM COMMON
	currency: new Intl.NumberFormat('es-MX', {
		style: 'currency',
		currency: 'MXN'
	}),
	// TODO: REPLACE FROM COMMON
	percent: new Intl.NumberFormat('es-MX', {
		style: 'percent',
		maximumFractionDigits: 2
	}),
	id: formatId,
	serieFolio: serieFolio,
	initDateFormat: initDateFormat,
	finalDateFormat: finalDateFormat,
	dateToSaveFormat: dateToSaveFormat,
	getDateForCalendar: getDateForCalendar,
	nullValueFormatting: nullValueFormatting,
	hyphenDateFormat: hyphenDateFormat,
	nullValueFormattingToNA: nullValueFormattingToNA,
	codeDescription,
	getInvoiceIdRelated
}
