import arrowDown from '@netcurio/frontend-assets/src/icons/arrow-down-reports.svg'
import arrowUp from '@netcurio/frontend-assets/src/icons/arrow-up-reports.svg'
import { Company, Status } from '@netcurio/frontend-common'
import classNames from 'classnames'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { NetcurioTooltip } from '../NetcurioTooltip/NetcurioTooltip'
import styles from './DropdownMain.module.scss'

export enum DROPDOWN_TYPES {
	COMPANY = 'COMPANY',
	REPORT = 'REPORT'
}
const enum USER_STATUS {
	BLOCKED = 'BLOCKED'
}
export interface Item {
	value: string | null
	label: string | null
	tooltip?: string
	blocked?: boolean
}
export interface Report {
	label: string
	items: Array<{
		label: string
		url: string
	}>
}
interface DOMEvent<T extends EventTarget> extends Event {
	readonly target: T
}
interface DropdownMainProps {
	data: Array<Company> | Array<Report>
	menuType: DROPDOWN_TYPES
	defaultValue: string
	onChange(item: Item | null): void
	label: string | null
	showLabelTooltip?: boolean
	maxHeight?: '90vh' | '45vh'
}

export const DropdownMain = ({
	data,
	menuType,
	defaultValue,
	onChange,
	label,
	showLabelTooltip = true,
	maxHeight = '90vh'
}: DropdownMainProps) => {
	const [isOpen, setOpen] = useState(false)
	const [positionTooltip, setPositionTooltip] = useState<'left' | 'top'>('top')

	// TODO: Why do we have this? Should it be a useMemo instead? Do we need to persist this state?
	const [items] = useState<Array<Item>>(() => {
		switch (menuType) {
			case DROPDOWN_TYPES.COMPANY: {
				const company = data as Array<Company>
				return [
					{
						label: t('selectCompanyAtLoginTitle'),
						value: null
					},
					...company.map((company: Company) => ({
						label: company.name,
						value: company.rfc,
						tooltip:
							(company.status as Status).key === USER_STATUS.BLOCKED
								? t('fullCompanyBlock')
								: ''
					}))
				]
			}
			case DROPDOWN_TYPES.REPORT: {
				const report = data as Array<Report>
				return [
					{
						label: report[0].label,
						value: null
					},
					report[0].items.map((item) => ({
						label: item.label,
						value: item.url
					})),
					{
						label: report[1].label,
						value: null
					},
					report[1].items.map((item) => ({
						label: item.label,
						value: item.url
					}))
				].flat()
			}
		}
	})
	const icon = isOpen ? arrowUp : arrowDown
	const isMenu = menuType === DROPDOWN_TYPES.COMPANY

	useEffect(() => {
		window.document.addEventListener('click', handleOutsideClick)

		return () => {
			window.document.removeEventListener('click', handleOutsideClick)
		}

		/**
		 * TODO: Check if we need to encapsulate handleOutsideClick
		 * in a useCallback and add it as dependency.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const toggleDropdown = () => setOpen(!isOpen)

	const handleItemClick = (item: Item) => {
		if (item.value && !item.tooltip && item.label !== label) {
			onChange(item)
		}
	}

	const handleOutsideClick = (event: DOMEvent<HTMLElement>) => {
		if (
			event.target?.id !== 'dropdown-component' &&
			event.target?.id !== 'dropdown-component-label' &&
			event.target?.id !== 'dropdown-component-icon' &&
			!isOpen
		) {
			setOpen(false)
		}
	}

	return (
		<div className={styles.dropdown}>
			<NetcurioTooltip title={showLabelTooltip && label ? label : ''} placement={positionTooltip}>
				<div
					id="dropdown-component"
					className={classNames(styles.buttonMenu, styles.dropdownHeader, {
						[styles.dropdownHeaderMenu]: isMenu,
						[styles.dropdownHeaderReport]: !isMenu,
						[styles.focus]: isOpen
					})}
					onClick={toggleDropdown}
					onMouseEnter={() => setPositionTooltip('left')}
					onMouseLeave={() => setPositionTooltip('top')}
				>
					<p id="dropdown-component-label" className={styles.textButtonMenu}>
						{label || defaultValue}
					</p>
					<img
						id="dropdown-component-icon"
						className={classNames(styles.toggleOn, { [styles.companiesArrowIconColor]: isMenu })}
						src={icon}
						alt="down sorting"
					/>
				</div>
			</NetcurioTooltip>
			<div
				className={classNames(styles.dropdownBody, {
					[styles.open]: isOpen,
					[styles.dropdownHeaderMenu]: isMenu,
					[styles.dropdownHeaderReport]: !isMenu
				})}
				style={
					isOpen
						? {
								maxHeight
							}
						: {}
				}
			>
				{items.map((item: Item, index: number) => (
					<NetcurioTooltip
						title={item.tooltip}
						placement={positionTooltip}
						key={`${index}-${item.value}`}
					>
						<div>
							<button
								className={classNames(
									styles.dropdownItem,
									{
										[styles.dropdownItemDisabled]: !item.value || item.tooltip,
										[styles.dropdownBlocked]: item.tooltip
									},
									label == item.label ? styles.companySelected : ''
								)}
								onClick={() => handleItemClick(item)}
								disabled={!item.value}
							>
								{item.label}
							</button>
						</div>
					</NetcurioTooltip>
				))}
			</div>
		</div>
	)
}
