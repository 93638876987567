import React from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'

interface SendNewEnrollmentModalProps {
	open: boolean
	confirm: () => void
	close: () => void
}

/**
 * SendNewEnrollmentModal
 * @description Helps to display a message when user try to block another user
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param confirm <function>: this function handles any action on the parent when confirm action is triggered
 * @returns Modal component
 */
export const SendNewEnrollmentModal = ({ open, confirm, close }: SendNewEnrollmentModalProps) => {
	const { t } = useTranslation()

	const triggerConfirm = () => {
		confirm()
	}

	const triggerClose = () => {
		close()
	}

	return (
		<>
			<NetcurioDialog
				open={open}
				onClose={triggerClose}
				titleText={t('confirmNewEnrollmentTitle')}
				actionButtons={
					<>
						<NetcurioButton variant="text" onClick={triggerClose}>
							{t('goBackText')}
						</NetcurioButton>
						<NetcurioButton variant="contained" onClick={triggerConfirm}>
							{t('confirmButtonText')}
						</NetcurioButton>
					</>
				}
			>
				<p>{t('confirmNewEnrollmentText')}</p>
			</NetcurioDialog>
		</>
	)
}
