import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const CREATE_GOOD_RECEIPT: DocumentNode = gql`
	mutation createGoodsReceipt($purchase_order: ID, $items: [GoodsReceiptItemInput!]!) {
		createGoodsReceipt(purchase_order: $purchase_order, items: $items)
	}
`

export const ITEM_PENDING_GOOD_RECEIPT: DocumentNode = gql`
	query ItemsPendingForGoodsReceipt($purchase_order: ID!, $customer: ID!) {
		ItemsPendingForGoodsReceipt(purchase_order: $purchase_order, customer: $customer) {
			position
			name
			code
			confirmed_amount
			pending_delivery
			unit
			storage_location
			requires_confirmation
		}
	}
`
