import { NetcurioButton, NetcurioDialog, Severity } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ChangePoTypeModalProps {
	open: boolean
	onClose: () => void
	onAccept: () => void
}

/**
 * ConfirmPurchaseOrderModal
 * @description Helps to display a message when user try to save the changes to ticket permissions
 * @param open <boolean>: tells if the dialog is open or not
 * @param onClose <function>: this function handles any action on the parent when close action is triggered
 * @param onAccept <function>: this function handles any action on the parent when accept action is triggered
 * @returns Modal component
 */
export const ChangePoTypeModal = ({ open, onClose, onAccept }: ChangePoTypeModalProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioDialog
			open={open}
			headerTitleSeverity={Severity.Warning}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={onClose}>
						{t('cancelButton')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('change')}
					</NetcurioButton>
				</>
			}
		>
			<label>{t('changeOrderTypeQuestion')}</label>
		</NetcurioDialog>
	)
}
