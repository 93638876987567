import box from '@netcurio/frontend-assets/src/icons/box.svg'
import { NetcurioIconButton, NetcurioTableCell, NetcurioTableRow } from '@netcurio/frontend-components'
import classNames from 'classnames'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GoodReceiptItem } from '../../types'
import styles from './goodsReceiptDetail.module.scss'
import { BatchesGoodsReceiptDetailModal } from './Modals/BatchesGoodsReceiptDetailModal/BatchesGoodsReceiptDetailModal'

interface ItemProps {
	data: GoodReceiptItem
}
export const Item: FC<ItemProps> = ({ data }) => {
	const { t } = useTranslation()
	const [showBatchesDetail, setShowBatchesDetail] = useState<boolean>(false)

	return (
		<NetcurioTableRow isDetailRow>
			<NetcurioTableCell align="center">
				<p>{data.position}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p>{data.product_description}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p>{data.purchase_order_item?.storage_location || t('NAtext')}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p>{data.quantity + ' ' + data.unit}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<NetcurioIconButton
					disabled={!(data.batches && data.batches.length > 0)}
					onClick={() => setShowBatchesDetail(true)}
				>
					<div
						className={classNames(
							styles.containerIconBatch,
							data.batches && data.batches.length > 0
								? styles.iconBatchActive
								: styles.iconBatchDisabled
						)}
					>
						<img src={box} alt="batches" className={styles.iconBatch} />
					</div>
				</NetcurioIconButton>
			</NetcurioTableCell>
			<BatchesGoodsReceiptDetailModal
				open={showBatchesDetail}
				closeBatchModal={() => setShowBatchesDetail(false)}
				batchesInfo={data.batches ?? []}
				unit={data.unit}
			/>
		</NetcurioTableRow>
	)
}
