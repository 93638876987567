import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { InformationRFQItem, ItemQ, SelectedProductItemProps, ValidationItem } from '../types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
	NetcurioFormControl,
	NetcurioInputLabel,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioTooltip
} from '@netcurio/frontend-components'
import Formatter from '../../../utilities/formatter'
import { parseTimestampToTimeZone, dateFormatter, Status } from '@netcurio/frontend-common'
import trashRed from '@netcurio/frontend-assets/src/icons/trash-red.svg'
import revertRejected from '@netcurio/frontend-assets/src/icons/return-blue.svg'
import { QuotationRegisterItemList } from './QuotationRegisterItemList'
import styles from './NewQuotation.module.scss'
import { Product } from '../../../types'

interface QuotationRegisterRowContainerProps {
	informationItem: InformationRFQItem
	keyRFQ: number
	addItem(keyRFQ: number): void
	settingValidateCatalogue: boolean
	itemsQuotation: Array<ItemQ>
	selectedProductItem(selectedProductItemProps: SelectedProductItemProps): void
	handleItemFieldChange(
		event: ChangeEvent<HTMLInputElement>,
		index: number,
		field: string,
		requestPosition: number
	): void
	deleteItem(keyRFQ: number, itemPosition: number): void
	updateInfoRow(positionRFQ: number): void
	validationItems?: Array<ValidationItem>
	validateDifferentCurrency: Array<boolean>
	cleanReasons: boolean
	resetDataQuotation: boolean
	allReasons: Array<Status>
	rejectItemForQ(rfqIndex): void
	setFirstProduct(positionRFQ: number, rejectRow: boolean, productDefault?: Product): void
	setReasonRejected(rfqIndex: number, reason: Status | string): void
}

export const QuotationRegisterRowContainer: FC<QuotationRegisterRowContainerProps> = ({
	informationItem,
	keyRFQ,
	addItem,
	settingValidateCatalogue,
	itemsQuotation,
	selectedProductItem,
	handleItemFieldChange,
	deleteItem,
	updateInfoRow,
	validationItems,
	validateDifferentCurrency,
	cleanReasons,
	resetDataQuotation,
	allReasons,
	rejectItemForQ,
	setFirstProduct,
	setReasonRejected
}) => {
	const { t } = useTranslation()
	const [showRegisterItemList, setShowRegisterItemList] = useState<boolean>(true)
	const [cleanReason, setCleanReason] = useState<boolean>(false)
	const [iconItemRFQ, setIconItemRFQ] = useState<string>(trashRed)
	const [selectedReason, setSelectedReason] = useState<Status | string>('Selecciona')

	useEffect(() => {
		setReasonRejected(keyRFQ, selectedReason)
	}, [selectedReason])

	useEffect(() => {
		addItem(keyRFQ)
	}, [])

	useEffect(() => {
		if (cleanReason) {
			setIconItemRFQ(trashRed)
			setCleanReason(true)
			setShowRegisterItemList(true)
		}
	}, [cleanReasons])

	const eventsToListRFQ = () => {
		if (showRegisterItemList) {
			rejectItemForQ(keyRFQ)
			setFirstProduct(keyRFQ, true)
			setIconItemRFQ(revertRejected)
			setCleanReason(false)
		} else {
			updateInfoRow(keyRFQ)
			if (itemsQuotation.length === 0) {
				addItem(keyRFQ)
			}
			setReasonRejected(keyRFQ, undefined)
			setIconItemRFQ(trashRed)
			setCleanReason(true)
		}
		setShowRegisterItemList(!showRegisterItemList)
	}
	const selectReason = (reason) => {
		setSelectedReason(reason.target.value)
	}

	return (
		<>
			<div className={classNames(styles.grayColorTextGeneralInfo, styles.rowInformationItem)}>
				<p className={classNames(styles.textRowItem, styles.rowInformationCode)}>
					{informationItem.position}
				</p>
				<div className={styles.rowInformationDescription}>
					<NetcurioTooltip
						title={
							<label>
								{t(Formatter.nullValueFormattingToNA(informationItem.product_code)) +
									' - ' +
									informationItem.product_description}
							</label>
						}
					>
						<div className={styles.containerProductDescription}>
							<p className={classNames(styles.productDescriptionRow)}>
								{t(Formatter.nullValueFormattingToNA(informationItem.product_code))}
							</p>
							<p className={classNames(styles.productDescriptionRow)}>-</p>
							<p className={classNames(styles.textRowItem, styles.itemDescriptionOverflow)}>
								{informationItem.product_description}
							</p>
						</div>
					</NetcurioTooltip>
				</div>
				<p className={classNames(styles.textRowItem, styles.rowInformationQuantity)}>
					{informationItem.quantity + ' ' + informationItem.unit}
				</p>
				<p className={classNames(styles.textRowItem, styles.rowInformationDateRequired)}>
					{dateFormatter.format(parseTimestampToTimeZone(informationItem.required_delivery_date))}
				</p>
				<NetcurioTooltip title={<label>{t('revertRejected')} </label>}>
					<button
						className={classNames(styles.widthIcon, styles.backgroundTransparent)}
						onClick={() => eventsToListRFQ()}
					>
						<img alt={'delete'} src={iconItemRFQ} className={styles.deleteIcon} />
					</button>
				</NetcurioTooltip>
			</div>
			{showRegisterItemList ? (
				<QuotationRegisterItemList
					keyRFQ={keyRFQ}
					addItem={addItem}
					informationItem={informationItem}
					settingValidateCatalogue={settingValidateCatalogue}
					itemsQuotation={itemsQuotation}
					selectedProductItem={selectedProductItem}
					handleItemFieldChange={handleItemFieldChange}
					deleteItem={deleteItem}
					updateInfoRow={updateInfoRow}
					validationItems={validationItems}
					validateDifferentCurrency={validateDifferentCurrency}
					cleanReason={cleanReason}
					resetDataQuotation={resetDataQuotation}
				/>
			) : (
				<div className={styles.rejectedReasonsContainer}>
					<NetcurioFormControl>
						<NetcurioInputLabel id="rejectedReason">{t('rejectedReason')}</NetcurioInputLabel>
						<NetcurioSelect
							labelId="rejectedReason"
							variant="outlined"
							label={t('rejectedReason')}
							displayEmpty
							fullWidth
							size="medium"
							value={selectedReason}
							onChange={(event) => selectReason(event)}
							height="smaller"
						>
							<NetcurioMenuItem value="Selecciona">
								<em>{t('placeholderSelect')}</em>
							</NetcurioMenuItem>
							{allReasons.map((reason, i) => (
								<NetcurioMenuItem key={i} value={reason.key}>
									{reason.value}
								</NetcurioMenuItem>
							))}
						</NetcurioSelect>
					</NetcurioFormControl>
				</div>
			)}
		</>
	)
}
