import React from 'react'
import { useTranslation } from 'react-i18next'
import { URLS } from '@netcurio/frontend-common'
import { NetcurioButton, NetcurioGrid } from '@netcurio/frontend-components'
import { useHistory } from 'react-router-dom'
import coverImage from '@netcurio/frontend-assets/src/imagesNewDesign/landingPageLogin.jpg'
import coverImageWebp from '@netcurio/frontend-assets/src/imagesNewDesign/landingPageLogin.webp'
import logo from '@netcurio/frontend-assets/src/images/secondaryBlueNavyLogo.svg'
import styles from './passwordResetSuccessful.module.scss'

export const PassResetSuccessful = () => {
	const { t } = useTranslation()
	const history = useHistory()
	return (
		<NetcurioGrid container alignContent="flex-start" justifyContent="flex-start" className={styles.body}>
			<NetcurioGrid item xs={4}>
				<div className={styles.buttonsPosition}>
					<div>
						<img src={logo} className={styles.logo} alt="Netcurio" />
					</div>
					<div className={styles.spaceBetweenTitle}>
						<span className={styles.pwdLabel}>{t('resetPasswordConfirmationTitle')}</span>
					</div>
					<div className={styles.spaceBetweenTitle}>
						<span className={styles.textLabel}>{t('resetPasswordConfirmationText')}</span>
					</div>
					<div>
						<NetcurioButton
							variant="contained"
							size="small"
							type="submit"
							sx={{ width: '22rem' }}
							onClick={() => {
								history.push(URLS.LOGIN)
							}}
						>
							{t('labelLoginTitle')}
						</NetcurioButton>
					</div>
				</div>
			</NetcurioGrid>
			<NetcurioGrid item xs={8}>
				<link rel="preload" as="image" href={coverImageWebp} />
				<picture className={styles.coverImage}>
					<source type="image/webp" srcSet={coverImageWebp} />
					<source type="image/png" srcSet={coverImage} />
					<img src={coverImage} alt="" />
				</picture>
			</NetcurioGrid>
		</NetcurioGrid>
	)
}
