import React from 'react'
import { AddButton } from '../../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../../components/HeaderButtons/ExportButton'

export const EnrollmentsListButton = ({
	actionHeaderButton
}: {
	actionHeaderButton: (action: string) => void
}) => {
	return (
		<>
			<div>
				<AddButton
					onClick={() => actionHeaderButton('newEnrollment')}
					translationKey="newEnrollment"
				/>
			</div>
			<div>
				<ExportButton onClick={() => actionHeaderButton('exportListEnrollmentsList')} />
			</div>
		</>
	)
}
