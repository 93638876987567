import gql from 'graphql-tag'

export const CustomersSuppliers = gql`
	query CustomersSuppliers($filter: [FilterInput]) {
		CustomersSuppliers(filter: $filter) {
			list {
				payment_days
				company {
					name
					address_line_1
					country_code
					state_code
					postal_code
					industry_description
					created_at
					supportingDocuments {
						document_type
						link
					}
				}
			}
		}
	}
`
