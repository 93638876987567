import { refreshToken } from './connection'
import Constants from './constants'

export function expiredToken(errorCode: string) {
	if (errorCode === Constants.ERROR.INVALID_TOKEN) {
		refreshToken(Constants.ERROR.INVALID_TOKEN)
		return true
	}
	return false
}
