import React, { FC } from 'react'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import { useTranslation } from 'react-i18next'

interface CancelAddNewCommentModalProps {
	openModal: boolean
	onCloseModal(): void
	onAcceptModal(): void
}

/**
 * CancelAddNewCommentModal
 * @description Helps to display a modal when the user is trying to reject a RFQ
 * @param openModal <boolean>: If `true`, the component is shown
 * @param onCloseModal <boolean>: If `false`, the component is hide
 * @param onAcceptModal <void>: Callback fired when the user accept the action the modal
 * @return component <ReactNode>
 **/

export const CancelAddNewCommentModal: FC<CancelAddNewCommentModalProps> = ({
	onAcceptModal,
	onCloseModal,
	openModal
}) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('goToList')}
			open={openModal}
			actionButtons={
				<>
					<NetcurioButton onClick={onCloseModal}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAcceptModal}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('questionCommentWithoutSend')}</p>
		</NetcurioDialog>
	)
}
