import { tableFillSelectionFilter, Roles } from '@netcurio/frontend-common'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import Constants from '../../utilities/constants'
import styles from './ticketList.module.scss'

export function objectHeaderArray(userRole: string) {
	const idTicketColumn = new tableFillHeader('idTicket', styles.headerIdTicket, '', 'id', 'text')

	const categoryColumn = new tableFillHeader(
		'categoryTicket',
		styles.headerCategoryTicket,
		'',
		'category',
		'text'
	)
	const statusColumn = new tableFillHeader(
		'statusTicket',
		styles.headerStatusTicket,
		'',
		'status',
		'status'
	)

	const myCounterpartColumn = new tableFillHeader(
		userRole === Roles.CUSTOMER ? 'myCounterpartSeller' : 'myCounterpartBuyer',
		styles.headerMyCounterpartTicket,
		'',
		'my_counterpart',
		'text'
	)

	const createdDateColumn = new tableFillHeader(
		'createdDateTicket',
		styles.headerDateTicket,
		'',
		'created_at',
		'date'
	)

	const attendColumn = new tableFillHeader(
		'assignedToTicket',
		styles.headerAttendTicket,
		'',
		'company_is_sender',
		'type'
	)

	return [
		idTicketColumn,
		categoryColumn,
		statusColumn,
		myCounterpartColumn,
		createdDateColumn,
		attendColumn
	]
}

export function objectStatusFilterArray() {
	const reviewStatusOption = new tableFillSelectionFilter(
		Constants.TICKET_STATUS.REVIEW,
		styles.filterButtonTicket
	)
	const openedStatusOption = new tableFillSelectionFilter(
		Constants.TICKET_STATUS.OPEN,
		styles.filterButtonTicket
	)
	const closedStatusOption = new tableFillSelectionFilter(
		Constants.TICKET_STATUS.CLOSED,
		styles.filterButtonTicket
	)

	return [openedStatusOption, reviewStatusOption, closedStatusOption]
}

export function objectTypeFilterArray() {
	const myCompanyOption = new tableFillSelectionFilter(
		Constants.TICKET_REVIEW.MY_COMPANY,
		styles.filterButtonTicket
	)
	const myCounterpartOption = new tableFillSelectionFilter(
		Constants.TICKET_REVIEW.MY_COUNTERPART,
		styles.filterButtonTicket
	)

	return [myCompanyOption, myCounterpartOption]
}
