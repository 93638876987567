import { getAuthToken, refreshToken } from '../connection'
import Constants from '../constants'

const { SUCCESSFUL, UNKNOWN_ERROR } = Constants.UPLOAD_FILES.UPLOAD_FILES_RESPONSE

export interface FileEntry {
	description: string
	name: string
	url: string
}

interface UploadFilesResponse {
	successfulResponse?: FileEntry[]
	unknownError?: string
}

export const uploadFiles = async (
	filesToUpload: File[],
	signal: AbortSignal
): Promise<UploadFilesResponse> => {
	const formData = new FormData()
	filesToUpload.forEach((file) => {
		formData.append('file', file, file.name)
	})
	refreshToken()
	const token = await getAuthToken()

	const headers = {
		Accept: 'application/json',
		Authorization: 'Bearer ' + token
	}

	const response = await fetch('/api/v1/files', {
		method: 'POST',
		headers,
		signal,
		body: formData
	})
		.then((res: Response) => res)
		.catch((error) => {
			throw new Error(error)
		})

	if (response.ok) {
		const uploadedFiles = await response.json()
		if (uploadedFiles.files.length > 0) {
			return { [SUCCESSFUL]: uploadedFiles.files }
		}
	}
	return { [UNKNOWN_ERROR]: 'UNKNOWN_EXCEPTION' }
}
