import { useState, useEffect } from 'react'

export function useDelayUnmount(isMounted: boolean, delayTime: number) {
	const [showDiv, setShowDiv] = useState(true)
	const [hideStyle, setHideStyle] = useState(false)

	useEffect(() => {
		let timeoutId: NodeJS.Timeout
		if (isMounted) {
			setShowDiv(true)
			timeoutId = setTimeout(() => setHideStyle(false), delayTime / delayTime)
		} else if (!isMounted) {
			setHideStyle(true)
			timeoutId = setTimeout(() => setShowDiv(false), delayTime)
		}
		return () => clearTimeout(timeoutId)
	}, [isMounted, delayTime])
	return [showDiv, hideStyle]
}
