import React from 'react'
import { t } from 'i18next'
import { NetcurioButton, NetcurioDialog, TextArea } from '@netcurio/frontend-components'
import { User } from '@netcurio/frontend-common'
import { ValidationTable } from './ValidationTable'
import { Validations } from '../../../../../types/Validations'
import styles from './ManuallyAuthInvoice.module.scss'

interface ManuallyAuthInvoiceSummaryModalProps {
	onClose(): void
	open: boolean
	override_by: User
	override_on: string
	override_message: string
	previousValidation?: Validations
	currentValidation?: Validations
	passAllValidations?: boolean
}
/**
 * @description Helps to display a modal with the informations of who manually authorize the invoice
 * @param onClose<function>: when trigger, the modal will close
 * @param open <boolean>: tell us if the modal is open
 * @param override_by <User>: is the user who manually authorized the invoice
 * @param override_on <string> is the date when the invoice was manually authorized
 * @param override_message <string> is the reason why it was manually authorized.
 * @param previousValidation <object>: get the previousValidation
 * @param currentValidation <object>: get the currentValidation
 * @param passAllValidations <boolean>:tells us if the validation passed or not
 * @returns component <ReactNode>
 */
export const ManuallyAuthInvoiceSummaryModal = ({
	onClose,
	open,
	override_by,
	override_message,
	override_on,
	previousValidation,
	currentValidation,
	passAllValidations
}: ManuallyAuthInvoiceSummaryModalProps) => {
	return (
		<>
			<NetcurioDialog
				titleText={t('authorizeManualInvoice.summaryTitle')}
				open={open}
				actionButtons={
					<>
						<NetcurioButton variant="contained" onClick={() => onClose()}>
							{t('goBackText')}
						</NetcurioButton>
					</>
				}
				contentFontSize="0"
			>
				<div className={styles.authorizeContainerColumn}>
					<div className={styles.parent}>
						<div className={styles.authorizeby}>
							<p className={styles.dataTitle}>{t('authorizeBy')}</p>
							<p
								className={styles.dataDescription}
							>{`${override_by.name} ${override_by.lastname}`}</p>
						</div>
						<div className={styles.dateCrated}>
							<p className={styles.dataTitle}>{t('authorizeDate')}</p>
							<p className={styles.dataDescription}>{override_on}</p>
						</div>
					</div>
					<div>
						<TextArea
							disabledInput
							readValue={override_message}
							rows={3}
							placeholder=""
							maxLength={500}
						/>
					</div>
					<div>
						<p className={styles.titleSummary}>{t('authorizeManualInvoice.summary')}</p>
					</div>
					<ValidationTable
						previousValidation={previousValidation}
						currentValidation={currentValidation}
						passAllValidations={passAllValidations}
					/>
				</div>
			</NetcurioDialog>
		</>
	)
}
