import { Company, getCurrentUser, Roles, User } from '@netcurio/frontend-common'
import * as queries from '../views/login/queries'
import { connection } from './connection'
import Constants from './constants'

const setCurrentUser = (user: User, company: any) => {
	user.company = company
	localStorage.setItem(Constants.AUTH.NETCURIO_USER, JSON.stringify(user))
}

export const getCompanyRole = () => {
	const currentUser = getCurrentUser()
	if (currentUser && currentUser.company !== undefined) {
		const currentCompanyRoles = currentUser.company.roles
		const findRole = currentCompanyRoles?.find((role) => {
			return role.id === Roles.CUSTOMER || role.id === Roles.SUPPLIER
		})
		return findRole?.id
	} else {
		return undefined
	}
}

const hasAdminRole = () => {
	return getCurrentUser()?.company?.is_admin
}

const isEnrollmentRequestApprover = () => {
	const company = getCurrentUser()?.company
	return company?.is_admin && company?.enrollment_request_approver
}

const getCompanySelected = (userCompanies: Company[], selectedRfc: string) => {
	return userCompanies.find((company) => {
		return company.rfc === selectedRfc
	})
}

const getDefaultCompany = (): string | undefined => {
	return getCurrentUser()?.default_company
}

const updateUserInfo = async () => {
	const userData = getCurrentUser()
	const client = connection()
	await client
		.mutate({
			mutation: queries.LOGIN_USER
		})
		.then((result) => {
			if (userData) {
				const companySelected = getCompanySelected(
					result.data.loginUser.companies,
					userData.company?.rfc ?? ''
				)
				setCurrentUser(result.data.loginUser, companySelected)
			}
		})
		.catch((error) => {
			console.log(error)
		})
}

const getCompaniesUnblocked = () => {
	const companies: Company[] | undefined = getCurrentUser()?.companies
	const companiesUnblocked: Company[] = []
	if (companies instanceof Array) {
		companies.forEach((company: Company) => {
			if (company?.status?.key !== Constants.USER_STATUS.BLOCKED) {
				companiesUnblocked.push(company)
			}
		})
	}
	return companiesUnblocked
}

export default {
	setCurrentUser,
	getCompanySelected,
	getDefaultCompany,
	getCompanyRole,
	hasAdminRole,
	isEnrollmentRequestApprover,
	getCompaniesUnblocked,
	updateUserInfo
}
