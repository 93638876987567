import { NetcurioButton, NetcurioIcons, Severity, useNetcurioLoader } from '@netcurio/frontend-components'
import { FetchResult } from 'apollo-boost'
import { Auth } from 'aws-amplify'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useClient } from '../../../hooks/useClient'
import { GenericResponse, Invoice, InvoiceItem } from '../../../types'
import constants from '../../../utilities/constants'
import status from '../../../utilities/constants/status'
import { ASSOCIATE_INVOICE } from '../graphql'
import { ErrorAssociationModal, SendInvoiceConfirmationModal } from '../InvoiceEdit/Modals'

interface SendInvoiceProps {
	invoice: Invoice
	errorHandler?: (error: Error) => void
	disabled?: boolean
	relatedDocumentType?: string
	documentSelected?: string
	invoiceItems?: InvoiceItem[]
	getInvoiceInfo?: () => void
	onSendInvoiceSuccess: () => void
	isStandaloneFlow: boolean
}

export const SendInvoice: React.FC<SendInvoiceProps> = ({
	invoice,
	errorHandler,
	disabled = false,
	relatedDocumentType,
	documentSelected,
	invoiceItems = [],
	getInvoiceInfo,
	onSendInvoiceSuccess,
	isStandaloneFlow = false
}) => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
	const { t } = useTranslation()
	const history = useHistory()
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
	const client = useClient()

	const handleSendForInApproval = useCallback(async () => {
		showLoadingSpinner()
		const invoiceDto = { status: status.IN_APPROVAL, referenceType: constants.DOCUMENT_TYPE.STANDALONE }

		try {
			const user = await Auth.currentAuthenticatedUser()
			const token = user.signInUserSession.idToken.jwtToken

			const headers = {
				'Content-Type': 'application/json; charset=UTF-8',
				Accept: 'application/json',
				authorization: 'Bearer ' + token
			}

			const response = await fetch(`/api/v1/invoices/${invoice.uuid}`, {
				method: 'PATCH',
				headers,
				body: JSON.stringify(invoiceDto)
			})

			if (response.ok) {
				sessionStorage.setItem('sendInvoice', 'true')
				onSendInvoiceSuccess?.()
			} else {
				const error = await response.json()
				errorHandler?.(error)
			}
		} catch (error) {
			errorHandler?.(error as Error)
		} finally {
			setShowConfirmationModal(false)
			hideLoadingSpinner()
		}
	}, [invoice.uuid, errorHandler, showLoadingSpinner, hideLoadingSpinner, history])

	const handleSendInvoice = useCallback(() => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: ASSOCIATE_INVOICE,
				variables: {
					uuid: invoice.uuid,
					related_document_type: relatedDocumentType,
					related_document_id: documentSelected,
					...(!invoice.advance && {
						items: Array.from(
							new Set(
								invoiceItems.map((item) => ({
									position: item.position,
									reference_position: item.reference_position
								}))
							)
						)
					})
				}
			})
			.then((result: FetchResult<{ associateInvoice: GenericResponse }>) => {
				if (result?.data?.associateInvoice.message === 'VALIDATION_ERROR') {
					setShowErrorModal(true)
				} else {
					sessionStorage.setItem('sendInvoice', 'true')
					onSendInvoiceSuccess?.()
				}
			})
			.catch((error: Error) => {
				errorHandler?.(error)
			})
			.finally(hideLoadingSpinner)
	}, [
		client,
		invoice.uuid,
		relatedDocumentType,
		documentSelected,
		invoiceItems,
		showLoadingSpinner,
		hideLoadingSpinner,
		history,
		errorHandler
	])

	return (
		<>
			<NetcurioButton
				variant="outlined"
				color="primary"
				onClick={() => setShowConfirmationModal(true)}
				disabled={disabled}
				endIcon={<NetcurioIcons.Check />}
			>
				{t('sendInvoice')}
			</NetcurioButton>

			<SendInvoiceConfirmationModal
				open={showConfirmationModal}
				onClose={() => setShowConfirmationModal(false)}
				onAccept={isStandaloneFlow ? handleSendForInApproval : handleSendInvoice}
			/>

			<ErrorAssociationModal
				open={showErrorModal}
				title={t('invoiceError')}
				textButton={t('acceptTextButton')}
				bodyText={t('invoiceErrorMessage')}
				headerTitleSeverity={Severity.Error}
				onAccept={() => {
					setShowErrorModal(false)
					setShowConfirmationModal(false)
					getInvoiceInfo?.()
				}}
			/>
		</>
	)
}
