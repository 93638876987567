import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const CustomerSupplier: DocumentNode = gql`
	query CustomersSuppliers(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		CustomersSuppliers(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			total
			list {
				payment_days
				customer_reference
				company {
					rfc
					name
					created_at
				}
			}
		}
	}
`
