import { gql } from 'graphql-tag'

export const Tickets = gql`
	query Tickets($limit: Int, $skip: Int, $sort_field: String, $sort_order: String, $filter: [FilterInput]) {
		Tickets(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			total
			list {
				id
				status {
					value
				}
				description
				category {
					code
					description
				}
				created_at
				sender {
					rfc
					name
				}
				my_counterpart {
					rfc
					name
				}
				company_is_sender
			}
		}
	}
`
