import React, { forwardRef } from 'react'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

interface NetcurioIconButtonProps extends IconButtonProps {
	restingIcon?: React.ReactNode
	hoverIcon?: React.ReactNode
}

/**
 * NetcurioIconButton
 * @param restingIcon <React.ReactNode>: Icon shown in resting state, if null or hoverIcon is null, icon will be taken from children
 * @param hoverIcon <React.ReactNode>: Icon shown in hover state, if null or restingIcon is null, icon will be taken from children
 * @returns component
 */
export const NetcurioIconButton = forwardRef<HTMLButtonElement, NetcurioIconButtonProps>(
	({ children, hoverIcon, restingIcon, onMouseEnter, onMouseLeave, ...rest }, ref) => {
		const [hover, setHover] = React.useState(false)
		const hoverBehaviourOn = restingIcon && hoverIcon

		return (
			<IconButton
				onMouseEnter={hoverBehaviourOn ? () => setHover(true) : onMouseEnter}
				onMouseLeave={hoverBehaviourOn ? () => setHover(false) : onMouseLeave}
				ref={ref}
				{...rest}
			>
				{hoverBehaviourOn ? (hover ? hoverIcon : restingIcon) : children}
			</IconButton>
		)
	}
)

NetcurioIconButton.displayName = 'NetcurioIconButton'
