import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const CONSIGNMENT_STOCKS_BY_DESCRIPTION_OR_CODE: DocumentNode = gql`
	query ConsignmentStocksByDescriptionOrCode(
		$searchText: String!
		$branch_office: String!
		$limit: Int
		$skip: Int
	) {
		ConsignmentStocksByDescriptionOrCode(
			searchText: $searchText
			branch_office: $branch_office
			limit: $limit
			skip: $skip
		) {
			total
			list {
				description
				unit
				quantity
				product {
					id
					supplier {
						rfc
						name
					}
					tax
					currency
					unit_price
				}
				customer
				branch_office
				storage_location {
					id
					description
					branch_office
					company
				}
			}
		}
	}
`
export const CREATE_CONSUMPTION = gql`
	mutation createConsumption(
		$branch_office_id: String!
		$items: [ConsumptionItemInput!]!
		$comment: CommentInput
	) {
		createConsumption(branch_office_id: $branch_office_id, items: $items, comment: $comment)
	}
`
