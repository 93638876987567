import { Roles, URLS, dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import { NetcurioButton, NetcurioIconButton, NetcurioIcons } from '@netcurio/frontend-components'
import { ColorBaseGrayGrotto, NetcurioRefPrimaryAMain } from '@netcurio/frontend-design-tokens'
import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Constants from '../../../utilities/constants'
import Formatter from '../../../utilities/formatter'
import UserInformation from '../../../utilities/userInformation'
import { GoToNewOrderModal } from '../ModalsRFQ/GoToNewOrderModal'
import { QuotationInfo, RequestPosition } from '../types'
import { QuotationItem } from '../types/QuotationItem'
import styles from './Quotation.module.scss'
import { QuotationRowContainer } from './QuotationRowContainer'

interface QuotationDetailProps {
	hideQuotationDetailComponent(): void
	quotationInfo: QuotationInfo
	saveCommentPendingWhenCreateOrder(commentPending: { text?: string }): void
	commentPending: { text?: string }
	settingValidateCatalogue: boolean
}

const roleUser: string = UserInformation.getCompanyRole()

export const QuotationDetail: FC<QuotationDetailProps> = ({
	quotationInfo,
	settingValidateCatalogue,
	commentPending,
	saveCommentPendingWhenCreateOrder,
	hideQuotationDetailComponent
}) => {
	const { t } = useTranslation()
	const history = useHistory<string>()
	const searchParams = new URLSearchParams(location.search)
	const [showPoLink, setShowPoLink] = useState<boolean>(false)
	const [hideCreateOrderBtn, setHideCreateOrderBtn] = useState<boolean>(true)
	const [goToNewOrderModal, setGoToNewOrderModal] = useState<boolean>(false)
	const [rfqItems, setRfqItems] = useState<Array<RequestPosition>>([])
	const [quotationPO, setQuotationPO] = useState<{
		id: string
		customer: string
	}>()

	useEffect(() => {
		if (roleUser === Roles.SUPPLIER) {
			setHideCreateOrderBtn(false)
		}
	}, [])

	useEffect(() => {
		if (quotationInfo?.items) {
			const tempRfqItems: Array<RequestPosition> = []
			let lastPosition: number
			quotationInfo?.items.forEach((item: QuotationItem) => {
				if (lastPosition !== (item.request_position as RequestPosition).position) {
					const tempRequestPosition: RequestPosition = item.request_position as RequestPosition
					tempRequestPosition.product_code = item.product_code
					tempRfqItems.push(tempRequestPosition)
					lastPosition = tempRequestPosition.position
				}
			})

			setRfqItems(tempRfqItems)
		}
	}, [quotationInfo])

	useEffect(() => {
		if (quotationInfo.purchaseOrders && quotationInfo.purchaseOrders.length > 0) {
			setQuotationPO({
				id: quotationInfo.purchaseOrders[0].id,
				customer: quotationInfo.purchaseOrders[0].customer.rfc
			})
			setHideCreateOrderBtn(false)
			setShowPoLink(true)
		} else {
			setQuotationPO({
				id: undefined,
				customer: undefined
			})
			setHideCreateOrderBtn(roleUser !== Roles.SUPPLIER)
			setShowPoLink(false)
		}
	}, [quotationInfo.purchaseOrders])

	const goToPORelated = () => {
		history.push(`${URLS.PO_DETAIL}?order=${quotationPO.id}&customer=${quotationPO.customer}`)
	}

	const goToNewOrder = () => {
		if (!quotationInfo.hasExpired) {
			setGoToNewOrderModal(true)
		}
	}

	const acceptActionModal = () => {
		saveCommentPendingWhenCreateOrder(commentPending)
		window.sessionStorage.setItem(
			Constants.QUOTATION.QUOTATION_INFO,
			JSON.stringify({
				id: quotationInfo.id,
				supplier: quotationInfo.supplier.rfc,
				customer: searchParams.get('customer'),
				rfqId: searchParams.get('rfq')
			})
		)
		history.push(URLS.PO_NEW)
	}

	return (
		<div>
			<div className={styles.headerContainerQuotationListDetails}>
				<div className={styles.titleTableAlign}>
					<p className={styles.titleTableEntries}>
						{t('quotationText')} {Formatter.id(quotationInfo.id)}{' '}
					</p>
					<NetcurioIconButton
						sx={{ color: ColorBaseGrayGrotto }}
						restingIcon={<NetcurioIcons.CancelOutlined />}
						hoverIcon={<NetcurioIcons.Cancel />}
						onClick={hideQuotationDetailComponent}
					/>
				</div>
			</div>
			<div className={styles.containerEntriesQuotations}>
				<div className={styles.containerQuotationListDetailAndButton}>
					<div className={styles.containerQuotationListDetail}>
						<div className={styles.containerQuotationListDetailTitles}>
							<p className={styles.marginRightQuotationItemTitle}>{t('totalAmount')}</p>
							<p className={styles.marginRightQuotationItemTitleCurrency}>
								{t('currencyText')}
							</p>
							<p className={styles.marginRightQuotationItemTitle}>{t('expiredDateTitle')}</p>
							{!settingValidateCatalogue && (
								<>
									<p className={styles.marginRightQuotationItemTitle}>{t('freight')}</p>
									<p className={styles.marginRightQuotationItemTitle}>
										{t('paymentTerms')}
									</p>
								</>
							)}
							{showPoLink && (
								<p
									className={classNames(
										styles.marginRightQuotationItemTitle,
										styles.poRelatedWidth
									)}
								>
									{t('id_PO')}
								</p>
							)}
						</div>
						<div className={styles.containerQuotationListDetailData}>
							<div className={styles.dataQuotation}>
								<p className={styles.positionCurrency}>
									{Formatter.currency.format(quotationInfo.total)}
								</p>
							</div>
							<div className={styles.dataQuotationCurrency}>
								<span>{quotationInfo.currency}</span>
							</div>
							<div
								className={classNames(
									styles.dataQuotation,
									quotationInfo.hasExpired ? styles.hasExpiredDate : styles.expiredDate
								)}
							>
								<span>
									{dateFormatter.format(
										parseTimestampToTimeZone(quotationInfo.expiration_date)
									)}
								</span>
							</div>
							{!settingValidateCatalogue && (
								<>
									<div className={styles.dataQuotation}>
										{Formatter.codeDescription(
											quotationInfo.incoterm?.code,
											quotationInfo.incoterm?.description
										)}
									</div>
									<div className={styles.dataQuotation}>
										{Formatter.codeDescription(
											quotationInfo.payment_term?.code,
											quotationInfo.payment_term?.description
										)}
									</div>
								</>
							)}
							{showPoLink && (
								<div className={styles.quotationPurchaseOrderRelated}>
									<p className={styles.marginLeftBottomQuotationPO}>
										{quotationInfo.purchaseOrders[0]
											? Formatter.id(quotationInfo.purchaseOrders[0].id)
											: ''}
									</p>
									<NetcurioIcons.ArrowCircleRight
										sx={{ color: NetcurioRefPrimaryAMain }}
										className={styles.iconDetailRFQ}
										onClick={goToPORelated}
									/>
								</div>
							)}
						</div>
					</div>
					{settingValidateCatalogue && hideCreateOrderBtn && (
						<div className={styles.alignButtons}>
							<NetcurioButton
								variant="outlined"
								className={styles.buttonCreateOrderQuotationListDetail}
								sx={{
									fontSize: '1.2rem'
								}}
								disabled={quotationInfo.hasExpired}
								onClick={goToNewOrder}
							>
								<span className={styles.textButtonEntryRegister}>{t('createOrder')}</span>
							</NetcurioButton>
						</div>
					)}
				</div>
				<div className={styles.headersTableQuotation}>
					<div className={styles.headerTableQuotationInfoLeft}>
						<div
							className={classNames(
								styles.headerTableQuotation,
								styles.rowInformationEntryQuotationDetail
							)}
						>
							<span>{t('positionText')}</span>
						</div>
						<div
							className={classNames(
								styles.headerTableQuotation,
								styles.rowInformationEntryQuotationDetail
							)}
						>
							<span>{t('descriptionQuotationListHeader')}</span>
						</div>
					</div>
					<div className={styles.headerTableQuotationInfoRight}>
						<div
							className={classNames(
								styles.headerTableQuotation,
								styles.rowInformationEntryQuotationDetail
							)}
						>
							{t('requestedAmountQuotationListHeader')}
						</div>
						<div
							className={classNames(
								styles.headerTableQuotation,
								styles.rowInformationEntryQuotationDetail
							)}
						>
							{t('dateRequiredQuotationListHeader')}
						</div>
					</div>
				</div>
				<div id="productInfoContainer" className={styles.overflowScroll}>
					{rfqItems &&
						rfqItems.map((item: RequestPosition, key: number) => (
							<QuotationRowContainer
								key={`${item.position}-${key}`}
								rfqItem={item}
								quotationItems={quotationInfo.items.filter(
									(element: QuotationItem) =>
										(element.request_position as RequestPosition).position ===
										item.position
								)}
							/>
						))}
				</div>
			</div>
			<GoToNewOrderModal
				openModal={goToNewOrderModal}
				onCloseModal={() => setGoToNewOrderModal(!goToNewOrderModal)}
				onAcceptModal={acceptActionModal}
			/>
		</div>
	)
}
