import gql from 'graphql-tag'

export const CONSUMPTION_COMMENTS = gql`
	query Consumption($id: ID!, $customer: ID!) {
		Consumption(id: $id, customer: $customer) {
			id
			customer {
				rfc
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`
