import React, { FC, ReactElement } from 'react'
import { NetcurioButton, NetcurioDialog, Severity } from '@netcurio/frontend-components'
import { useTranslation } from 'react-i18next'
import { acceptActionErrorModal, getErrorCodeModal } from '../../../../../utilities/getErrorCodeModal'
import { RouterChildContext, useHistory } from 'react-router-dom'

interface ErrorGRModalProps {
	open: boolean
	errorCode: string
}

export const ErrorModal: FC<ErrorGRModalProps> = ({ open, errorCode }): ReactElement => {
	const { t } = useTranslation()
	const history: RouterChildContext['router']['history'] = useHistory()
	const { title, message, idCode, isUserBlocked } = getErrorCodeModal({ errorCode })

	return (
		<NetcurioDialog
			open={open}
			titleText={title}
			headerTitleSeverity={Severity.Error}
			actionButtons={
				<NetcurioButton
					variant="contained"
					onClick={() => {
						if (!isUserBlocked) {
							history.goBack()
						} else {
							acceptActionErrorModal(history)
						}
					}}
				>
					{t('acceptTextButton')}
				</NetcurioButton>
			}
		>
			<p>{t(message, { idCode: idCode ?? ' ' })}</p>
		</NetcurioDialog>
	)
}
