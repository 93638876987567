import React, { FC, ReactElement } from 'react'
import { NetcurioButton, NetcurioDialog, Severity } from '@netcurio/frontend-components'

interface CancelAssociationModalProps {
	onAccept: () => void
	title?: string
	bodyText: string
	textButton: string
	headerTitleSeverity?: Severity
	open: boolean
}

export const ErrorAssociationModal: FC<CancelAssociationModalProps> = ({
	onAccept,
	title,
	bodyText,
	textButton,
	headerTitleSeverity,
	open
}): ReactElement => {
	return (
		<NetcurioDialog
			open={open}
			titleText={title}
			headerTitleSeverity={headerTitleSeverity}
			actionButtons={
				<NetcurioButton variant="contained" onClick={onAccept}>
					{textButton}
				</NetcurioButton>
			}
		>
			<p>{bodyText}</p>
		</NetcurioDialog>
	)
}
