import React from 'react'
import { DialogModal } from './dialogModal'

interface MainModalProps {
	fillModal?: Array<{
		getActionModal: () => string
		getActionAccept: () => string
		getTextCancel: () => string
		getTextAction: () => string
		getQuestion: () => string
		getTitle: () => string
	}>
	unit?: string
	acceptActionModal?: (action?: string) => void
	hideModal?: (action?: string) => void
}

const defaultProps: MainModalProps = {
	fillModal: [],
	unit: '',
	acceptActionModal: () => console.warn('acceptActionModal is missing on MainModal'),
	hideModal: () => console.warn('hideModal is missing on MainModal'),
}

export const MainModal = ({
	fillModal,
	acceptActionModal,
	hideModal
}: MainModalProps = defaultProps) => {
	const showModal = () => {
		return (
			<DialogModal
				fillModal={fillModal}
				acceptActionModal={acceptActionModal}
				hideModal={hideModal}
			/>
		)
	}

	return (
		<div id="modal" className={`modal-position displayFlex`}>
			{showModal()}
		</div>
	)
}
