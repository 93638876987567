import checkIcon from '@netcurio/frontend-assets/src/icons/check-white.svg'
import closeIcon from '@netcurio/frontend-assets/src/icons/close-comment-section.svg'
import minimizedIcon from '@netcurio/frontend-assets/src/icons/minimized-comment-section.svg'
import openIcon from '@netcurio/frontend-assets/src/icons/open-comment-section.svg'
import classnames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Translation } from 'react-i18next'
import { Admin } from '../enrollmentDetail'
import styles from './adminSection.module.scss'

export enum AdminsSectionSize {
	Minimized,
	Intermediate,
	Extended
}

interface AdminsSectionSizeState {
	currentSize: AdminsSectionSize
	previousSize: AdminsSectionSize
}

interface Props {
	adminsInformation: Array<Admin>
	maxHeight?: number
	baseHeight?: number
	paddingTop?: number
	adminSectionOpen?: (adminsSectionSize: AdminsSectionSize) => void
	controlledSize?: AdminsSectionSizeState
}

export default function AdminsSection({
	adminsInformation,
	maxHeight,
	baseHeight = 250,
	paddingTop = 100,
	adminSectionOpen,
	controlledSize
}: Props) {
	const [height, setHeight] = useState(baseHeight)
	const [size, setSize] = useState<AdminsSectionSizeState>({
		currentSize: AdminsSectionSize.Intermediate,
		previousSize: AdminsSectionSize.Intermediate
	})

	useEffect(() => {
		if (controlledSize) setSize(controlledSize)
	}, [controlledSize])

	const calculateMaxHeight = useCallback(() => {
		const windowHeight = document.documentElement.clientHeight
		const scrollHeight = document.documentElement.scrollHeight
		let difference = scrollHeight - windowHeight
		let height
		if (scrollHeight !== windowHeight) {
			difference = difference <= paddingTop ? paddingTop - difference : 0
			height = windowHeight - difference
		} else {
			height = windowHeight - paddingTop
		}
		return height
	}, [paddingTop])

	const mapActionMinimize = {
		[AdminsSectionSize.Minimized]: AdminsSectionSize.Intermediate,
		[AdminsSectionSize.Intermediate]: AdminsSectionSize.Minimized,
		[AdminsSectionSize.Extended]: AdminsSectionSize.Minimized
	}

	const mapActionExpand = {
		[AdminsSectionSize.Minimized]: AdminsSectionSize.Intermediate,
		[AdminsSectionSize.Intermediate]: AdminsSectionSize.Extended,
		[AdminsSectionSize.Extended]: AdminsSectionSize.Intermediate
	}

	const mapIcon = {
		[AdminsSectionSize.Minimized]: minimizedIcon,
		[AdminsSectionSize.Intermediate]: openIcon,
		[AdminsSectionSize.Extended]: closeIcon
	}

	const mapHeight = useMemo(
		() => ({
			[AdminsSectionSize.Minimized]: 0,
			[AdminsSectionSize.Intermediate]: baseHeight,
			[AdminsSectionSize.Extended]: maxHeight || calculateMaxHeight
		}),
		[baseHeight, calculateMaxHeight, maxHeight]
	)

	useEffect(() => {
		setHeight(mapHeight[size.currentSize])
	}, [mapHeight, size.currentSize])

	const onClickIcon = () =>
		setSize((state) => {
			const currentSize = mapActionExpand[state.currentSize]
			adminSectionOpen(currentSize)
			return { currentSize, previousSize: state.currentSize }
		})
	const onClickMinimize = () =>
		setSize((state) => {
			const currentSize =
				state.currentSize === AdminsSectionSize.Minimized
					? state.previousSize
					: mapActionMinimize[state.currentSize]
			adminSectionOpen(currentSize)
			return { currentSize, previousSize: state.currentSize }
		})

	return (
		<Translation>
			{(t) => (
				<div className={styles.adminSectionContainer}>
					<div className={styles.adminSectionHeader}>
						<div className={styles.iconTextContainer} onClick={onClickMinimize}>
							<img src={checkIcon} alt={'Admin icon'} className={styles.sizeIconTitle} />
							<p className={'headerText'}> {t('in_approval_flow')}</p>
						</div>
						<div className={styles.marginRight12px}>
							<img
								src={mapIcon[size.currentSize]}
								alt={'Admin icon'}
								className={styles.imgIconExpand}
								onClick={onClickIcon}
							/>
						</div>
					</div>
					<div
						className={classnames(styles.adminSectionBody, {
							[styles.animateHideAdmins]: size.currentSize === AdminsSectionSize.Minimized
						})}
						style={{ height: height + 'px' }}
					>
						<div className={styles.relatedApprovers}>
							<span>{t('relatedApprovers')}</span>
						</div>
						<div className={styles.containerAdmin}>
							<div
								className={`${styles.grayColorTextGeneralInfo} ${styles.headerMainTableAdmins}`}
							>
								<div
									className={`${styles.textHeaderTableAdmins} ${styles.nameHeaderTableEnrollments}`}
								>
									{t('userName')}
								</div>
								<div
									className={`${styles.textHeaderTableAdmins} ${styles.statusHeaderTableEnrollments}`}
								>
									{t('stateEnrollmentENROLLMENTS')}
								</div>
							</div>
							<div id={'adminSection'} className={styles.listAdminContainer}>
								{adminsInformation.map((admin) => (
									<div
										className={styles.containerColumnAdmin}
										key={admin.fullName + admin.status}
									>
										<p
											className={`${styles.textColumnMainTableAdmins} ${styles.nameRowMainTableAdmins}`}
										>
											{admin.fullName}
										</p>
										<p
											className={`${styles.textColumnMainTableAdmins} ${styles.statusRowMainTableAdmins}`}
										>
											{t(admin.status)}
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			)}
		</Translation>
	)
}
