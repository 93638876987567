import { Roles } from '@netcurio/frontend-common'
import { Header } from '@netcurio/frontend-components'
import React from 'react'

import { AddButton } from '../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../components/HeaderButtons/ExportButton'

interface HeaderButtonsProps {
	onExport: () => void
	onNewInvoice: () => void
	userRole: Roles | undefined
}

export const HeaderButtons = ({ onExport, userRole, onNewInvoice }: HeaderButtonsProps) => {
	return (
		<Header>
			<div>
				{userRole === Roles.CUSTOMER && (
					<AddButton onClick={onNewInvoice} translationKey="newRequestForCreditMemo" />
				)}
			</div>
			<div>
				<ExportButton onClick={onExport} />
			</div>
		</Header>
	)
}
