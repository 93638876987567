import gql from 'graphql-tag'

export const LOGIN_USER = gql`
	mutation loginUser {
		loginUser {
			email
			name
			lastname
			companies {
				name
				rfc
				address_line_1
				address_line_2
				country_description
				postal_code
				state_description
				state_code
				country_code
				industry_description
				roles {
					id
				}
				is_admin
				enrollment_request_approver
				status {
					key
					value
				}
				homepage
				new_po_notification
				confirmed_po_notification
				rejected_po_notification
				deleted_po_notification
				new_gr_notification
				scheduled_inv_notification
				paid_inv_notification
				canceled_inv_notification
				new_pc_notification
				new_rfq_notification
				rejected_rfq_notification
				closed_rfq_notification
				new_q_notification
				new_consumption_notification
				enrollment_new_notification
				enrollment_approve_notification
				enrollment_review_notification
				enrollment_reject_notification
				new_rfcm_notification
				accepted_rfcm_notification
				rejected_rfcm_notification
				accounted_cm_notification
				new_ticket_notification
				closed_ticket_notification
				review_ticket_notification
				reopened_ticket_notification
				update_po_notification
				void_pc_notification
			}
			time_zone
			default_company
			phone_number
		}
	}
`

export const DOCUMENT_FROM_LINK = gql`
	query getDocumentFromLink($id: ID!, $company: ID, $docType: String!) {
		getDocumentFromLink(id: $id, company: $company, docType: $docType) {
			id
			customer
			suppliers
		}
	}
`

export const REGISTER_NEW_COMPANY = gql`
	mutation registerNewCompany(
		$rfc: ID!
		$name: String!
		$address_line_1: String
		$state_code: String
		$postal_code: String
		$industry_description: String
		$name_user: String
		$last_name_user: String
		$email: String
	) {
		registerNewCompany(
			rfc: $rfc
			name: $name
			address_line_1: $address_line_1
			state_code: $state_code
			postal_code: $postal_code
			industry_description: $industry_description
			name_user: $name_user
			last_name_user: $last_name_user
			email: $email
		) {
			code
			message
		}
	}
`
