import { NetcurioAlert, NetcurioButton, NetcurioDialog, Severity } from '@netcurio/frontend-components'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PurchaseOrderItem, RegisterBatch } from '../../../../../types'
import { ListRegisterBatch } from './ListRegisterBatch'

interface RegisterBatchModalProps {
	open: boolean
	closeNewBatchModal(): void
	updateReceivedAmount(valueReceivedAmount: string, batchItems: Array<RegisterBatch>)
	dataPendingForGoodsReceipt: PurchaseOrderItem & { batches?: Array<RegisterBatch> }
	resetItem(): void
}

/**
 * RegisterBatchModal
 * @description Helps to display a modal message when user try to cancel a new request for quotation
 * @param open <boolean>: tells if the dialog is open or not
 * @param closeNewBatchModal <function>: this function handles any action on the parent when close action is triggered
 * @param updateReceivedAmount <function>: this function update the value of the received amount of the batches
 * @param dataPendingForGoodsReceipt <object>: are the values  of the pending for goods receipt
 * @param resetItem <function>: this function reset all data of the PO detail
 * @returns Modal component
 */
export const RegisterBatchModal: FC<RegisterBatchModalProps> = ({
	open,
	closeNewBatchModal,
	updateReceivedAmount,
	dataPendingForGoodsReceipt,
	resetItem
}) => {
	const { t } = useTranslation()
	const [disableRegisterButton, setDisableRegisterButton] = useState<boolean>(true)
	const [showUpdateAmountModal, setShowUpdateAmountModal] = useState<boolean>(false)
	const [missingFields, setMissingFields] = useState<boolean>(false)
	const [totalAmount, setTotalAmount] = useState<number>(undefined)
	const [batchItems, setBatchItems] = useState<Array<RegisterBatch>>([
		{
			quantity: undefined,
			batch: '',
			isValidQuantity: false,
			isValidBatch: false
		}
	])
	const [showAlert, setShowAlert] = useState({
		show: false,
		message: '',
		type: undefined
	})

	useEffect(() => {
		setDisableRegisterButton(
			!batchItems.some((item: RegisterBatch) => item.batch !== '' && item.quantity > 0)
		)
	}, [batchItems])

	useEffect(() => {
		if (!dataPendingForGoodsReceipt?.batches) {
			setBatchItems([
				{
					quantity: undefined,
					batch: '',
					isValidQuantity: false,
					isValidBatch: false
				}
			])
			setShowAlert({
				show: false,
				message: '',
				type: undefined
			})
			setShowUpdateAmountModal(false)
		}
	}, [dataPendingForGoodsReceipt?.batches])

	const hideModal = () => {
		if (!dataPendingForGoodsReceipt?.batches) {
			setBatchItems([
				{
					quantity: undefined,
					batch: '',
					isValidQuantity: false,
					isValidBatch: false
				}
			])
		}
		setShowAlert({
			show: false,
			message: '',
			type: undefined
		})
		setShowUpdateAmountModal(false)
		closeNewBatchModal()
	}

	const acceptActionModal = () => {
		if (showUpdateAmountModal) {
			updateReceivedAmount(totalAmount.toString(), batchItems)
		}
		setShowUpdateAmountModal(false)
		closeNewBatchModal()
		setShowAlert({
			show: false,
			message: '',
			type: undefined
		})
	}

	const checkMissingFields = (): boolean => {
		const items: Array<RegisterBatch> = batchItems
		let missingFiled = false
		items.forEach((item) => {
			if (item.batch === '') {
				item.isValidBatch = true
				missingFiled = true
			}
			if (!item.quantity) {
				item.isValidQuantity = true
				missingFiled = true
			}
		})
		setBatchItems(batchItems)
		return missingFiled
	}

	const saveBatchItems = (totalAmount: number) => {
		updateReceivedAmount(totalAmount.toString(), batchItems)
		closeNewBatchModal()
	}

	const validateBatchItemsAmount = () => {
		const updateTotalAmount: number = batchItems.reduce(
			(accumulator, batchItem) => accumulator + batchItem.quantity,
			0
		)

		if (
			dataPendingForGoodsReceipt.receivedAmount &&
			updateTotalAmount === parseFloat(dataPendingForGoodsReceipt.receivedAmount)
		) {
			saveBatchItems(updateTotalAmount)
		} else if (
			dataPendingForGoodsReceipt.receivedAmount &&
			updateTotalAmount <= dataPendingForGoodsReceipt.pending_delivery
		) {
			setShowUpdateAmountModal(true)
			setTotalAmount(updateTotalAmount)
			setShowAlert({
				show: true,
				message: 'updateAmountReceivedQuestion',
				type: Severity.Warning
			})
		} else if (
			!dataPendingForGoodsReceipt.receivedAmount &&
			updateTotalAmount <= dataPendingForGoodsReceipt.pending_delivery
		) {
			saveBatchItems(updateTotalAmount)
		} else if (updateTotalAmount > dataPendingForGoodsReceipt.pending_delivery) {
			setShowUpdateAmountModal(false)
			setShowAlert({
				show: true,
				message: 'pendingQuantityExceededQuestion',
				type: Severity.Error
			})
		}
	}

	const registerBatches = () => {
		const missingFields: boolean = checkMissingFields()
		if (missingFields) {
			setMissingFields(true)
		} else {
			validateBatchItemsAmount()
		}
	}

	const handleItemFieldChange = (input: string, value: string, position: number) => {
		const updatedBatchItems: Array<RegisterBatch> = [...batchItems]
		if (showUpdateAmountModal) {
			setShowUpdateAmountModal(false)
		}
		if (input === 'quantity') {
			updatedBatchItems[position].quantity = value ? parseFloat(value) : undefined
			updatedBatchItems[position].isValidQuantity = value === ''
		} else if (input === 'batch') {
			updatedBatchItems[position].batch = value
			updatedBatchItems[position].isValidBatch = value === ''
		}
		const { batch, quantity } = updatedBatchItems[position]
		setMissingFields(!(batch && quantity))
		setBatchItems(updatedBatchItems)
	}

	const removeItem = (position: number) => {
		const updatedBatchItems: Array<RegisterBatch> = [...batchItems]
		if (position >= 0 && position <= updatedBatchItems.length) {
			updatedBatchItems.splice(position, 1)
		}
		if (position === 0 && updatedBatchItems.length === 0) {
			updatedBatchItems.push({
				quantity: undefined,
				batch: '',
				isValidQuantity: false,
				isValidBatch: false
			})
			resetItem()
		}
		setBatchItems(updatedBatchItems)
	}

	const addBatchItem = () => {
		const tempBatchItems: Array<RegisterBatch> = structuredClone(batchItems)
		const missingFields: boolean = checkMissingFields()
		if (!missingFields || tempBatchItems.length < 1) {
			tempBatchItems.push({
				quantity: undefined,
				batch: '',
				isValidQuantity: false,
				isValidBatch: false
			})
			setBatchItems(tempBatchItems)
			setMissingFields(missingFields)
		} else {
			setMissingFields(missingFields)
		}
	}

	return (
		<NetcurioDialog
			open={open}
			titleText={t('addBatches')}
			actionButtons={
				<>
					<NetcurioButton onClick={hideModal}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton
						onClick={() => {
							if (showUpdateAmountModal) acceptActionModal()
							else registerBatches()
						}}
						variant="contained"
						disabled={disableRegisterButton}
					>
						{t(showUpdateAmountModal ? 'updateFile' : 'textActionRegister')}
					</NetcurioButton>
				</>
			}
		>
			{showAlert.show && (
				<div className="margin-bottom-10">
					<NetcurioAlert severity={showAlert.type}>{t(showAlert.message)}</NetcurioAlert>
				</div>
			)}
			<ListRegisterBatch
				unit={dataPendingForGoodsReceipt.unit}
				pendingDelivery={dataPendingForGoodsReceipt.pending_delivery}
				missingFields={missingFields}
				batchItems={batchItems}
				handleItemFieldChange={handleItemFieldChange}
				removeItem={removeItem}
				addBatchItem={addBatchItem}
			/>
		</NetcurioDialog>
	)
}
