import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const PAYMENT_COMPLEMENT_DETAILS: DocumentNode = gql`
	query PaymentComplement($uuid: ID!) {
		PaymentComplement(uuid: $uuid) {
			uuid
			serie
			folio
			status {
				key
				value
			}
			void_reason
			sender {
				name
				rfc
			}
			receiver {
				rfc
				name
			}
			date
			uploaded_by {
				name
				lastname
				email
			}
			created_at
			payments {
				position
				date
				amount
				currency
				items {
					uuid
					invoice {
						uuid
						serie
						folio
					}
					previous_balance
					paid_amount
					pending_balance
				}
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`

export const CREATE_PAYMENT_COMPLEMENT_COMMENT = gql`
	mutation createPaymentComplementComment($payment_complement: ID!, $text: String!) {
		createPaymentComplementComment(payment_complement: $payment_complement, text: $text) {
			code
			message
		}
	}
`

export const PAYMENT_COMPLEMENT_COMMENTS = gql`
	query PaymentComplement($uuid: ID!) {
		PaymentComplement(uuid: $uuid) {
			uuid
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`
export const VOID_PAYMENT_COMPLEMENT = gql`
	mutation voidPaymentComplement($uuid: ID!, $reason: String!) {
		voidPaymentComplement(uuid: $uuid, reason: $reason) {
			code
			message
		}
	}
`
