import { allowDecimals, currencyFormatter } from '@netcurio/frontend-common'
import {
	NetcurioAutocomplete,
	NetcurioDatePicker,
	NetcurioFormControl,
	NetcurioIcons,
	NetcurioInputLabel,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioSelectChangeEvent,
	NetcurioTextField,
	NetcurioTooltip,
	PriceField
} from '@netcurio/frontend-components'
import { ColorBaseGrayFog, ColorBaseWhite, NetcurioRefErrorAMain } from '@netcurio/frontend-design-tokens'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import React, { ChangeEvent, FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Product, PurchaseOrderItem } from '../../../../types'
import { connection } from '../../../../utilities/connection'
import Formatter from '../../../../utilities/formatter'
import { useDebounce } from '../../../../utilities/useDebounce'
import { PRODUCT_NAME_CODE } from '../../graphQl/productNameCode.query'
import { FieldValidations } from '../newPurchaseOrder'
import styles from './Item.module.scss'

interface Storage {
	id: string
	description: string
	branch_office: string
	company: string
}

interface ItemProps {
	itemPosition: number
	deleteItem: (itemPosition: number) => void
	handleItemFieldChange: (
		value: string | number | ChangeEvent<HTMLInputElement>,
		index: number,
		nameField: string
	) => void
	itemData: PurchaseOrderItem
	selectedProductItem: (
		product: Product | undefined,
		itemPosition: number,
		unit_price: string | number,
		unit: string,
		tax: string | number,
		referencePrice: number,
		currency: string,
		storageLocation: string
	) => void
	selectedSupplier?: {
		rfc: string
		name: string
	}
	storageLocations: Array<Storage>
	totalErrorsByPurchaseOrder: Array<FieldValidations>
	isBranchSelected: boolean
	POFromQuotation: boolean
}

export const Item: FC<ItemProps> = ({
	itemPosition,
	selectedSupplier,
	selectedProductItem,
	itemData,
	handleItemFieldChange,
	storageLocations,
	deleteItem,
	totalErrorsByPurchaseOrder,
	isBranchSelected,
	POFromQuotation
}) => {
	const { t } = useTranslation()
	const client = connection()
	const minDate = dayjs()
	const [loading, setLoading] = useState<boolean>(false)
	const [searchProduct, setSearchProduct] = useState<string>('')
	const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined)
	const debouncedProductInput = useDebounce<string>(searchProduct, 500)
	const [currentProductsList, setCurrentProductsList] = useState<Product[]>([])
	const [storageLocation, setStorageLocation] = useState<string>('')
	const [requestedAmount, setRequestedAmount] = useState<string>('')
	const [itemUnitMeasure, setItemUnitMeasure] = useState('')
	const [itemTax, setItemTax] = useState<string>('')
	const [itemNetPrice, setItemNetPrice] = useState<string>('')
	const [date, setDate] = useState<Dayjs | dayjs.Dayjs | undefined>(undefined)
	const [canBeDeleted, setCanBeDeleted] = useState<boolean>(false)
	const [isHovering, setIsHovering] = useState<boolean>(false)
	const [newUnitPriceValue, setNewUnitPriceValue] = useState<boolean>(false)
	const [disabledInputPrice, setDisabledInputPrice] = useState<boolean>(true)
	const [itemUnitPrice, setItemUnitPrice] = useState<string>('')
	const isMouseOverInput: MutableRefObject<boolean> = useRef<boolean>(true)
	let errorsInPurchaseOrder = totalErrorsByPurchaseOrder[itemPosition]

	useEffect(() => {
		errorsInPurchaseOrder = totalErrorsByPurchaseOrder[itemPosition]
	}, [totalErrorsByPurchaseOrder])

	const getProductFromQuotation = async (itemName: string, code: string) => {
		if (selectedSupplier?.rfc) {
			await client
				.query({
					query: PRODUCT_NAME_CODE,
					variables: {
						searchText: itemName.toLowerCase(),
						supplier: selectedSupplier?.rfc
					}
				})
				.then((result) => {
					const itemFromQuotation: Product = result.data.ProductsByNameOrCode.find(
						(product: Product) => product.id === code
					)
					setSelectedProduct(itemFromQuotation)
				})
				.catch((error) => {
					console.error(error)
				})
				.finally(() => setLoading(false))
		}
	}

	useEffect(() => {
		if (itemData.code && itemData.name && POFromQuotation) {
			getProductFromQuotation(itemData.name, itemData.code)
			setSearchProduct(itemData.code + ' - ' + itemData.name)
			setSelectedProduct(currentProductsList.find((product) => product.id === itemData.code))
		} else if (itemData.code === '' && itemData.name === '' && !itemData.product) {
			setSelectedProduct(undefined)
			setSearchProduct('')
		} else if (itemData.code && itemData.name && itemData.product) {
			if (selectedProduct?.id?.trim() !== itemData.code.trim()) {
				if (itemData.product) {
					setSelectedProduct(itemData.product)
					setSearchProduct(itemData.code + ' - ' + itemData.name)
				} else {
					setSelectedProduct(undefined)
					setSearchProduct('')
				}
			} else if (selectedProduct?.id?.trim() === itemData.code.trim()) {
				setSelectedProduct(selectedProduct)
				setSearchProduct(selectedProduct.id + ' - ' + selectedProduct.description)
			}
		}
		setRequestedAmount(itemData.requested_amount ? itemData.requested_amount.toString() : '')
		setItemUnitMeasure(itemData.unit ?? '')
		setStorageLocation(itemData.storage_location ?? '')
		setItemTax(itemData.tax ? Formatter.percent.format(itemData.tax) : '')
		setItemNetPrice(currencyFormatter.format(itemData.net_value as number) ?? '')
		setItemUnitPrice(itemData.unit_price?.toString() ?? '')
		setCanBeDeleted(itemData.can_be_deleted ?? false)
		setDate(itemData.required_delivery_date ? itemData.required_delivery_date : undefined)
	}, [itemData, POFromQuotation])

	useEffect(() => {
		if (!selectedProduct) {
			itemData.can_be_deleted = false
			setCanBeDeleted(false)
		} else {
			itemData.can_be_deleted = true
			setCanBeDeleted(true)
		}
	}, [selectedProduct])

	useEffect(() => {
		if (selectedSupplier?.rfc) {
			if (debouncedProductInput.length >= 3) {
				setLoading(true)
				client
					.query({
						query: PRODUCT_NAME_CODE,
						variables: {
							searchText: debouncedProductInput.toLowerCase(),
							supplier: selectedSupplier?.rfc
						}
					})
					.then((result) => {
						setCurrentProductsList(result.data.ProductsByNameOrCode)
					})
					.catch((error) => {
						console.error(error)
					})
					.finally(() => setLoading(false))
			}
		} else {
			setCurrentProductsList([])
		}
	}, [debouncedProductInput])

	useEffect(() => {
		if (storageLocations) {
			handleStorageLocationOnChange({ target: { value: '' } })
		}
	}, [storageLocations])

	const getSuggestion = (searchText: string) => {
		setSearchProduct(searchText)
	}

	const validateProductValue = (option: unknown, value: unknown) => {
		const product = option as Product
		const selection = value as Product
		return product.id === selection.id
	}

	const getProductLabel = (option: unknown) => {
		const product = option as Product
		return `${product.id} - ${product.description}`
	}

	const selectProduct = (option: unknown) => {
		const product = option as Product
		if (product) {
			setSelectedProduct(product)
			selectedProductItem(
				product ?? product,
				itemPosition,
				product.unit_price ? product.unit_price : '',
				product.unit ? product.unit : '',
				product.tax ? product.tax : '',
				product.unit_price ? product.unit_price : 0,
				product.currency ? product.currency : '',
				product.storageLocation ? product.storageLocation : ''
			)
		}
	}

	const handleStorageLocationOnChange = (event: unknown): void => {
		const selectedEvent = event as NetcurioSelectChangeEvent
		setStorageLocation(selectedEvent.target.value)
		handleItemFieldChange(selectedEvent.target.value, itemPosition, 'storageLocation')
	}

	const eraseItem = () => {
		if (canBeDeleted) deleteItem(itemPosition)
	}

	const setAmount = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.value === '.') {
			event.target.value = '0.'
		}
		if (allowDecimals(event.target.value)) {
			handleItemFieldChange(event.target.value, itemPosition, 'amount')
			setRequestedAmount(event.target.value)
		}
	}

	const handleOnChangeDate = (date: Dayjs) => {
		setDate(date)
		handleItemFieldChange(date?.toISOString(), itemPosition, 'requiredDate')
	}

	const activateInputPrice = () => {
		isMouseOverInput.current = false
		setDisabledInputPrice(false)
		setIsHovering(false)
	}

	const revertUnitPrice = () => {
		setIsHovering(true)
		if (itemData.product) {
			if (itemData.product?.unit_price)
				handleItemFieldChange(itemData.product?.unit_price.toString(), itemPosition, 'unitPrice')
			setNewUnitPriceValue(false)
			if (itemData.unit_price) setItemUnitPrice(itemData.unit_price.toString())
		} else {
			handleItemFieldChange('0', itemPosition, 'unitPrice')
			setNewUnitPriceValue(false)
			setItemUnitPrice('0')
		}
	}

	const blurInputPrice = () => {
		setNewUnitPriceValue(itemData.reference_price !== itemData.unit_price)
		setDisabledInputPrice(true)
		setIsHovering(false)
		isMouseOverInput.current = true
	}

	const setNewPrice = (tempTarget: unknown, nameField: string) => {
		const event = tempTarget as NetcurioSelectChangeEvent
		handleItemFieldChange(event.target.value, itemPosition, nameField)
		setItemUnitPrice(event.target.value)
	}

	const displayWarningMsg = () => {
		if (errorsInPurchaseOrder?.amount || errorsInPurchaseOrder?.product) {
			return <div className={styles.errorText}>{t('fieldNoFillText')}</div>
		} else {
			return null
		}
	}

	return (
		<div className={styles.itemModule}>
			<div className={styles.rightModule}>
				<div className={styles.alignModules}>
					<NetcurioTooltip title={t('positionText')}>
						<div
							className={classNames(
								styles.onlyReadFieldsPO,
								styles.resizePosition,
								styles.heightPositionNewPO
							)}
						>
							{itemPosition + 1}
						</div>
					</NetcurioTooltip>
					<div className={styles.containerSize}>
						<div className={styles.flexContainer}>
							<div className={styles.resizeProduct}>
								{POFromQuotation ? (
									<NetcurioTextField value={searchProduct} fullWidth disabled={true} />
								) : (
									<NetcurioAutocomplete
										id={'productItem' + (itemPosition + 1)}
										label={t('productText')}
										placeholder={t('selectProduct')}
										height="smaller"
										size="small"
										variant="outlined"
										options={currentProductsList}
										getOptionLabel={getProductLabel}
										isOptionEqualToValue={validateProductValue}
										value={selectedProduct}
										onSelectValue={selectProduct}
										inputValue={searchProduct}
										onInputValueChange={getSuggestion}
										loading={loading}
										minLength={3}
										disabled={!selectedSupplier}
										freeSolo
										fullWidth
										error={errorsInPurchaseOrder ? errorsInPurchaseOrder.product : false}
									/>
								)}
							</div>
							<NetcurioTooltip title={t('storageLocation')} placement="top">
								<div className={styles.containerStorage}>
									<NetcurioFormControl fullWidth>
										<NetcurioInputLabel id="storeHouse" size="small">
											{t('storeHouse')}
										</NetcurioInputLabel>
										<NetcurioSelect
											labelId="storeHouse"
											variant="outlined"
											label={t('storeHouse')}
											size="medium"
											value={storageLocation}
											onChange={(event) => handleStorageLocationOnChange(event)}
											height="smaller"
											disabled={!isBranchSelected}
											fullWidth
										>
											<NetcurioMenuItem key={-1} value={''}>
												<em>{t('N/A')}</em>
											</NetcurioMenuItem>
											{storageLocations?.map((item, i) => (
												<NetcurioMenuItem key={i} value={item.id}>
													<span>{item.description}</span>
												</NetcurioMenuItem>
											))}
										</NetcurioSelect>
									</NetcurioFormControl>
								</div>
							</NetcurioTooltip>
							<NetcurioTooltip title={t('delete')}>
								<NetcurioIcons.Cancel
									sx={{
										color: canBeDeleted ? NetcurioRefErrorAMain : ColorBaseGrayFog
									}}
									className={styles.deletePositionIcon}
									onClick={eraseItem}
								/>
							</NetcurioTooltip>
						</div>
						<div className={classNames(styles.flexContainer, styles.marginRowPosition)}>
							<NetcurioTooltip title={t('quantity')}>
								<div className={styles.productSpace}>
									<NetcurioTextField
										label={t('quantity')}
										value={requestedAmount}
										onChange={setAmount}
										error={errorsInPurchaseOrder ? errorsInPurchaseOrder.amount : false}
										fullWidth
									/>
								</div>
							</NetcurioTooltip>
							<NetcurioTooltip title={t('unitMeasureText')}>
								<div className={styles.resizeUm}>
									<NetcurioTextField
										id={'itemUnitMeasure' + (itemPosition + 1)}
										label={t('unitMeasureText')}
										value={t(itemUnitMeasure)}
										disabled
									/>
								</div>
							</NetcurioTooltip>
							<div className={styles.resizeCost}>
								{itemData.unit_price === 0 ? (
									<NetcurioTooltip
										title={<label>{t('priceTooltip')}</label>}
										placement="top"
									>
										<NetcurioTextField label={t('unitPriceText')} disabled />
									</NetcurioTooltip>
								) : (
									<NetcurioTooltip
										title={<label>{t('priceTooltip')}</label>}
										placement="top"
									>
										<div
											id={'unitPriceItem'}
											className={classNames(
												styles.resizeCost,
												styles.padding0Important,
												styles.containerEditPrice
											)}
											onMouseEnter={() =>
												isMouseOverInput.current && setIsHovering(true)
											}
											onMouseLeave={() => setIsHovering(false)}
										>
											{isHovering ? (
												<div
													id={'firstStateUnitPrice'}
													className={classNames(
														styles.containerButtonsHoverUnitPrice
													)}
												>
													<div
														onClick={activateInputPrice}
														className={styles.width100}
													>
														<NetcurioIcons.Edit
															sx={{ color: ColorBaseWhite }}
															className={styles.buttonsHoverUnitPrice}
														/>
													</div>
													{newUnitPriceValue && (
														<div
															className={classNames(styles.width100)}
															onClick={revertUnitPrice}
														>
															<NetcurioIcons.Undo
																sx={{ color: ColorBaseWhite }}
																className={styles.buttonsHoverUnitPrice}
															/>
														</div>
													)}
												</div>
											) : (
												<PriceField
													idPriceField={'inputPriceItem'}
													label={'unitPriceText'}
													disabledField={disabledInputPrice}
													readValue={
														disabledInputPrice
															? itemUnitPrice
															: itemUnitPrice.toString()
													}
													onChangeText={(e) => setNewPrice(e, 'unitPrice')}
													onBlurPrice={blurInputPrice}
												/>
											)}
										</div>
									</NetcurioTooltip>
								)}
							</div>
							<NetcurioTooltip title={t('taxIVA')}>
								<div className={styles.resizeIva}>
									<NetcurioTextField
										id={'taxIVA' + (itemPosition + 1)}
										label={t('taxIVA')}
										value={itemTax}
										disabled
									/>
								</div>
							</NetcurioTooltip>
							<NetcurioTooltip title={t('netValue')}>
								<div className={styles.productSpace}>
									<NetcurioTextField
										id={'netValue' + (itemPosition + 1)}
										label={t('netValue')}
										value={itemNetPrice}
										disabled
									/>
								</div>
							</NetcurioTooltip>
							<NetcurioDatePicker
								height="smaller"
								label={t('requiredDateText')}
								format="DD/MM/YY"
								value={date}
								onChange={(date: Dayjs) => handleOnChangeDate(date)}
								minDate={minDate}
							/>
						</div>
						{displayWarningMsg()}
					</div>
				</div>
			</div>
		</div>
	)
}
