import { generateDownloadElement } from './generate-download-element'

export function downloadFile(fileName: string, URL: string, fileType: string) {
	const xhr = new XMLHttpRequest()
	xhr.open('GET', URL, true)
	xhr.responseType = 'arraybuffer'
	xhr.onload = function () {
		if (this.status == 200) {
			const blob = new Blob([this.response], { type: fileType })
			generateDownloadElement(blob, fileName)
		}
	}
	xhr.send()
}
