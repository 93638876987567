import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const UPDATED_INVOICES_DATA: DocumentNode = gql`
	query Invoice($uuid: ID!) {
		Invoice(uuid: $uuid) {
			uuid
			status {
				key
				value
			}
			reference
			reference_type
			purchase_order {
				id
				customer {
					rfc
				}
			}
			consumption {
				id
				customer {
					rfc
				}
			}
			modified_by_customer {
				email
				name
				lastname
			}
			updated_at
			error {
				key
				value
			}
		}
	}
`
