import {
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow,
	NetcurioTooltip
} from '@netcurio/frontend-components'
import { t } from 'i18next'
import React from 'react'
import { Validations } from '../../../../../types/Validations'
import { ValidationRow } from './ValidationRow'
import styles from './ValidationTable.module.scss'

interface ValidationTableProps {
	currentValidation?: Validations
	previousValidation?: Validations
	passAllValidations: boolean
}
/**
 * @description Helps to display a table when the use tries to manually authorize
 * @param previousValidation <object>: get the previousValidation
 * @param currentValidation <object>: get the currentValidation
 * @param passAllValidations <boolean>:tells us if the validation passed or not
 * @returns component <ReactNode>
 */
export const ValidationTable = ({ currentValidation, passAllValidations }: ValidationTableProps) => {
	return (
		<NetcurioTableContainer>
			<NetcurioTable size="small">
				<NetcurioTableHead>
					<NetcurioTableRow isDetailHeader>
						<NetcurioTableCell>
							<NetcurioTooltip title={t('validations')} placement="top">
								<p className={styles.tableTitleValidations}>{t('validations')}</p>
							</NetcurioTooltip>
						</NetcurioTableCell>
						{passAllValidations ? (
							<NetcurioTableCell align="center">
								<NetcurioTooltip title={t('oldStatus')} placement="top">
									<p className={styles.tableTitleOldStatus}>{t('oldStatus')}</p>
								</NetcurioTooltip>
							</NetcurioTableCell>
						) : (
							<NetcurioTableCell align="center">
								<NetcurioTooltip title={t('status')} placement="top">
									<p className={styles.tableTitleActualStatus}>{t('statusHeader')}</p>
								</NetcurioTooltip>
							</NetcurioTableCell>
						)}
						{passAllValidations && (
							<NetcurioTableCell align="center">
								<NetcurioTooltip title={t('actualStatus')} placement="top">
									<p className={styles.tableTitleActualStatus}>{t('actualStatus')}</p>
								</NetcurioTooltip>
							</NetcurioTableCell>
						)}
					</NetcurioTableRow>
				</NetcurioTableHead>
				<NetcurioTableBody>
					{Object.keys(currentValidation).map((validation) => {
						return (
							<ValidationRow
								key={validation}
								validationKey={validation}
								currentValidation={currentValidation[validation]}
								passAllValidations={passAllValidations}
							/>
						)
					})}
				</NetcurioTableBody>
			</NetcurioTable>
		</NetcurioTableContainer>
	)
}
