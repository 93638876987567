import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import { ThemeOptions } from '@mui/material/styles'
import { FC } from 'react'

interface NetcurioCheckboxProps {
	iconFontSize?: string
}
export const NetcurioCheckboxThemeOptions: ThemeOptions = {
	components: {
		MuiTypography: {
			styleOverrides: {
				root: {
					fontSize: '1.6rem'
				}
			}
		}
	}
}

/**
 * NetcurioCheckbox
 * @param checked <boolean>: If `true`, the component is checked.
 * @param checkedIcon <ReactNode>: The icon to display when the component is checked.
	  * @default <CheckBoxIcon />
 * @param color <'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default'>: The color of the component.
 * @param disabled <boolean>: If `true`, the component is disabled.
	  * @default false
* @param disableRipple <boolean>: If `true`, the ripple effect is disabled.
	  * @default false
* @param icon <ReactNode>: The icon to display when the component is unchecked.
	* @default <CheckBoxOutlineBlankIcon />
* @param indeterminate <boolean>: If `true`, the component appears indeterminate.
	  * @default false
* @param indeterminateIcon <ReactNode>: The icon to display when the component is indeterminate.
	* @default <IndeterminateCheckBoxIcon />
* @param onChange <React.ChangeEvent<HTMLInputElement>: Callback fired when the state is changed.
* @param required <boolean>: If `true`, the `input` element is required.
	* @default false
* @param size <'small' | 'medium'>: The size of the component.
	* @default <IndeterminateCheckBoxIcon />
@returns component
*/
export const NetcurioCheckbox: FC<CheckboxProps & NetcurioCheckboxProps> = ({ iconFontSize, ...props }) => {
	return <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: iconFontSize ?? '2.4rem' } }} {...props} />
}
