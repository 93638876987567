import gql from 'graphql-tag'

export const VOID_INVOICE = gql`
	mutation voidInvoice($uuid: ID!) {
		voidInvoice(uuid: $uuid) {
			code
			message
		}
	}
`
