import { gql } from 'graphql-tag'

export const UPDATE_NOTIFICATIONS = gql`
	mutation updateNotifications(
		$new_po_notification: Boolean
		$confirmed_po_notification: Boolean
		$rejected_po_notification: Boolean
		$deleted_po_notification: Boolean
		$new_gr_notification: Boolean
		$scheduled_inv_notification: Boolean
		$paid_inv_notification: Boolean
		$canceled_inv_notification: Boolean
		$new_pc_notification: Boolean
		$void_pc_notification: Boolean
		$new_rfq_notification: Boolean
		$rejected_rfq_notification: Boolean
		$closed_rfq_notification: Boolean
		$new_q_notification: Boolean
		$new_consumption_notification: Boolean
		$enrollment_new_notification: Boolean
		$enrollment_reject_notification: Boolean
		$enrollment_approve_notification: Boolean
		$enrollment_review_notification: Boolean
		$new_rfcm_notification: Boolean
		$accepted_rfcm_notification: Boolean
		$rejected_rfcm_notification: Boolean
		$accounted_cm_notification: Boolean
		$new_ticket_notification: Boolean
		$closed_ticket_notification: Boolean
		$review_ticket_notification: Boolean
		$reopened_ticket_notification: Boolean
		$update_po_notification: Boolean
	) {
		updateNotifications(
			new_po_notification: $new_po_notification
			confirmed_po_notification: $confirmed_po_notification
			rejected_po_notification: $rejected_po_notification
			deleted_po_notification: $deleted_po_notification
			new_gr_notification: $new_gr_notification
			scheduled_inv_notification: $scheduled_inv_notification
			paid_inv_notification: $paid_inv_notification
			canceled_inv_notification: $canceled_inv_notification
			new_pc_notification: $new_pc_notification
			new_rfq_notification: $new_rfq_notification
			rejected_rfq_notification: $rejected_rfq_notification
			closed_rfq_notification: $closed_rfq_notification
			new_q_notification: $new_q_notification
			new_consumption_notification: $new_consumption_notification
			enrollment_new_notification: $enrollment_new_notification
			enrollment_reject_notification: $enrollment_reject_notification
			enrollment_approve_notification: $enrollment_approve_notification
			enrollment_review_notification: $enrollment_review_notification
			new_rfcm_notification: $new_rfcm_notification
			accepted_rfcm_notification: $accepted_rfcm_notification
			rejected_rfcm_notification: $rejected_rfcm_notification
			accounted_cm_notification: $accounted_cm_notification
			new_ticket_notification: $new_ticket_notification
			closed_ticket_notification: $closed_ticket_notification
			review_ticket_notification: $review_ticket_notification
			reopened_ticket_notification: $reopened_ticket_notification
			update_po_notification: $update_po_notification
			void_pc_notification: $void_pc_notification
		) {
			code
			message
		}
	}
`

export const RESTORE_DEFAULT_NOTIFICATIONS = gql`
	mutation restoreDefaultNotifications {
		restoreDefaultNotifications {
			code
			message
		}
	}
`
