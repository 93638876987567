import classNames from 'classnames'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './TextArea.module.scss'

interface TextAreaProps {
	label?: string
	placeholder: string
	errorMessage?: string
	disabledInput?: boolean
	readValue?: string
	onChangeText?: (text: string) => void
	maxLength?: number
	showMsgError?: boolean
	rows?: number
}
export const TextArea = ({
	label,
	placeholder,
	errorMessage = '',
	disabledInput,
	readValue = '',
	onChangeText,
	maxLength,
	showMsgError = true,
	rows
}: TextAreaProps): ReactElement => {
	const { t } = useTranslation()

	return (
		<div className={styles.moduleNewTicket}>
			{label && <label className={styles.labelText}>{t(label)}</label>}
			<textarea
				className={classNames(
					styles.inputText,
					styles.pInputText,
					disabledInput ? styles.disabledInput : '',
					errorMessage ? styles.borderRedError : styles.borderOk
				)}
				placeholder={t(placeholder)}
				disabled={disabledInput}
				onChange={(e) => onChangeText((e.target as HTMLTextAreaElement).value)}
				value={readValue}
				maxLength={maxLength}
				rows={rows}
			/>
			{showMsgError ? errorMessage && <p className={styles.errorMessage}>{t(errorMessage)}</p> : null}
		</div>
	)
}
