import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioTooltip, NetcurioButton, NetcurioNumericField } from '@netcurio/frontend-components'
import { allowDecimals } from '@netcurio/frontend-common'
import { ConsignmentStock } from '../../../types'
import Formatter from '../../../utilities/formatter'
import classNames from 'classnames'
import styles from '../newConsumption.module.scss'

interface TableRowProps {
	position: string
	stockItem: ConsignmentStock
	addItemToConsumption: (
		consumptionQuantity: number,
		position: number
	) => { showErrorMessage: boolean; quantity: number }
	consumptionInputChange: () => void
	clearConsumptionInput: boolean
	inputRowChange: boolean
}

export default function TableRow({
	stockItem,
	clearConsumptionInput,
	inputRowChange,
	consumptionInputChange,
	addItemToConsumption,
	position
}: TableRowProps) {
	const { t } = useTranslation()
	const [consumptionQuantity, setConsumptionQuantity] = useState<string>('')
	const [disableAddItemButton, setDisableAddItemButton] = useState<boolean>(true)
	const [amountConsumed, setAmountConsumed] = useState<number>(0)
	const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
	const netValue =
		stockItem.product.unit_price * (consumptionQuantity ? parseFloat(consumptionQuantity) : 0)
	const errorMessage =
		amountConsumed === stockItem.quantity ? 'consumptionNotAvailable' : 'consumptionExceeded'
	const storageLocation = stockItem.storage_location ? stockItem.storage_location.description : 'NAtext'

	useEffect(() => {
		setDisableAddItemButton(!(parseFloat(consumptionQuantity) !== 0 && consumptionQuantity !== ''))
	}, [consumptionQuantity])

	useEffect(() => {
		if (clearConsumptionInput) setConsumptionQuantity('')
	}, [clearConsumptionInput])

	useEffect(() => {
		if (inputRowChange) setShowErrorMessage(false)
	}, [inputRowChange])

	function consumptionInputChangeCall(evt: React.ChangeEvent<HTMLInputElement>) {
		if (evt.target.value === '.') {
			evt.target.value = '0.'
		}
		if (allowDecimals(evt.target.value) && (parseInt(evt.target.value) || 0) <= stockItem.quantity) {
			setConsumptionQuantity(evt.target.value)
		}
		consumptionInputChange()
	}

	function addProduct() {
		const showMessage = addItemToConsumption(parseFloat(consumptionQuantity), parseInt(position))
		setConsumptionQuantity('')
		setAmountConsumed(showMessage.quantity)
		setShowErrorMessage(showMessage.showErrorMessage)
	}

	return (
		<div className={styles.stockNewConsumptionContainerRow}>
			<div className={styles.stockNewConsumptionRow}>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.supplierRowStockNewConsumption
					)}
				>
					{stockItem.product.supplier.name}
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.productHeaderTableStockNewConsumption,
						styles.productInformationStockNewConsumption
					)}
				>
					<NetcurioTooltip title={stockItem.description} placement={'right'}>
						<p className={styles.productDescriptionStockNewConsumption}>
							{t('textDescription')}
							{stockItem.description}
						</p>
					</NetcurioTooltip>
					<p className={'product-code-row-stock-new-consumption'}>
						{t('itemCode')} {stockItem.product.id}
					</p>
					<p>
						{t('ivaText')} {Formatter.percent.format(stockItem.product.tax)}
					</p>
					<p>
						{t('currencyComplement')} {stockItem.product.currency}
					</p>
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.storageRowStockNewConsumption
					)}
				>
					{t(storageLocation)}
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.quantityUmRowStockNewConsumption
					)}
				>
					<p>{stockItem.quantity}</p>
					<p className={styles.umRowStockNewConsumption}>{stockItem.unit}</p>
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.priceRowStockNewConsumption
					)}
				>
					<p>{Formatter.currency.format(stockItem.product.unit_price)}</p>
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.qtyConsumptionRowStockNewConsumption
					)}
				>
					<NetcurioNumericField
						size="small"
						onChange={consumptionInputChangeCall}
						value={consumptionQuantity}
						maxLimit={stockItem.quantity}
						minLimit={1}
						decimalScale={2}
						allowNegative={false}
					/>
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.netValueRowStockNewConsumption
					)}
				>
					<p className={styles.onlyReadFieldNetValueNewConsumptionStock}>
						{Formatter.currency.format(netValue)}
					</p>
				</div>
				<div className={styles.addProductButtonContainerStockNewConsumption}>
					<NetcurioButton
						size="small"
						variant="outlined"
						onClick={addProduct}
						disabled={disableAddItemButton}
					>
						{t('addItem')}
					</NetcurioButton>
				</div>
			</div>

			{showErrorMessage && (
				<p className={styles.productConsumptionExceededMessageStockNewConsumption}>
					{t(errorMessage)}
				</p>
			)}
		</div>
	)
}
