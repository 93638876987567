import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const USERS: DocumentNode = gql`
	query Users($limit: Int, $skip: Int, $sort_field: String, $sort_order: String, $filter: [FilterInput]) {
		Users(limit: $limit, skip: $skip, sort_field: $sort_field, sort_order: $sort_order, filter: $filter) {
			total
			list {
				email
				name
				lastname
				status {
					key
					value
				}
				created_at
				created_by {
					email
					name
					lastname
				}
				is_admin
			}
		}
	}
`
