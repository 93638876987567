import Select, { SelectChangeEvent as NetcurioSelectChangeEvent } from '@mui/material/Select'
import { SxProps, Theme, ThemeOptions } from '@mui/material/styles'
import {
	NetcurioRefActionCDisabled,
	NetcurioRefPrimaryBDark,
	NetcurioRefPrimaryCLight,
	NetcurioRefWarningCLight
} from '@netcurio/frontend-design-tokens'
import React, { useEffect, useState } from 'react'
import { NetcurioIconButton } from '../NetcurioIconButton/NetcurioIconButton'
import { NetcurioIcons } from '../NetcurioIcons/NetcurioIcons'

export { type NetcurioSelectChangeEvent }

const sizes = {
	smaller: {
		line: '4rem',
		outlined: '3.2rem',
		filled: '4.2rem'
	},
	small: {
		line: '4.5rem',
		outlined: '4rem',
		filled: '4.8rem'
	},
	medium: {
		line: '4.8rem',
		outlined: '5.6rem',
		filled: '5.6rem'
	}
}

interface NetcurioSelectProps<T = unknown> {
	autoWidth?: boolean
	children?: React.ReactNode
	minWidth?: string
	defaultOpen?: boolean
	defaultValue?: T
	displayEmpty?: boolean
	multiple?: boolean
	onChange?: (event: NetcurioSelectChangeEvent) => void
	onClose?: (event: React.SyntheticEvent) => void
	onOpen?: (event: React.SyntheticEvent) => void
	open?: boolean
	value?: T | ''
	label?: React.ReactNode
	variant?: 'standard' | 'outlined' | 'filled'
	size?: 'small' | 'medium'
	disabled?: boolean
	clearFunction?: () => void
	error?: boolean
	fullWidth?: boolean
	height?: 'medium' | 'small' | 'smaller'
	labelId?: string
	id?: string
	warning?: boolean
}

export const NetcurioSelectThemeOptions: ThemeOptions = {
	components: {
		MuiSelect: {
			styleOverrides: {
				select: {
					minHeight: 'auto'
				}
			}
		}
	}
}

/**
 * NetcurioSelect
 * @param autoWidth <boolean>: If `true`, the width of the popover will automatically be set according to the items inside the menu,
 * otherwise it will be at least the width of the select input. @default false
 * @param defaultOpen <boolean>: If `true`, the component is initially open. Use when the component open state is not controlled (i.e. the `open` prop is not defined).
 * You can only use it when the `native` prop is `false` (default). @default false
 * @param defaultValue <T>: The default value. Use when the component is not controlled.
 * @param displayEmpty <boolean>: If `true`, a value is displayed (the NetcurioMenuItem with null value) even if no items where selected. @default false
 * @param multiple <boolean>: If `true`, the component uses a native `select` element. @default false
 * @param onChange <(event: NetcurioSelectChangeEvent) => void>: Callback fired when a menu item is selected.
 * @param onClose <(event: React.SyntheticEvent) => void>: Callback fired when the component requests to be closed.
 * @param onOpen <(event: React.SyntheticEvent) => void>: Callback fired when the component requests to be opened.
 * @param open <boolean>: If `true`, the component is shown.
 * @param value <T>: The `input` value.
 * @param variant <'standard' | 'outlined' | 'filled'>: The variant to use. @default 'outlined'
 * @param minWidth <string>: min-width of the component
 * @param children: <ReactNode>: The option elements to populate the select with. The elements must be NetcurioMenuItems
 * @param label: <ReactNode>: Label, must be used in conjunction with NetcurioInputLabel
 * @param clearFunction: <() => void>: If set, Select is clearable with a button
 * @param height <'medium' | 'small' | 'smaller'>
 * @returns component
 */
export const NetcurioSelect = ({
	children,
	minWidth,
	value,
	label,
	clearFunction,
	disabled,
	height = 'smaller',
	variant = 'outlined',
	warning,
	...rest
}: React.PropsWithChildren<NetcurioSelectProps>) => {
	const [sxValue, setSxValue] = useState<SxProps<Theme>>({})
	const clearButtonStyle = clearFunction
		? {
				'& .MuiSelect-iconOutlined': { display: value ? 'none' : '' },
				marginRight: value ? '34px' : '14px'
			}
		: null

	useEffect(() => {
		if (disabled) {
			setSxValue({
				'& .MuiInputBase-root': {
					height: sizes[height] ? sizes[height][variant] : ''
				},
				'&:hover .MuiOutlinedInput-notchedOutline': {
					borderColor: NetcurioRefActionCDisabled
				},
				'& .MuiOutlinedInput-root': {
					height: sizes[height] ? sizes[height][variant] : '',
					'& fieldset': {
						borderColor: NetcurioRefActionCDisabled
					}
				},
				/* Disabled Inputs Section */
				'& .MuiInputBase-input.Mui-disabled': {
					WebkitTextFillColor: NetcurioRefActionCDisabled,
					borderColor: NetcurioRefActionCDisabled
				},
				'& .MuiOutlinedInput-root.Mui-disabled': {
					'& fieldset': {
						color: NetcurioRefActionCDisabled,
						borderColor: NetcurioRefActionCDisabled
					}
				},
				/* Adornment */
				'& .MuiAutocomplete-endAdornment': {
					zIndex: 1,
					position: 'absolute !important',
					right: 0,
					top: 'calc(50% - 1rem)'
				},
				height: sizes[height] ? sizes[height][variant] : '',
				minWidth: minWidth ? minWidth : 'initial',
				cursor: 'unset',
				...clearButtonStyle
			})
		} else if (warning) {
			setSxValue({
				'& .MuiInputBase-root': {
					height: sizes[height] ? sizes[height][variant] : '',
					borderColor: NetcurioRefWarningCLight,
					'& fieldset': {
						borderColor: NetcurioRefWarningCLight
					}
				},
				'&:hover .MuiOutlinedInput-notchedOutline': {
					borderColor: NetcurioRefWarningCLight
				},
				'& .MuiOutlinedInput-root': {
					height: sizes[height] ? sizes[height][variant] : '',
					'& fieldset': {
						borderColor: NetcurioRefWarningCLight
					}
				},
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: NetcurioRefWarningCLight,
					'& fieldset': {
						borderColor: NetcurioRefWarningCLight
					}
				},
				/* Disabled Inputs Section */
				'& .MuiInputBase-input.Mui-disabled': {
					WebkitTextFillColor: NetcurioRefActionCDisabled,
					borderColor: NetcurioRefActionCDisabled
				},
				'& .MuiOutlinedInput-root.Mui-disabled': {
					'& fieldset': {
						borderColor: NetcurioRefActionCDisabled
					}
				},
				/* Adornment */
				'& .MuiAutocomplete-endAdornment': {
					zIndex: 1,
					position: 'absolute !important',
					right: 0,
					top: 'calc(50% - 1rem)'
				},
				height: sizes[height] ? sizes[height][variant] : '',
				minWidth: minWidth ? minWidth : 'initial',
				...clearButtonStyle
			})
		} else {
			setSxValue({
				'& .MuiInputBase-root': {
					height: sizes[height] ? sizes[height][variant] : '',
					borderColor: NetcurioRefPrimaryCLight,
					'& fieldset': {
						borderColor: NetcurioRefPrimaryCLight
					}
				},
				'&:hover .MuiOutlinedInput-notchedOutline': {
					borderColor: NetcurioRefPrimaryBDark
				},
				'& .MuiOutlinedInput-root': {
					height: sizes[height] ? sizes[height][variant] : '',
					'& fieldset': {
						borderColor: NetcurioRefPrimaryCLight
					}
				},
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: NetcurioRefPrimaryCLight,
					'& fieldset': {
						borderColor: NetcurioRefPrimaryCLight
					}
				},
				/* Disabled Inputs Section */
				'& .MuiInputBase-input.Mui-disabled': {
					WebkitTextFillColor: NetcurioRefActionCDisabled,
					borderColor: NetcurioRefActionCDisabled
				},
				'& .MuiOutlinedInput-root.Mui-disabled': {
					'& fieldset': {
						borderColor: NetcurioRefActionCDisabled
					}
				},
				/* Adornment */
				'& .MuiAutocomplete-endAdornment': {
					zIndex: 1,
					position: 'absolute !important',
					right: 0,
					top: 'calc(50% - 1rem)'
				},
				height: sizes[height] ? sizes[height][variant] : '',
				minWidth: minWidth ? minWidth : 'initial',
				...clearButtonStyle
			})
		}
	}, [disabled])

	return (
		<Select
			sx={sxValue}
			value={value}
			disabled={disabled}
			label={label}
			endAdornment={
				clearFunction ? (
					<NetcurioIconButton
						size="small"
						sx={{ display: value ? 'inline-flex' : 'none' }}
						onClick={clearFunction}
					>
						<NetcurioIcons.Clear />
					</NetcurioIconButton>
				) : (
					<></>
				)
			}
			{...rest}
		>
			{children}
		</Select>
	)
}
