import React from 'react'
import { useTranslation } from 'react-i18next'
import { Company } from '@netcurio/frontend-common'
import { CommentSectionVariant, CommentsSection, NetcurioButton } from '@netcurio/frontend-components'
import styles from './newEnrollment.module.scss'

interface Props {
	addNewComment: (text: string) => void
	changeConfirmationModal: (value: boolean) => void
	selectedCustomer: Company
}

export default function CommentComponent({
	addNewComment,
	changeConfirmationModal,
	selectedCustomer
}: Props) {
	const { t } = useTranslation()
	const addComment = (comment: string) => addNewComment(comment)
	return (
		<div className={styles.moduleComments}>
			<div className={styles.totalModuleContainer}>
				<CommentsSection variant={CommentSectionVariant.Simple} onChange={addComment} />
				<div className={styles.buttonContainer}>
					<NetcurioButton
						color="primary"
						variant="outlined"
						onClick={() => changeConfirmationModal(true)}
						disabled={!selectedCustomer}
					>
						{t('sendEnrollmentButton')}
					</NetcurioButton>
				</div>
			</div>
		</div>
	)
}
