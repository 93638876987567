import { NetcurioIconButton, NetcurioIcons, NetcurioTooltip } from '@netcurio/frontend-components'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './AssociatedDocumentViewer.module.scss'

export interface AssociationItemProps {
	url: string
	label?: string
	tooltip?: string
	data?: string
	disabled?: boolean
}
export const AssociateDocumentItem = ({ url, label, tooltip, data, disabled = false }: AssociationItemProps) => {
	const history = useHistory()

	return (
		<article className={styles['association-item']}>
			{label && <p className={styles['title-label']}>{label}</p>}
			<div
				className={styles['item-container']}
			>
				<p className={styles['item-label']}>{data}</p>
				<div className={styles['item-link']}>
					<NetcurioTooltip title={disabled ? '' : tooltip} placement={'top'}>
						<div>
							<NetcurioIconButton
								disabled={disabled}
								onClick={() => history.push(url)}
								color="primary"
								hoverIcon={<NetcurioIcons.ArrowCircleRight />}
								restingIcon={<NetcurioIcons.ArrowCircleRightOutlined />}
							/>
						</div>
					</NetcurioTooltip>
				</div>
			</div>
		</article>
	)
}
