/* global window, console, location, sessionStorage, URL */
import { Company, getCurrentUser, URLS } from '@netcurio/frontend-common'
import * as queries from '../views/login/queries'
import { connection } from './connection'
import Constants from './constants'
import { getUrlParameter } from './getUrlParameter'
import UserInformation from './userInformation'

export function redirectToPage() {
	if (window.history.length > 2) {
		window.history.back()
	} else location.href = URLS.SITE
}

function getParameterSession(session: URL, param: string) {
	return session.searchParams.get(param)
}

function getInformationDocType(fromLogin: boolean, sessionPath: URL): Promise<Company | undefined> {
	let company: string | null = null
	let id: string | null = ''
	const docType = getDocType(fromLogin, sessionPath)
	if (docType !== '') {
		switch (docType) {
			case Constants.DOCUMENT_TYPE.PURCHASE_ORDER:
				id = fromLogin ? getParameterSession(sessionPath, 'order') : getUrlParameter('order')
				company = fromLogin
					? getParameterSession(sessionPath, 'customer')
					: getUrlParameter('customer')
				break
			case Constants.DOCUMENT_TYPE.INVOICE:
				id = fromLogin ? getParameterSession(sessionPath, 'invoice') : getUrlParameter('invoice')
				break
			case Constants.DOCUMENT_TYPE.PAYMENT_COMPLEMENT:
				id = fromLogin
					? getParameterSession(sessionPath, 'paymentComplement')
					: getUrlParameter('paymentComplement')
				break
			case Constants.DOCUMENT_TYPE.REQUEST_FOR_QUOTATION:
				id = fromLogin ? getParameterSession(sessionPath, 'rfq') : getUrlParameter('rfq')
				company = fromLogin
					? getParameterSession(sessionPath, 'customer')
					: getUrlParameter('customer')
				break
			case Constants.DOCUMENT_TYPE.CONSUMPTION:
				id = fromLogin
					? getParameterSession(sessionPath, 'consumption')
					: getUrlParameter('consumption')
				company = fromLogin
					? getParameterSession(sessionPath, 'customer')
					: getUrlParameter('customer')
				break
			case Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO:
				id = fromLogin
					? getParameterSession(sessionPath, 'requestforcreditmemo')
					: getUrlParameter('requestforcreditmemo')
				company = fromLogin
					? getParameterSession(sessionPath, 'customer')
					: getUrlParameter('customer')
				break
			case Constants.DOCUMENT_TYPE.CREDIT_MEMO:
				id = fromLogin
					? getParameterSession(sessionPath, 'creditmemo')
					: getUrlParameter('creditmemo')
				break
			case Constants.DOCUMENT_TYPE.TICKET:
				id = fromLogin ? getParameterSession(sessionPath, 'ticket') : getUrlParameter('ticket')
				company = fromLogin ? getParameterSession(sessionPath, 'sender') : getUrlParameter('sender')
				break
		}
		const client = connection()
		return new Promise(function (resolve) {
			client
				.query({
					query: queries.DOCUMENT_FROM_LINK,
					variables: {
						id: id,
						company: company,
						docType: docType
					}
				})
				.then((result) => {
					const suppliersRfcs = result.data.getDocumentFromLink?.suppliers ?? []
					const customerRfc = result.data.getDocumentFromLink?.customer ?? ''
					const currentUser = getCurrentUser()
					const currentCompanyRfc = currentUser ? currentUser.company?.rfc : ''
					const companiesUnblocked = UserInformation.getCompaniesUnblocked()
					let companySelected

					if (currentCompanyRfc && [customerRfc, ...suppliersRfcs].includes(currentCompanyRfc)) {
						companySelected = UserInformation.getCompanySelected(
							companiesUnblocked,
							currentCompanyRfc
						)
					} else {
						companySelected = UserInformation.getCompanySelected(companiesUnblocked, customerRfc)
						if (companySelected === undefined) {
							companySelected = UserInformation.getCompanySelected(
								companiesUnblocked,
								suppliersRfcs
							)
						}
					}
					sessionStorage.clear()
					resolve(companySelected)
				})
				.catch((error) => {
					console.error(error)
				})
		})
	} else {
		return new Promise(function (resolve) {
			resolve(undefined)
		})
	}
}

export function validateMultiCompany(companiesUnblocked: Company[], goTo: string | undefined) {
	const user = getCurrentUser()
	if (companiesUnblocked.length > 1) location.href = URLS.SELECT_COMPANY_AT_LOGIN
	else {
		if (user) {
			UserInformation.setCurrentUser(user, companiesUnblocked[0])
			if (goTo !== undefined) {
				location.href = goTo
			} else {
				location.href = companiesUnblocked[0].homepage
					? URLS[Constants.RELATION_KEY_URL[companiesUnblocked[0].homepage]]
					: URLS.PO_LIST
			}
			sessionStorage.clear()
		} else {
			location.href = URLS.SELECT_COMPANY_AT_LOGIN
		}
	}
}

function getDocType(fromLogin: boolean, sessionPath: URL) {
	let docType = ''
	const evalDoc = fromLogin ? sessionPath.pathname : window.location.pathname

	switch (evalDoc) {
		case URLS.PO_DETAIL:
		case URLS.PO_EDIT:
			docType = Constants.DOCUMENT_TYPE.PURCHASE_ORDER
			break
		case URLS.INVOICE_DETAIL:
		case URLS.INVOICE_EDIT:
			docType = Constants.DOCUMENT_TYPE.INVOICE
			break
		case URLS.COMPLEMENT_DETAIL:
			docType = Constants.DOCUMENT_TYPE.PAYMENT_COMPLEMENT
			break
		case URLS.RFQ_DETAIL:
			docType = Constants.DOCUMENT_TYPE.REQUEST_FOR_QUOTATION
			break
		case URLS.CONSUMPTION_DETAIL:
			docType = Constants.DOCUMENT_TYPE.CONSUMPTION
			break
		case URLS.RFCM_DETAIL:
			docType = Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO
			break
		case URLS.CREDIT_MEMO_DETAIL:
			docType = Constants.DOCUMENT_TYPE.CREDIT_MEMO
			break
		case URLS.TICKET_DETAIL:
			docType = Constants.DOCUMENT_TYPE.TICKET
			break
	}
	return docType
}

function updateUser(newCompany: string): Promise<Company | undefined> {
	const client = connection()
	return new Promise(function (resolve) {
		client
			.mutate({
				mutation: queries.LOGIN_USER
			})
			.then((result) => {
				UserInformation.setCurrentUser(result.data.loginUser, undefined)
				const companiesUnblocked = UserInformation.getCompaniesUnblocked()
				const companySelected = UserInformation.getCompanySelected(companiesUnblocked, newCompany)
				resolve(companySelected)
			})
			.catch((error) => {
				console.error(error)
			})
	})
}

const removeLngParameterFromSearch = () => {
	const url = new URL(window.location.href)
	url.searchParams.delete('lng')
	return url.search
}

export function validationToRedirection(fromLogin: boolean) {
	return new Promise(function (resolve) {
		const user = getCurrentUser()
		if (user) {
			const companiesUnblocked = UserInformation.getCompaniesUnblocked()
			let newCompany = ''
			let sessionPath: Location | URL | null = null
			let companySelected = undefined
			let searchUrl = ''
			const defaultCompanyRFC = UserInformation.getDefaultCompany()

			if (companiesUnblocked.length > 0) {
				if (fromLogin) {
					const internalUrl = sessionStorage.getItem('internalUrl')
					if (internalUrl !== null) {
						sessionPath = new URL(internalUrl)
						searchUrl = sessionPath.search
						newCompany = sessionPath.searchParams.get('companyRFC') || ''
					}
				} else {
					searchUrl = removeLngParameterFromSearch()
					newCompany = getUrlParameter('companyRFC')
					sessionPath = new URL(window.location.href)
				}
				if (sessionPath !== null) {
					if (
						(sessionPath.pathname === URLS.PO_LIST && newCompany !== '') ||
						(newCompany === '' && searchUrl !== '' && sessionPath.pathname !== URLS.GR_DETAIL)
					) {
						if (newCompany !== '') {
							updateUser(newCompany)
								.then((response) => {
									UserInformation.setCurrentUser(user, response)
									if (response !== undefined) {
										location.href = response.homepage
											? URLS[Constants.RELATION_KEY_URL[response.homepage]]
											: URLS.PO_LIST
										resolve(undefined)
									} else {
										location.href = URLS.WITHOUT_ACCESS
										resolve(undefined)
									}
								})
								.catch((error) => {
									console.error(error)
								})
						} else {
							/* Case URL of documents */
							getInformationDocType(fromLogin, sessionPath)
								.then((selectedCompany) => {
									if (selectedCompany !== undefined) {
										UserInformation.setCurrentUser(user, selectedCompany)
										if (fromLogin && sessionPath !== null) {
											location.href = sessionPath.href
										}
										resolve(selectedCompany)
									} else {
										location.href = URLS.WITHOUT_ACCESS
										resolve(selectedCompany)
									}
								})
								.catch((error) => {
									console.error(error)
								})
						}
					} else {
						/* Case URL of lists */
						if (!user.company) {
							if (defaultCompanyRFC) {
								companySelected = UserInformation.getCompanySelected(
									companiesUnblocked,
									defaultCompanyRFC ?? ''
								)
								if (companySelected !== undefined) {
									UserInformation.setCurrentUser(user, companySelected)
									location.href = sessionPath.href
									sessionStorage.clear()
								} else {
									validateMultiCompany(companiesUnblocked, sessionPath.href)
								}
							} else {
								validateMultiCompany(companiesUnblocked, sessionPath.href)
							}
						} else if (user.company && location.href !== sessionPath.href) {
							location.href = sessionPath.href
							sessionStorage.clear()
						}
						resolve(companySelected)
					}
				} else {
					/* Cases of validation to status of Default Company */
					if (defaultCompanyRFC) {
						companySelected = UserInformation.getCompanySelected(
							companiesUnblocked,
							defaultCompanyRFC ?? ''
						)
						if (companySelected !== undefined) {
							UserInformation.setCurrentUser(user, companySelected)
							location.href = companySelected.homepage
								? URLS[Constants.RELATION_KEY_URL[companySelected.homepage]]
								: URLS.PO_LIST
						} else {
							const statusCompanyDefault = UserInformation.getCompanySelected(
								user.companies,
								defaultCompanyRFC ?? ''
							)
							if (statusCompanyDefault === undefined) {
								resolve(Constants.USER_STATUS.UNSUBSCRIBED)
							} else {
								resolve(Constants.USER_STATUS.BLOCKED)
							}
						}
					} else {
						validateMultiCompany(companiesUnblocked, undefined)
					}
					resolve(companySelected)
				}
			} else {
				/*Case all Companies Blocked */
				resolve(Constants.AUTH.ALLCOMPANIESBLOCKED)
			}
		} else {
			resolve(undefined)
		}
	})
}
