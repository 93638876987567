import React, { useContext } from 'react'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import { useTranslation } from 'react-i18next'
import { RegisterCompanyForm } from './RegisterCompanyForm'
import { ShowRegisterCompanyContext } from './ShowRegisterCompanyContext'

export const RegisterCompanyModal = () => {
	const { t } = useTranslation()
	const { validateForm, isOpenRegisterCompanyModal, closeModal } = useContext(ShowRegisterCompanyContext)

	return (
		<div>
			<NetcurioDialog
				open={isOpenRegisterCompanyModal}
				actionButtons={
					<>
						<NetcurioButton onClick={closeModal} variant="text">
							{t('cancelButton')}
						</NetcurioButton>
						<NetcurioButton variant="contained" onClick={validateForm}>
							{t('textActionRegister')}
						</NetcurioButton>
					</>
				}
				maxWidth="xs"
				minWidth="xs"
				contentFontSize="1.4rem"
			>
				<RegisterCompanyForm />
			</NetcurioDialog>
		</div>
	)
}
