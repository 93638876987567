import { Filter, getCurrentUser, Roles, URLS } from '@netcurio/frontend-common'
import { Header, useNetcurioLoader } from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { Dayjs } from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AddButton } from '../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../components/HeaderButtons/ExportButton'
import { useCompanySettings } from '../../hooks/useCompanySettings'
import '../../style.scss'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { downloadExcelFile } from '../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../utilities/file-handling/file-descriptor'
import listHelper from '../../utilities/listHelper'
import UserInformation from '../../utilities/userInformation'
import { ErrorModal } from '../goodsReceiptList/Modals/Errormodal/ErrorModal'
import { GoodsReceiptTable } from './goodsReceiptTable'
import * as queries from './queries'
import {
	TypesDataGoodReceipts,
	TypesFilterToApply,
	TypesGoodReceipts,
	TypesGoodsReceiptsList,
	TypesModal,
	TypesUpdateFilter
} from './types'

export const GoodsReceiptList = (): React.ReactElement => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const [userRole, setUserRole] = useState<Roles>()
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [stopPagination, setStopPagination] = useState<boolean>(true)
	const isMounted = true
	const initialSort = 'created_at'
	const history = useHistory()
	const currentUser = getCurrentUser()

	const { companySettings } = useCompanySettings({
		rfc: currentUser?.company?.rfc ?? null,
		errorHandler: (error: Error) => handleError(error)
	})

	const isCustomer = userRole === Roles.CUSTOMER
	const isNewEntryButtonDisabled = isCustomer && companySettings?.process_invoice_in_external_system

	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [modal, setModal] = useState<TypesModal>({
		errorModal: false,
		errorCode: undefined
	})
	const [goodsReceiptsList, setGoodsReceiptsList] = useState<TypesGoodsReceiptsList>({
		rowsActual: undefined,
		totalRows: undefined,
		filterContainerBar: false,
		dataFiltersArray: [],
		dataFilters: {},
		deleteRange: false,
		limitRows: undefined,
		fieldList: initialSort,
		orderList: Constants.ORDER.DESC,
		actualFilterData: undefined,
		numSelectOptions: 0,
		dataGoodsReceipts: {}
	})

	useEffect(() => {
		const mediumScreenHeight = 600
		const headerHeight = 95
		const elementMinHeight = 500
		if (isMounted) {
			let finalHeight: number
			if (window.innerHeight > mediumScreenHeight) {
				finalHeight = window.innerHeight - headerHeight
			} else finalHeight = elementMinHeight
			const numberRows: number = finalHeight / 45 + 2
			setUserRole(UserInformation.getCompanyRole())
			setGoodsReceiptsList((prevState) => ({
				...prevState,
				limitRows: parseInt(numberRows.toFixed()),
				rowsActual: parseInt(numberRows.toFixed())
			}))
			showLoadingSpinner()
		}
	}, [])

	useEffect(() => {
		if (isMounted && goodsReceiptsList.limitRows !== undefined)
			filteringGoodsReceipts(0, initialSort, '', initialSort)
	}, [goodsReceiptsList.limitRows])

	useEffect(() => {
		if (activePagination && stopPagination) {
			setActivePagination(false)
			setStopPagination(false)
			setShowBarLoader(true)
			filteringGoodsReceipts(
				goodsReceiptsList.rowsActual,
				'',
				'',
				'',
				undefined,
				undefined,
				undefined,
				'none'
			)
		}
	}, [activePagination, stopPagination])

	const downloadReportExcel = () => {
		if (Object.keys(goodsReceiptsList.dataGoodsReceipts).length > 0) {
			showLoadingSpinner()
			const copyFilterToApply: Array<Filter> = JSON.parse(
				JSON.stringify(goodsReceiptsList.dataFiltersArray)
			)
			copyFilterToApply.forEach(listHelper.applyTimeZoneDate)

			const currentUser = getCurrentUser()

			const body = JSON.stringify({
				companyFilter: currentUser ? currentUser.company.rfc : undefined,
				filters: copyFilterToApply,
				sort_field: goodsReceiptsList.fieldList,
				sort_order: goodsReceiptsList.orderList
			})
			listHelper
				.generateReportList(body, 'goods-receipts')
				.then(async (res) => {
					if (res.ok) {
						res.json().then((responseJson: FileDescriptor) => {
							downloadExcelFile(responseJson)
						})
						hideLoadingSpinner()
					} else {
						const error = await res.json()
						handleError(error)
						hideLoadingSpinner()
					}
				})
				.catch(handleError)
		}
	}

	const queryGoodsReceipts = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: TypesDataGoodReceipts
	) => {
		const copyfilterToApply: Array<Filter> = JSON.parse(JSON.stringify(filterToApply))
		copyfilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query: queries.GOODS_RECEIPTS,
				variables: {
					limit: goodsReceiptsList.limitRows,
					skip: skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyfilterToApply
				}
			})
			.then((result) => {
				let interCount: number = skip
				const data: TypesDataGoodReceipts = { ...currentList }
				const obj: Array<TypesGoodReceipts> = result.data.GoodsReceipts.list
				for (const keyName in obj) {
					interCount++
					data[interCount] = obj[keyName]
				}
				if (isMounted) {
					setGoodsReceiptsList((prevState) => ({
						...prevState,
						totalRows: result.data.GoodsReceipts.total,
						dataGoodsReceipts: data
					}))
					setStopPagination(true)
					hideLoadingSpinner()
					setShowBarLoader(false)
				}
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		const errorCode: string = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setGoodsReceiptsList((prevState) => ({ ...prevState, dataGoodsReceipts: {} }))
			setStopPagination(true)
			setModal({
				errorModal: true,
				errorCode: errorCode
			})
		}
		hideLoadingSpinner()
		setShowBarLoader(false)
	}

	const filteringGoodsReceipts = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter?: string,
		initRange?: number | Dayjs,
		finalRange?: number | Dayjs,
		filterRemove?: string
	) => {
		let fieldListData: string = goodsReceiptsList.fieldList
		let orderListData: string = goodsReceiptsList.orderList
		let columnFilterActual: string = goodsReceiptsList.actualFilterData
		let currentList: TypesDataGoodReceipts = { ...goodsReceiptsList.dataGoodsReceipts }

		if (sortOrder !== '') {
			orderListData = sortOrder
			setGoodsReceiptsList((prevState) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortField) {
			setGoodsReceiptsList((prevState) => ({
				...prevState,
				fieldList: sortField
			}))
			fieldListData = sortField
		}
		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setGoodsReceiptsList((prevState) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}
		let typeFilterActual: string
		let initRangeActual: number | Dayjs
		let finalRangeActual: number | Dayjs
		const valueFilterActual: string = valueFilter
		switch (columnFilterActual) {
			case 'id':
			case 'customer_reference':
			case 'created_by':
			case 'supplier':
			case 'purchase_order':
				typeFilterActual = 'wildcard'
				break
			case 'created_at':
				typeFilterActual = 'date'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
		}
		if (skip > 0) {
			const rowsActual: number = goodsReceiptsList.rowsActual + goodsReceiptsList.limitRows
			setGoodsReceiptsList((prevState) => ({
				...prevState,
				rowsActual: rowsActual
			}))
		} else {
			setGoodsReceiptsList((prevState) => ({
				...prevState,
				rowsActual: prevState.limitRows
			}))
			currentList = {}
		}
		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField
		)
		queryGoodsReceipts(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number | Dayjs,
		finalRangeActual: number | Dayjs,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		const resultFilterToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			goodsReceiptsList.dataFilters,
			goodsReceiptsList.numSelectOptions
		) as TypesFilterToApply
		const objUpdate = resultFilterToApply.objectForStateUpdate as TypesUpdateFilter
		setGoodsReceiptsList((prevState) => ({
			...prevState,
			dataFilters: objUpdate.dataFilters,
			dataFiltersArray: objUpdate.dataFiltersArray,
			deleteRange: objUpdate.deleteRange,
			filterContainerBar: objUpdate.filterContainerBar
		}))
		return resultFilterToApply.filterToApply
	}

	const closeFilterContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringGoodsReceipts, initialSort)
		setGoodsReceiptsList((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) => {
		listHelper.deleteFilterActive(
			indexObject,
			goodsReceiptsList.dataFilters,
			closeFilterContainerBar,
			filteringGoodsReceipts
		)
		setGoodsReceiptsList((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	return (
		<>
			<Header>
				<div>
					<AddButton
						onClick={() => history.push(URLS.GR_NEW)}
						translationKey="newEntryGR"
						disabled={isNewEntryButtonDisabled}
					/>
				</div>
				<div>
					<ExportButton onClick={() => downloadReportExcel()} />
				</div>
			</Header>
			<GoodsReceiptTable
				userRole={userRole}
				dataGoodsReceipt={goodsReceiptsList.dataGoodsReceipts}
				filteringGoodsReceipts={filteringGoodsReceipts}
				rowsActual={goodsReceiptsList.rowsActual}
				totalRows={goodsReceiptsList.totalRows}
				filterContainerBar={goodsReceiptsList.filterContainerBar}
				closeFilterContainerBar={closeFilterContainerBar}
				dataFiltersArray={goodsReceiptsList.dataFiltersArray}
				deleteFilterActive={deleteFilterActive}
				deleteRange={goodsReceiptsList.deleteRange}
				setActivePagination={setActivePagination}
				showBarLoader={showBarLoader}
				setShowBarLoader={setShowBarLoader}
			/>
			<ErrorModal open={!!modal?.errorCode} errorCode={modal?.errorCode} />
		</>
	)
}
