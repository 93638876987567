import Constants from './constants'

export function showErrorComponent(error: any) {
	if (error) {
		if (error.networkError) {
			if (error.networkError.result && error.networkError.result.errors.length) {
				if (
					error.networkError.result.errors[0].message === Constants.ERROR.INVALID_TOKEN ||
					error.networkError.result.errors[0].code === Constants.ERROR.INVALID_TOKEN
				) {
					return Constants.ERROR.INVALID_TOKEN
				} else if (
					error.networkError.result.errors[0].message === Constants.USER.USER_BLOCKED ||
					error.networkError.result.errors[0].code === Constants.USER.USER_BLOCKED
				) {
					return Constants.USER.USER_BLOCKED
				} else if (
					error.networkError.result.errors[0].message === Constants.USER.USER_UNSUBSCRIBED ||
					error.networkError.result.errors[0].code === Constants.USER.USER_UNSUBSCRIBED
				) {
					return Constants.USER.USER_UNSUBSCRIBED
				}
			}
			return Constants.ERROR.NETWORK_ERROR
		} else if (error.graphQLErrors !== undefined && error.graphQLErrors[0] !== undefined) {
			return getErrorCode(error.graphQLErrors[0].code)
		} else {
			return getErrorCode(error.code ?? error.error)
		}
	} else {
		return Constants.ERROR.NO_DATA_FOUND
	}
}

function getErrorCode(code: string) {
	if (code !== null && code !== undefined && code.trim() !== '') {
		return code
	} else {
		return Constants.ERROR.UNEXPECTED_ERROR
	}
}
