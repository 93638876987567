import gql from 'graphql-tag'

export default {
	LOG_ERROR: gql`
		mutation logError($user: ID, $message: String, $exception: String) {
			logError(user: $user, message: $message, exception: $exception)
		}
	`,
	SUPPLIERS_BY_NAME_OR_CODE: gql`
		query SuppliersByNameOrCode($search_text: String!, $sort_field: String, $sort_order: String) {
			SuppliersByNameOrCode(
				search_text: $search_text
				sort_field: $sort_field
				sort_order: $sort_order
			) {
				rfc
				name
			}
		}
	`,
	CUSTOMERS_BY_NAME_OR_CODE: gql`
		query CustomersByNameOrCode($search_text: String!, $sort_field: String, $sort_order: String) {
			CustomersByNameOrCode(
				search_text: $search_text
				sort_field: $sort_field
				sort_order: $sort_order
			) {
				rfc
				name
			}
		}
	`,
	CUSTOMERS_NOT_ENROLLED_BY_NAME_OR_CODE: gql`
		query CustomersNotEnrolledByNameOrCode(
			$search_text: String!
			$sort_field: String
			$sort_order: String
		) {
			CustomersNotEnrolledByNameOrCode(
				search_text: $search_text
				sort_field: $sort_field
				sort_order: $sort_order
			) {
				rfc
				name
			}
		}
	`,
	PRODUCT_NAME_CODE: gql`
		query ProductsByNameOrCode($searchText: String!, $supplier: ID!) {
			ProductsByNameOrCode(searchText: $searchText, supplier: $supplier) {
				id
				supplier {
					rfc
				}
				description
				unit_price
				unit
				tax
				ean
				currency
			}
		}
	`,
	MEASUREMENT_UNITS: gql`
		query MeasurementUnits {
			MeasurementUnits {
				code
				description
			}
		}
	`,
	CURRENCIES: gql`
		query Currencies {
			Currencies {
				code
				description
			}
		}
	`,
	BRANCH_OFFICES: gql`
		query BranchOffices($company: ID!) {
			BranchOffices(company: $company) {
				id
				description
				address_line_1
				address_line_2
				country_code
				country_description
				state_description
				state_code
				postal_code
				company {
					rfc
				}
				storage_locations {
					id
					description
					branch_office
					company
				}
			}
		}
	`,
	INVOICES_DETAIL: gql`
		query Invoice($uuid: ID!) {
			Invoice(uuid: $uuid) {
				uuid
				serie
				folio
				status {
					key
					value
				}
				sender {
					rfc
					name
				}
				receiver {
					rfc
					name
				}
				reference
				reference_type
				purchase_order {
					id
					customer {
						rfc
					}
					customer_reference
				}
				consumption {
					id
					customer {
						rfc
					}
					customer_reference
				}
				uploaded_by {
					email
					name
					lastname
				}
				modified_by_supplier {
					email
					name
					lastname
				}
				modified_by_customer {
					email
					name
					lastname
				}
				payment_method
				currency
				subtotal
				iva
				total
				date
				proposed_payment_date
				payment_date
				date_voided
				bank_reference
				updated_at
				created_at
				items {
					position
					concept
					quantity
					unit_price
					net_amount
					tax
					error
					purchase_order_item {
						code
						name
						unit_price
					}
					consumption_item {
						code
						description
						unit_price
					}
					reference_position
					error
					code
				}
				payment_complements {
					uuid
					serie
					folio
					date
					created_at
					payments {
						position
						date
						currency
						amount
						items {
							uuid
							paid_amount
							pending_balance
							invoice {
								uuid
								serie
								folio
							}
						}
					}
				}
				comments {
					uuid
					text
					created_by {
						email
						name
						lastname
					}
					created_at
				}
				invoice_documents {
					link
					document_type {
						code
						description
					}
				}
				error {
					key
					value
				}
			}
		}
	`
}
