import { dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import classnames from 'classnames'
import { ReactElement, forwardRef, useContext } from 'react'
import { CommentSectionSize, CommentsSectionContext } from './CommentsSection.context'
import styles from './CommentsSection.module.scss'
import themeStyles from './CommentsSectionTheme.module.scss'

export const CommentsSectionsBody = forwardRef<HTMLUListElement>((_, ref): ReactElement => {
	const { comments, commentSectionSize } = useContext(CommentsSectionContext)

	const commentsLength = comments?.length || 0
	const lastIndex = commentsLength - 1

	const isCommentsSectionMinimized = commentSectionSize?.currentSize === CommentSectionSize.Minimized
	const isCommentsSectionExtended = commentSectionSize?.currentSize === CommentSectionSize.Extended

	return (
		<div
			className={classnames(styles.containerComments, {
				[styles.animateHideComments]: isCommentsSectionMinimized,
				[styles.containerCommentsList]: isCommentsSectionExtended
			})}
		>
			<ul
				ref={ref}
				className={classnames(styles.commentsList, styles.grayColorTextGeneralInfo, {
					[styles.commentsListMinimized]: isCommentsSectionMinimized,
					[styles.commentsListExtended]: isCommentsSectionExtended
				})}
			>
				{comments?.map(({ created_at, created_by, text }, index) => {
					return (
						<li
							key={`${created_at}-${created_by}-${text}`}
							className={classnames(styles.listCommentSection, {
								[themeStyles.CommentBorderBottom]: index !== lastIndex
							})}
						>
							<div
								className={classnames(
									styles.listCommentSectionNameDate,
									themeStyles.CommentColorText
								)}
							>
								<p>{`${created_by?.name} ${created_by?.lastname}`}</p>
								<p>
									{dateFormatter.format(parseTimestampToTimeZone(created_at ?? new Date()))}
								</p>
							</div>
							<p className={styles.listCommentSectionComment}>{text}</p>
						</li>
					)
				})}
			</ul>
		</div>
	)
})

CommentsSectionsBody.displayName = 'CommentsSectionsBody'
