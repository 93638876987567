import { ConsumptionItem, InvoiceItem, PurchaseOrderItem } from '../../types'

export const associateInvoiceItems = ({
	invoiceItems,
	purchaseOrderItems
}: {
	invoiceItems: InvoiceItem[]
	purchaseOrderItems: (PurchaseOrderItem | ConsumptionItem)[]
}) => {
	const invoiceItemsToAssociate = [...invoiceItems]
	const availableItems = [...purchaseOrderItems]

	let exceedItems: (PurchaseOrderItem | ConsumptionItem)[] = []

	const matches = new Map<InvoiceItem, PurchaseOrderItem | ConsumptionItem>()

	const generateMatch = (
		invoiceItem: InvoiceItem,
		invoiceItemIndex: number,
		suggestionItem: PurchaseOrderItem | ConsumptionItem
	) => {
		matches.set(invoiceItem, suggestionItem)
		availableItems.splice(availableItems.indexOf(suggestionItem), 1)
		invoiceItemsToAssociate[invoiceItemIndex].reference_position = suggestionItem.position
	}

	const checkExceedItems = (invoiceItem: InvoiceItem) => {
		const exceedItemsFilter = availableItems.filter((item) =>
			(item as PurchaseOrderItem).unlimited_tolerance
				? false
				: item.unit_price === invoiceItem.unit_price &&
					invoiceItem.quantity > (item?.pending_quantity_to_invoice ?? 0)
		)
		exceedItems = [...exceedItems, ...exceedItemsFilter]
	}

	invoiceItemsToAssociate.forEach((invoiceItem: InvoiceItem, index: number) => {
		let suggestionItems = availableItems.filter((item) =>
			(item as PurchaseOrderItem).unlimited_tolerance
				? item.unit_price === invoiceItem.unit_price
				: item.unit_price === invoiceItem.unit_price &&
					invoiceItem.quantity === (item?.pending_quantity_to_invoice ?? 0)
		)

		if (suggestionItems.length === 1) {
			const suggestionItem = suggestionItems[0]
			generateMatch(invoiceItem, index, suggestionItem)
		} else if (suggestionItems.length === 0) {
			suggestionItems = availableItems.filter(
				(item) =>
					item.unit_price === invoiceItem.unit_price &&
					invoiceItem.quantity < (item?.pending_quantity_to_invoice ?? 0)
			)

			if (suggestionItems.length === 1) {
				const suggestionItem = suggestionItems[0]
				generateMatch(invoiceItem, index, suggestionItem)
			} else {
				checkExceedItems(invoiceItem)
			}
		}
	})

	return {
		matches,
		matchCount: matches.size,
		invoiceItems: invoiceItemsToAssociate,
		exceedItems
	}
}
