import React from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioNumericField, NetcurioTooltip, NetcurioTextField } from '@netcurio/frontend-components'
import { NewConsumptionItem } from '../../types'
import cancelGray from '@netcurio/frontend-assets/src/icons/cancel-gray.svg'
import cancelRed from '@netcurio/frontend-assets/src/icons/cancel-red.svg'
import classNames from 'classnames'
import styles from './newConsumption.module.scss'
import Formatter from '../../utilities/formatter'

interface Props {
	itemData?: NewConsumptionItem
	itemPosition?: number
	deleteItem?: (position: number) => void
	quantityInputChange?: (evt: React.FormEvent<HTMLInputElement>, index: number) => void
	messageMissingStock?: boolean
}

export default function Item({
	itemData,
	itemPosition,
	deleteItem,
	quantityInputChange,
	messageMissingStock
}: Props) {
	const { t } = useTranslation()
	const storageLocation: string = itemData ? itemData?.storage_location || 'NAtext' : ''

	return (
		<div className={styles.singleItemContainer}>
			<div className={styles.consumptionItemHeight}>
				<div className={classNames(styles.position, styles.readOnlyField)}>
					{itemData ? itemData.position : 1}
				</div>
				<div className={styles.newConsumptionPreviewItemFieldsContainer}>
					<div className={styles.newConsumptionPreviewItemFields}>
						<div className={classNames(styles.supplier)}>
							<NetcurioTextField
								label={t('supplierText')}
								fullWidth
								height="smaller"
								variant="outlined"
								disabled
								value={itemData && itemData.product ? itemData.product.supplier.name : ''}
							/>
						</div>

						<NetcurioTooltip
							title={itemData && itemData.product ? itemData.description : ''}
							placement={'top'}
						>
							<div className={classNames(styles.product)}>
								<NetcurioTextField
									label={t('codeDescriptionProduct')}
									fullWidth
									height="smaller"
									variant="outlined"
									disabled
									value={
										itemData && itemData.product
											? itemData.product.id + ' - ' + itemData.description
											: ''
									}
								/>
							</div>
						</NetcurioTooltip>
						<div
							className={classNames(styles.storageLocation, {
								[styles.colorReadModeText]: !itemData
							})}
						>
							<NetcurioTextField
								label={t('storageLocation')}
								fullWidth
								height="smaller"
								variant="outlined"
								disabled
								value={t(storageLocation)}
							/>
						</div>
						<div className={styles.consumptionItemDeleteIconContainer}>
							<NetcurioTooltip title={t('delete')} placement={'top'}>
								<img
									src={itemData ? cancelRed : cancelGray}
									alt="cancel"
									className={'cancel-icon'}
									onClick={() => {
										if (itemData) deleteItem(itemPosition)
									}}
								/>
							</NetcurioTooltip>
						</div>
					</div>
					<div className={styles.newConsumptionPreviewItemFields}>
						<NetcurioTooltip title={t('availableQuantity')} placement={'top'}>
							<div className={classNames(styles.availability)}>
								<NetcurioTextField
									label={t('availability')}
									fullWidth
									height="smaller"
									variant="outlined"
									disabled
									value={itemData ? itemData.availability + ' ' + itemData.unit : t('')}
								/>
							</div>
						</NetcurioTooltip>
						<div className={classNames(styles.taxField)}>
							<div
								className={classNames(styles.itemDescriptionOverflow, {
									[styles.colorReadModeText]: !itemData
								})}
							>
								<NetcurioTextField
									label={t('taxIVA')}
									fullWidth
									height="smaller"
									variant="outlined"
									disabled
									value={
										itemData && itemData.product
											? Formatter.percent.format(itemData.product.tax)
											: t('')
									}
								/>
							</div>
						</div>
						<NetcurioTooltip title={t('unitPrice')} placement={'top'}>
							<div className={classNames(styles.unitPrice)}>
								<div className={classNames(styles.itemDescriptionOverflow)}>
									<NetcurioTextField
										label={t('taxIVA')}
										fullWidth
										height="smaller"
										variant="outlined"
										disabled
										value={
											itemData && itemData.product
												? Formatter.currency.format(itemData.product.unit_price)
												: '$0.00'
										}
									/>
								</div>
							</div>
						</NetcurioTooltip>
						{itemData ? (
							<div className={styles.inputNewConsumption}>
								<NetcurioNumericField
									label={t('placeholderAmount')}
									id={'item' + itemPosition}
									size="small"
									onChange={(evt) => {
										quantityInputChange(evt, itemPosition)
									}}
									value={itemData.quantity}
									maxLimit={itemData.availability}
									minLimit={1}
									allowNegative={false}
									error={itemData && messageMissingStock}
								/>
							</div>
						) : (
							<NetcurioTooltip title={t('consumptionQuantity')} placement={'top'}>
								<div className={classNames(styles.quantity)}>
									<NetcurioTextField
										label={t('placeholderAmount')}
										fullWidth
										height="smaller"
										variant="outlined"
										disabled
									/>
								</div>
							</NetcurioTooltip>
						)}
						<NetcurioTooltip title={t('netValue')}>
							<div className={classNames(styles.totalPrice)}>
								<div className={classNames(styles.itemDescriptionOverflow)}>
									<NetcurioTextField
										label={t('netValue')}
										fullWidth
										height="smaller"
										variant="outlined"
										disabled
										value={
											itemData ? Formatter.currency.format(itemData.net_value) : '$0.00'
										}
									/>
								</div>
							</div>
						</NetcurioTooltip>
						<div className={classNames(styles.currencyField)}>
							<NetcurioTextField
								label={t('currencyShortText')}
								fullWidth
								height="smaller"
								variant="outlined"
								disabled
								value={itemData && itemData.product ? itemData.product.currency : t('')}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
