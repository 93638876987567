export enum ModalTypes {
	Error,
	SendAssociation,
	Void,
	Cancel,
	RejectPendingMessage
}

export interface TrackInformationData {
	emission_date: string
	emissary: string
	emissary_rfc: string
	payment_method: string
	receptor: string
	receptor_rfc: string
	uploaded_date: string
	uploaded_by?: string
	serie_folio_uuid: string
	uuid: string
	invoice_reference: string
}

export interface ItemsTableData {
	position: number
	concept: string
	code: string
	unitPrice: string
	amount: number
	net_amount: string
	tax: string
	request_concept: JSX.Element | string
	error?: boolean
}

export interface StatusMessage {
	missingLink: boolean
	error: {
		key: boolean
		value: string
	}
}

export interface CreditMemoAssociationItem {
	position: number
	reference_position: number | string
}

export enum WorkflowValueEnum {
	AdvanceDiscount = 'AdvanceDiscount',
	OtherCreditMemo = 'OtherCreditMemo',
	Empty = ''
}
export interface AutoCompleteOption {
	id: string
	items: []
}
