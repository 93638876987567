import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const WorkflowChangeModal = ({
	open,
	onClose,
	onAccept
}: {
	open: boolean
	onClose(): void
	onAccept(): void
}) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			open={open}
			titleText={t('creditMemos.changeWorkflowType')}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton onClick={onAccept} variant="contained">
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('creditMemos.confirmation.changeWorkflowType')}</p>
		</NetcurioDialog>
	)
}
