import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const PAYMENT_COMPLEMENT_CUSTOMER: DocumentNode = gql`
	query PaymentComplements(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		PaymentComplements(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			list {
				uuid
				sender {
					name
					rfc
				}
				serie
				status {
					key
					value
				}
				folio
				date
				created_at
			}
			total
		}
	}
`

export const PAYMENT_COMPLEMENT_SUPPLIER: DocumentNode = gql`
	query PaymentComplements(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		PaymentComplements(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			list {
				uuid
				receiver {
					name
					rfc
				}
				status {
					key
					value
				}
				serie
				folio
				date
				created_at
			}
			total
		}
	}
`

export const SEND_NEW_PAYMENT_COMPLEMENT_EMAIL = gql`
	mutation sendNewPaymentComplementEmail($uuid: ID!) {
		sendNewPaymentComplementEmail(uuid: $uuid)
	}
`
