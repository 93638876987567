import React from 'react'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import { useTranslation } from 'react-i18next'

interface CancelAddProductModalProps {
	open: boolean
	onAccept(): void
	onClose(): void
}

/**
 * CancelAddProductModal
 * @description Helps to display a modal when the user supplier admin is trying to cancel add new product process
 * @param open <boolean>: If `true`, the component is shown
 * @param onClose <boolean>: If `false`, the component is hide
 * @param onAccept <void>: Callback fired when the user accept the action the modal
 * @return component <ReactNode>
 **/
export const CancelAddProductModal = ({ open, onAccept, onClose }: CancelAddProductModalProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioDialog
			open={open}
			titleText={t('cancelNewProductTitle')}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('questionCancelNewProduct')}</p>
		</NetcurioDialog>
	)
}
