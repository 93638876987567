import gql from 'graphql-tag'

export const INVITATION_ENROLLMENT_DETAIL = gql`
	query EnrollmentInvitation($id: ID!, $supplier: ID!) {
		EnrollmentInvitation(id: $id, supplier: $supplier) {
			id
			payment_days
			created_at
			updated_at
			created_by {
				name
				lastname
				email
			}
			updated_by {
				name
				lastname
				email
			}
			supplier {
				rfc
				name
				industry_description
				postal_code
				country_code
				state_code
				address_line_1
			}
			customer {
				rfc
				name
			}
			status {
				key
				value
			}
			admins {
				status {
					key
					value
				}
				user {
					name
					lastname
					email
				}
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
			additional_doc
			incorporation_act
			rfc_registration
			attorney
			compliance
			id_card
			proof_address
			bank_statement
			specialty_services
			signed_bank_data_form
			supplier_data_format
			quotation_price_list
			operation_notice
			cofepris_health_records
			authorized_distribution_letter
			health_license_controlled_medicine
			notice_health_manager
			other_doc
			customer_reference
		}
	}
`

export const INVITATION_ENROLLMENT_COMMENTS = gql`
	query EnrollmentInvitation($id: ID!, $supplier: ID!) {
		EnrollmentInvitation(id: $id, supplier: $supplier) {
			id
			customer {
				rfc
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`

export const CREATE_ENROLLMENT_INVITATION_COMMENT = gql`
	mutation createEnrollmentInvitationComment($invitation: ID!, $supplier: ID!, $text: String!) {
		createEnrollmentInvitationComment(invitation: $invitation, supplier: $supplier, text: $text) {
			code
			message
		}
	}
`

export const APPROVE_ENROLLMENT = gql`
	mutation approveEnrollmentInvitation(
		$id: ID!
		$supplier: ID!
		$payment_days: Int!
		$customer_reference: String
	) {
		approveEnrollmentInvitation(
			id: $id
			supplier: $supplier
			payment_days: $payment_days
			customer_reference: $customer_reference
		) {
			code
			message
		}
	}
`

export const REJECT_ENROLLMENT = gql`
	mutation rejectEnrollmentInvitation($id: ID!, $supplier: ID!) {
		rejectEnrollmentInvitation(id: $id, supplier: $supplier) {
			code
			message
		}
	}
`

export const IS_BLACKLISTED = gql`
	query IsBlacklisted($rfc: String!) {
		IsBlacklisted(rfc: $rfc) {
			is_blacklisted
		}
	}
`
