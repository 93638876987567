import { gql } from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const REQUEST_FOR_CREDIT_MEMOS: DocumentNode = gql`
	query RequestForCreditMemos(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		RequestForCreditMemos(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			list {
				id
				customer_reference
				customer {
					name
					rfc
				}
				status {
					key
					value
				}
				created_at
				created_by {
					email
					name
					lastname
				}
				supplier {
					rfc
					name
				}
				modified_by_supplier {
					name
					lastname
					email
				}
			}
			total
		}
	}
`
