import { createContext, ReactNode, useContext, useState } from 'react'
import { NetcurioLoaderSpinner } from './NetcurioLoaderSpinner'

export interface NetcurioLoaderSpinnerProps {
	setSpinner: (newValue: boolean) => void
}
interface NetcurioLoaderSpinnerContextProviderProps {
	children: ReactNode
}

export const NetcurioLoaderSpinnerContext = createContext<NetcurioLoaderSpinnerProps>({
	setSpinner: (newValue: boolean) => {
		return newValue
	}
})

export const NetcurioLoaderSpinnerContextProvider = ({
	children
}: NetcurioLoaderSpinnerContextProviderProps) => {
	const [showSpinner, setShowSpinner] = useState<boolean>(false)
	const setSpinner = (newValue: boolean) => {
		setShowSpinner(newValue)
	}
	return (
		<NetcurioLoaderSpinnerContext.Provider value={{ setSpinner }}>
			<div className={'appDiv'}>
				{showSpinner && (
					<div className={'appDiv'}>
						<NetcurioLoaderSpinner idSpinner={'generalSpinner'} />
					</div>
				)}
				{children}
			</div>
		</NetcurioLoaderSpinnerContext.Provider>
	)
}

export const useNetcurioLoader = () => {
	const { setSpinner } = useContext(NetcurioLoaderSpinnerContext)

	const showLoadingSpinner = () => {
		setSpinner(true)
	}

	const hideLoadingSpinner = () => {
		setSpinner(false)
	}

	return {
		setSpinner,
		showLoadingSpinner,
		hideLoadingSpinner
	}
}
