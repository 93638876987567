import React from 'react'
import styles from './RelatedAdvances.module.scss'

interface Header {
	title: string
	width?: string
}

interface AdvanceListProps {
	title: string
	headers: Header[]
	children: React.ReactNode
}

export const AdvanceList = ({ title, headers, children }: AdvanceListProps) => {
	return (
		<div>
			<p className={styles.listTitle}>{title}</p>

			<div className={styles.listHeader}>
				{headers.map(({ title, width }) => (
					<div key={title} className={styles.column} style={{ width: width ?? 'auto' }}>
						{title}
					</div>
				))}
			</div>
			<div className={styles.listContainer}>{children}</div>
		</div>
	)
}
