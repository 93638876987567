import { NetcurioButton } from '../NetcurioButton/NetcurioButton'
import { useLanguageSwitcher } from './useLanguageSwitcher'
export * from './useLanguageSwitcher'

interface SwitchLanguageButtonProps {
	color?: 'secondary' | 'primary'
}

export const SwitchLanguageButton = ({ color = 'primary' }: SwitchLanguageButtonProps) => {
	const { languageButtonLabel, switchLanguage } = useLanguageSwitcher()

	return (
		<NetcurioButton
			variant="outlined"
			color={color}
			onClick={switchLanguage}
			size="small"
			style={{ height: '3rem' }}
		>
			{languageButtonLabel}
		</NetcurioButton>
	)
}
