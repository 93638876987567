export default {
	REPORT_TYPES: {
		RESPONSE_TIME: 'RESPONSE_TIME_REPORT',
		DIFFERENCE_BETWEEN_PRODUCT_QUANTITIES: 'DIFFERENCE_BETWEEN_PRODUCT_QUANTITIES_REPORT',
		HISTORICAL_INVOICES: 'HISTORICAL_INVOICES_REPORT',
		LIST_INVOICES: 'LIST_INVOICES_REPORT',
		HISTORICAL_PAYMENT_COMPLEMENT: 'HISTORICAL_PAYMENT_COMPLEMENT_REPORT',
		PRODUCT_MISSING_INVOICE: 'PRODUCT_MISSING_INVOICE_REPORT',
		HISTORICAL_PURCHASE_ORDERS: 'HISTORICAL_PURCHASE_ORDERS_REPORT',
		PRODUCTS_CATALOG: 'PRODUCTS_CATALOG_REPORT',
		CONSIGNMENT_STOCK: 'CONSIGNMENT_STOCK_REPORT',
		LIST_PURCHASE_ORDERS: 'LIST_PURCHASE_ORDERS_REPORT',
		LIST_USERS: 'LIST_USERS_REPORT',
		LIST_RELATED_COMPANIES: 'LIST_RELATED_COMPANIES_REPORT',
		LIST_ENROLLMENT_INVITATIONS: 'LIST_ENROLLMENT_INVITATIONS_REPORT',
		HISTORICAL_CREDIT_MEMOS: 'HISTORICAL_CREDIT_MEMOS_REPORT',
		LIST_QUOTATION_REQUEST: 'LIST_QUOTATION_REQUEST_REPORT',
		LIST_CONSUMPTION_REPORT: 'LIST_CONSUMPTION_REPORT',
		LIST_CREDIT_MEMOS_REQUEST: 'LIST_CREDIT_MEMOS_REQUEST',
		LIST_CREDIT_MEMO: 'LIST_CREDIT_MEMO',
		LIST_GOODS_RECEIPTS: 'LIST_GOODS_RECEIPTS',
		PRODUCT_LIST: 'PRODUCT_LIST',
		LIST_PAYMENT_COMPLEMENT: 'LIST_PAYMENT_COMPLEMENT',
		HISTORICAL_TICKETS_REPORT: 'HISTORICAL_TICKETS_REPORT',
		TICKET_LIST: 'TICKET_LIST'
	},
	REPORT_URLS: {
		REPORT_RESPONSE_TIME: '/responsetimereport',
		REPORT_DIFFERENCE_BETWEEN_PRODUCT_QUANTITIES: '/differencebetweenproductquantitiesreport',
		REPORT_PRODUCT_MISSING_INVOICE: '/productmissinginvoicereport',
		REPORT_PRODUCTS_CATALOG: '/productscatalogreport',
		REPORT_CONSIGNMENT_STOCK: '/consignmentstockreport',
		HISTORICAL_PURCHASE_ORDERS: '/purchaseordershistorical',
		LIST_PURCHASE_ORDERS: '/purchaseorderslist',
		HISTORICAL_INVOICES: '/invoicehistorical',
		HISTORICAL_PAYMENT_COMPLEMENT: '/paymentcomplementhistorical',
		HISTORICAL_CREDIT_MEMOS: '/creditmemoshistorical',
		REPORT_HISTORICAL_TICKETS: '/tickethistorical'
	}
}
