import gql from 'graphql-tag'

export const EXPENSE_SUBTYPES = gql`
	query getExpenseSubtypes($companyRfc: String!, $expenseTypeKey: String!) {
		getExpenseSubtypes(companyRfc: $companyRfc, expenseTypeKey: $expenseTypeKey) {
			key
			value
			expenseTypeKey
			companyRfc
		}
	}
`
