import { Company, getCompany, getCurrentUser, Roles, URLS } from '@netcurio/frontend-common'
import {
	FileViewerModal,
	Header,
	NetcurioAutocomplete,
	NetcurioButton,
	NetcurioIcons,
	NetcurioTextField,
	SectionData,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Translation, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ComponentQueries from '../../components/queries'
import { useEnvironment } from '../../hooks/useEnvironment'
import { ErrorWrapper, FileViewerObject, InformationFile } from '../../types'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import fileToBase64 from '../../utilities/fileToBase64'
import { useDebounce } from '../../utilities/useDebounce'
import { redirectByRoles } from '../../utilities/validateUrlRoles'
import Comment from './comment'
import { CancelNewEnrollmentModal } from './Modals/CancelNewEnrollmentModal'
import { CatchedErrorModalNewEnrollment } from './Modals/CatchedErrorModalNewEnrollment'
import { SendNewEnrollmentModal } from './Modals/SendNewEnrollmentModal'
import styles from './newEnrollment.module.scss'
import * as queries from './queries'
import UploadFile from './uploadFile'

interface CustomerForEnrollment {
	name: string
	rfc: string
}

export const NewEnrollment = () => {
	const { environment } = useEnvironment()
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }

	const { COMPANIES_FOLDER } = Constants.FOLDER
	const { t } = useTranslation()
	const companyInfo = getCompany()
	const userInfo = getCurrentUser()
	const client = connection()
	const today = new Date()
	const dd: string = String(today.getDate()).padStart(2, '0')
	const mm: string = String(today.getMonth() + 1).padStart(2, '0')
	const yyyy: string = today.getFullYear().toString()
	const creationDate: string = dd + '/' + mm + '/' + yyyy
	const company = getCompany()
	const history = useHistory()
	let isMounted = true
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [commentState, setCommentState] = useState<string>('')
	const [selectedCustomer, setSelectedCustomer] = useState<CustomerForEnrollment | undefined>(undefined)
	const [currentCustomerList, setCurrentCustomerList] = useState<CustomerForEnrollment[]>([])
	const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<ErrorWrapper>({})
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
	const [enrollmentFiles, setEnrollmentFiles] = useState<InformationFile>({
		charter: { code: 'INCORPORATION_ACT' },
		registration: { code: 'RFC_REGISTRATION' },
		attorney: { code: 'ATTORNEY' },
		compliance: { code: 'COMPLIANCE' },
		ine: { code: 'ID_CARD' },
		address: { code: 'PROOF_ADDRESS' },
		account: { code: 'BANK_STATEMENT' },
		services: { code: 'SPECIALTY_SERVICES' },
		bankForm: { code: 'SIGNED_BANK_DATA_FORM' },
		supplierDataFormat: { code: 'SUPPLIER_DATA_FORMAT' },
		priceList: { code: 'QUOTATION_PRICE_LIST' },
		operationNotice: { code: 'OPERATION_NOTICE' },
		cofeprisHealthRecords: { code: 'COFEPRIS_HEALTH_RECORDS' },
		authorizedDistributionLetter: { code: 'AUTHORIZED_DISTRIBUTION_LETTER' },
		healthLicenseControlledMedicine: { code: 'HEALTH_LICENSE_CONTROLLED_MEDICINE' },
		noticeFromHealthManager: { code: 'NOTICE_HEALTH_MANAGER' },
		other: { code: 'OTHER' }
	})
	const [newFile, setNewFile] = useState<File | undefined>()
	const [fileViewer, setFileViewer] = useState<FileViewerObject>({
		open: false,
		titleText: t('documentText'),
		fileSrc: ''
	})
	const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [searchCustomer, setSearchCustomers] = useState<string>('')
	const debouncedCustomerInput = useDebounce<string>(searchCustomer, 500)

	useEffect(() => {
		if (debouncedCustomerInput.length >= 3) {
			setLoading(true)
			client
				.query({
					query: ComponentQueries.CUSTOMERS_NOT_ENROLLED_BY_NAME_OR_CODE,
					variables: {
						search_text: debouncedCustomerInput.toLowerCase()
					}
				})
				.then((result) => {
					const results = result.data.CustomersNotEnrolledByNameOrCode
					setCurrentCustomerList(results)
				})
				.catch(handleError)
				.finally(() => {
					setLoading(false)
				})
		}
	}, [debouncedCustomerInput])

	useEffect(() => {
		redirectByRoles(
			`${URLS.ADMINISTRATION}/${Constants.ADMIN.ADMIN_TABS.ENROLLMENTS_TAB}`,
			Roles.CUSTOMER
		)
		getCompanyDocuments()
		return () => {
			isMounted = false
		}
	}, [])

	useEffect(() => {
		openCatchedErrorModal()
	}, [errorMessage])

	function getCompanyDocuments() {
		showLoadingSpinner()
		client
			.query({
				query: queries.CompanyDocuments,
				variables: {
					rfc: company.rfc
				}
			})
			.then((result) => {
				if (isMounted) {
					const newFilesState = { ...enrollmentFiles }
					for (const key in newFilesState) {
						const document = result.data.Company.supportingDocuments.filter(
							(doc: { document_type: string; link: string }) =>
								doc.document_type === newFilesState[key].code
						)[0]?.link
						if (document) newFilesState[key].uuid = document
					}
					setEnrollmentFiles(newFilesState)
				}
			})
			.catch(handleError)
			.finally(() => hideLoadingSpinner())
	}

	function returnToRequestsList() {
		setShowCancelModal(false)
		setErrorMessage({})
		redirectToListOrRequest()
	}

	function evaluateCancelRequest() {
		if (!selectedCustomer && commentState === '') redirectToListOrRequest()
		else setShowCancelModal(true)
	}

	function redirectToListOrRequest(id?: string) {
		let baseURL = `${URLS.ADMINISTRATION}/${Constants.ADMIN.ADMIN_TABS.ENROLLMENTS_TAB}`
		if (id) baseURL += `/${encodeURIComponent(companyInfo.rfc ?? '')}&${id}`
		history.push(baseURL)
	}

	function selectCustomer(customer: CustomerForEnrollment) {
		setSelectedCustomer(customer)
	}

	async function sendInvitation() {
		showLoadingSpinner()
		setShowConfirmationModal(false)
		let additional_doc
		if (newFile) {
			await fileToBase64(newFile)
				.then((res: string | ArrayBuffer) => {
					additional_doc = typeof res === 'string' ? res.split('base64,')[1] : ''
				})
				.catch(handleError)
		}

		client
			.mutate({
				mutation: queries.CREATE_ENROLLMENT_INVITATION,
				variables: {
					customer: selectedCustomer?.rfc || '',
					comment: { text: commentState },
					additional_doc,
					incorporation_act: enrollmentFiles.charter.uuid,
					rfc_registration: enrollmentFiles.registration.uuid,
					attorney: enrollmentFiles.attorney.uuid,
					compliance: enrollmentFiles.compliance.uuid,
					id_card: enrollmentFiles.ine.uuid,
					proof_address: enrollmentFiles.address.uuid,
					bank_statement: enrollmentFiles.account.uuid,
					other_doc: enrollmentFiles.other.uuid
				}
			})
			.then((result) => {
				redirectToListOrRequest(result.data.createEnrollmentInvitation)
			})
			.catch(handleError)
			.finally(() => hideLoadingSpinner())
	}

	const formatErrorMessage = (message: string) => {
		return message.split(':')[1]
	}

	function handleError(error: Error) {
		setErrorMessage({
			code: showErrorComponent(error),
			message: formatErrorMessage(error.message)
		})
	}

	function addNewComment(comment: string) {
		setCommentState(comment)
	}

	function changeConfirmationModal(value: boolean) {
		setShowConfirmationModal(value)
	}

	function setSelectedFile(file: File) {
		setNewFile(file)
	}

	function addFile(uuid: string) {
		const fileName = `${uuid.toLowerCase()}.pdf`
		const fileSrc = `${S3_BUCKET}${COMPANIES_FOLDER}/${fileName}`
		setFileViewer((state) => ({ ...state, fileSrc, open: true }))
	}
	const closePDFModal = () => setFileViewer((state) => ({ ...state, open: false }))
	const openCatchedErrorModal = () => {
		if (errorMessage.code) {
			setOpenErrorModal(true)
		}
	}
	const getSuggestion = (searchText: string) => {
		setSearchCustomers(searchText)
	}
	const validateCustomerValue = (option: CustomerForEnrollment, value: { rfc: string }) =>
		option.rfc === value.rfc
	const getCustomerLabel = (customer: { name: any; rfc: any }) => `${customer.name} - ${customer.rfc}`

	return (
		<Translation>
			{(t) => (
				<div className={styles.newEnrollmentContainer}>
					<Header>
						<div>
							<NetcurioButton
								variant="outlined"
								color="error"
								className={styles.btnHeader}
								onClick={() => {
									evaluateCancelRequest()
								}}
								endIcon={<NetcurioIcons.ArrowBack className={classNames(styles.icon)} />}
							>
								<span> {t('comebackListText')} </span>
							</NetcurioButton>
						</div>
						<div></div>
					</Header>
					<div className={styles.totalMiddleContainer}>
						<div className={styles.mainNewEnrollmentContainer}>
							<div className={styles.headerNewEnrollment}>
								<p className={styles.alignTitleEnrollment}>{t('titleNewEnrollment')}</p>
							</div>
							<div className={styles.containerInformationNewEnrollment}>
								<SectionData title={'enrollmentClientTitle'}>
									<div className={styles.rowContainer}>
										<div className={styles.autocompleteSelector}>
											<NetcurioAutocomplete
												label={t('enrollmentClientPlc')}
												fullWidth
												height="smaller"
												size="small"
												variant="outlined"
												options={currentCustomerList}
												getOptionLabel={getCustomerLabel}
												isOptionEqualToValue={validateCustomerValue}
												value={selectedCustomer}
												onSelectValue={selectCustomer}
												onInputValueChange={getSuggestion}
												loading={loading}
												minLength={3}
											/>
										</div>
									</div>
								</SectionData>

								<SectionData title={'enrollmentInformationTitle'}>
									<div className={styles.parent}>
										<div className={`${styles.block1} ${styles.alignment}`}>
											<NetcurioTextField
												size="small"
												label={t('companyNameText')}
												fullWidth
												variant="outlined"
												value={companyInfo.name}
												disabled
											/>
										</div>
										<div className={`${styles.block2} ${styles.alignment}`}>
											<NetcurioTextField
												size="small"
												label={t('textDotRFC')}
												fullWidth
												variant="outlined"
												value={companyInfo.rfc}
												disabled
											/>
										</div>
										<div className={`${styles.block3} ${styles.alignment}`}>
											<NetcurioTextField
												size="small"
												label={t('companyAddress')}
												fullWidth
												variant="outlined"
												value={companyInfo.address_line_1}
												disabled
											/>
										</div>
										<div className={`${styles.block4} ${styles.alignment}`}>
											<NetcurioTextField
												size="small"
												label={t('industryNewCompany')}
												fullWidth
												variant="outlined"
												value={companyInfo.industry_description ?? 'S/N'}
												disabled
											/>
										</div>
										<div className={`${styles.block5} ${styles.alignment}`}>
											<NetcurioTextField
												size="small"
												label={t('countryTokenLabel')}
												fullWidth
												variant="outlined"
												value={companyInfo.country_code ?? 'S/N'}
												disabled
											/>
										</div>
										<div className={`${styles.block6} ${styles.alignment}`}>
											<NetcurioTextField
												size="small"
												label={t('stateTokenLabel')}
												fullWidth
												variant="outlined"
												value={companyInfo.state_code}
												disabled
											/>
										</div>
										<div className={`${styles.block7} ${styles.alignment}`}>
											<NetcurioTextField
												size="small"
												label={t('zipcodeNewCompany')}
												fullWidth
												variant="outlined"
												value={companyInfo.postal_code}
												disabled
											/>
										</div>
									</div>
								</SectionData>

								<SectionData title={'enrollmentRequestTitle'}>
									<div className={styles.parentBellow}>
										<div className={`${styles.block4} ${styles.alignment}`}>
											<NetcurioTextField
												size="small"
												label={t('creationDate')}
												fullWidth
												variant="outlined"
												value={creationDate}
												disabled
											/>
										</div>
										<div className={`${styles.block5} ${styles.alignment}`}>
											<NetcurioTextField
												size="small"
												label={t('userNameLabel')}
												fullWidth
												variant="outlined"
												value={`${userInfo?.name} ${userInfo?.lastname}`}
												disabled
											/>
										</div>
										<div className={`${styles.block6} ${styles.alignment}`}>
											<NetcurioTextField
												size="small"
												label={t('userEmailLabel')}
												fullWidth
												variant="outlined"
												value={userInfo?.email}
												disabled
											/>
										</div>
									</div>
								</SectionData>
							</div>
							<div className={styles.uploadAndCommentsModule}>
								<UploadFile
									enrollmentFiles={enrollmentFiles}
									addFile={addFile}
									setSelectedFile={setSelectedFile}
									newFile={newFile}
								/>
								<Comment
									addNewComment={addNewComment}
									changeConfirmationModal={changeConfirmationModal}
									selectedCustomer={selectedCustomer as Company}
								/>
							</div>
						</div>
					</div>
					<FileViewerModal onClose={closePDFModal} {...fileViewer} />
					<CancelNewEnrollmentModal
						open={showCancelModal}
						cancel={returnToRequestsList}
						close={() => setShowCancelModal(false)}
					/>
					<SendNewEnrollmentModal
						open={showConfirmationModal}
						confirm={sendInvitation}
						close={() => setShowConfirmationModal(false)}
					/>
					<CatchedErrorModalNewEnrollment
						open={openErrorModal}
						errorCode={errorMessage.code ?? ''}
						errorGraphQlMessage={errorMessage.message}
						closeModalNoPriority={() => setOpenErrorModal(false)}
					/>
				</div>
			)}
		</Translation>
	)
}
