import Slide, { SlideProps } from '@mui/material/Slide'
import Snackbar from '@mui/material/Snackbar'
import React, { createContext, ReactNode, useContext, useState } from 'react'
import { NetcurioAlert, Severity } from '../NetcurioAlert/NetcurioAlert'

interface SnackbarProps {
	message: string
	title?: string
	severity?: Severity
}

interface NetcurioSnackbarProviderProps {
	children: ReactNode
}

const NetcurioSnackbarContext = createContext({
	/**
	 * showSnackbar, this method shows a Snackbar when called
	 * @param message <string>: Snackbar message
	 * @param title <string>: Snackbar title (Optional)
	 * @param severity <Severity>: Snackbar's severity (Optional)
	 * @returns void
	 */
	showSnackbar: () => {
		return
	}
})

function TransitionDown(props: SlideProps) {
	return <Slide {...props} direction="down" />
}

export const NetcurioSnackbarProvider = ({ children }: NetcurioSnackbarProviderProps) => {
	const [open, setOpen] = useState(false)
	const [messsage, setMessage] = useState<string>()
	const [title, setTitle] = useState<string>('')
	const [severity, setSeverity] = useState<Severity>(Severity.Success)

	const showSnackbar = ({ message, title, severity }: SnackbarProps) => {
		setMessage(message)
		if (title) setTitle(title)
		else setTitle('')
		if (severity) setSeverity(severity)
		else setSeverity(Severity.Success)
		setOpen(true)
	}

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return
		}

		setOpen(false)
	}

	return (
		<NetcurioSnackbarContext.Provider
			value={{
				showSnackbar
			}}
		>
			<>
				<Snackbar
					TransitionComponent={TransitionDown}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={open}
					onClose={handleClose}
					autoHideDuration={5000}
				>
					<NetcurioAlert elevation={6} onClose={handleClose} severity={severity}>
						{title && <div style={{ fontWeight: 600 }}>{title}</div>}
						<div>{messsage}</div>
					</NetcurioAlert>
				</Snackbar>
				{children}
			</>
		</NetcurioSnackbarContext.Provider>
	)
}

export const useNetcurioSnackbar = () => {
	const { showSnackbar } = useContext(NetcurioSnackbarContext)

	return { showSnackbar }
}
