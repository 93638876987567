import gql from 'graphql-tag'

export const EXPENSE_TYPES = gql`
	query getExpenseTypes($companyRfc: String!) {
		getExpenseTypes(companyRfc: $companyRfc) {
			key
			value
		}
	}
`
