import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	NetcurioIconButton,
	NetcurioTableCell,
	NetcurioTableRow,
	NetcurioTooltip
} from '@netcurio/frontend-components'
import { BatchesModal } from '../../Modals/BatchesModal/BatchesModal'
import { GoodReceiptItem } from '../../../../../types'
import box from '@netcurio/frontend-assets/src/icons/box.svg'
import styles from './goodsReceiptDetail.module.scss'
import classNames from 'classnames'

interface GoodReceiptDetailItemProps {
	dataGoodsReceiptsDetail: GoodReceiptItem
}

export const GoodReceiptDetailItem: FC<GoodReceiptDetailItemProps> = ({ dataGoodsReceiptsDetail }) => {
	const { t } = useTranslation()
	const [showBatchesDetail, setShowBatchesDetail] = useState<boolean>(false)

	return (
		<NetcurioTableRow isDetailRow>
			<NetcurioTableCell align="center">
				<p className={styles.textRowDetailPOGR}>{dataGoodsReceiptsDetail?.position}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				{dataGoodsReceiptsDetail?.product_description.length > 50 ? (
					<NetcurioTooltip title={dataGoodsReceiptsDetail.product_description} placement={'top'}>
						<p className={classNames(styles.textRowDetailPOGR, styles.productDescriptionColumn)}>
							{dataGoodsReceiptsDetail?.product_description}
						</p>
					</NetcurioTooltip>
				) : (
					<p className={styles.textRowDetailPOGR}>{dataGoodsReceiptsDetail?.product_description}</p>
				)}
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p className={styles.textRowDetailPOGR}>
					{dataGoodsReceiptsDetail?.purchase_order_item?.storage_location ?? t('NAtext')}
				</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p className={styles.textRowDetailPOGR}>{`${dataGoodsReceiptsDetail?.quantity ?? 0} ${
					dataGoodsReceiptsDetail?.unit ?? ''
				}`}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<NetcurioIconButton
					className={styles.buttonBatch}
					disabled={!dataGoodsReceiptsDetail?.batches.length}
					onClick={() => setShowBatchesDetail(true)}
				>
					<img src={box} className={styles.iconBatchGR} alt={'batch'} />
				</NetcurioIconButton>
			</NetcurioTableCell>
			<BatchesModal
				open={showBatchesDetail}
				closeBatchModal={() => setShowBatchesDetail(false)}
				batchesInfo={dataGoodsReceiptsDetail.batches}
				unit={dataGoodsReceiptsDetail.unit}
			/>
		</NetcurioTableRow>
	)
}
