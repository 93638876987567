import { Roles, URLS } from '@netcurio/frontend-common'

export interface RouteApp {
	link: string
	icon: string
	name: string
	section?: string
	dataTip: string
	roles: Array<Roles>
	hide?: boolean
	isAdmin?: boolean
}
export const routes: Array<RouteApp> = [
	{
		link: URLS.RFQ,
		section: 'RFQ',
		icon: 'RFQIcon',
		dataTip: 'rfqMenuTooltip',
		name: 'rfqMenuText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.PO_LIST,
		section: 'Purchase',
		icon: 'POIcon',
		dataTip: 'purchaseOrdersTooltip',
		name: 'purchaseOrdersMenu',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.GR_LIST,
		section: 'GoodsReceipts',
		icon: 'GRIcon',
		dataTip: 'goodsReceiptTooltip',
		name: 'goodsReceiptTooltip',
		roles: [Roles.CUSTOMER]
	},
	{
		link: URLS.CONSUMPTION_LIST,
		section: 'Consumption',
		icon: 'consumptionIcon',
		dataTip: 'consignmentTooltip',
		name: 'consignmentText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.INVOICE_LIST,
		section: 'Invoice',
		icon: 'invoiceIcon',
		dataTip: 'invoicesTooltip',
		name: 'invoicesText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.REQUEST_FOR_CREDIT_MEMO_LIST,
		section: 'RequestForCreditMemo',
		icon: 'RFCMemo',
		dataTip: 'requestForCreditMemoTooltip',
		name: 'requestForCreditMemoText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.CREDIT_MEMO_LIST,
		section: 'CreditMemo',
		icon: 'CM',
		dataTip: 'creditMemoTooltip',
		name: 'creditMemoText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.COMPLEMENT_LIST,
		section: 'Complement',
		icon: 'complement',
		dataTip: 'complementTooltip',
		name: 'paymentComplementsText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.TICKET_LIST,
		section: 'Ticket',
		icon: 'ticket',
		dataTip: 'ticketText',
		name: 'ticketText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER],
		hide: true
	},
	{
		link: URLS.REPORT_LIST,
		section: 'Reports',
		icon: 'report',
		dataTip: 'reportsText',
		name: 'reportsText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	}
]

export interface RouteMenu {
	link?: string
	name: string
	isAdmin: boolean
	command?: boolean
}
export const routesMenu: Array<RouteMenu> = [
	{
		link: URLS.USER_SETTINGS,
		name: 'profilePreferences',
		isAdmin: false
	},
	{
		link: URLS.ADMINISTRATION,
		name: 'administrationText',
		isAdmin: true
	},
	{
		name: 'LogoutText',
		isAdmin: false,
		command: true
	}
]
