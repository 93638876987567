import React, { FC } from 'react'
import { QuotationData } from '../types'
import { parseTimestampToTimeZone, dateFormatter } from '@netcurio/frontend-common'
import styles from './Quotation.module.scss'
import Formatter from '../../../utilities/formatter'
import classNames from 'classnames'

interface QuotationRowProps {
	dataQuotation: QuotationData
	showQuotationDetailComponent(id: string, supplierRFC: string): void
}

export const QuotationRow: FC<QuotationRowProps> = ({
	dataQuotation: { id, total, expiration_date, hasExpired, supplier },
	showQuotationDetailComponent
}) => {
	const showModalQuotationDetail = () => {
		showQuotationDetailComponent(id, supplier.rfc)
	}

	return (
		<div
			className={classNames(styles.containerColumnQuotation, {
				[styles.containerColumnQuotationHasExpired]: hasExpired
			})}
			onClick={showModalQuotationDetail}
		>
			<p className={styles.quotationColumn}>{Formatter.id(id)}</p>
			<p className={styles.quotationColumn}>{Formatter.currency.format(total)}</p>
			<p className={styles.quotationColumn}>
				{dateFormatter.format(parseTimestampToTimeZone(expiration_date))}
			</p>
		</div>
	)
}
