import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const PRODUCT: DocumentNode = gql`
	query Product($id: ID!, $supplier: ID!) {
		Product(id: $id, supplier: $supplier) {
			id
			supplier {
				rfc
			}
			description
			currency
			unit_price
			unit
			is_active
			tax
			ean
			created_at
			updated_at
			created_by {
				email
				name
				lastname
			}
			modified_by {
				email
				name
				lastname
			}
		}
	}
`

export const MODIFY_PRODUCT = gql`
	mutation modifyProduct(
		$id: ID!
		$unit_price: Float
		$is_active: Boolean
		$tax: Float
		$ean: String
		$unit: String
		$currency: String
		$description: String
	) {
		modifyProduct(
			id: $id
			unit_price: $unit_price
			is_active: $is_active
			tax: $tax
			ean: $ean
			unit: $unit
			currency: $currency
			description: $description
		) {
			code
			message
		}
	}
`
