import { useSuspenseQuery } from '@tanstack/react-query'
import { fetchEnvironment } from '../api/fetch-environment'

export const useEnvironment = () => {
	const { data: environment, isSuccess } = useSuspenseQuery({
		queryKey: ['environment'],
		queryFn: fetchEnvironment,
		staleTime: Infinity
	})

	return { environment, isSuccess }
}
