import { currencyFormatter, URLS } from '@netcurio/frontend-common'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Invoice, RelatedAdvance } from '../../../../types'
import { AdvanceList } from './AdvanceList'
import styles from './RelatedAdvances.module.scss'

interface RelatedAdvancesProps {
	invoice: Invoice
}

const filterLoadedInNetcurio = (relatedAdvances: RelatedAdvance[]) =>
	relatedAdvances.filter((relatedAdvance) => Boolean(relatedAdvance?.destinationData)) ?? []

const filterNotLoadedInNetcurio = (relatedAdvances: RelatedAdvance[]) =>
	relatedAdvances.filter((relatedAdvance) => !relatedAdvance?.destinationData) ?? []

const Title = ({ title }: { title: string }) => (
	<p className={styles.headerText}>
		<i className={classNames(styles.icon, styles['invoiceIcon'])}></i>
		{title}
	</p>
)

export const RelatedAdvances = ({ invoice }: RelatedAdvancesProps) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const { t } = useTranslation()

	const loadedInNetcurio = filterLoadedInNetcurio(invoice?.related_advances ?? [])
	const notLoadedInNetcurio = filterNotLoadedInNetcurio(invoice?.related_advances ?? [])

	if (invoice?.related_advances?.length === 0) return null

	if (!isExpanded) {
		return (
			<div className={styles.container}>
				<div className={styles.header} onClick={() => setIsExpanded(true)}>
					<Title title={t('invoices.relatedInvoices')} />
				</div>
			</div>
		)
	}

	return (
		<div className={styles.expandedContainer}>
			<div className={styles.expandedHeader} onClick={() => setIsExpanded(false)}>
				<Title title={t('invoices.relatedInvoices')} />
			</div>
			<div className={styles.expandedBody}>
				<AdvanceList
					title={t('invoices.loadedInNetcurio')}
					headers={[
						{
							title: t('serieFolio'),
							width: '80%'
						},
						{
							title: t('invoices.amount'),
							width: '20%'
						}
					]}
				>
					{loadedInNetcurio.map((relatedAdvance) => (
						<a
							key={relatedAdvance.destination}
							className={classNames(styles.listItem, styles.loadedItem)}
							href={`${URLS.INVOICE_DETAIL}?invoice=${relatedAdvance.destination}`}
						>
							<p>
								{relatedAdvance.destinationData?.serie}-
								{relatedAdvance.destinationData?.folio}
							</p>
							<p className={styles.subtotalText}>
								{currencyFormatter.format(relatedAdvance.destinationData?.total ?? 0)}
							</p>
						</a>
					))}
				</AdvanceList>
				<div className={styles.divider} />
				<AdvanceList title={t('invoices.notLoadedInNetcurio')} headers={[{ title: 'UUID' }]}>
					{notLoadedInNetcurio.map((relatedAdvance) => (
						<div key={relatedAdvance.destination} className={styles.listItem}>
							<p className={styles.destinationText}>{relatedAdvance?.destination}</p>
						</div>
					))}
				</AdvanceList>
			</div>
		</div>
	)
}
