import { getCurrentUser, Status, URLS, User } from '@netcurio/frontend-common'
import {
	NetcurioAlert,
	NetcurioButton,
	NetcurioCheckbox,
	NetcurioDialog,
	NetcurioFormControl,
	NetcurioFormControlLabel,
	NetcurioFormGroup,
	NetcurioInputLabel,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioTooltip,
	Severity
} from '@netcurio/frontend-components'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useClient } from '../../hooks/useClient'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import UserInformation from '../../utilities/userInformation'
import { UPDATE_DEFAULT_COMPANY } from './queries'
import styles from './SelectCompanyAtLogin.module.scss'

interface OptionsMenuItems {
	label: string
	value: string
	blocked: boolean
}

const user: User | null = getCurrentUser()

const getDefaultCompany = () => {
	const defaultCompany = user?.companies?.find((company) => {
		return company.rfc === user?.default_company && company?.status?.key !== Constants.USER_STATUS.BLOCKED
	})

	if (!defaultCompany) {
		return user?.companies?.find((company) => {
			return company?.status?.key !== Constants.USER_STATUS.BLOCKED
		})
	}

	return defaultCompany
}

export const SelectCompanyAtLogin: FC = () => {
	const { t } = useTranslation()
	const client = useClient()
	const history = useHistory<string>()
	const [isDefaultCompany, setIsDefaultCompany] = useState<boolean>(false)
	const [companySelected, setCompanySelected] = useState<OptionsMenuItems | undefined>(() => {
		const defaultCompany = getDefaultCompany()
		if (!defaultCompany) return undefined
		return {
			label: defaultCompany?.name ?? '',
			value: defaultCompany?.rfc ?? '',
			blocked: false
		}
	})
	const [optionsMenuItems, setOptionsMenuItems] = useState<Array<OptionsMenuItems>>([])
	const [errorUpdate, setErrorUpdate] = useState<string>()

	useEffect(() => {
		const newOptions = user?.companies?.map((company) => {
			const newOption: OptionsMenuItems = {
				label: company.name ?? '',
				value: company.rfc ?? '',
				blocked: (company.status as Status).key === 'BLOCKED'
			}
			return newOption
		})
		setOptionsMenuItems(newOptions ?? [])
	}, [])

	const changeCompany = (event: any) => {
		const company = optionsMenuItems.find((option) => option.value === event.target.value)
		if (!company?.blocked) {
			setCompanySelected(company)
		}
	}

	const acceptActionModal = async () => {
		let objectCompany
		if (user) {
			objectCompany = UserInformation.getCompanySelected(user.companies, companySelected?.value ?? '')
			UserInformation.setCurrentUser(user, objectCompany)
		}
		if (isDefaultCompany) {
			await client
				.mutate({
					mutation: UPDATE_DEFAULT_COMPANY,
					variables: { default_company: companySelected?.value }
				})
				.catch((error) => {
					const idCode = showErrorComponent(error)
					if (!expiredToken(idCode)) {
						setErrorUpdate(t('errorCodeText', { idCode }))
					}
				})
		}
		const internalUrl = sessionStorage.getItem('internalUrl')
		if (internalUrl !== null) {
			const sessionPath = new URL(internalUrl)
			history.replace(sessionPath.pathname)
			sessionStorage.clear()
		} else {
			const homepage = objectCompany?.homepage
			history.replace(homepage ? URLS[Constants.RELATION_KEY_URL[homepage]] : URLS.PO_LIST)
		}
	}

	return (
		<div>
			<NetcurioDialog
				open={true}
				maxWidth="60rem"
				titleText={t('selectCompanyAtLoginTitle')}
				actionButtons={
					<NetcurioButton
						variant="contained"
						disabled={!companySelected?.value}
						onClick={() => {
							acceptActionModal()
						}}
					>
						{t('continueText')}
					</NetcurioButton>
				}
				contentFontSize="1.4rem"
			>
				{errorUpdate && (
					<NetcurioAlert severity={Severity.Error}>
						<span className={styles.messageError}>{errorUpdate}</span>
					</NetcurioAlert>
				)}

				<p className={styles.description}>{t('selectCompanyAtLoginText')}</p>
				<NetcurioFormControl fullWidth>
					<NetcurioInputLabel id="selectCompanyAtLoginTitle">
						{t('selectCompanyAtLoginTitle')}
					</NetcurioInputLabel>
					<NetcurioSelect
						labelId="selectCompanyAtLoginTitle"
						label={t('selectCompanyAtLoginTitle')}
						variant="standard"
						minWidth="100%"
						value={companySelected?.value}
						onChange={changeCompany}
						displayEmpty
					>
						<NetcurioMenuItem disabled value={t('selectCompanyAtLoginTitle')}>
							<span>{t('selectCompanyAtLoginTitle')}</span>
						</NetcurioMenuItem>
						{optionsMenuItems.map((item) => (
							<NetcurioMenuItem key={item.value} value={item?.value} disabled={item.blocked}>
								<NetcurioTooltip
									title={item?.blocked ? <label>{t('fullCompanyBlock')}</label> : ''}
									placement="top"
									enterDelay={0}
								>
									<span className={styles.itemCompany}>{item?.label}</span>
								</NetcurioTooltip>
							</NetcurioMenuItem>
						))}
					</NetcurioSelect>
				</NetcurioFormControl>

				<div className={styles.checkBoxContainer}>
					<NetcurioFormGroup>
						<NetcurioFormControlLabel
							control={
								<NetcurioCheckbox onChange={() => setIsDefaultCompany(!isDefaultCompany)} />
							}
							label={t('checkboxSelectCompanyAtLoginText')}
						/>
					</NetcurioFormGroup>
				</div>
			</NetcurioDialog>
		</div>
	)
}
