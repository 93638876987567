import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const REMOVE_ASSOCIATION: DocumentNode = gql`
	mutation removeAssociation($uuid: ID!) {
		removeAssociation(uuid: $uuid) {
			code
			message
		}
	}
`
