import { URLS } from '@netcurio/frontend-common'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { Trans, Translation } from 'react-i18next'
import { beforeUnloadListener } from '../../utilities/beforeUnloadListener'
import Constants from '../../utilities/constants'

type ExpiredSessionModalProps = {
	fillModal: Array<{
		getActionModal: () => string
		getActionAccept: () => string
		getTextCancel: () => string
		getTextAction: () => string
		getQuestion: () => string
		getTitle: () => string
	}>
	acceptActionModal: (action: string) => void
	hideModal: (action: string) => void
	timeOutToken: number
}

const defaultProps: ExpiredSessionModalProps = {
	fillModal: [
		{
			getActionModal: () => {
				console.warn('getActionModal is missing on expiredSessionModal')
				return ''
			},
			getActionAccept: () => {
				console.warn('getActionAccept is missing on expiredSessionModal')
				return ''
			},
			getTextCancel: () => {
				console.warn('getTextCancel is missing on expiredSessionModal')
				return ''
			},
			getTextAction: () => {
				console.warn('getTextAction is missing on expiredSessionModal')
				return ''
			},
			getQuestion: () => {
				console.warn('getQuestion is missing on expiredSessionModal')
				return ''
			},
			getTitle: () => {
				console.warn('getTitle is missing on expiredSessionModal')
				return ''
			}
		}
	],
	acceptActionModal: () => console.warn('acceptActionModal is missing on expiredSessionModal'),
	hideModal: () => console.warn('hideModal is missing on expiredSessionModal'),
	timeOutToken: 0
}

type ExpiredSessionModalState = {
	colorCancel: string
	colorAccept: string
	modalType: string | void
	showTimerText: boolean
	currentCount: number
}

export const ExpiredSessionModal: React.FC<ExpiredSessionModalProps> = ({
	fillModal,
	acceptActionModal,
	hideModal,
	timeOutToken
} = defaultProps) => {
	const [state, setState] = useState<ExpiredSessionModalState>({
		colorCancel: 'cancel-button',
		colorAccept: 'accept-button',
		modalType: fillModal[0].getActionModal(),
		showTimerText: false,
		currentCount: 60
	})

	const { currentCount, colorCancel, colorAccept } = state

	useEffect(() => {
		const intervalId = setInterval(timer, 1000)
		setState((prevState) => ({ ...prevState, intervalId: intervalId, showTimerText: true }))

		return () => {
			clearInterval(intervalId)
		}
	}, [currentCount, timeOutToken])

	const timer = () => {
		const now = new Date()
		const nextCount = Math.max(0, currentCount - 1)
		if (timeOutToken < now.getTime()) {
			beforeUnloadListener('remove')
			Auth.signOut()
				.then(() => {
					sessionStorage.setItem('correctMessage', Constants.ERROR.INVALID_TOKEN)
					localStorage.clear()
					window.location.href = URLS.LOGIN
				})
				.catch((err) => console.error(err))
		}
		setState((prevState) => ({ ...prevState, currentCount: nextCount }))
	}

	const eventMouseOption = (optionButton: 'cancel' | 'confirm', event: 'over' | 'leave') => {
		if (event === 'over') {
			setState((prevState) => ({
				...prevState,
				[optionButton === 'cancel' ? 'colorCancel' : 'colorAccept']:
					optionButton === 'cancel' ? 'colorCancelOver' : 'colorAcceptOver'
			}))
		} else if (event === 'leave') {
			setState((prevState) => ({
				...prevState,
				[optionButton === 'cancel' ? 'colorCancel' : 'colorAccept']:
					optionButton === 'cancel' ? 'cancel-button' : 'accept-button'
			}))
		}
	}

	const closeModal = () => {
		hideModal(fillModal?.[0].getActionAccept() || '')
	}

	const acceptAction = () => {
		acceptActionModal(fillModal[0].getActionAccept() || '')
	}

	const showButtons = () => {
		const cancelText = fillModal[0].getTextCancel() || ''
		const acceptText = fillModal[0].getTextAction() || ''
		return (
			<div className="box-buttons">
				<div className="box-confirm">
					<button
						className={`button-modal ${colorCancel}`}
						onClick={closeModal}
						onMouseOver={() => eventMouseOption('cancel', 'over')}
						onMouseLeave={() => eventMouseOption('cancel', 'leave')}
					>
						<Trans>
							<p className="text-button">{cancelText}</p>
						</Trans>
					</button>
					<button
						className={`button-modal ${colorAccept}`}
						onClick={acceptAction}
						onMouseOver={() => eventMouseOption('confirm', 'over')}
						onMouseLeave={() => eventMouseOption('confirm', 'leave')}
					>
						<Trans>
							<p className="text-button">{acceptText}</p>
						</Trans>
					</button>
				</div>
			</div>
		)
	}

	const contentTitle = () => {
		const title = fillModal[0].getTitle() || ''
		return (
			<Trans>
				<p className="content-title-modal">{title}</p>
			</Trans>
		)
	}

	const questionText = () => {
		const question = fillModal[0].getQuestion() || ''
		return (
			<Translation>
				{(t) => (
					<p className="box-question-modal box-expired-session-modal">
						{t(question, {
							idCode: currentCount
						})}
					</p>
				)}
			</Translation>
		)
	}

	return (
		<div id="modal" className={`modal-position displayFlex`}>
			<div className="body-modal gray-color-text-general-info">
				<div className="box-title-modal box-title-error-modal" />

				<div className="box-question-response-modal size-container-expired-session">
					<div>
						{contentTitle()}
						{questionText()}
					</div>
					{showButtons()}
				</div>
				<div />
			</div>
		</div>
	)
}
