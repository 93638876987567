import consumptionIcon from '@netcurio/frontend-assets/src/icons/consume-purple-consumption.svg'
import {
	BarLoader,
	NetcurioButton,
	NetcurioIcons,
	NetcurioTextField,
	ResultNotFound,
	Severity
} from '@netcurio/frontend-components'
import { ApolloQueryResult } from 'apollo-boost'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory } from 'react-router-dom'
import { ConsignmentStock, ErrorWrapper, ListResult, NewConsumptionItem, Product } from '../../../types'
import { connection } from '../../../utilities/connection'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import listHelper from '../../../utilities/listHelper'
import { ErrorModal } from '../Modals/ErrorModal'
import styles from '../newConsumption.module.scss'
import { CONSIGNMENT_STOCKS_BY_DESCRIPTION_OR_CODE } from '../queries'
import TableRow from './tableRow'

interface Props {
	hideStock: (newConsumptionItems: Array<NewConsumptionItem>) => void
	branchOffice: string
	branchOfficeChange: boolean
	showStock: boolean
	consumptionItems: Array<NewConsumptionItem>
}

interface ItemToPush {
	position: number
	quantity: number
	net_value: number
	product: Product
	description: string
	unit: string
	availability: number
	storage_location_id?: string
	storage_location?: string
}

export default function Stock({
	hideStock,
	branchOffice,
	branchOfficeChange,
	showStock,
	consumptionItems
}: Props) {
	const [showBarLoader, setShowBarLoader] = useState<boolean>(false)
	const client = connection()
	const history: RouterChildContext['router']['history'] = useHistory()
	const { t } = useTranslation()
	const [showProductAddedMessage, setShowProductAddedMessage] = useState<boolean>(false)
	const [stockItems, setStockItems] = useState<Record<number, ConsignmentStock>>({})
	const [searchProduct, setSearchProduct] = useState<undefined | string>()
	const [skip, setSkip] = useState<number>(0)
	const [clearConsumptionInput, setClearConsumptionInput] = useState<boolean>(true)
	const [consumptionItemsState, setConsumptionItemsState] = useState<Array<NewConsumptionItem>>([])
	const [inputRowChange, setInputRowChange] = useState<boolean>(false)
	const [resultNotFound, setResultNotFound] = useState<boolean>(false)
	const [inputSearchTimer, setInputSearchTimer] = useState<NodeJS.Timeout>()
	const [productAddedMessageTimer, setProductAddedMessageTimer] = useState<NodeJS.Timeout>()
	const [totalRows, setTotalRows] = useState<undefined | number>()
	const [limitRows, setLimitRows] = useState<undefined | number>()
	const [paginationHandler, setPaginationHandler] = useState<boolean>(false)
	const [errorResponse, setErrorResponse] = useState<ErrorWrapper | undefined>(undefined)

	useEffect(() => {
		if (totalRows && skip)
			window.document
				.getElementsByClassName(styles.tableContainerStockNewConsumption)[0]
				.addEventListener('scroll', () => setPaginationHandler(true))

		return () => {
			const element: Element = window.document.getElementsByClassName(
				styles.tableContainerStockNewConsumption
			)[0]
			if (element) element.removeEventListener('scroll', () => setPaginationHandler(true))
		}
	}, [totalRows, skip])

	useEffect(() => {
		if (paginationHandler) {
			listHelper.handlePagination(
				styles.tableContainerStockNewConsumption,
				totalRows,
				skip,
				undefined,
				getStock,
				setShowBarLoader
			)
			setPaginationHandler(false)
		}
	}, [paginationHandler])

	useEffect(() => {
		if (showStock && clearConsumptionInput) {
			getLimitRows()
			getStock()
			setClearConsumptionInput(false)
			setConsumptionItemsState(consumptionItems)
		}
	}, [showStock, clearConsumptionInput])

	useEffect(() => {
		if (branchOfficeChange) setConsumptionItemsState([])
	}, [branchOfficeChange])

	useEffect(() => {
		setResultNotFound(totalRows < 1)
	}, [totalRows])

	useEffect(() => {
		if (searchProduct !== undefined) {
			setShowBarLoader(true)
			clearTimeout(inputSearchTimer)
			setInputSearchTimer(
				setTimeout(() => {
					getStock()
				}, 500)
			)
		}
	}, [searchProduct])

	useEffect(() => {
		return () => {
			clearTimeout(inputSearchTimer)
			clearTimeout(productAddedMessageTimer)
		}
	}, [inputSearchTimer, productAddedMessageTimer])

	function getLimitRows() {
		const finalHeight = window.innerHeight - 186
		setLimitRows(Math.floor(finalHeight / 106) + 1)
	}

	function getStock() {
		if (totalRows === undefined || totalRows > skip) {
			setShowBarLoader(true)
			client
				.query({
					query: CONSIGNMENT_STOCKS_BY_DESCRIPTION_OR_CODE,
					variables: {
						branch_office: branchOffice,
						searchText: searchProduct || '',
						limit: limitRows,
						skip: skip
					},
					fetchPolicy: 'no-cache'
				})
				.then(
					(
						result: ApolloQueryResult<{
							ConsignmentStocksByDescriptionOrCode: ListResult<ConsignmentStock>
						}>
					) => {
						const newStockItems: Record<number, ConsignmentStock> = { ...stockItems }
						let interCount: number = skip
						const stockList: Array<ConsignmentStock> =
							result.data.ConsignmentStocksByDescriptionOrCode.list
						for (const keyName in stockList) {
							interCount++
							newStockItems[interCount] = stockList[keyName]
						}
						setStockItems(newStockItems)
						setTotalRows(result.data.ConsignmentStocksByDescriptionOrCode.total)
						setSkip(Object.keys(newStockItems).length)
						setShowBarLoader(false)
					}
				)
				.catch((error) => {
					setShowBarLoader(false)
					console.error(error)
					const newErrorCode = showErrorComponent(error)
					if (!expiredToken(newErrorCode)) {
						setErrorResponse({
							code: newErrorCode,
							message: error.message
						})
					}
				})
		}
	}

	function consumptionInputChange() {
		setShowProductAddedMessage(false)
		setInputRowChange(true)
	}

	function addItemToConsumption(consumptionQuantity: number, position: number) {
		const newConsumptionItems: Array<NewConsumptionItem> = consumptionItemsState
		let newShowProductAddedMessage = true
		let quantity = 0
		let existingConsumption = false
		if (newConsumptionItems.length > 0) {
			for (let i = 0; i < newConsumptionItems.length; i++) {
				if (
					newConsumptionItems[i].product.id === stockItems[position].product.id &&
					newConsumptionItems[i].product.supplier.rfc ===
						stockItems[position].product.supplier.rfc &&
					(!stockItems[position].storage_location ||
						(stockItems[position].storage_location &&
							stockItems[position].storage_location.id ===
								newConsumptionItems[i].storage_location_id))
				) {
					const newConsumptionQuantity: number = parseFloat(
						(newConsumptionItems[i].quantity + consumptionQuantity).toFixed(2)
					)
					newConsumptionItems[i].availability = stockItems[position].quantity
					if (newConsumptionQuantity <= newConsumptionItems[i].availability) {
						newConsumptionItems[i].quantity = newConsumptionQuantity
						newConsumptionItems[i].net_value =
							newConsumptionQuantity * stockItems[position].product.unit_price
					} else {
						newShowProductAddedMessage = false
					}
					quantity = newConsumptionItems[i].quantity
					existingConsumption = true
					break
				}
			}
		}
		if (!existingConsumption || !newConsumptionItems.length) {
			const itemToPush: ItemToPush = {
				position: newConsumptionItems.length + 1,
				quantity: consumptionQuantity,
				net_value: consumptionQuantity * stockItems[position].product.unit_price,
				product: stockItems[position].product,
				description: stockItems[position].description,
				unit: stockItems[position].unit,
				availability: stockItems[position].quantity
			}
			if (stockItems[position].storage_location) {
				itemToPush.storage_location_id = stockItems[position].storage_location.id
				itemToPush.storage_location = stockItems[position].storage_location.description
			}
			newConsumptionItems.push(itemToPush)
		}
		if (newShowProductAddedMessage) {
			setShowProductAddedMessage(true)
			clearTimeout(productAddedMessageTimer)
			setProductAddedMessageTimer(
				setTimeout(() => {
					clearTimeout(productAddedMessageTimer)
					setShowProductAddedMessage(false)
				}, 3000)
			)
		}
		setInputRowChange(false)
		setConsumptionItemsState(newConsumptionItems)
		return { showErrorMessage: !newShowProductAddedMessage, quantity }
	}

	function inputSearchProduct(evt: React.ChangeEvent<HTMLInputElement>) {
		setShowProductAddedMessage(false)
		setSearchProduct(evt.target.value)
		setSkip(0)
		setStockItems([])
		setTotalRows(undefined)
		setInputRowChange(false)
	}

	function hideStockReset() {
		setSearchProduct(undefined)
		setClearConsumptionInput(true)
		setSkip(0)
		setStockItems([])
		setTotalRows(undefined)
		setInputRowChange(false)
		hideStock(consumptionItemsState)
	}

	return (
		<div className={styles.stockNewConsumption}>
			<div className={styles.headerContainerNewConsumption}>
				<p className={styles.titleHeaderNewConsumption}>{t('availableStock')}</p>
			</div>
			<div className={styles.inputSearchAndConsumptionButtonContainer}>
				<div className={styles.inputFieldSearchStockNewConsumption}>
					<div className={classNames(styles.titleDataNewConsumption)}>{t('filterByProduct')}</div>
					<NetcurioTextField
						fullWidth
						value={searchProduct || ''}
						placeholder={t('selectProduct')}
						onChange={inputSearchProduct}
						size="small"
						adornment={<NetcurioIcons.Search />}
					/>
				</div>
				{showProductAddedMessage && (
					<div className={styles.productAddedMessageStockNewConsumption}>
						<p>{t('productAdded')}</p>
					</div>
				)}
				<NetcurioButton
					startIcon={
						<img
							src={consumptionIcon}
							alt={'Consumptions'}
							className={styles.seeConsumptionsIconNewConsumptionStock}
						/>
					}
					variant="outlined"
					onClick={hideStockReset}
				>
					{t('comeBackText')}
				</NetcurioButton>
			</div>
			<div className={styles.headersTableStockNewConsumption}>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.supplierColumnStockNewConsumption
					)}
				>
					{t('supplierText')}
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.productHeaderTableStockNewConsumption
					)}
				>
					{t('productText')}
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.storageHeaderTableStockNewConsumption
					)}
				>
					{t('storageLocation')}
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.availabilityHeaderTableStockNewConsumption
					)}
				>
					{t('availability')}
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.priceHeaderTableStockNewConsumption
					)}
				>
					{t('unitPrice')}
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.consumptionHeaderTableStockNewConsumption
					)}
				>
					{t('consumptionText')}
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.netValueHeaderTableStockNewConsumption
					)}
				>
					{t('netValue')}
				</div>
				<div
					className={classNames(
						styles.textHeaderTableStockNewConsumption,
						styles.addHeaderTableStockNewConsumption
					)}
				>
					{t('addItem')}
				</div>
			</div>
			<div className={styles.tableContainerStockNewConsumption}>
				<ResultNotFound showNotFound={resultNotFound} />
				{Object.keys(stockItems).map((key) => (
					<TableRow
						key={key}
						position={key}
						stockItem={stockItems[parseInt(key)]}
						addItemToConsumption={addItemToConsumption}
						consumptionInputChange={consumptionInputChange}
						clearConsumptionInput={clearConsumptionInput}
						inputRowChange={inputRowChange}
					/>
				))}
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
			<ErrorModal
				open={!!errorResponse?.code}
				onAccept={() => history.goBack()}
				title={t('showError')}
				bodyText={t('errorCodeText', { idCode: errorResponse?.code })}
				textButton={t('acceptTextButton')}
				headerTitleSeverity={Severity.Error}
			/>
		</div>
	)
}
