import { getCurrentUser, PURCHASE, URLS } from '@netcurio/frontend-common'
import {
	Header,
	NetcurioAutocomplete,
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioTextField,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { ApolloQueryResult, FetchResult, NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory } from 'react-router-dom'
import { ListResult, PurchaseOrder, PurchaseOrderItem, RegisterBatch } from '../../types'
import { beforeUnloadListener } from '../../utilities/beforeUnloadListener'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import Formatter from '../../utilities/formatter'
import { CancelNewGRModal } from './Modals/CancelNewGRModal/CancelNewGRModal'
import { ErrorGRModal } from './Modals/ErrorGRModal/ErrorGRModal'
import { RemoveAssociationPOModal } from './Modals/RemoveAssociationPOModal/RemoveAssociationPOModal'
import { SaveNewGRModal } from './Modals/SaveNewGRModal/SaveNewGRModal'
import styles from './newGoodsReceipt.module.scss'
import { CREATE_GOOD_RECEIPT, ITEM_PENDING_GOOD_RECEIPT, PURCHASE_ORDERS_FOR_GR } from './query'
import { TableEntriesRecord } from './TableEntriesRecord/TableEntriesRecord'

interface ObjItemToSave {
	product_code: string
	product_description: string
	quantity: number
	purchase_order_position: number
	unit: string
	batches?: Array<{
		quantity: number
		batch: string
	}>
}

export const NewGoodsReceipt: FC = () => {
	const history: RouterChildContext['router']['history'] = useHistory()
	const client: DefaultClient<NormalizedCacheObject> = useMemo(
		(): DefaultClient<NormalizedCacheObject> => connection(),
		[]
	)
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const { t } = useTranslation()
	const debounceRef = useRef<ReturnType<typeof setTimeout>>()
	const [poSuggestions, setPoSuggestions] = useState<Array<string>>([])
	const [searchPo, setSearchPo] = useState<string>('')
	const [suggestionsLoading, setSuggestionsLoading] = useState<boolean>(false)
	const [itemsPendingForGoodsReceipt, setItemsPendingForGoodsReceipt] = useState<
		Array<PurchaseOrderItem & { batches?: Array<RegisterBatch> }>
	>([])
	const [itemsPendingForGoodsReceiptCache, setItemsPendingForGoodsReceiptCache] = useState<
		Array<PurchaseOrderItem & { batches?: Array<RegisterBatch> }>
	>([])
	const [purchaseOrdersForGR, setPurchaseOrdersForGR] = useState<Array<PurchaseOrder>>()
	const [information_PO, setInformation_PO] = useState<PurchaseOrder>()
	const [disabledButtonRegisterGRS, setDisabledButtonRegisterGRS] = useState<boolean>(true)
	const [filterText, setFilterText] = useState<string>('')
	const [removePOModal, setRemovePOModal] = useState<boolean>(false)
	const [saveGRModal, setSaveGRModal] = useState<boolean>(false)
	const [exitGRModal, setExitGRModal] = useState<boolean>(false)
	const [errorCode, setErrorCode] = useState<string>()
	const [editGoodReceive, setEditGoodReceive] = useState<boolean>(false)
	const [isFiltering, setIsFiltering] = useState<boolean>(false)

	useEffect(() => {
		return () => {
			beforeUnloadListener('remove')
		}
	}, [])

	const actionButtonExitNewGR = () => {
		if (editGoodReceive) {
			setExitGRModal(true)
		} else {
			history.push(URLS.GR_LIST)
		}
	}

	const editGoodReceipt = () => {
		beforeUnloadListener('add')
		setEditGoodReceive(true)
	}

	const getItemsPending = (purchaseOrder: string, customer: string) => {
		client
			.query({
				query: ITEM_PENDING_GOOD_RECEIPT,
				variables: {
					purchase_order: purchaseOrder,
					customer: customer
				}
			})
			.then((result: ApolloQueryResult<{ ItemsPendingForGoodsReceipt: Array<PurchaseOrderItem> }>) => {
				setItemsPendingForGoodsReceipt(result.data.ItemsPendingForGoodsReceipt)
				setItemsPendingForGoodsReceiptCache(result.data.ItemsPendingForGoodsReceipt)
			})
			.catch(errorHandler)
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	const onSelectValue = (value: string) => {
		if (value) {
			editGoodReceipt()
			const indexSelect = poSuggestions.indexOf(value)
			const customer: string = getCurrentUser().company.rfc
			getItemsPending(value, customer)
			setSearchPo(value)
			setInformation_PO(purchaseOrdersForGR[indexSelect])
		} else if (information_PO || itemsPendingForGoodsReceipt.length > 0 || searchPo) {
			setRemovePOModal(true)
		}
	}

	const errorHandler = (error: Error) => {
		console.error(error)
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setErrorCode(errorCode)
		}
		hideLoadingSpinner()
	}

	const queryPurchaseForGR = (action, search: string) => {
		if (search !== '') {
			setSuggestionsLoading(true)
			client
				.query({
					query: PURCHASE_ORDERS_FOR_GR,
					variables: {
						limit: 10,
						filter: [
							{
								column_name: 'id',
								type: 'wildcard',
								value: search
							},
							{
								column_name: 'status',
								type: 'exact_match',
								value: Constants.STATUS.CONFIRMED
							},
							{
								column_name: 'status',
								type: 'exact_match',
								value: PURCHASE.HALF_CONFIRMED
							},
							{
								column_name: 'status',
								type: 'exact_match',
								value: PURCHASE.HALF_DELIVERED
							}
						]
					}
				})
				.then((result: ApolloQueryResult<{ PurchaseOrders: ListResult<PurchaseOrder> }>) => {
					const purchaseOrdersForGR: Array<PurchaseOrder> = result.data.PurchaseOrders.list
					const arrayPoSuggestions: Array<string> = purchaseOrdersForGR.map(
						(item: PurchaseOrder) => item.id
					)
					setPurchaseOrdersForGR(purchaseOrdersForGR)
					setPoSuggestions(arrayPoSuggestions)
				})
				.catch(errorHandler)
				.finally(() => {
					setSuggestionsLoading(false)
				})
		}
	}

	const onInputValueChange = (value: string) => {
		if (debounceRef.current) {
			clearTimeout(debounceRef.current)
		}
		debounceRef.current = setTimeout(() => {
			queryPurchaseForGR('purchaseOrderSearch', value.toLowerCase())
		}, 500)
	}

	const filterItemsPendingForGoodsReceipt = (event: ChangeEvent<HTMLInputElement>) => {
		const value: string = event.target.value
		setFilterText(value)
		setIsFiltering(true)
		const filtered: Array<PurchaseOrderItem> = itemsPendingForGoodsReceiptCache.filter(
			(item: PurchaseOrderItem) =>
				item.name.toLowerCase().includes(value.toLowerCase()) || item.code.includes(value)
		)
		setItemsPendingForGoodsReceipt(filtered)
	}

	const saveGoodReceive = () => {
		if (editGoodReceive) {
			setSaveGRModal(true)
		}
	}

	const receivedAmount = (valueReceivedAmount, indexPosition) => {
		const updatedItems: Array<PurchaseOrderItem & { batches?: Array<RegisterBatch> }> = [
			...itemsPendingForGoodsReceipt
		]
		updatedItems[indexPosition].receivedAmount = valueReceivedAmount

		if (
			!valueReceivedAmount &&
			(updatedItems[indexPosition]?.batches || updatedItems[indexPosition].receivedAmount)
		) {
			updatedItems[indexPosition].batches = undefined
			updatedItems[indexPosition].receivedAmount = undefined
		}

		const newReceivedAmount: boolean = updatedItems.some((item: PurchaseOrderItem) => item.receivedAmount)
		setDisabledButtonRegisterGRS(!newReceivedAmount)
		setItemsPendingForGoodsReceipt(updatedItems)
		editGoodReceipt()
	}

	const saveBatchItems = (batchItems: Array<RegisterBatch>, position: number) => {
		const updatedItemsPendingForGoodsReceipt: Array<
			PurchaseOrderItem & { batches?: Array<RegisterBatch> }
		> = [...itemsPendingForGoodsReceipt]
		if (position >= 0 && position <= updatedItemsPendingForGoodsReceipt.length) {
			updatedItemsPendingForGoodsReceipt[position] = {
				...updatedItemsPendingForGoodsReceipt[position],
				batches: batchItems
			}
		}
		setItemsPendingForGoodsReceipt(updatedItemsPendingForGoodsReceipt)
	}

	const removeAssociationPO = () => {
		setRemovePOModal(false)
		setInformation_PO(undefined)
		setItemsPendingForGoodsReceipt([])
		setItemsPendingForGoodsReceiptCache([])
		setEditGoodReceive(false)
		setSearchPo('')
		setDisabledButtonRegisterGRS(true)
		beforeUnloadListener('remove')
	}

	const saveNewGR = () => {
		showLoadingSpinner()
		const itemsToSaveGoodReceive: Array<ObjItemToSave> = itemsPendingForGoodsReceipt
			.filter((item: PurchaseOrderItem & { batches?: Array<RegisterBatch> }) => item.receivedAmount)
			.map((item: PurchaseOrderItem & { batches?: Array<RegisterBatch> }) => {
				const objItemToSave: ObjItemToSave = {
					product_code: item.code,
					product_description: item.name,
					quantity: parseFloat(item.receivedAmount),
					purchase_order_position: item.position,
					unit: item.unit
				}

				if (item.batches) {
					objItemToSave.batches = item.batches.map((batchItem) => ({
						quantity: batchItem.quantity,
						batch: batchItem.batch
					}))
				}

				return objItemToSave
			})

		client
			.mutate({
				mutation: CREATE_GOOD_RECEIPT,
				variables: {
					purchase_order: searchPo,
					items: itemsToSaveGoodReceive
				}
			})
			.then((result: FetchResult<{ createGoodsReceipt: string }>) => {
				const idGoodReceipt: string = result.data.createGoodsReceipt
				beforeUnloadListener('remove')
				sessionStorage.setItem('newGRmessage', 'true')
				history.replace(URLS.GR_DETAIL + '?goodsReceipt=' + idGoodReceipt)
			})
			.catch(errorHandler)
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	return (
		<NetcurioGrid
			container
			minWidth="100%"
			minHeight="100vh"
			display="grid"
			gridTemplateRows="5.5rem 1fr"
		>
			<Header>
				<div>
					<NetcurioButton
						color="error"
						variant={'outlined'}
						onClick={actionButtonExitNewGR}
						size="small"
						endIcon={<NetcurioIcons.CancelOutlined />}
					>
						<span> {t('cancelText')} </span>
					</NetcurioButton>
				</div>
				<div></div>
			</Header>
			<NetcurioGrid container className={styles.containerNewGR}>
				<NetcurioGrid item display="grid" xs={12} gridTemplateRows="40rem 1fr">
					<div>
						<div className={styles.containerTitleTableEntries}>
							<p className={styles.titleTableEntries}>{t('titleTableRegisterEntries')}</p>
						</div>
						<div className={styles.containerSectionAssociation}>
							<span className={styles.titleSectionAssociationPO}>{t('associateGR')}</span>
							<label className={styles.titleSelectedOrder}>{t('id_PO')}</label>
							<div className={styles.selectorField}>
								<NetcurioAutocomplete
									size="small"
									height="smaller"
									variant="outlined"
									placeholder={t('textSearch')}
									options={poSuggestions}
									getOptionLabel={(selectPo: string) => Formatter.id(selectPo)}
									value={searchPo}
									onSelectValue={onSelectValue}
									onInputValueChange={onInputValueChange}
									loading={suggestionsLoading}
									isOptionEqualToValue={(option) => option === searchPo}
									minLength={1}
								/>
							</div>
							<div
								className={classNames(styles.titleSectionAssociationPO, styles.marginTop2Rem)}
							>
								{t('dataPOAssociateGR')}
							</div>
							<div className={styles.displayBlock}>
								<div className={`${styles.marginLeft0} ${styles.containerDescriptionDataPO}`}>
									<p className={styles.nameData}>{t('supplier_PO')}</p>
									<div className={styles.infoData}>
										{information_PO ? information_PO.supplier.name : t('pendingText')}
									</div>
								</div>
								<div className={styles.containerDescriptionDataPO}>
									<p className={styles.nameData}>{t('supplierRfc')}</p>
									<div className={styles.infoData}>
										{information_PO ? information_PO.supplier.rfc : t('pendingText')}
									</div>
								</div>
								<div className={styles.containerDescriptionDataPO}>
									<p className={styles.nameData}>{t('deliveryBranch')}</p>
									<div className={styles.infoData}>
										{information_PO ? information_PO.branch_office : t('pendingText')}
									</div>
								</div>
								<div className={styles.containerDescriptionDataPO}>
									<p className={styles.nameData}>{t('addressDelivery')}</p>
									<div className={styles.infoData}>
										{information_PO
											? information_PO.delivery_address_line_1 +
												' ' +
												(information_PO.delivery_address_line_2 !== null
													? information_PO.delivery_address_line_2
													: '') +
												' ' +
												information_PO.delivery_address_state +
												' ' +
												information_PO.delivery_address_postal_code +
												' ' +
												information_PO.delivery_address_country
											: t('pendingText')}
									</div>
								</div>
							</div>
							<span className={styles.titleSectionAssociationPO}>{t('deliveryData')}</span>
						</div>
						<div className={styles.containerSearchAndButton}>
							<div>
								<NetcurioTextField
									inputProps={{
										style: {
											width: '31.6rem'
										}
									}}
									size="small"
									placeholder={t('textSearchGR')}
									variant="outlined"
									value={filterText}
									onChange={filterItemsPendingForGoodsReceipt}
									adornment={<NetcurioIcons.Search />}
									adornmentPosition="end"
								/>
								<div className={styles.buttonsAlignment}>
									<NetcurioButton
										size="small"
										variant="outlined"
										color="secondary"
										fullWidth
										disabled={disabledButtonRegisterGRS}
										onClick={saveGoodReceive}
									>
										{t('registerEntries')}
									</NetcurioButton>
								</div>
							</div>
						</div>
					</div>
					<NetcurioGrid item>
						<TableEntriesRecord
							itemsPendingForGoodsReceipt={itemsPendingForGoodsReceipt}
							receivedAmount={receivedAmount}
							saveBatchItems={saveBatchItems}
							isFiltering={isFiltering}
						/>
					</NetcurioGrid>
				</NetcurioGrid>
			</NetcurioGrid>
			<CancelNewGRModal
				open={exitGRModal}
				onClose={() => {
					setExitGRModal(false)
				}}
				onAccept={() => history.push(URLS.GR_LIST)}
			/>
			<RemoveAssociationPOModal
				open={removePOModal}
				onClose={() => {
					setRemovePOModal(false)
				}}
				onAccept={removeAssociationPO}
			/>
			<SaveNewGRModal
				open={saveGRModal}
				onClose={() => {
					setSaveGRModal(false)
				}}
				onAccept={saveNewGR}
			/>
			<ErrorGRModal open={!!errorCode} errorCode={errorCode} />
		</NetcurioGrid>
	)
}
