import { Header } from '@netcurio/frontend-components'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { showModalExpiredSession } from '../../components/expiredSessionMessage'
import { beforeUnloadListener } from '../../utilities/beforeUnloadListener'
import Constants from '../../utilities/constants'
import TabsUserSelection from './tabs/userProfile/tabsUserSelection'
import styles from './userSettings.module.scss'

export const UserSettings = () => {
	const { t } = useTranslation()
	const [selectedTab, setSelectedTab] = useState<string>(
		Constants.USER_SETTINGS.USER_SETTINGS_TABS.USER_INFORMATION
	)
	const [cancelConfig, setCancelConfig] = useState<boolean>(false)
	const [somethingChangeInNotifications, setSomethingChangeInNotifications] = useState<boolean>(false)
	const [userGeneralInfo, setUserGeneralInfo] = useState<string>('')
	const [notifications, setNotifications] = useState<string>('')

	//Mounted
	useEffect(() => {
		showModalExpiredSession()
		//Unmounted
		return () => {
			setCancelConfig(true)
		}
	}, [])

	//update
	useEffect(() => {
		if (somethingChangeInNotifications) {
			beforeUnloadListener('add')
		} else {
			beforeUnloadListener('remove')
		}

		if (selectedTab === Constants.USER_SETTINGS.USER_SETTINGS_TABS.USER_INFORMATION) {
			setUserGeneralInfo(styles.selected)
			setNotifications('')
		} else if (selectedTab === Constants.USER_SETTINGS.USER_SETTINGS_TABS.NOTIFICATIONS) {
			setNotifications(styles.selected)
			setUserGeneralInfo('')
		}
	}, [somethingChangeInNotifications, selectedTab])

	const changeInNotification = (value: boolean) => {
		setSomethingChangeInNotifications(value)
	}

	const goToUserGeneralInfo = (): void => {
		if (
			selectedTab === Constants.USER_SETTINGS.USER_SETTINGS_TABS.NOTIFICATIONS &&
			somethingChangeInNotifications
		) {
			sessionStorage.setItem('TabSelected', Constants.USER_SETTINGS.USER_SETTINGS_TABS.USER_INFORMATION)
			setCancelConfig(true)
		} else {
			setSelectedTab(Constants.USER_SETTINGS.USER_SETTINGS_TABS.USER_INFORMATION)
		}
	}

	const stayInNotificationsTab = () => {
		sessionStorage.removeItem('TabSelected')
		setCancelConfig(false)
	}

	const leaveNotificationsTab = (): void => {
		const tabSelected = sessionStorage.getItem('TabSelected')
		if (tabSelected) {
			sessionStorage.removeItem('TabSelected')
			setCancelConfig(false)
			setSelectedTab(tabSelected)
			setSomethingChangeInNotifications(false)
		}
	}

	return (
		<div className={styles.settings}>
			<Header />
			<div className={'margins-table padding-15'}>
				<div className={styles.header}>
					<p className={styles.headerTextSize}>{t('userProfile')}</p>
				</div>
				<div className={styles.tabSelection}>
					<button
						className={classNames(styles.tab, userGeneralInfo)}
						onClick={() => goToUserGeneralInfo()}
					>
						{t('generalInfo')}
					</button>
					<button
						className={classNames(styles.tab, notifications)}
						onClick={() =>
							setSelectedTab(Constants.USER_SETTINGS.USER_SETTINGS_TABS.NOTIFICATIONS)
						}
					>
						{t('notifications')}
					</button>
				</div>
				<TabsUserSelection
					selectedTab={selectedTab}
					cancelConfig={cancelConfig}
					stayInNotificationsTab={stayInNotificationsTab}
					leaveNotificationsTab={leaveNotificationsTab}
					changeInNotification={changeInNotification}
				/>
			</div>
		</div>
	)
}
