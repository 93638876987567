import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const BRANCH_OFFICES: DocumentNode = gql`
	query BranchOffices($company: ID!) {
		BranchOffices(company: $company) {
			id
			description
			address_line_1
			address_line_2
			country_code
			country_description
			state_description
			state_code
			postal_code
			company {
				rfc
			}
			storage_locations {
				id
				description
				branch_office
				company
			}
		}
	}
`
