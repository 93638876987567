import { NetcurioMenuItem, NetcurioSelect, NetcurioTooltip } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUserRole } from '../../hooks/useUserRole'
import { CreditMemoItem, RequestForCreditMemoItem } from '../../types'
import Formatter from '../../utilities/formatter'
import styles from './creditMemoDetail.module.scss'

interface RequestConceptProps {
	item: CreditMemoItem
	creditMemoItemIndex: number
	rfcmItems: RequestForCreditMemoItem[]
	cmHasBeenSent?: boolean
	statusMessageCM: any
	onRfcmItemAssociation: (rfcmItem: RequestForCreditMemoItem, index: number) => void
}

const RequestConcept: React.FC<RequestConceptProps> = ({
	item,
	creditMemoItemIndex,
	rfcmItems,
	cmHasBeenSent = false,
	statusMessageCM,
	onRfcmItemAssociation
}) => {
	const { isUserSupplier } = useUserRole()
	const { t } = useTranslation()
	const shouldShowSelect = (rfcmItems.length > 0 && !cmHasBeenSent) || (isUserSupplier && statusMessageCM.error.key)
	if (shouldShowSelect) {
		return (
			<NetcurioSelect
				variant="outlined"
				key={`request-concept-auto-complete-${item.position}`}
				fullWidth
			>
				{rfcmItems.map((rfcmItem) => (
					<NetcurioMenuItem
						key={rfcmItem.position}
						value={`${rfcmItem.position} - ${rfcmItem.concept_description} - ${rfcmItem.net_amount}`}
						onClick={() => onRfcmItemAssociation(rfcmItem, creditMemoItemIndex)}
					>
						{`${rfcmItem.position} - ${rfcmItem.concept_description} - ${rfcmItem.net_amount}`}
					</NetcurioMenuItem>
				))}
			</NetcurioSelect>
		)
	}

	if (item.request_for_credit_memo_item || cmHasBeenSent) {
		const conceptDescription = item.request_for_credit_memo_item?.concept_description ?? ''
		const netAmount = Formatter.currency.format(item.request_for_credit_memo_item?.net_amount ?? 0)
		const tax = Formatter.percent.format(item.request_for_credit_memo_item?.tax ?? 0)

		return (
			<span className={styles.cmRequestConceptField}>
				<NetcurioTooltip title={conceptDescription} placement="top">
					<span className={styles.cmRequestConceptFieldConceptDescription}>
						{conceptDescription}
					</span>
				</NetcurioTooltip>
				<span>
					{t('amount')} {netAmount}
				</span>
				<br />
				<span>
					{t('iva')} {tax}
				</span>
			</span>
		)
	}
	return null
}

export default RequestConcept
