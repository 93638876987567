import React from 'react'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import { useTranslation } from 'react-i18next'
import { ListBatches } from './ListBatches'

interface BatchesModalProps {
	open: boolean
	closeBatchModal(): void
	batchesInfo: Array<any>
	unit: string
}
export const BatchesModal = ({ open, closeBatchModal, batchesInfo, unit }: BatchesModalProps) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			open={open}
			titleText={t('batchesText')}
			actionButtons={
				<>
					<NetcurioButton onClick={closeBatchModal}>{t('closeText')}</NetcurioButton>
				</>
			}
		>
			<ListBatches batchesInfo={batchesInfo} unit={unit} />
		</NetcurioDialog>
	)
}
