import {
	NetcurioCollapse,
	NetcurioIcons,
	NetcurioTableCell,
	NetcurioTableRow
} from '@netcurio/frontend-components'
import classNames from 'classnames'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { InvoiceValidationEnum } from '../../../../../types'
import { ValidationItem } from '../../../../../types/Validations'
import styles from './ValidationTable.module.scss'

interface ValidationRowProps {
	validationKey: string
	previousValidation?: ValidationItem
	currentValidation: ValidationItem
	passAllValidations: boolean
}

const iconMap = {
	[InvoiceValidationEnum.NOT_VALIDATED]: (
		<NetcurioIcons.Error className={classNames(styles.btnStatusError)} />
	),
	[InvoiceValidationEnum.NOT_OK]: (
		<div className={styles.iconPosition}>
			<NetcurioIcons.CancelRounded className={classNames(styles.btnStatusWarning)} />
		</div>
	),
	[InvoiceValidationEnum.OK]: (
		<NetcurioIcons.CheckCircleRounded className={classNames(styles.btnStatusValid)} />
	)
}

export const ValidationRow = ({
	validationKey,
	currentValidation,
	passAllValidations
}: ValidationRowProps) => {
	const [openCollapsible, setOpenCollapsible] = useState(false)
	const [icon, setIcon] = useState<InvoiceValidationEnum>()
	const headerValidation = validationKey === 'accountingPeriodValidation'

	useEffect(() => {
		const selectedIcon = getValidationResult(currentValidation)
		setIcon(selectedIcon)
		return () => {
			setIcon(InvoiceValidationEnum.OK)
		}
	}, [currentValidation, passAllValidations])

	const getPositionsLabel = () => {
		return currentValidation?.positions?.join(' - ')
	}

	const getValidationResult = (validation) => {
		return headerValidation ? validation : validation.value
	}

	const renderCollapsible = () => {
		return !headerValidation && currentValidation.value === InvoiceValidationEnum.NOT_OK
	}

	const hideCollapsible = () => {
		return currentValidation?.positions?.length === 0 || currentValidation?.positions === null
	}

	return (
		<>
			<NetcurioTableRow hasCollapsible={renderCollapsible() && !hideCollapsible()}>
				<NetcurioTableCell>
					<p>{t(validationKey)}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<div className={classNames(styles.alignTableItems)}>
						{iconMap[icon]}

						{renderCollapsible() && !hideCollapsible() && (
							<div
								className={styles.arrowIconPosition}
								onClick={() => {
									setOpenCollapsible(!openCollapsible)
								}}
							>
								{openCollapsible ? (
									<NetcurioIcons.ArrowDropUp />
								) : (
									<NetcurioIcons.ArrowDropDown />
								)}
							</div>
						)}
					</div>
				</NetcurioTableCell>
				{passAllValidations && (
					<NetcurioTableCell align="center">{iconMap[InvoiceValidationEnum.OK]}</NetcurioTableCell>
				)}
			</NetcurioTableRow>
			{renderCollapsible() && !hideCollapsible() && (
				<NetcurioTableRow>
					<NetcurioTableCell
						colSpan={passAllValidations ? 3 : 2}
						sx={{ paddingBottom: 0, paddingTop: 0 }}
					>
						<NetcurioCollapse in={openCollapsible} timeout="auto" unmountOnExit>
							<p className={styles.errorLabel}>
								Posiciones con este error:&nbsp;
								{getPositionsLabel()}
							</p>
						</NetcurioCollapse>
					</NetcurioTableCell>
				</NetcurioTableRow>
			)}
		</>
	)
}
