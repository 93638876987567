import { NetcurioButton, NetcurioDialog, useNetcurioLoader } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../hooks/useClient'
import { getUrlParameter } from '../../../utilities/getUrlParameter'
import { VOID_CREDIT_MEMO } from '../queries'

interface VoidCreditMemoModalProps {
	open: boolean
	onSuccess(): void
	onClose(): void
	handleError(error: Error): void
}

/**
 * VoidCreditMemoModal
 * @description Helps to display a modal when the user is trying to void a credit memo
 * @param open <boolean>: If `true`, the component is shown
 * @param onClose <boolean>: If `false`, the component is hide
 * @param onSuccess <void>: Callback fired when the user accept the action the modal
 * @return component <ReactNode>
 **/
export const VoidCreditMemoModal = ({ open, onSuccess, onClose, handleError }: VoidCreditMemoModalProps) => {
	const { t } = useTranslation()

	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const client = useClient()
	const creditMemoId = getUrlParameter('creditmemo')

	const voidCreditMemo = (): void => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: VOID_CREDIT_MEMO,
				variables: {
					uuid: creditMemoId
				}
			})
			.then(() => {
				onSuccess()
			})
			.catch(handleError)
			.finally(() => {
				onClose()
				hideLoadingSpinner()
			})
	}

	return (
		<NetcurioDialog
			open={open}
			titleText={t('voidCreditMemoTitle')}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={voidCreditMemo}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('voidCreditMemoQuestion')}</p>
		</NetcurioDialog>
	)
}
