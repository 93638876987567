import { dateFormatter, getSerieFolio, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import {
	accountedStatus,
	canceledStatus,
	DetailTable,
	errorStatus,
	missingLinkStatus,
	StatusInformation,
	statusLoading,
	TrackInformation,
	voidedStatus
} from '@netcurio/frontend-components'
import React, { useCallback } from 'react'
import { useUserRole } from '../../../hooks/useUserRole'
import { CreditMemo, ErrorItems, RequestForCreditMemoItem } from '../../../types'
import constants from '../../../utilities/constants'
import formatter from '../../../utilities/formatter'
import RequestConcept from '../RequestConcept'
import { ItemsTableData, StatusMessage, TrackInformationData, WorkflowValueEnum } from '../types'
import styles from './InformationContainer.module.scss'
import { getHeaderMessage, getHeaderMessageSeverity } from './utils'

interface InformationContainerProps {
	creditMemoDetail: CreditMemo
	showHeaderMessage: boolean
	closeWindow?: () => void
	rfcmItems: RequestForCreditMemoItem[]
	onRfcmItemAssociation(rfcmItemPosition: RequestForCreditMemoItem, index: number): void
	cmHasBeenSent?: boolean
	workflow: WorkflowValueEnum
}

export const InformationContainer = ({
	creditMemoDetail,
	showHeaderMessage,
	rfcmItems,
	onRfcmItemAssociation,
	closeWindow,
	cmHasBeenSent,
	workflow
}: InformationContainerProps): React.ReactElement => {
	const { isUserSupplier, isUserCustomer } = useUserRole()
	const showRequestConcept = workflow !== WorkflowValueEnum.AdvanceDiscount
	const creditMemoItems = creditMemoDetail?.items
	const errorItems: Array<ErrorItems> = []
	const statusMessageCM: StatusMessage = {
		missingLink: creditMemoDetail?.status.key === constants.LINKS.MISSING_LINK && isUserSupplier,
		error: {
			key: creditMemoDetail?.status.key === constants.ERROR.ERROR,
			value: creditMemoDetail?.error || 'showError'
		}
	}

	const mapTrackInformationData = useCallback((): TrackInformationData => {
		return {
			emission_date: dateFormatter.format(parseTimestampToTimeZone(creditMemoDetail?.date)),
			emissary: creditMemoDetail?.sender.name ?? '',
			emissary_rfc: creditMemoDetail?.sender.rfc ?? '',
			payment_method: creditMemoDetail?.payment_method,
			receptor: creditMemoDetail?.receiver.name ?? '',
			receptor_rfc: creditMemoDetail?.receiver.rfc ?? '',
			uploaded_date: dateFormatter.format(parseTimestampToTimeZone(creditMemoDetail?.created_at)),
			...(isUserCustomer ? {} : { uploaded_by: creditMemoDetail?.uploaded_by }),
			...(creditMemoDetail?.invoice
				? {
						serie_folio_uuid: `${getSerieFolio(
							creditMemoDetail?.invoice?.serie,
							creditMemoDetail?.invoice?.folio
						)} - ${creditMemoDetail?.invoice?.uuid}`
					}
				: { invoice_reference: creditMemoDetail?.invoice_reference }),
			uuid: creditMemoDetail?.uuid
		}
	}, [creditMemoDetail, isUserCustomer])

	const mapItemsTable = (): ItemsTableData[] => {
		return (
			creditMemoItems?.map((item, creditMemoItemIndex) => {
				if (item.error)
					errorItems.push({
						index: creditMemoItemIndex,
						error: true
					})

				return {
					position: item.position,
					concept: item.concept,
					amount: item.quantity,
					productCode: item.code ?? '-',
					unitPrice: isNaN(item.unit_price) ? '-' : formatter.currency.format(item.unit_price),
					net_amount: formatter.currency.format(item.net_amount),
					tax: formatter.percent.format(item.tax),
					...(showRequestConcept
						? {
								request_concept: (
									<RequestConcept
										item={item}
										creditMemoItemIndex={creditMemoItemIndex}
										rfcmItems={rfcmItems}
										cmHasBeenSent={cmHasBeenSent}
										statusMessageCM={statusMessageCM}
										onRfcmItemAssociation={onRfcmItemAssociation}
									/>
								)
							}
						: {})
				}
			}) || []
		)
	}

	const getStatuses = useCallback((): StatusInformation[] => {
		const statuses = {
			[constants.CREDIT_MEMO_STATUS.MISSING_LINK]: [missingLinkStatus()],
			[constants.CREDIT_MEMO_STATUS.ERROR]: [errorStatus()],
			[constants.CREDIT_MEMO_STATUS.ACCOUNTED]: [accountedStatus()],
			[constants.CREDIT_MEMO_STATUS.VOIDED]: [voidedStatus()],
			[constants.CREDIT_MEMO_STATUS.CANCELED]: [accountedStatus(), canceledStatus()],
			DEFAULT: [statusLoading()]
		}
		return statuses[creditMemoDetail?.status.key] || statuses['DEFAULT']
	}, [creditMemoDetail])

	return (
		<div className={styles.creditMemoInformationContainer}>
			<TrackInformation
				id={getSerieFolio(creditMemoDetail?.serie, creditMemoDetail?.folio)}
				information={mapTrackInformationData()}
				title="creditMemo"
				gridTemplateAreas={`
				'emission_date emissary emissary_rfc payment_method'
                'receptor receptor_rfc uploaded_date uploaded_by'
                '${mapTrackInformationData().invoice_reference ? 'invoice_reference invoice_reference' : 'serie_folio_uuid serie_folio_uuid'} uuid uuid'
				`}
				statuses={getStatuses()}
				showHeaderMessage={showHeaderMessage}
				headerMessage={getHeaderMessage(creditMemoDetail, rfcmItems)}
				closeWindow={closeWindow}
				statusMessage={statusMessageCM}
				headerMessageSeverity={getHeaderMessageSeverity(
					creditMemoDetail?.error,
					creditMemoDetail?.status.key,
					workflow
				)}
			/>
			<div className={styles.separatorGrayCreditMemo}></div>
			<DetailTable
				items={mapItemsTable()}
				errorItems={errorItems}
				section={'creditMemoDetail'}
				itemsJustifyContent={{ 1: 'flex-start', 2: 'items-center' }}
				itemsDataClassName={{ 7: styles.cmRFCMDataContainer }}
				dynamicTop="14.9rem"
				gridTemplateColumns={`1fr 3fr 1fr 1fr 1fr 1fr 1fr ${showRequestConcept ? '2fr' : '0'}`}
			/>
		</div>
	)
}
