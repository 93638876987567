import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const CONSUMPTIONS: DocumentNode = gql`
	query Consumptions(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		Consumptions(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			list {
				id
				customer {
					rfc
					name
				}
				branch_office
				created_by {
					email
					name
					lastname
				}
				created_at
				updated_at
				customer_reference
			}
			total
		}
	}
`
