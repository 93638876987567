import invoicesvg from '@netcurio/frontend-assets/src/icons/invoice.svg'
import {
	PURCHASE,
	Roles,
	Status,
	URLS,
	User,
	currencyFormatter,
	dateFormatter,
	parseTimestampToTimeZone
} from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioGrid,
	NetcurioIconButton,
	NetcurioIcons,
	StatusBadges,
	TotalContainerBar,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import { t } from 'i18next'
import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import { RouterChildContext, useHistory } from 'react-router-dom'
import { ConditionalRender } from '../../../../components/ConditionalRender/ConditionalRender'
import { Invoice } from '../../../../types'
import { Validations } from '../../../../types/Validations'
import { connection } from '../../../../utilities/connection'
import Constants from '../../../../utilities/constants'
import UserInformation from '../../../../utilities/userInformation'
import { defaultValuesValidations } from '../../../invoices/InvoiceDetail/utilities/defaultValues'
import { getVisualStatusInvoiceDetail } from '../../../invoices/helpers/visualStatusHelperInvoiceDetail'
import { DetailField } from '../../DetailField/DetailField'
import { MANUAL_INVOICE_AUTHORIZATION } from '../../graphQl/manualAuthorization.query'
import { InvoicesModule } from '../GoodsReceiptAndInvoicesModule/InvoicesModule/InvoicesModule'
import { ManuallyAuthInvoiceSummaryModal } from './ManuallyAuthInvoiceModal/ManuallyAuthInvoiceModal'
import { RelatedInvoiceItemList } from './RelatedInvoiceItemList/RelatedInvoiceItemList'
import styles from './relatedInvoiceDetail.module.scss'

interface RelatedInvoiceDetailProps {
	invoiceInformation: Invoice
	setShowNewInvoiceModal?(open: boolean): void
	allInvoices?: Array<Invoice>
	openInvoiceDetail?(uuid: string): void
	setShowInfoInvoice: Dispatch<SetStateAction<boolean>>
}

export const RelatedInvoiceDetail: FC<RelatedInvoiceDetailProps> = ({
	invoiceInformation,
	setShowNewInvoiceModal,
	allInvoices,
	openInvoiceDetail,
	setShowInfoInvoice
}) => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [overrideBy, setOverrideBy] = useState<User>({ name: '', lastname: '', email: '' })
	const [overrideMessage, setOverrideMessage] = useState<string>('')
	const [overrideOn, setOverrideOn] = useState<string>('')
	const [openSummaryAuthInvoiceModal, setOpenSummaryAuthInvoiceModal] = useState<boolean>(false)
	const [currentValidation, setCurrentValidation] = useState<Validations>(defaultValuesValidations)
	const history: RouterChildContext['router']['history'] = useHistory()
	const userRole: Roles = useMemo(
		(): Roles => UserInformation.getCompanyRole(),
		[UserInformation.getCompanyRole]
	)
	const isCustomer: boolean = userRole === Roles.CUSTOMER

	const modifiedBy = () => {
		const userType = isCustomer ? 'modified_by_customer' : 'modified_by_supplier'
		const userName = invoiceInformation
			? invoiceInformation[userType]
				? (invoiceInformation[userType] as User)?.name +
					' ' +
					(invoiceInformation[userType] as User)?.lastname
				: PURCHASE.TEXT_PENDING
			: ''
		const userEmail = invoiceInformation
			? invoiceInformation[userType]
				? (invoiceInformation[userType] as User)?.email
				: ''
			: ''
		return { name: userName, email: userEmail }
	}

	const goToInvoice = () => {
		const invStatus: string =
			typeof invoiceInformation?.status === 'object'
				? invoiceInformation.status?.key
				: invoiceInformation?.status

		if (invStatus === Constants.ERROR.ERROR || invStatus === Constants.LINKS.MISSING_LINK) {
			history.push(
				`${isCustomer ? URLS.INVOICE_DETAIL : URLS.INVOICE_EDIT}?invoice=${invoiceInformation.uuid}`
			)
		} else if (
			invStatus === PURCHASE.PAYMENT_SCHEDULED ||
			invStatus === PURCHASE.PAID ||
			invStatus === PURCHASE.CANCELED ||
			invStatus === PURCHASE.VOIDED
		) {
			history.push(URLS.INVOICE_DETAIL + '?invoice=' + invoiceInformation.uuid)
		}
	}

	const getManualAuthorizationSummary = () => {
		showLoadingSpinner()
		client
			.query({
				query: MANUAL_INVOICE_AUTHORIZATION,
				variables: {
					uuid: invoiceInformation.uuid
				},
				fetchPolicy: 'no-cache'
			})
			.then((result) => {
				setOverrideBy(result.data.ManualInvoiceAuthorization.overrideBy)
				setOverrideMessage(result.data.ManualInvoiceAuthorization.overrideMessage)
				setOverrideOn(
					dateFormatter.format(
						parseTimestampToTimeZone(result.data.ManualInvoiceAuthorization.overrideOn)
					)
				)
				const validation = result.data.ManualInvoiceAuthorization.validations
				delete validation.__typename
				setCurrentValidation(validation)
				setOpenSummaryAuthInvoiceModal(true)
				hideLoadingSpinner()
			})
			.catch()
	}

	return (
		<NetcurioGrid container height="64.3vh" alignItems="flex-start">
			<NetcurioGrid item xs={8} sm={8} md={9} lg={9} xl={10} className={styles.mainSection}>
				<NetcurioGrid container>
					<NetcurioGrid item xs={12}>
						<div className={styles.card}>
							<span>{`${t('invoiceText')}`}</span>
							<span>{`${invoiceInformation.serie}-${invoiceInformation.folio}`}</span>
							<div className={styles.rightContentContainer}>
								<ConditionalRender
									condition={
										Boolean(invoiceInformation.override_by) || invoiceInformation.advance
									}
								>
									<ConditionalRender condition={Boolean(invoiceInformation.override_by)}>
										<div
											onClick={() => getManualAuthorizationSummary?.()}
											className={styles.manualAuthorizationLink}
										>
											<span>{t('manuallyProcessed')}</span>
											<NetcurioIcons.InfoOutlined />
										</div>
									</ConditionalRender>
									<ConditionalRender
										condition={
											Boolean(invoiceInformation.override_by) &&
											invoiceInformation.advance
										}
									>
										<div className={classNames(styles.separator)}>-</div>
									</ConditionalRender>
									<ConditionalRender condition={Boolean(invoiceInformation.advance)}>
										<div>{t('advance')}</div>
									</ConditionalRender>
								</ConditionalRender>

								<NetcurioIconButton
									onClick={() => {
										setShowInfoInvoice(false)
									}}
								>
									<NetcurioIcons.HighlightOff sx={{ color: 'white' }} />
								</NetcurioIconButton>
							</div>
						</div>
					</NetcurioGrid>
					<NetcurioGrid container className={classNames(styles.invoiceInformationSection)}>
						<NetcurioGrid container item xs={12} className={classNames(styles.statusSection)}>
							<div>
								<StatusBadges
									statusArray={getVisualStatusInvoiceDetail(
										invoiceInformation?.modified_by_supplier,
										(invoiceInformation?.status as Status)?.key
									)}
								/>
							</div>
						</NetcurioGrid>
						<NetcurioGrid container item xs={12} className={styles.invoiceDetailsContainer}>
							<NetcurioGrid item xs={3}>
								<DetailField
									title={t('dateInvoicesHeader')}
									information={dateFormatter.format(
										parseTimestampToTimeZone(invoiceInformation?.date)
									)}
								/>
								{isCustomer ? (
									<DetailField
										title={t('sender')}
										information={invoiceInformation?.sender?.name}
										tooltip={invoiceInformation?.sender?.name}
									/>
								) : (
									<DetailField
										title={t('uploadedAt')}
										information={dateFormatter.format(
											parseTimestampToTimeZone(invoiceInformation?.created_at)
										)}
									/>
								)}
								<DetailField
									title={t('rfcSender')}
									information={invoiceInformation?.sender?.rfc}
									tooltip={isCustomer ? '' : invoiceInformation?.sender?.name}
								/>
							</NetcurioGrid>
							<NetcurioGrid item xs={3}>
								<DetailField
									title={t('receiver')}
									information={invoiceInformation?.receiver?.name}
									tooltip={invoiceInformation?.receiver?.name}
								/>
								<DetailField
									title={t('rfcReceiver')}
									information={invoiceInformation?.receiver?.rfc}
								/>
								<DetailField
									title={t('UUID')}
									information={invoiceInformation?.uuid}
									tooltip={invoiceInformation?.uuid}
								/>
							</NetcurioGrid>
							<NetcurioGrid item xs={3}>
								<DetailField
									title={t('proposedPaymentDate')}
									information={
										invoiceInformation?.proposed_payment_date
											? dateFormatter.format(
													parseTimestampToTimeZone(
														invoiceInformation?.proposed_payment_date
													)
												)
											: t(PURCHASE.TEXT_PENDING)
									}
								/>
								{isCustomer ? (
									<DetailField
										title={t('editedBy')}
										information={t(modifiedBy().name)}
										tooltip={t(modifiedBy().email)}
									/>
								) : (
									<DetailField
										title={t('uploadedBy')}
										information={
											invoiceInformation?.uploaded_by
												? invoiceInformation?.uploaded_by?.name +
													' ' +
													invoiceInformation?.uploaded_by?.lastname
												: ''
										}
										tooltip={invoiceInformation?.uploaded_by?.email}
									/>
								)}
								{isCustomer ? (
									<DetailField
										title={t('dateLastModification')}
										information={dateFormatter.format(
											parseTimestampToTimeZone(invoiceInformation?.updated_at)
										)}
										tooltip={t('dateLastModification')}
									/>
								) : (
									<DetailField
										title={t('editedBy')}
										information={t(modifiedBy().name)}
										tooltip={t(modifiedBy().email)}
									/>
								)}
							</NetcurioGrid>
							<NetcurioGrid item xs={3}>
								<DetailField
									title={t('paymentDate')}
									information={
										invoiceInformation.payment_date
											? dateFormatter.format(
													parseTimestampToTimeZone(invoiceInformation?.payment_date)
												)
											: t('pending')
									}
								/>
								<DetailField
									title={t('bankReference')}
									information={
										invoiceInformation.bank_reference
											? invoiceInformation.bank_reference
											: t('pending')
									}
								/>
								{isCustomer ? (
									<DetailField
										title={t('uploadedAt')}
										information={dateFormatter.format(
											parseTimestampToTimeZone(invoiceInformation?.created_at)
										)}
									/>
								) : (
									<DetailField
										title={t('dateLastModification')}
										information={dateFormatter.format(
											parseTimestampToTimeZone(invoiceInformation?.updated_at)
										)}
										tooltip={t('dateLastModification')}
									/>
								)}
							</NetcurioGrid>
						</NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid
						container
						justifyContent={'flex-start'}
						alignItems={'center'}
						alignContent={'start'}
						className={styles.listContainer}
						marginTop="1rem"
					>
						<RelatedInvoiceItemList
							invoiceItems={invoiceInformation?.items}
							referenceType={invoiceInformation?.reference_type}
							status={(invoiceInformation?.status as Status)?.key}
						/>
					</NetcurioGrid>
				</NetcurioGrid>
			</NetcurioGrid>
			<NetcurioGrid
				item
				xs={4}
				sm={4}
				md={3}
				lg={3}
				xl={2}
				width="100%"
				height="100%"
				justifyContent="flex-start"
				flexDirection="column"
				paddingLeft="1rem"
			>
				<NetcurioGrid item marginBottom="1rem" xs={12}>
					<div className={styles.relatedInvoicesContainer}>
						<TotalContainerBar
							title="total"
							fields={{
								currency: invoiceInformation.currency,
								subtotalText: currencyFormatter.format(invoiceInformation.subtotal),
								ivaText: currencyFormatter.format(invoiceInformation.iva)
							}}
							total={{
								totalDotText: currencyFormatter.format(invoiceInformation.total)
							}}
							isBgDark={true}
						/>
						<div className={styles.containerButtonGoInvoice}>
							<NetcurioButton
								endIcon={<NetcurioIcons.ArrowCircleRight />}
								onClick={goToInvoice}
								variant="outlined"
								color="secondary"
							>
								{t('goToInvoice')}
							</NetcurioButton>
						</div>
					</div>
				</NetcurioGrid>
				<NetcurioGrid
					item
					height="100%"
					width="100%"
					display="flex"
					flexDirection="column"
					alignItems="center"
					xs={12}
				>
					<div className={styles.titleInvoicesModal}>
						<img src={invoicesvg} className={styles.imgInvoicesDetails} alt="invoice" />
						<p className={styles.invoicesSubtitlePODetail}>{t('invoicesText')}</p>
					</div>
					<InvoicesModule
						userRole={userRole}
						dataInvoices={allInvoices}
						setShowNewInvoiceModal={setShowNewInvoiceModal}
						openInvoiceDetail={openInvoiceDetail}
						statusPO={''}
					/>
				</NetcurioGrid>
			</NetcurioGrid>
			<ManuallyAuthInvoiceSummaryModal
				onClose={() => setOpenSummaryAuthInvoiceModal(false)}
				open={openSummaryAuthInvoiceModal}
				override_by={overrideBy}
				override_message={overrideMessage}
				override_on={overrideOn}
				currentValidation={currentValidation}
				passAllValidations={false}
			/>
		</NetcurioGrid>
	)
}
