import { fillModal } from '../../components/dialogModal/fillModal'

export function objectModalReviewTicket(): fillModal[] {
	const reviewTicket = new fillModal(
		'confirmModal',
		'reviewTicketTitle',
		'reviewTicketText',
		'reviewTicket',
		'buttonSendText',
		'comeBackTextModal',
		''
	)
	return [reviewTicket]
}

export function objectModalCloseTicket(): fillModal[] {
	const closeTicket = new fillModal(
		'rejectModalWithReasonTicket',
		'closeTicketTitle',
		'closeTicketText',
		'closeTicket',
		'closeActionText',
		'comeBackTextModal',
		''
	)
	return [closeTicket]
}

export function objectModalReopenTicket(): fillModal[] {
	const reopenTicket = new fillModal(
		'confirmModla',
		'reopenTicketTitle',
		'reopenTicketText',
		'reopenTicket',
		'buttonReopenText',
		'comeBackTextModal',
		''
	)
	return [reopenTicket]
}
