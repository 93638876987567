import * as queries from './NewUserModal/queries'
import { FetchResult } from 'apollo-boost'
import { URLS } from '@netcurio/frontend-common'
import { connection } from '../../../../utilities/connection'
import Constants from '../../../../utilities/constants'

interface CreateInvitationProps {
	emailInvite: string
	roleInvite: string
	isAdminInvite: boolean
	enrollmentRequestApprover: boolean
}

interface ResponseQuery {
	createInvitation: {
		code: string
		message: string
	}
}

interface ResponseCreateInvitation {
	error?: Error
	response?: ResponseQuery
	route?: string
}
export const createInvitationHelper = async ({
	emailInvite,
	roleInvite,
	isAdminInvite,
	enrollmentRequestApprover
}: CreateInvitationProps): Promise<ResponseCreateInvitation> => {
	return new Promise((resolve, reject) => {
		const client = connection()
		client
			.mutate({
				mutation: queries.CREATE_INVITATION,
				variables: {
					email: emailInvite,
					role: roleInvite,
					is_admin: isAdminInvite,
					enrollment_request_approver: enrollmentRequestApprover
				}
			})
			.then((result: FetchResult<ResponseQuery>) => {
				const codeInvitation = result.data.createInvitation.code
				const email = emailInvite.replace('@', '%40').split('.').join('&')
				switch (codeInvitation) {
					case Constants.INVITATION_STATUS.USER_ADDED_TO_COMPANY:
						sessionStorage.setItem(
							'statusInvitation',
							Constants.INVITATION_STATUS.USER_ADDED_TO_COMPANY
						)
						return resolve({
							response: result.data,
							route: `${URLS.ADMINISTRATION}/${Constants.ADMIN.ADMIN_TABS.USER_TAB}/${email}`
						})

					case Constants.INVITATION_STATUS.INVITATION_SENT:
						sessionStorage.setItem(
							'statusInvitation',
							Constants.INVITATION_STATUS.INVITATION_SENT
						)
						return resolve({
							response: result.data,
							route: `${URLS.ADMINISTRATION}/${Constants.ADMIN.ADMIN_TABS.USER_TAB}/${email}`
						})
					case Constants.INVITATION_STATUS.USER_REGISTERED:
					case Constants.INVITATION_STATUS.USER_UNSUBSCRIBED:
						return resolve({
							response: result.data
						})
					case Constants.INVITATION_STATUS.INVITATION_RESENT:
					case Constants.INVITATION_STATUS.VALID_INVITATION:
						sessionStorage.setItem(
							'statusInvitation',
							Constants.INVITATION_STATUS.INVITATION_RESENT
						)
						return resolve({
							response: result.data,
							route: `${URLS.ADMINISTRATION}/${Constants.ADMIN.ADMIN_TABS.USER_TAB}/${email}`
						})
					default:
						return resolve({
							response: result.data
						})
				}
			})
			.catch((error: Error) => {
				return reject({
					error: error
				})
			})
	})
}
