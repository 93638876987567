import React from 'react'
import { BackButton } from '../../../components/HeaderButtons/BackButton'
import { ExportButton } from '../../../components/HeaderButtons/ExportButton'
import { EnrollmentsListButton } from './EnrollmentsListButton'
import { InviteSupplierButtons } from './InviteSupplierButtons'
import { ProductsListButtons } from './ProductsListButtons'
import { UserListTabButtons } from './UserListTabButtons'

interface HeaderButtonsProps {
	selectedTab: string
	actionHeaderButton: (action: string) => void
}

function LeftBackButton({ onClick }: { onClick: () => void }) {
	return (
		<>
			<div>
				<BackButton onClick={onClick} translationKey="comebackListText" />
			</div>
			<div />
		</>
	)
}

export const HeaderButtons = ({ selectedTab, actionHeaderButton }: HeaderButtonsProps) => {
	switch (selectedTab) {
		case 'usersListTab':
			return <UserListTabButtons actionHeaderButton={actionHeaderButton} />
		case 'productsList':
			return <ProductsListButtons actionHeaderButton={actionHeaderButton} />
		case 'relatedCompaniesList':
			return (
				<div>
					<ExportButton onClick={() => actionHeaderButton('exportListRelatedCompanies')} />
				</div>
			)
		case 'enrollmentsListExport':
			return (
				<div>
					<ExportButton onClick={() => actionHeaderButton('exportListEnrollmentsList')} />
				</div>
			)
		case 'inviteSupplier':
			return <InviteSupplierButtons actionHeaderButton={actionHeaderButton} />
		case 'userDetailTab':
			return <LeftBackButton onClick={() => actionHeaderButton('comeBackUserList')} />
		case 'relatedCompanyDetail':
			return <LeftBackButton onClick={() => actionHeaderButton('relatedCompanyDetail')} />
		case 'enrollmentsList':
			return <EnrollmentsListButton actionHeaderButton={actionHeaderButton} />
		case 'enrollmentsDetail':
			return <LeftBackButton onClick={() => actionHeaderButton('comeBackEnrollmentList')} />
		case 'productDetailTab':
			return <LeftBackButton onClick={() => actionHeaderButton('comeBackProductList')} />
		default:
			return <div />
	}
}
