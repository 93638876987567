import gql from 'graphql-tag'

export const CompanyDocuments = gql`
	query Company($rfc: ID!) {
		Company(rfc: $rfc) {
			supportingDocuments {
				document_type
				link
			}
		}
	}
`

export const CREATE_ENROLLMENT_INVITATION = gql`
	mutation createEnrollmentInvitation($customer: ID!, $comment: CommentInput, $additional_doc: String) {
		createEnrollmentInvitation(customer: $customer, comment: $comment, additional_doc: $additional_doc)
	}
`
