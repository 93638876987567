import { fillModal } from '../../components/dialogModal/fillModal'

export function objectModalSaveDataRFQ(): fillModal[] {
	const createRFQNew = new fillModal(
		'confirmModal',
		'sendNewRFQ',
		'createNewRFQtext',
		'createNewRFQ',
		'buttonSendText',
		'comeBackTextModal',
		''
	)
	return [createRFQNew]
}

export function objectModalChangeSupplier(): fillModal[] {
	const changeSupplier = new fillModal(
		'cleanList',
		'deleteProducts',
		'deleteProductsQuestion',
		'cleanList',
		'deleteText',
		'comeBackTextModal',
		''
	)
	return [changeSupplier]
}
