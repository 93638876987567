import { currencyFormatter } from '@netcurio/frontend-common'
import {
	CommentSectionSize,
	CommentSectionVariant,
	CommentsSection,
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	TotalContainerBar
} from '@netcurio/frontend-components'
import classnames from 'classnames'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AssociatedDocumentViewer } from '../../components/AssociatedDocumentViewer/AssociatedDocumentViewer'
import { useUserRole } from '../../hooks/useUserRole'
import { CreditMemo } from '../../types'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { useDelayUnmount } from '../../utilities/useDelayUnmount'
import styles from './creditMemoDetail.module.scss'
import { ModalTypes, WorkflowValueEnum } from './types'
import { useComments } from './useComment'
import { WorkflowSection } from './WorkflowSection/WorkflowSection'

interface LateralColumnProps {
	creditMemoDetail: CreditMemo
	creditMemoId: string
	sendAssociation: () => void
	isEditionMode: boolean
	disabledSendButton: boolean
	rfcmReference?: string
	setErrorCode(errorCode: string): void
	setModalType(modalType: ModalTypes): void
	onDocumentSelected(rfcmId: string | undefined, items: unknown): void
	refreshDocumentData(): void
	hasPendingMsg(pendingMsg: boolean): void
	workflow: WorkflowValueEnum
	setWorkflow(workflowValue: WorkflowValueEnum): void
}

export const LateralColumn = ({
	creditMemoDetail,
	creditMemoId,
	setErrorCode,
	setModalType,
	sendAssociation,
	isEditionMode,
	onDocumentSelected,
	disabledSendButton,
	rfcmReference,
	refreshDocumentData,
	hasPendingMsg,
	workflow,
	setWorkflow
}: LateralColumnProps): React.ReactElement => {
	const { t } = useTranslation()
	const [isCommentSectionOpen, setIsCommentSectionOpen] = useState<boolean>(false)
	const [showDiv, hideStyle] = useDelayUnmount(!isCommentSectionOpen, 1000)
	const { isUserCustomer } = useUserRole()
	const showCancelCMButton = creditMemoDetail?.status.key === Constants.STATUS.ACCOUNTED && isUserCustomer
	const isMissingLinkStatus =
		Constants.CREDIT_MEMO_STATUS.MISSING_LINK === (creditMemoDetail?.status?.key ?? '')
	const showAssociatedDocument =
		!(isMissingLinkStatus && isUserCustomer) || creditMemoDetail?.status.key === Constants.STATUS.VOIDED

	const handleError = useCallback(
		(error: Error) => {
			const errorCode = showErrorComponent(error)
			if (!expiredToken(errorCode)) {
				setModalType(ModalTypes.Error)
				setErrorCode(errorCode)
			}
		},
		[setErrorCode, setModalType]
	)

	const { addNewComment, creditMemoComments } = useComments({
		creditMemoId,
		handleError,
		hasPendingMsg
	})

	return (
		<div className={styles.creditMemoLateralColumnContainer}>
			{showDiv && (
				<div
					className={classnames(styles.creditMemoLateralColumnContainerTotalDetail, {
						transitionHide: hideStyle
					})}
				>
					<TotalContainerBar
						title={'netTotal'}
						fields={{
							currency: creditMemoDetail?.currency,
							subtotal: currencyFormatter.format(
								creditMemoDetail?.subtotal ? creditMemoDetail?.subtotal : 0
							),
							iva: currencyFormatter.format(creditMemoDetail?.iva ?? 0),
							ieps: currencyFormatter.format(creditMemoDetail?.ieps ?? 0),
							isr: currencyFormatter.format(creditMemoDetail?.isr ?? 0)
						}}
						total={{
							total: currencyFormatter.format(
								creditMemoDetail?.total ? creditMemoDetail?.total : 0
							)
						}}
					/>
				</div>
			)}
			<CommentsSection
				variant={CommentSectionVariant.WithBody}
				comments={creditMemoComments}
				onAddComment={addNewComment}
				onSizeChange={(e) => setIsCommentSectionOpen(e === CommentSectionSize.Extended)}
				onChange={(pendingMsg) => hasPendingMsg(!!pendingMsg)}
			/>
			<WorkflowSection
				creditMemo={creditMemoDetail}
				onRefreshDocumentData={refreshDocumentData}
				onAssociateDocument={onDocumentSelected}
				workflow={workflow}
				setWorkflow={setWorkflow}
				rfcmReference={rfcmReference}
			/>
			{!isCommentSectionOpen && (
				<div className={styles.lateralColumnContainerAssociationDocument}>
					{isEditionMode ? (
						<NetcurioGrid>
							<div className={styles.buttonsContainer}>
								<NetcurioButton
									disabled={disabledSendButton}
									variant={'outlined'}
									onClick={() => sendAssociation()}
									endIcon={<NetcurioIcons.Check />}
								>
									<span className={styles.textButton}>{t('sendInvoice')}</span>
								</NetcurioButton>
								<NetcurioButton
									variant={'text'}
									color={'secondary'}
									onClick={() => {
										setModalType(ModalTypes.Void)
									}}
									endIcon={<NetcurioIcons.Close />}
								>
									<span className={styles.textButton}>{t('voidInvoice')}</span>
								</NetcurioButton>
							</div>
						</NetcurioGrid>
					) : (
						<NetcurioGrid>
							{showAssociatedDocument && (
								<AssociatedDocumentViewer
									referenceType={Constants.DOCUMENT_TYPE.CREDIT_MEMO}
									documentReferenceId={
										(creditMemoDetail?.request_for_credit_memo?.id || rfcmReference) ?? ''
									}
									customerReference={
										creditMemoDetail?.request_for_credit_memo?.customer_reference ?? ''
									}
									customerRFC={
										creditMemoDetail?.request_for_credit_memo?.customer.rfc ?? ''
									}
									parentStatus={creditMemoDetail?.status?.key}
									invoice={creditMemoDetail?.invoice}
								/>
							)}
							{showCancelCMButton && (
								<div className={styles.containerButtonsCM}>
									<NetcurioButton
										variant="text"
										color="secondary"
										onClick={() => {
											setModalType(ModalTypes.Cancel)
										}}
										endIcon={<NetcurioIcons.Close />}
									>
										<span>{t('cancelCMButton')}</span>
									</NetcurioButton>
								</div>
							)}
						</NetcurioGrid>
					)}
				</div>
			)}
		</div>
	)
}
