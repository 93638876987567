import gql from 'graphql-tag'

export default {
	CREATE_PRODUCT: gql`
		mutation createProduct(
			$id: ID!
			$description: String!
			$currency: String
			$unit_price: Float!
			$unit: String!
			$is_active: Boolean
			$tax: Float
			$ean: String
		) {
			createProduct(
				id: $id
				description: $description
				currency: $currency
				unit_price: $unit_price
				unit: $unit
				is_active: $is_active
				tax: $tax
				ean: $ean
			) {
				code
				message
			}
		}
	`
}
