import React from 'react'
import { useTranslation } from 'react-i18next'
import { NewConsumptionItem } from '../../types'
import Item from './item'
import { NetcurioButton, NetcurioIcons } from '@netcurio/frontend-components'
import classNames from 'classnames'
import styles from './newConsumption.module.scss'

interface Props {
	showStock: () => void
	consumptionItems: Array<NewConsumptionItem>
	deleteItem: (position: number) => void
	quantityInputChange: (evt: React.ChangeEvent<HTMLInputElement>, index: number) => void
	notFilledMessageVisibility: boolean
	messageMissingStock: boolean
}

export default function ItemsList({
	showStock,
	consumptionItems,
	deleteItem,
	quantityInputChange,
	notFilledMessageVisibility,
	messageMissingStock
}: Props) {
	const { t } = useTranslation()

	return (
		<div className={styles.mainTableNewConsumption}>
			<div className={styles.headerMainTableNewConsumption}>
				<p className={styles.textSizeHeaderNewConsumption}>{t('consumptionText')}</p>
			</div>
			{consumptionItems && consumptionItems.length ? (
				Object.keys(consumptionItems).map((key) => (
					<Item
						key={key}
						itemData={consumptionItems[parseInt(key)]}
						itemPosition={parseInt(key)}
						deleteItem={deleteItem}
						quantityInputChange={quantityInputChange}
						messageMissingStock={messageMissingStock}
					/>
				))
			) : (
				<Item />
			)}
			{notFilledMessageVisibility && (
				<p className={classNames(styles.notFilledMessageNewConsumption, styles.messageMargin)}>
					{t('missingFields')}
				</p>
			)}
			<div className={styles.addProductPosition}>
				<NetcurioButton
					variant="text"
					onClick={showStock}
					startIcon={<NetcurioIcons.AddCircleOutlineOutlined />}
				>
					{t('addItem')}
				</NetcurioButton>
			</div>
		</div>
	)
}
