import gql from 'graphql-tag'

export const CREATE_INVOICE = gql`
	mutation createInvoice($xml: FileInput!, $pdf: FileInput!, $reference: String, $referenceType: String) {
		createInvoice(xml: $xml, pdf: $pdf, reference: $reference, referenceType: $referenceType) {
			uuid
			message
		}
	}
`
