import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const CONSUMPTION: DocumentNode = gql`
	query Consumption($id: ID!, $customer: ID!) {
		Consumption(id: $id, customer: $customer) {
			id
			customer_reference
			customer {
				rfc
			}
			items {
				code
				description
				position
				unit_price
				pending_quantity_to_invoice
			}
		}
	}
`
