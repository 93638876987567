import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const INVOICES_CUSTOMER: DocumentNode = gql`
	query Invoices(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$archived: Boolean
		$filter: [FilterInput]
	) {
		Invoices(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			archived: $archived
			filter: $filter
		) {
			list {
				uuid
				serie
				folio
				status {
					key
					value
				}
				sender {
					name
					rfc
				}
				reference_type
				purchase_order {
					id
					customer_reference
					customer {
						rfc
					}
				}
				consumption {
					id
					customer_reference
					customer {
						rfc
					}
				}
				proposed_payment_date
				total
				reference
				created_at
				payment_complements {
					uuid
					serie
					folio
				}
				pinned
				advance
				override_by {
					email
					name
					lastname
				}
			}
			total
		}
	}
`

export const INVOICES_SUPPLIER: DocumentNode = gql`
	query Invoices(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$archived: Boolean
		$filter: [FilterInput]
	) {
		Invoices(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			archived: $archived
			filter: $filter
		) {
			list {
				uuid
				serie
				folio
				status {
					key
					value
				}
				receiver {
					name
					rfc
				}
				reference_type
				purchase_order {
					id
					customer_reference
					customer {
						rfc
					}
				}
				consumption {
					id
					customer_reference
					customer {
						rfc
					}
				}
				proposed_payment_date
				total
				reference
				created_at
				payment_complements {
					uuid
					serie
					folio
				}
				pinned
				advance
				override_by {
					email
					name
					lastname
				}
			}
			total
		}
	}
`

export const ARCHIVE_INVOICES: DocumentNode = gql`
	mutation archiveInvoices($invoicesIds: [String!]!) {
		archiveInvoices(invoicesIds: $invoicesIds) {
			message
		}
	}
`

export const UNARCHIVE_INVOICES: DocumentNode = gql`
	mutation unarchiveInvoices($invoicesIds: [String!]!) {
		unarchiveInvoices(invoicesIds: $invoicesIds) {
			message
		}
	}
`

export const PIN_INVOICES: DocumentNode = gql`
	mutation pinInvoices($invoicesIds: [String!]!) {
		pinInvoices(invoicesIds: $invoicesIds) {
			message
		}
	}
`

export const UNPIN_INVOICES: DocumentNode = gql`
	mutation unpinInvoices($invoicesIds: [String!]!) {
		unpinInvoices(invoicesIds: $invoicesIds) {
			message
		}
	}
`
export const PURCHASE_ORDERS_ROWS: DocumentNode = gql`
	query PurchaseOrders($limit: Int) {
		PurchaseOrders(limit: $limit) {
			list {
				total
			}
			total
		}
	}
`
