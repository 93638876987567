import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioTooltip, NetcurioAutocomplete } from '@netcurio/frontend-components'
import { FreightAndTerms, NewQuotation } from '../types'
import styles from './NewQuotation.module.scss'

interface PaymentTermAndFreightProps {
	newQuotation: NewQuotation
	allFreights: Array<FreightAndTerms>
	allPaymentTerms: Array<FreightAndTerms>
	searchFreight(search_text: string): void
	searchPaymentTerms(search_text: string): void
	activateNewQuotationButton(): void
	selectedPaymentTerms(event: { originalEvent?: Event; value?: FreightAndTerms }): void
	selectedFreight(event: { originalEvent?: Event; value?: FreightAndTerms }): void
	resetDataQuotation: boolean
	setResetDataQuotation(): void
}

export const PaymentTermAndFreight: FC<PaymentTermAndFreightProps> = ({
	newQuotation,
	allFreights,
	allPaymentTerms,
	searchFreight,
	searchPaymentTerms,
	activateNewQuotationButton,
	selectedPaymentTerms,
	selectedFreight,
	resetDataQuotation,
	setResetDataQuotation
}) => {
	const { t } = useTranslation()
	const [valueFreight, setValueFreight] = useState<string>('')
	const [valuePaymentTerms, setValuePaymentTerms] = useState<string>('')

	useEffect(() => {
		searchFreight(valueFreight)
	}, [valueFreight])

	useEffect(() => {
		searchPaymentTerms(valuePaymentTerms)
	}, [valuePaymentTerms])

	useEffect(() => {
		if (newQuotation.freight?.code && !valueFreight) {
			const event = {
				value: {
					code: newQuotation.freight?.code,
					description: newQuotation.freight?.description,
					label: newQuotation.freight?.code + ' - ' + newQuotation.freight?.description
				}
			}
			setSelectedFreight(event)
		}
		if (newQuotation.paymentTerms?.code && !valuePaymentTerms) {
			const event = {
				value: {
					code: newQuotation.paymentTerms?.code,
					description: newQuotation.paymentTerms?.description,
					label: newQuotation.paymentTerms?.code + ' - ' + newQuotation.paymentTerms?.description
				}
			}
			setSelectedPaymentTerm(event)
		}

		if (resetDataQuotation) {
			setResetDataQuotation()
			if (newQuotation.freight) {
				const evt = {
					value: {
						code: newQuotation.freight?.code,
						description: newQuotation.freight?.description,
						label: newQuotation.freight?.code + ' - ' + newQuotation.freight?.description
					}
				}
				setSelectedFreight(evt)
			} else {
				setSelectedFreight(undefined)
			}
			if (newQuotation.paymentTerms) {
				const evt = {
					value: {
						code: newQuotation.paymentTerms?.code,
						description: newQuotation.paymentTerms?.description,
						label:
							newQuotation.paymentTerms?.code + ' - ' + newQuotation.paymentTerms?.description
					}
				}
				setSelectedPaymentTerm(evt)
			} else {
				setSelectedPaymentTerm(undefined)
			}
		}
	}, [newQuotation, resetDataQuotation])

	useEffect(() => {
		activateNewQuotationButton()
	}, [newQuotation.paymentTerms, newQuotation.freight])

	const setSelectedFreight = (event: { value: FreightAndTerms }) => {
		if (!event.value) {
			selectedFreight(undefined)
			setValueFreight('')
		} else if (event) {
			selectedFreight(event)
			setValueFreight(event.value.label)
		}
	}

	const setSelectedPaymentTerm = (event: { value: FreightAndTerms }) => {
		if (!event.value) {
			setValuePaymentTerms('')
			selectedPaymentTerms(undefined)
		} else if (event) {
			selectedPaymentTerms(event)
			setValuePaymentTerms(event.value.label)
		}
	}

	return (
		<div className={styles.alignAutoCompleteQuotation}>
			<div className={styles.modulePaymentFreight}>
				<NetcurioTooltip title={<label>{t('freight')}</label>} enterDelay={500}>
					<div className={styles.selectFieldQuotation}>
						<label className={styles.titleFieldQuotation}>{t('freight')}</label>
						<NetcurioAutocomplete
							variant={'outlined'}
							height="smaller"
							minLength={3}
							placeholder={t('freight')}
							value={valueFreight}
							options={allFreights}
							onSelectValue={(value: FreightAndTerms) => setSelectedFreight({ value })}
						/>
					</div>
				</NetcurioTooltip>
			</div>
			<div className={styles.modulePaymentFreight}>
				<NetcurioTooltip title={<label>{t('paymentTerms')}</label>} enterDelay={500}>
					<div className={styles.selectFieldQuotation}>
						<label className={styles.titleFieldQuotation}>{t('paymentTerms')}</label>
						<NetcurioAutocomplete
							variant={'outlined'}
							height="smaller"
							minLength={3}
							placeholder={t('paymentTerms')}
							value={valuePaymentTerms}
							options={allPaymentTerms}
							onSelectValue={(value: FreightAndTerms) => setSelectedPaymentTerm({ value })}
						/>
					</div>
				</NetcurioTooltip>
			</div>
		</div>
	)
}
