import { NetcurioTooltip } from '@netcurio/frontend-components'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Constants from '../../utilities/constants'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './HeaderTable.module.scss'

interface mainHeaderTableProps {
	textHeader: string
	showFilters?: (field: string, fieldStyle: string) => void
	dataMainHeaderTable: tableFillHeader
	isForGridArea?: boolean
}

/**
 * MainHeaderTable
 * @param textHeader <string> class to be used in the main div of each header
 * @param showFilters <function> function called to show or hide the table's filters
 * @param dataMainHeaderTable <object> group of classes used to give custom style to each table's headers
 * @param isForGridArea <boolean>
 */
export const HeaderTable = ({
	textHeader,
	showFilters,
	dataMainHeaderTable,
	isForGridArea
}: mainHeaderTableProps) => {
	const { t } = useTranslation()
	const headerTitle = t(
		Constants.TOOLTIP.TOOLTIP_HEADER[
			dataMainHeaderTable.getName() as keyof typeof Constants.TOOLTIP.TOOLTIP_HEADER
		]
	)
	return (
		<div className={isForGridArea ? styles.headerForGridArea : ''}>
			<NetcurioTooltip title={headerTitle !== '' ? headerTitle : t(dataMainHeaderTable.getName())}>
				<div
					className={classNames(
						textHeader,
						dataMainHeaderTable.getSecondaryClass(),
						dataMainHeaderTable.getSelectedFieldClass(),
						styles.textHeaderOrderList,
						isForGridArea && styles.textHeaderOrderListFoGridArea
					)}
					onClick={() => {
						if (showFilters) {
                            showFilters(
                                dataMainHeaderTable.getParameterField(),
                                dataMainHeaderTable.getParameterFieldClass()
                            )
                        }
					}}
				>
					<div>{t(dataMainHeaderTable.getName())}</div>
				</div>
			</NetcurioTooltip>
		</div>
	)
}
