import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { TableRowProps } from './types'
import { parseTimestampToTimeZone, dateFormatter, Roles, URLS } from '@netcurio/frontend-common'
import Formatter from '../../utilities/formatter'
import Constants from '../../utilities/constants'
import styles from './requestForCreditMemoList.module.scss'

export const TableRow = ({ data, userRole }: TableRowProps): ReactElement => {
	const isCustomer = userRole === Roles.CUSTOMER
	const {
		created_at,
		created_by,
		customer,
		customer_reference,
		id,
		modified_by_supplier,
		status,
		supplier
	} = data

	return (
		<Link
			className={styles.rowRFCM}
			to={`${URLS.RFCM_DETAIL}?requestforcreditmemo=${id}&customer=${customer.rfc}`}
		>
			<p className={`${styles.textColumnMainTableRFCM} ${styles.rowStandardSize}`}>
				{Formatter.id(id)}
			</p>
			<p className={`${styles.textColumnMainTableRFCM} ${styles.rowStandardSize}`}>
				{customer_reference || Constants.SYMBOL.HYPHEN}
			</p>
			<p className={`${styles.textColumnMainTableRFCM} ${styles.rowStandardSize}`}>{status.value}</p>
			<p className={`${styles.textColumnMainTableRFCM} ${styles.rowStandardSize}`}>
				{isCustomer
					? created_by && `${created_by.name} ${created_by.lastname}`
					: modified_by_supplier && `${modified_by_supplier.name} ${modified_by_supplier.lastname}`}
			</p>
			<p className={`${styles.textColumnMainTableRFCM} ${styles.rowLargerSize}`}>
				{isCustomer ? supplier?.name : customer?.name}
			</p>
			<p className={`${styles.textColumnMainTableRFCM} ${styles.rowCreatedAt}`}>
				{dateFormatter.format(parseTimestampToTimeZone(created_at))}
			</p>
		</Link>
	)
}
