import gql from 'graphql-tag'

export const INVITE_SUPPLIER = gql`
	mutation inviteSupplier($email: String!) {
		inviteSupplier(email: $email) {
			code
			message
		}
	}
`
