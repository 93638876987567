import React from 'react'
import Constants from '../../../../utilities/constants'
import { UserProfileTab } from './userInformation/userProfileTab'
import { NotificationsTab } from './notificationTab/notificationsTab'

interface IUserSelectionProps {
	selectedTab: string
	cancelConfig: boolean
	stayInNotificationsTab: () => void
	leaveNotificationsTab: () => void
	changeInNotification: (value: boolean) => void
}

const TabsUserSelection = ({
	selectedTab,
	cancelConfig,
	stayInNotificationsTab,
	leaveNotificationsTab,
	changeInNotification
}: IUserSelectionProps) => {
	const selectUserScreen = () => {
		switch (selectedTab) {
			case Constants.USER_SETTINGS.USER_SETTINGS_TABS.USER_INFORMATION:
				return <UserProfileTab />
			case Constants.USER_SETTINGS.USER_SETTINGS_TABS.NOTIFICATIONS:
				return (
					<NotificationsTab
						cancelConfig={cancelConfig}
						stayInNotificationsTab={stayInNotificationsTab}
						leaveNotificationsTab={leaveNotificationsTab}
						changeInNotification={changeInNotification}
					/>
				)
		}
	}
	return selectUserScreen()
}

export default TabsUserSelection
