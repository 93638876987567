import gql from 'graphql-tag'

export const COST_CENTERS = gql`
	query getCostCenters($companyRfc: String!) {
		getCostCenters(companyRfc: $companyRfc) {
			key
			value
		}
	}
`
