import { Comment } from '@netcurio/frontend-common'
import { useEffect, useState } from 'react'
import { useClient } from '../../hooks/useClient'
import { CREATE_CREDIT_MEMO_COMMENT, CREDIT_MEMO_COMMENTS } from './queries'

export const useComments = ({
	creditMemoId,
	hasPendingMsg,
	handleError
}: {
	creditMemoId: string
	hasPendingMsg: (pending: boolean) => void
	handleError: (error: Error) => void
}) => {
	const client = useClient()
	const [creditMemoComments, setCreditMemoComments] = useState<Comment[]>([])

	const fetchComments = () => {
		client
			.query({
				query: CREDIT_MEMO_COMMENTS,
				variables: { uuid: creditMemoId },
				fetchPolicy: 'no-cache'
			})
			.then((result) => {
				setCreditMemoComments(result.data.CreditMemo.comments)
			})
			.catch(handleError)
	}
	useEffect(() => {
		fetchComments()
	}, [])

	const addNewComment = (newCommentData: Comment) => {
		const dataNewComment = {
			creditMemo: creditMemoId,
			text: newCommentData.text
		}
		client
			.mutate({
				mutation: CREATE_CREDIT_MEMO_COMMENT,
				variables: dataNewComment
			})
			.then(fetchComments)
			.catch(handleError)
			.finally(() => hasPendingMsg(false))
	}

	return {
		creditMemoComments,
		addNewComment
	}
}
