import { getSerieFolio, URLS } from '@netcurio/frontend-common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Consumption, Invoice, PurchaseOrder } from '../../types'
import Constants from '../../utilities/constants'
import Formatter from '../../utilities/formatter'
import { AssociationItemProps } from './AssociateDocumentItem'

interface AssociatedDocumentViewerProps {
	documentReferenceId: string
	customerRFC: string
	customerReference: string
	referenceType: string
	parentStatus?: string
	invoice?: Invoice
}

export const useAssociatedDocuments = ({
	documentReferenceId,
	customerRFC,
	customerReference,
	referenceType,
	parentStatus,
	invoice
}: AssociatedDocumentViewerProps) => {
	const { t } = useTranslation()
	const [associatedDocuments, setAssociatedDocuments] = useState<AssociationItemProps[]>([])

	useEffect(() => {
		const _associatedDocuments: AssociationItemProps[] = []

		const createDocumentPreset = (
			url: string,
			label: string,
			tooltip: string,
			data: string,
			disabled: boolean = false
		): AssociationItemProps => ({
			url,
			label,
			tooltip,
			data,
			disabled
		})

		if (referenceType) {
			const data = documentReferenceId
				? `${Formatter.id(documentReferenceId)} - ${customerReference || t('NAtext')}`
				: ''

			let preset: AssociationItemProps | null = null

			switch (referenceType) {
				case Constants.DOCUMENT_TYPE.PURCHASE_ORDER:
				case Constants.DOCUMENT_TYPE.CUSTOMER_PURCHASE_ORDER:
					preset = createDocumentPreset(
						`${URLS.PO_DETAIL}?order=${documentReferenceId}&customer=${customerRFC}`,
						t('id_PO'),
						t('goToPurchaseOrder'),
						data,
						!documentReferenceId
					)
					break
			}

			if (preset) {
				_associatedDocuments.push(preset)
			}
		}

		if (
			(parentStatus === Constants.STATUS.ACCOUNTED ||
				parentStatus === Constants.STATUS.CANCELED ||
				parentStatus === Constants.STATUS.VOIDED) &&
			invoice
		) {
			_associatedDocuments.push(
				createDocumentPreset(
					`${URLS.INVOICE_DETAIL}?invoice=${invoice.uuid}`,
					t('invoiceText'),
					t('goToInvoice'),
					getSerieFolio(invoice.serie, invoice.folio)
				)
			)

			if (invoice.purchase_order) {
				const purchaseOrder = invoice.purchase_order as PurchaseOrder
				_associatedDocuments.push(
					createDocumentPreset(
						`${URLS.PO_DETAIL}?order=${purchaseOrder.id}&customer=${purchaseOrder.customer.rfc}`,
						t('PURCHASE_ORDER'),
						t('goToPurchaseOrder'),
						Formatter.getInvoiceIdRelated(
							purchaseOrder.id,
							purchaseOrder.customer_reference || ''
						)
					)
				)
			}

			if (invoice.consumption) {
				const consumption = invoice.consumption as Consumption
				_associatedDocuments.push(
					createDocumentPreset(
						`${URLS.CONSUMPTION_DETAIL}?consumption=${consumption.id}&customer=${consumption.customer.rfc}`,
						t('CONSUMPTION'),
						t('goToConsumption'),
						Formatter.getInvoiceIdRelated(consumption.id, consumption.customer_reference || '')
					)
				)
			}
		}

		setAssociatedDocuments(_associatedDocuments)
	}, [referenceType, parentStatus, invoice, documentReferenceId, customerRFC, t, customerReference])

	return associatedDocuments
}
