import { t } from 'i18next'
import { Invoice } from '../../../../types'
import Constants from '../../../../utilities/constants'
import { OptionsDocumentType } from '../types'

export const defaultValuesInvoice: Invoice = {
	uuid: '',
	serie: '',
	folio: '',
	status: {
		key: '',
		value: ''
	},
	sender: {},
	receiver: {},
	reference: '',
	reference_type: '',
	purchase_order: undefined,
	consumption: undefined,
	uploaded_by: {},
	modified_by_supplier: undefined,
	modified_by_customer: undefined,
	payment_method: '',
	currency: '',
	subtotal: 0,
	iva: 0,
	total: 0,
	date: 0,
	proposed_payment_date: 0,
	payment_date: 0,
	updated_at: 0,
	created_at: 0,
	error: undefined,
	items: [],
	comments: [],
	invoice_documents: []
}

export const optionsDocumentsType: Array<OptionsDocumentType> = [
	{
		label: t(Constants.DOCUMENT_TYPE.PURCHASE_ORDER),
		value: Constants.DOCUMENT_TYPE.PURCHASE_ORDER
	},
	{
		label: t(Constants.DOCUMENT_TYPE.CONSUMPTION),
		value: Constants.DOCUMENT_TYPE.CONSUMPTION
	},
	{
		label: t(Constants.DOCUMENT_TYPE.STANDALONE),
		value: Constants.DOCUMENT_TYPE.STANDALONE
	}
]
