import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const SEARCH_PURCHASE_ORDERS_FOR_INVOICE: DocumentNode = gql`
	query SearchPurchaseOrdersForInvoice($search_text: String!, $customer: ID!) {
		SearchPurchaseOrdersForInvoice(search_text: $search_text, customer: $customer) {
			id
			customer_reference
			customer {
				rfc
			}
			items {
				code
				name
				position
				unit_price
				unit
				pending_quantity_to_invoice
				ean
				percent_tolerance
				unlimited_tolerance
				requires_gr_confirmation
				confirmed_amount
				requested_amount
			}
		}
	}
`
