import { Amplify } from 'aws-amplify'

export const initializeAmplify = (region: string, poolid: string, clientid: string) => {
	Amplify.configure({
		aws_project_region: region,
		aws_cognito_region: region,
		aws_user_pools_id: poolid,
		aws_user_pools_web_client_id: clientid
	})
}
