import gql from 'graphql-tag'

export const INVOICES_BY_UUID_OR_SERIE_FOLIO = gql`
	query InvoicesByUUIDOrSerieFolio($searchText: String!, $sender: String!, $limit: Int) {
		InvoicesByUUIDOrSerieFolio(searchText: $searchText, sender: $sender, limit: $limit) {
			uuid
			serie
			folio
			currency
			subtotal
			total
			proposed_payment_date
		}
	}
`

export const CREATE_REQUEST_FOR_CREDIT_MEMO = gql`
	mutation createRequestForCreditMemo(
		$supplier: String!
		$request_reason: String!
		$invoice: ID!
		$items: [RequestForCreditMemoItemInput!]!
		$comment: CommentInput
	) {
		createRequestForCreditMemo(
			supplier: $supplier
			request_reason: $request_reason
			invoice: $invoice
			items: $items
			comment: $comment
		) {
			id
			message
		}
	}
`
