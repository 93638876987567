import { getCompany } from '@netcurio/frontend-common'
import {
	FileViewerModal,
	NetcurioButton,
	NetcurioIcons,
	UploadDocument,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import React, { useEffect, useState } from 'react'
import { Translation, useTranslation } from 'react-i18next'
import { useEnvironment } from '../../../../hooks/useEnvironment'
import { FileViewerObject } from '../../../../types'
import { connection } from '../../../../utilities/connection'
import Constants from '../../../../utilities/constants'
import { showErrorComponent } from '../../../../utilities/errorCode'
import { expiredToken } from '../../../../utilities/expiredToken'
import fileToBase64 from '../../../../utilities/fileToBase64'
import { AdministrationConsoleErrorModal } from '../../Modals/AdministrationConsoleErrorModal'
import styles from './companyDocuments.module.scss'
import * as queries from './queries'

interface Props {
	chooseMainHeaderButton: (button: string) => void
}

interface InformationFile {
	[index: string]: {
		code: string
		error: boolean
		file?: File
		uuid?: string
	}
}

export default function CompanyDocuments({ chooseMainHeaderButton }: Props) {
	const { environment } = useEnvironment()
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }
	const { COMPANIES_FOLDER } = Constants.FOLDER
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const { t } = useTranslation()
	const client = connection()
	const company = getCompany()
	let isMounted = true
	const [errorCode, setErrorCode] = useState<string>('')
	const [documents, setDocuments] = useState<InformationFile>({
		charter: { code: 'INCORPORATION_ACT', error: false },
		registration: { code: 'RFC_REGISTRATION', error: false },
		attorney: { code: 'ATTORNEY', error: false },
		compliance: { code: 'COMPLIANCE', error: false },
		ine: { code: 'ID_CARD', error: false },
		address: { code: 'PROOF_ADDRESS', error: false },
		account: { code: 'BANK_STATEMENT', error: false },
		servicesApply: { code: 'SPECIALTY_SERVICES', error: false },
		bankFormApply: { code: 'SIGNED_BANK_DATA_FORM', error: false },
		supplierDataFormat: { code: 'SUPPLIER_DATA_FORMAT', error: false },
		priceList: { code: 'QUOTATION_PRICE_LIST', error: false },
		operationNotice: { code: 'OPERATION_NOTICE', error: false },
		cofeprisHealthRecords: { code: 'COFEPRIS_HEALTH_RECORDS', error: false },
		authorizedDistributionLetter: { code: 'AUTHORIZED_DISTRIBUTION_LETTER', error: false },
		healthLicenseControlledMedicine: { code: 'HEALTH_LICENSE_CONTROLLED_MEDICINE', error: false },
		noticeFromHealthManager: { code: 'NOTICE_HEALTH_MANAGER', error: false },
		other: { code: 'OTHER', error: false }
	})
	const [fileViewer, setFileViewer] = useState<FileViewerObject>({
		open: false,
		titleText: t('documentText'),
		fileSrc: ''
	})

	useEffect(() => {
		chooseMainHeaderButton('')
		setCompanyDocuments()
		return () => {
			isMounted = false
		}
	}, [])

	function setCompanyDocuments(keyRemoveFile?: string) {
		showLoadingSpinner()
		client
			.query({
				query: queries.CompanyDocuments,
				variables: {
					rfc: company.rfc
				}
			})
			.then((result) => {
				hideLoadingSpinner()
				if (isMounted) {
					const newFilesState = { ...documents }
					for (const key in newFilesState) {
						if (keyRemoveFile === key) newFilesState[key].file = undefined
						const document = result.data.Company.supportingDocuments.filter(
							(doc: { document_type: string; link: string }) =>
								doc.document_type === newFilesState[key].code
						)[0]?.link
						if (document) newFilesState[key].uuid = document
					}
					setDocuments(newFilesState)
				}
			})
			.catch((error) => handleError(error))
	}

	function handleUpload(key: string) {
		if (documents[key]?.file) {
			showLoadingSpinner()
			fileToBase64(documents[key]?.file)
				.then((res: string) => {
					client
						.mutate({
							mutation: queries.uploadDocument,
							variables: {
								file: res.split('base64,')[1],
								key: documents[key].code
							}
						})
						.then(() => {
							setCompanyDocuments(key)
						})
						.catch((err) => handleError(err))
				})
				.catch((err) => handleError(err))
		}
	}

	function handleError(error: ErrorEvent) {
		if (isMounted) {
			hideLoadingSpinner()
			const newErrorCode = showErrorComponent(error)
			expiredToken(newErrorCode)
			if (!expiredToken(newErrorCode)) {
				setErrorCode(newErrorCode)
			}
		}
	}

	function setFile(uuid: string) {
		const fileName = `${uuid.toLowerCase()}.pdf`
		const fileSrc = `${S3_BUCKET}${COMPANIES_FOLDER}/${fileName}`
		setFileViewer((state) => ({ ...state, fileSrc, open: true }))
	}

	const closePDFModal = () => setFileViewer((state) => ({ ...state, open: false }))

	return (
		<Translation>
			{(t) => (
				<div className={styles.companyDocumentsContainer}>
					<div className={styles.companyDocumentsTile}>
						<p>{t('fileUploadTitle')}</p>
						<span>{t('fileUploadSubtitle')}</span>
					</div>
					<div className={styles.companyDocumentsContent}>
						{Object.keys(documents).map((key) => (
							<div className={styles.companyDocumentsUploadRow} key={key}>
								<UploadDocument
									id={`${key}Field`}
									label={t(`${key}NewCompany`)}
									accept={Constants.MIME_TYPES.PDF}
									errorMessage={t(documents[key].error ? 'emptyFileError' : '')}
									fileName={documents[key]?.file?.name || ''}
									onFileChange={(file: File) =>
										setDocuments({
											...documents,
											[key]: { ...documents[key], file, error: false }
										})
									}
								/>
								<NetcurioButton
									variant={'outlined'}
									size={'small'}
									sx={{ height: '3rem', marginTop: '0.5rem' }}
									onClick={() => handleUpload(key)}
									disabled={documents[key].error || !documents[key]?.file}
									startIcon={<NetcurioIcons.Publish />}
								>
									<p>{t(documents[key].uuid ? 'updateFile' : 'uploadFile')}</p>
								</NetcurioButton>
								{documents[key].uuid && (
									<NetcurioButton
										variant={'outlined'}
										size={'small'}
										sx={{ height: '3rem', margin: '0.5rem 0 0 1rem' }}
										onClick={() => setFile(documents[key].uuid)}
										endIcon={<NetcurioIcons.Visibility />}
									>
										<p>{t('viewDocument')}</p>
									</NetcurioButton>
								)}
							</div>
						))}
					</div>
					<FileViewerModal onClose={closePDFModal} {...fileViewer} />
					<AdministrationConsoleErrorModal open={!!errorCode} errorCode={errorCode} />
				</div>
			)}
		</Translation>
	)
}
