export default {
	TOOLTIP_COLUMN_FILTER: {
		id_PO: 'id_PO',
		status_PO: 'status_text',
		branch_office_PO: 'branch_office_PO',
		supplier_PO: 'supplier_PO',
		customer_PO: 'customer_text',
		created_at_PO: 'created_at_PO',
		total_PO: 'total_PO',
		seriefolio_IN: 'serie_folio',
		status_IN: 'status_IN',
		proposed_payment_date_IN: 'proposed_payment_date_IN',
		receiver_IN: 'receiver_Tooltip',
		sender_IN: 'sender_Tooltip',
		created_at_IN: 'created_at_IN',
		total_IN: 'total_amount',
		reference_IN: 'reference_IN',
		payment_complement_IN: 'payment_complement_IN',
		id_GR: 'id_GR',
		created_at_GR: 'created_at_GR',
		created_by_GR: 'created_by_GR',
		purchase_order_GR: 'purchase_order_GR',
		supplier_GR: 'supplier_GR',
		seriefolio_PC: 'serie_folio',
		date_PC: 'date_PC',
		receiver_PC: 'receiver_Tooltip',
		sender_PC: 'sender_Tooltip',
		created_at_PC: 'created_at_PC',
		id_RFQ: 'id_RFQ',
		status_RFQ: 'status_RFQ',
		branch_office_RFQ: 'branch_office_RFQ',
		supplier_RFQ: 'supplier_RFQ',
		created_at_RFQ: 'created_at_RFQ',
		created_by_RFQ: 'created_by_RFQ',
		customer_RFQ: 'customer_RFQ',
		email_USERS: 'email_USERS',
		name_lastname_USERS: 'name_lastname_USERS',
		status_USERS: 'status_USERS',
		created_at_USERS: 'created_at_USERS',
		created_by_USERS: 'created_by_USERS',
		id_PRODUCTS: 'id_PRODUCTS',
		description_PRODUCTS: 'description_PRODUCTS',
		currency_PRODUCTS: 'currency_PRODUCTS',
		unit_price_PRODUCTS: 'unit_price_PRODUCTS',
		unit_PRODUCTS: 'unit_PRODUCTS',
		is_active_PRODUCTS: 'is_active_PRODUCTS',
		customer_reference_PO: 'customer_reference_PO',
		type_PO: 'type_PO',
		id_CONSUMPTIONS: 'idText',
		branch_office_CONSUMPTIONS: 'branch_office',
		created_by_CONSUMPTIONS: 'createdByText',
		created_at_CONSUMPTIONS: 'creationDate',
		is_admin_USERS: 'is_admin_USERS',
		customer_CONSUMPTIONS: 'customer_CONSUMPTIONS',
		id_REQUEST_FOR_CREDIT_MEMOS: 'billText',
		status_REQUEST_FOR_CREDIT_MEMOS: 'status_text',
		created_by_REQUEST_FOR_CREDIT_MEMOS: 'createdByText',
		supplier_REQUEST_FOR_CREDIT_MEMOS: 'supplierText',
		created_at_REQUEST_FOR_CREDIT_MEMOS: 'creationDate',
		modified_by_supplier_REQUEST_FOR_CREDIT_MEMOS: 'editBy',
		customer_REQUEST_FOR_CREDIT_MEMOS: 'customer_text',
		seriefolio_CREDIT_MEMOS: 'serie_folio',
		sender_CREDIT_MEMOS: 'sender_Tooltip',
		created_at_CREDIT_MEMOS: 'creationDate',
		total_CREDIT_MEMOS: 'total_amount',
		reference_CREDIT_MEMOS: 'associatedInvoice',
		receiver_CREDIT_MEMOS: 'receiver_Tooltip',
		customer_rfc_COMPANIES: 'customer_rfc_COMPANIES',
		supplier_rfc_COMPANIES: 'supplier_rfc_COMPANIES',
		customer_COMPANIES: 'customer_COMPANIES',
		supplier_COMPANIES: 'supplier_COMPANIES',
		payment_days_COMPANIES: 'payment_days_COMPANIES',
		created_at_COMPANIES: 'created_at_COMPANIES',
		id_ENROLLMENTS: 'idEnrollmentENROLLMENTS',
		status_ENROLLMENTS: 'stateEnrollmentENROLLMENTS',
		created_at_ENROLLMENTS: 'createdEnrollmentENROLLMENTS',
		customer_ENROLLMENTS: 'nameEnrollmentENROLLMENTS',
		customer_rfc_ENROLLMENTS: 'rfcEnrollmentENROLLMENTS'
	},
	TOOLTIP_HEADER: {
		typeOrderHeader: 'typeTooltip',
		customerReferenceOrderHeader: 'customerReferenceTooltip',
		companySocialNameText: 'companySocialNameTextTooltip',
		companyRFC: 'companyRFCTooltip',
		registrationTitle: 'registrationTitleTooltip'
	}
}
