import React, { FC, useMemo } from 'react'
import styles from '../requestForQuotation.module.scss'
import { RequestForQuotation } from '../types'
import { useTranslation } from 'react-i18next'
import UserInformation from '../../../utilities/userInformation'
import { NetcurioTooltip } from '@netcurio/frontend-components'
import { dateFormatter, parseTimestampToTimeZone, Roles } from '@netcurio/frontend-common'
import Formatter from '../../../utilities/formatter'
import classNames from 'classnames'

interface TrackInformationProps {
	informationRFQ: RequestForQuotation
}

const userRole: string = UserInformation.getCompanyRole()
const userCreateInfoByRole: string = userRole === Roles.CUSTOMER ? 'createByText' : 'editBy'
const userInfoByRoleCompanyName: string = userRole === Roles.CUSTOMER ? 'supplier_RFQ' : 'customer_RFQ'
const userInfoByRoleCompanyRFC: string = userRole === Roles.CUSTOMER ? 'supplierRfc' : 'rfcCustomerText'

export const TrackInformation: FC<TrackInformationProps> = ({ informationRFQ }) => {
	const { t } = useTranslation()
	const userData = useMemo((): {
		userName: string
		userEmail: string
	} => {
		const userInfo = {
			userName: '',
			userEmail: ''
		}
		if (userRole === Roles.CUSTOMER && informationRFQ.created_by) {
			userInfo.userName = informationRFQ.created_by.name + ' ' + informationRFQ.created_by.lastname
			userInfo.userEmail = informationRFQ.created_by.email
		} else if (informationRFQ.modified_by_supplier) {
			userInfo.userName =
				informationRFQ.modified_by_supplier.name + ' ' + informationRFQ.modified_by_supplier.lastname
			userInfo.userEmail = informationRFQ.modified_by_supplier.email
		} else {
			userInfo.userName = 'pendingText'
		}

		return userInfo
	}, [informationRFQ])

	const companyData = useMemo((): {
		companyName: string
		companyRFC: string
	} => {
		const companyInfo = {
			companyName: '',
			companyRFC: ''
		}
		if (userRole === Roles.CUSTOMER && informationRFQ.supplier) {
			companyInfo.companyName = informationRFQ.supplier.name
			companyInfo.companyRFC = informationRFQ.supplier.rfc
		} else if (informationRFQ.customer) {
			companyInfo.companyName = informationRFQ.customer.name
			companyInfo.companyRFC = informationRFQ.customer.rfc
		}

		return companyInfo
	}, [informationRFQ])

	return (
		<div className={styles.containerRFQ}>
			<div className={styles.containerTrackRFQ}>
				<div className={styles.descriptionContainRFQDetails}>
					<div className={styles.titleDescriptionRFQDetails}>{t(userCreateInfoByRole)}</div>
					<NetcurioTooltip title={userData.userEmail} placement="right">
						<div className={styles.descriptionRFQDetails}>
							{userData.userName === 'pendingText' ? t('pendingText') : userData.userName}
						</div>
					</NetcurioTooltip>
				</div>
				<div className={styles.descriptionContainRFQDetails}>
					<p className={styles.titleDescriptionRFQDetails}>{t(userInfoByRoleCompanyName)}</p>
					<p className={styles.descriptionRFQDetails}>{companyData.companyName}</p>
				</div>
				<div className={styles.descriptionContainRFQDetails}>
					<p className={styles.titleDescriptionRFQDetails}>{t(userInfoByRoleCompanyRFC)}</p>
					<p className={styles.descriptionRFQDetails}>{companyData.companyRFC}</p>
				</div>
				<div className={styles.descriptionContainRFQDetails}>
					<p className={styles.titleDescriptionRFQDetails}>{t('effectiveDateText')}</p>
					<p className={styles.descriptionRFQDetails}>
						{informationRFQ.expiration_date
							? dateFormatter.format(parseTimestampToTimeZone(informationRFQ.expiration_date))
							: 'N/A'}
					</p>
				</div>
				<div className={styles.descriptionContainRFQDetails}>
					<p className={styles.titleDescriptionRFQDetails}>{t('branch_office_PO')}</p>
					<p className={styles.descriptionRFQDetails}>{informationRFQ.branch_office}</p>
				</div>
				<div className={styles.descriptionContainRFQDetails}>
					<p className={styles.titleDescriptionRFQDetails}>{t('addressDelivery')}</p>
					<p className={styles.descriptionRFQDetails}>
						{informationRFQ.delivery_address_line_1 +
							' ' +
							(informationRFQ.delivery_address_line_2 ?? '') +
							' ' +
							informationRFQ.delivery_address_state +
							' ' +
							informationRFQ.delivery_address_postal_code +
							' ' +
							informationRFQ.delivery_address_country}
					</p>
				</div>
				<div className={styles.descriptionContainRFQDetails}>
					<p className={styles.titleDescriptionRFQDetails}>{t('referenceID')}</p>
					<p className={styles.descriptionRFQDetails}>
						{Formatter.nullValueFormatting(informationRFQ.customer_reference)}
					</p>
				</div>
				<div className={styles.descriptionContainRFQDetails}>
					<p className={styles.titleDescriptionRFQDetails}>{t('incoterm')}</p>
					<p className={styles.descriptionRFQDetails}>
						{Formatter.nullValueFormatting(
							informationRFQ?.incoterm?.code && informationRFQ?.incoterm?.description
								? informationRFQ.incoterm.code + ' - ' + informationRFQ.incoterm.description
								: null
						)}
					</p>
				</div>
				<div
					className={classNames(styles.descriptionContainRFQDetails, styles.titleDescriptionMedium)}
				>
					<p className={styles.titleDescriptionRFQDetails}>{t('paymentTerm')}</p>
					<p className={styles.descriptionRFQDetails}>
						{Formatter.nullValueFormatting(
							informationRFQ?.payment_term?.code && informationRFQ?.payment_term?.description
								? informationRFQ.payment_term.code +
										' - ' +
										informationRFQ.payment_term.description
								: null
						)}
					</p>
				</div>
				<div className={styles.descriptionContainRFQDetails}>
					<p className={styles.titleDescriptionRFQDetails}>{t('userReference')}</p>
					<p className={styles.descriptionRFQDetails}>
						{Formatter.nullValueFormatting(informationRFQ.user_reference)}
					</p>
				</div>
			</div>
		</div>
	)
}
