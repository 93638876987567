import { gql } from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const PURCHASE_ORDERS_CUSTOMER: DocumentNode = gql`
	query PurchaseOrders(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		PurchaseOrders(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			list {
				id
				status {
					key
					value
				}
				branch_office
				supplier {
					rfc
					name
				}
				customer {
					rfc
				}
				total
				created_at
				type {
					key
					value
				}
				customer_reference
			}
			total
		}
	}
`

export const PURCHASE_ORDERS_SUPPLIER: DocumentNode = gql`
	query PurchaseOrders(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		PurchaseOrders(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			list {
				id
				status {
					key
					value
				}
				branch_office
				customer {
					name
					rfc
				}
				total
				created_at
				type {
					key
					value
				}
				customer_reference
			}
			total
		}
	}
`
