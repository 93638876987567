import React, { FC } from 'react'
import { Roles, Status } from '@netcurio/frontend-common'
import { useTranslation } from 'react-i18next'
import { NetcurioButton } from '@netcurio/frontend-components'
import { NetcurioIcons } from '../../../../../components/src/NetcurioIcons/NetcurioIcons'
import Constants from '../../../utilities/constants'
import styles from '../requestForQuotation.module.scss'

interface ButtonsRFQDetailProps {
	userRole: string
	statusRFQ?: Status
	openNewQuotation(): void
	actionButton(button: string): void
}
export const ButtonsRFQDetail: FC<ButtonsRFQDetailProps> = ({
	userRole,
	statusRFQ,
	openNewQuotation,
	actionButton
}) => {
	const { t } = useTranslation()
	const isSupplier: boolean = userRole === Roles.SUPPLIER
	const isCustomer: boolean = userRole === Roles.CUSTOMER
	const showCreateButton: boolean =
		statusRFQ && (statusRFQ.key === Constants.STATUS.NEW || statusRFQ.key === Constants.QUOTATION.QUOTED)
	const showRejectButton: boolean = statusRFQ && statusRFQ.key === Constants.STATUS.NEW
	const showCloseQuotationButton: boolean =
		statusRFQ && (statusRFQ.key === Constants.STATUS.NEW || statusRFQ.key === Constants.QUOTATION.QUOTED)

	return (
		<div className={styles.buttonsPositon}>
			{isSupplier && showCreateButton && (
				<NetcurioButton
					variant="outlined"
					onClick={openNewQuotation}
					color="primary"
					endIcon={<NetcurioIcons.Check />}
				>
					{t('createQuotationText')}
				</NetcurioButton>
			)}
			{isSupplier && showRejectButton && (
				<NetcurioButton
					variant="text"
					onClick={() => actionButton('rejectRFQ')}
					color="secondary"
					endIcon={<NetcurioIcons.HighlightOff />}
				>
					{t('rejectRequestText')}
				</NetcurioButton>
			)}
			{isCustomer && showCloseQuotationButton && (
				<NetcurioButton
					variant="text"
					color="secondary"
					onClick={() => actionButton('closeRFQ')}
					endIcon={<NetcurioIcons.HighlightOff />}
				>
					{t('closeRequest')}
				</NetcurioButton>
			)}
		</div>
	)
}
