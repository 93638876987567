import { useEffect, useState } from 'react'

/**
 * useDebounce <T>
 * @param value <T>: value to be debounced
 * @param delay <number>: Timeout for the debounced value
 * @returns debouncedValue <T>
 * @usage const debounceInput = useDebounce<string>(input, 1000)
 * if the input still changing, timeout restart,
 * if input isn't changed in 1000 miliseconds, useDebounce returns input to debounceInput
 */
export function useDebounce<T>(value: T, delay: number) {
	const [debouncedValue, setDebouncedValue] = useState<T>(value)
	useEffect(() => {
		const handler = setTimeout(() => setDebouncedValue(value), delay)
		return () => clearTimeout(handler)
	}, [value, delay])
	return debouncedValue
}
