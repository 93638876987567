import gql from 'graphql-tag'
export const EXECUTE_MANUAL_AUTHORIZATION_INVOICE = gql`
	query ExecuteValidations($uuid: ID!) {
		ExecuteValidations(uuid: $uuid) {
			passAllValidations
			previousValidation {
				accountingPeriodValidation {
					value
					message
				}
				currencyValidation {
					value
					positions
				}
				priceValidation {
					value
					positions
				}
				taxValidation {
					value
					positions
				}
				quantityValidation {
					value
					positions
				}
				missingMerchandiseValidation {
					value
					positions
				}
				satValidation {
					value
					message
				}
				amountValidation {
					value
				}
			}
			currentValidation {
				accountingPeriodValidation {
					value
					message
				}
				currencyValidation {
					value
					positions
				}
				priceValidation {
					value
					positions
				}
				taxValidation {
					value
					positions
				}
				quantityValidation {
					value
					positions
				}
				missingMerchandiseValidation {
					value
					positions
				}
				satValidation {
					value
					message
				}
				amountValidation {
					value
				}
			}
		}
	}
`

export const EXECUTE_MANUAL_AUTHORIZATION_STANDALONE_ADVANCE_INVOICE = gql`
	query ExecuteValidations($uuid: ID!) {
		ExecuteValidations(uuid: $uuid) {
			passAllValidations
			previousValidation {
				accountingPeriodValidation {
					value
					message
				}
				satValidation {
					value
					message
				}
			}
			currentValidation {
				accountingPeriodValidation {
					value
					message
				}
				satValidation {
					value
					message
				}
			}
		}
	}
`
