import { NetcurioGrid } from '@netcurio/frontend-components'
import { t } from 'i18next'
import React from 'react'
import { DetailField } from '../../DetailField/DetailField'
import styles from './StandaloneDocumentManuallyAuthModal.module.scss'

interface AuthorizationInfoProps {
	hasValues: boolean
	authorizedBy: string
	authorizedDate: string
}

export const AuthorizationInfo = ({ hasValues, authorizedBy, authorizedDate }: AuthorizationInfoProps) => {
	if (!hasValues) {
		return null
	}

	return (
		<NetcurioGrid display="flex" gap="12px" className={styles.authorizationInfoContainer}>
			<DetailField
				title={t('authorizeBy')}
				information={authorizedBy}
				tooltip={t('dateLastModification') ?? ''}
			/>
			<DetailField
				title={t('dateLastModification')}
				information={authorizedDate}
				tooltip={t('dateLastModification') ?? ''}
			/>
		</NetcurioGrid>
	)
}
