import React from 'react'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import { useTranslation } from 'react-i18next'
import { ListBatchesGoodsReceiptDetail } from './ListBatchesGoodsReceiptDetail'

interface BatchesGoodsReceiptDetailModalProps {
	open: boolean
	closeBatchModal(): void
	batchesInfo: Array<any>
	unit: string
}

export const BatchesGoodsReceiptDetailModal = ({
	open,
	closeBatchModal,
	batchesInfo,
	unit
}: BatchesGoodsReceiptDetailModalProps) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			open={open}
			titleText={t('batchesText')}
			actionButtons={
				<>
					<NetcurioButton onClick={closeBatchModal}>{t('closeText')}</NetcurioButton>
				</>
			}
		>
			<ListBatchesGoodsReceiptDetail batchesInfo={batchesInfo} unit={unit} />
		</NetcurioDialog>
	)
}
