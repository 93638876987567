import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './goodsReceiptList.module.scss'

export function objectHeaderArray(): Array<tableFillHeader> {
	const idHeader: tableFillHeader = new tableFillHeader('idGRHeader', styles.headerId, '', 'id', 'text')
	const customerReferenceOrderColumn: tableFillHeader = new tableFillHeader(
		'customerReferenceGR',
		styles.headerCustomerReference,
		'',
		'customer_reference',
		'text'
	)
	const dateHeader: tableFillHeader = new tableFillHeader(
		'dateGRHeader',
		styles.headerDate,
		'',
		'created_at',
		'date'
	)
	const createdByHeader: tableFillHeader = new tableFillHeader(
		'createdByGRHeader',
		styles.headerCreatedBy,
		'',
		'created_by',
		'text'
	)
	const purchaseOrderHeader: tableFillHeader = new tableFillHeader(
		'poRelatedGRHeader',
		styles.headerPoRelated,
		'',
		'purchase_order',
		'text'
	)
	const supplierHeader: tableFillHeader = new tableFillHeader(
		'supplierGRHeader',
		styles.headerSupplier,
		'',
		'supplier',
		'text'
	)

	const objectHeaderArrayCustomer: Array<tableFillHeader> = [
		idHeader,
		customerReferenceOrderColumn,
		dateHeader,
		createdByHeader,
		purchaseOrderHeader,
		supplierHeader
	]
	return objectHeaderArrayCustomer
}
