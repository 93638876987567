import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const SEARCH_CONSUMPTIONS_FOR_INVOICE: DocumentNode = gql`
	query ConsumptionsForInvoice(
		$limit: Int
		$sort_field: String
		$sort_order: String
		$search_text: String!
		$customer: ID!
	) {
		ConsumptionsForInvoice(
			limit: $limit
			sort_field: $sort_field
			sort_order: $sort_order
			search_text: $search_text
			customer: $customer
		) {
			id
			customer_reference
			customer {
				rfc
			}
			items {
				code
				description
				position
				unit_price
				pending_quantity_to_invoice
			}
		}
	}
`
