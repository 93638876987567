import { TypesDataTable, tableFillSelectionFilter } from '@netcurio/frontend-common'
import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	ResultNotFound,
	TagsFilter,
	TextInputFilter,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { Dayjs } from 'dayjs'
import React, { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useState } from 'react'
import { HeaderTable } from '../../components/HeaderTable/HeaderTable'
import Constants from '../../utilities/constants'
import listHelper from '../../utilities/listHelper'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './requestForCreditMemoList.module.scss'
import { objectHeaderArray, objectStatusFilterArray } from './tableParameterArray'
import { TableRow } from './tableRow'
import { MainTableProps, StyleTable } from './types'

export const RequestForCreditMemoTable = ({
	userRole,
	filterContainerBar,
	dataRequestForCreditMemo,
	closeFilterContainerBar,
	dataFiltersArray,
	deleteFilterActive,
	filtersOfTypeStatus,
	filteringRequestForCreditMemo,
	deleteRange,
	totalRows,
	rowsActual,
	setActivePagination,
	showBarLoader,
	setShowBarLoader
}: MainTableProps): ReactElement => {
	const { showLoadingSpinner } = useNetcurioLoader()
	const heightRow = 40
	const [resultNotFound, setResultNotFound] = useState<boolean>(false)
	const [fillFields, setFillFields] = useState<Array<tableFillHeader>>([])
	const [styleMainTable, setStyleMainTable] = useState<StyleTable>({
		mainContainerHeight: undefined,
		mainTableHeight: undefined,
		activeHeight: true
	})
	const [TypesDataTable, setDataTable] = useState<TypesDataTable>({
		textHeader: styles.textHeaderListRFCM,
		textFilter: 'display-none',
		statusFilter: 'display-none',
		dateFilter: 'display-none',
		actualField: 'created_at',
		fillStatusInputFilter: objectStatusFilterArray(),
		filterValue: undefined,
		deleteRange: false,
		initDate: undefined,
		finalDate: undefined,
		initRange: undefined,
		finalRange: undefined,
		initDateErrorText: '',
		finalDateErrorText: '',
		initCreatedDateErrorText: '',
		finalCreatedDateErrorText: '',
		minAmount: undefined,
		maxAmount: undefined,
		minRange: undefined,
		maxRange: undefined
	})

	useEffect(() => {
		setShowBarLoader(false)
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: window.innerHeight - Constants.SIZE.MAIN_COMPONENTS_HEIGHT,
			mainContainerHeight: window.innerHeight - Constants.SIZE.HEADERS_HEIGHT
		}))
	}, [])

	useEffect(() => {
		setFillFields(objectHeaderArray(userRole))
	}, [userRole])

	useEffect(() => {
		if (totalRows && rowsActual)
			window.document
				.getElementsByClassName('tableInformation')[0]
				.addEventListener('scroll', handlePagination)
		return () => {
			const element: Element = window.document.getElementsByClassName('tableInformation')[0]
			if (element) element.removeEventListener('scroll', handlePagination)
		}
	}, [totalRows, rowsActual])

	useEffect(() => {
		if (deleteRange && TypesDataTable.deleteRange && TypesDataTable.actualField === 'created_at')
			setDataTable((prevState) => ({
				...prevState,
				deleteRange: false,
				initRange: undefined,
				finalRange: undefined
			}))

		if (filtersOfTypeStatus.status.dataFilter === 'return') {
			if (TypesDataTable.fillStatusInputFilter.length !== objectStatusFilterArray().length) {
				setDataTable((prevState) => ({
					...prevState,
					fillStatusInputFilter: objectStatusFilterArray()
				}))
			}
			if (TypesDataTable.filterValue !== undefined) {
				setDataTable((prevState) => ({
					...prevState,
					filterValue: undefined
				}))
			}
		} else if (
			filtersOfTypeStatus.status.dataFilter !== '' &&
			filtersOfTypeStatus.status.dataFilter &&
			!TypesDataTable.fillStatusInputFilter.some(
				(selectionFilter) =>
					selectionFilter.getSelectedParameter() === filtersOfTypeStatus.status.dataFilter
			)
		) {
			setDataTable((prevState) => ({
				...prevState,
				fillStatusInputFilter: [
					...prevState.fillStatusInputFilter,
					new tableFillSelectionFilter(filtersOfTypeStatus.status.dataFilter)
				]
			}))
		}

		if (filterContainerBar && styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight - heightRow,
				mainContainerHeight: prevState.mainContainerHeight - heightRow,
				activeHeight: false
			}))
		} else if (!filterContainerBar && !styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + heightRow,
				mainContainerHeight: prevState.mainContainerHeight + heightRow,
				activeHeight: true
			}))
		}

		setResultNotFound(totalRows < 1)
		listHelper.updateHeaderBottomRadius(styles.tableHeaderClicked)
	}, [
		deleteRange,
		filtersOfTypeStatus.status.dataFilter,
		totalRows,
		filterContainerBar,
		TypesDataTable.textHeader,
		TypesDataTable.deleteRange,
		TypesDataTable.actualField,
		styleMainTable.activeHeight
	])

	const handlePagination = () => {
		listHelper.handlePagination(
			'tableInformation',
			totalRows,
			rowsActual,
			undefined,
			setActivePagination,
			setShowBarLoader
		)
	}

	const showFilters = (field: string, fieldStyle: string) => {
		const filterActual: string = fieldStyle + 'Filter'
		let classActualField = 'display-none'
		let activeFilter = ''
		switch (filterActual) {
			case 'dateFilter':
				classActualField = `request-for-credit-memo-${fieldStyle}-filter`
				activeFilter = TypesDataTable.dateFilter
				break
			case 'statusFilter':
				classActualField = `request-for-credit-memo-${fieldStyle}-filter`
				activeFilter = TypesDataTable.statusFilter
				break
			case 'textFilter':
				classActualField = `display-block`
				activeFilter = TypesDataTable.textFilter
				break
		}
		if (activeFilter === 'display-none') {
			const verifyMainContainer: boolean =
				styleMainTable.mainTableHeight >
				window.innerHeight - (TypesDataTable.actualField === field || filterContainerBar ? 155 : 115)

			if (verifyMainContainer)
				setStyleMainTable((prevState) => ({
					...prevState,
					mainTableHeight: prevState.mainTableHeight - heightRow,
					mainContainerHeight: prevState.mainContainerHeight - heightRow
				}))
			setDataTable((prevState) => ({
				...prevState,
				initDate: undefined,
				finalDate: undefined,
				filterValue: '',
				initRange: undefined,
				finalRange: undefined,
				dateFilter: 'display-none',
				statusFilter: 'display-none',
				textFilter: 'display-none',
				[filterActual]: classActualField,
				actualField: field,
				initDateErrorText: '',
				finalDateErrorText: '',
				textHeader: styles.textHeaderListOnClick
			}))
		} else if (TypesDataTable.actualField === field) {
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + heightRow,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))
			setDataTable((prevState) => ({
				...prevState,
				filterValue: '',
				[filterActual]: 'display-none',
				textHeader: styles.textHeaderListRFCM,
				initRange: undefined,
				finalRange: undefined
			}))
		} else {
			setDataTable((prevState) => ({
				...prevState,
				actualField: field,
				filterValue: ''
			}))
		}
		listHelper.updateHeaderColors(fillFields, field, styles.tableHeaderClicked)
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		filterValue: string,
		objectRange?: { [key: string]: number | Dayjs }
	) => {
		showLoadingSpinner()
		let initRange: number | Dayjs = TypesDataTable.initRange
		let finalRange: number | Dayjs = TypesDataTable.finalRange
		if (objectRange) {
			if (objectRange.initRange) initRange = objectRange.initRange
			if (objectRange.finalRange) finalRange = objectRange.finalRange
		}
		if (sortField !== '') {
			filteringRequestForCreditMemo(
				0,
				sortField,
				order,
				fieldFilter,
				undefined,
				undefined,
				undefined,
				undefined
			)
		} else {
			filteringRequestForCreditMemo(
				0,
				sortField,
				order,
				fieldFilter,
				filterValue,
				initRange,
				finalRange,
				undefined
			)
		}
		setDataTable((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const selectionFilter = (status: string, position: number) => {
		if (filtersOfTypeStatus.status.numSelectOptions < 5) {
			callDataList('', '', TypesDataTable.actualField, status)
			TypesDataTable.fillStatusInputFilter.splice(position, 1)
		}
	}

	const closeFilter = () => {
		setDataTable((prevState) => ({
			...prevState,
			dateFilter: 'display-none',
			statusFilter: 'display-none',
			textFilter: 'display-none',
			textHeader: styles.tableHeaderClicked,
			filterValue: undefined,
			initRange: undefined,
			finalRange: undefined
		}))
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: prevState.mainTableHeight + heightRow,
			mainContainerHeight: prevState.mainContainerHeight + heightRow
		}))
	}

	const handleDateRangeFilterChange = (evt: Dayjs, field: string) => {
		const evtValue: Dayjs = evt
		const dateActual: string = field + 'Date'
		const rangeActual: string = field + 'Range'
		if (evtValue !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', TypesDataTable.actualField, undefined, {
					[rangeActual]: evtValue
				})
				setDataTable((prevState) => ({
					...prevState,
					[dateActual]: evtValue,
					[rangeActual]: evtValue,
					[dateActual + 'StyleErrorText']: ''
				}))
				emptyFilterField()
			}
		}
	}

	const emptyFilterField = () => {
		setDataTable((prevState) => ({
			...prevState,
			filterValue: '',
			minAmount: '',
			maxAmount: '',
			finalDate: undefined,
			initDate: undefined
		}))
	}

	const handleTextFilterChange = (evt: ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		const value: string = evt.target.value
		setDataTable((prevState) => ({
			...prevState,
			filterValue: value
		}))
	}

	const handleFilterKeyDown = (evt: KeyboardEvent<HTMLInputElement> | string) => {
		listHelper.handleFilterKeyDown(
			typeof evt === 'object' ? { ...evt, target: evt.target as HTMLInputElement } : evt,
			callDataList,
			emptyFilterField,
			TypesDataTable.actualField,
			TypesDataTable.filterValue,
			TypesDataTable.minRange,
			TypesDataTable.maxRange
		)
	}

	return (
		<div className="margins-table">
			<div className={`header ${styles.headerMainTableRFCM}`}>
				{fillFields.map((item: tableFillHeader) => (
					<HeaderTable
						key={item.name}
						textHeader={TypesDataTable.textHeader}
						showFilters={showFilters}
						dataMainHeaderTable={item}
					/>
				))}
			</div>
			<TagsFilter
				showTagsFilter={TypesDataTable.statusFilter}
				actualField={TypesDataTable.actualField}
				callDataList={callDataList}
				selectionFilter={selectionFilter}
				closeFilter={closeFilter}
				fillInputFilter={TypesDataTable.fillStatusInputFilter}
			/>
			<DateInputFilter
				showDateFilter={TypesDataTable.dateFilter}
				actualField={TypesDataTable.actualField}
				initDate={TypesDataTable.initDate}
				finalDate={TypesDataTable.finalDate}
				callDataList={callDataList}
				initReferenceText="init"
				finReferenceText="final"
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			<TextInputFilter
				showTextFilter={TypesDataTable.textFilter}
				actualField={TypesDataTable.actualField}
				valueFilter={TypesDataTable.filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			{filterContainerBar && (
				<ActiveFiltersBar
					closeFilterContainerBar={closeFilterContainerBar}
					dataFiltersArray={dataFiltersArray}
					deleteFilterActive={deleteFilterActive}
					objectType={Constants.LISTS.REQUEST_FOR_CREDIT_MEMOS}
				/>
			)}
			<div className="tableInformationWhiteStyle">
				<div className="tableInformation" style={{ height: styleMainTable.mainTableHeight }}>
					<ResultNotFound showNotFound={resultNotFound} />
					{Object.keys(dataRequestForCreditMemo).map((key: string) => (
						<TableRow
							key={key}
							data={dataRequestForCreditMemo[parseInt(key)]}
							userRole={userRole}
						/>
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
		</div>
	)
}
