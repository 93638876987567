import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'

interface ConfirmAcceptPOProps {
	open: boolean
	onClose(): void
	onAccept(): void
}

/**
 * ConfirmAcceptPO
 * @description Helps to display a modal when the user is trying to confirm a PO
 * @param open <boolean>: If `true`, the component is shown
 * @param onClose <void>: Callback fired to close modal
 * @param onAccept <void>: Callback fired when the user accept the action the modal
 * @return component <ReactNode>
 **/
export const ConfirmAcceptPO: FC<ConfirmAcceptPOProps> = ({ onAccept, onClose, open }) => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('confirmOrder')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('confirmPurchaseOrderQuestion')}</p>
		</NetcurioDialog>
	)
}
