import { Company, dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import { t } from 'i18next'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import '../../../../../style.scss'
import styles from '../../../adminConsole.module.scss'

interface Props {
	id: string
	status: string
	created_at: string
	customer: Company
	supplier: Company
	isCustomer: boolean
}

export default function TableRow({ id, status, created_at, customer, supplier, isCustomer }: Props) {
	const { url } = useRouteMatch()

	return (
		<div
			className={styles.rowContainerAdmin}
			onClick={() => (location.href = `${url}/${encodeURIComponent(supplier.rfc ?? '')}&${id}`)}
		>
			<p className={`${styles.tableFieldAdmin} ${styles.enrollmentRowStandard}`}>{id}</p>
			<p className={`${styles.tableFieldAdmin} ${styles.enrollmentRowStandard}`}>{t(status)}</p>
			<p className={`${styles.tableFieldAdmin} ${styles.enrollmentRowStandard}`}>
				{dateFormatter.format(parseTimestampToTimeZone(created_at))}
			</p>
			<p className={`${styles.tableFieldAdmin} ${styles.enrollmentRowLarge}`}>
				{t(isCustomer ? supplier.name : customer.name)}
			</p>
			<p className={`${styles.tableFieldAdmin} ${styles.enrollmentRowStandard}`}>
				{t(isCustomer ? supplier.rfc : customer.rfc)}
			</p>
		</div>
	)
}
