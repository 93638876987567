export default {
	GRAPH_BARS_COLORS: [
		'#29ABE2',
		'#0071BC',
		'#045A89',
		'#00A99D',
		'#59D181',
		'#E4B05A',
		'#EDCC96',
		'#EBA2A2',
		'#B784E4',
		'#29ABE2'
	],
	RED_COLOR_ERROR_BORDER: '#D84646',
	GRAY_COLOR_NORMAL_BORDER: '#D8D8D8',
	GRAY_COLOR_INITIAL: '#E6E6E6',
	BLUE_COLOR_GOOD_RECEIPT: '#045A89',
	BLUE_CONFIRMED: '#0071BC',
	BLUE_REJECTED: '#4C9BD0',
	BLUE_HALF_DELIVERED: '#7FB8DD',
	BLUE_DELIVERED: '#E5F1F8',
	GREEN_CREDIT_MEMO: '#005D56',
	GREEN_REQUEST_FOR_CREDIT_MEMO: '#008B81',
	GREEN_INVOICES: '#00A99D',
	COLOR_CONFIRMED_ITEM: '#1BC053',
	BLUE_RFQ: '#5CA4D4',
	BLUE_GREEN: '#44B6CA',
	GREEN_ADMINISTRATOR: '#44B6CA',
	GREEN_PAYMENT_COMPLEMENT: '#007A66',
	ORANGE_TICKET: '#D47E00',
	BLUE_COLOR_CONSUMPTION: '#044A71',
	BLUE_DISABLED_RFQ: '#97C5E3',
	BLUE_DISABLED_CONSUMPTION: '#487B97',
	GREEN_DISABLED_REQUEST_CREDIT_MEMO: '#45AAA3',
	GREY_PEBBLE: '#333333',
	BLUE_DARK: '#0D3C61',
	BASE_WHITE: '#ffffff',
	REVIEW_BUTTON: '#663c00'
}
