import React, { FC } from 'react'
import { QuotationItem } from '../types/QuotationItem'
import { RequestPosition } from '../types'
import styles from './Quotation.module.scss'
import { QuotationReferenceRow } from './QuotationReferenceRow'
import { QuotationDetailRow } from './QuotationDetailRow'

interface QuotationRowContainerProps {
	rfqItem: RequestPosition
	quotationItems: Array<QuotationItem>
}
export const QuotationRowContainer: FC<QuotationRowContainerProps> = ({ rfqItem, quotationItems }) => {
	return (
		<div className={styles.rowQuotationDetailInfoEntry}>
			<QuotationReferenceRow rfqItem={rfqItem} />
			{quotationItems.map((item) => (
				<QuotationDetailRow key={item.position} quotationItem={item} />
			))}
		</div>
	)
}
