import React, { ReactElement } from 'react'
import { Translation } from 'react-i18next'
import { NetcurioButton, NetcurioIcons, UploadDocument } from '@netcurio/frontend-components'
import Constants from '../../utilities/constants'
import { getCompany, Company } from '@netcurio/frontend-common'
import { DocumentSectionProps } from './types'
import './style.css'

const DocumentSection = ({
	tableHeight,
	ticketDetail,
	openModalPDF,
	additionalFile,
	setAdditionalFile,
	addRevisionDocument
}: DocumentSectionProps): ReactElement => {
	const userCompany: Company = getCompany()
	const viewDocuments =
		!!ticketDetail?.document_creation ||
		!!ticketDetail?.document_revision ||
		ticketDetail?.status.key === Constants.TICKET_STATUS.REVIEW
	const uploadDocument =
		ticketDetail?.status.key === Constants.TICKET_STATUS.REVIEW &&
		ticketDetail?.sender.rfc === userCompany.rfc
	const noDocumenteReceiver =
		ticketDetail?.status.key === Constants.TICKET_STATUS.REVIEW &&
		!ticketDetail?.document_creation &&
		ticketDetail?.receiver.rfc === userCompany.rfc

	return (
		<Translation>
			{(t) => (
				<div className="document-section-container" style={{ height: tableHeight }}>
					<div className="document-section-header">
						<div className="header-container-document">
							<p className={'title-header-document'}>{t('attachedDocumentTitle')}</p>
						</div>
						{viewDocuments ? (
							<div className="track-document-container gray-color-text-general-info">
								{ticketDetail?.document_creation && (
									<div className="ticket-documents-row">
										<span>{t('documentTicketText')}</span>
										<NetcurioButton
											variant={'outlined'}
											size={'small'}
											sx={{
												height: '3rem',
												margin: '0.5rem 0 0 0.5rem',
												border: 'solid 0.1rem'
											}}
											onClick={() => openModalPDF('creation')}
											endIcon={<NetcurioIcons.VisibilityOutlined />}
										>
											<p>{t('viewDocument')}</p>
										</NetcurioButton>
									</div>
								)}
								{ticketDetail?.document_revision ? (
									<div className="ticket-documents-row">
										<span>{t('additionalDoctext')}</span>
										<NetcurioButton
											variant={'outlined'}
											size={'small'}
											sx={{
												height: '3rem',
												margin: '0.5rem 0 0 0.5rem',
												border: 'solid 0.1rem'
											}}
											onClick={() => openModalPDF('revision')}
											endIcon={<NetcurioIcons.VisibilityOutlined />}
										>
											<p>{t('viewDocument')}</p>
										</NetcurioButton>
									</div>
								) : uploadDocument ? (
									<div className="additional-documents-upload-row ">
										<UploadDocument
											id={'newFileField'}
											label={t('additionalDoctext')}
											accept={Constants.MIME_TYPES.PDF}
											fileName={additionalFile?.name || ''}
											onFileChange={(file: File) => setAdditionalFile(file)}
										/>
										<NetcurioButton
											variant={'outlined'}
											size={'small'}
											disabled={!additionalFile}
											onClick={() => addRevisionDocument()}
											endIcon={<NetcurioIcons.UploadFile />}
										>
											<p>{t('uploadFile')}</p>
										</NetcurioButton>
									</div>
								) : noDocumenteReceiver ? (
									<p className="ticket-no-documents-receiver">{t('noDocumentsText')}</p>
								) : null}
							</div>
						) : (
							<p className="ticket-no-documents">{t('noDocumentsText')}</p>
						)}
					</div>
				</div>
			)}
		</Translation>
	)
}

export default DocumentSection
