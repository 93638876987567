import alertWhite from '@netcurio/frontend-assets/src/icons/alert.svg'
import aprobacion from '@netcurio/frontend-assets/src/icons/aprobacion.svg'
import brokenAssociated from '@netcurio/frontend-assets/src/icons/broken-associated.svg'
import calendarsvg from '@netcurio/frontend-assets/src/icons/calendar.svg'
import cancelRejectsvg from '@netcurio/frontend-assets/src/icons/cancel-black.svg'
import inProcess from '@netcurio/frontend-assets/src/icons/in_process.svg'
import paidsvg from '@netcurio/frontend-assets/src/icons/paid.svg'
import voidedSvg from '@netcurio/frontend-assets/src/icons/void.svg'
import { PURCHASE } from '@netcurio/frontend-common'
import { StatusSquareVariant, type StatusInformation } from '@netcurio/frontend-components'
import { Invoice } from '../../../types'
import Constants from '../../../utilities/constants'

const pendingAsociate = (subLabel?: string): StatusInformation => {
	return {
		label: 'invoices.MISSING_LINK',
		subLabel: subLabel || '',
		icon: brokenAssociated,
		invertIconColor: true,
		isLast: true
	}
}
const paidInvoice = (subLabel?: string): StatusInformation => {
	return {
		label: 'PAID',
		subLabel: subLabel || '',
		icon: paidsvg,
		invertIconColor: true,
		isLast: false
	}
}
const paymentScheduled = (subLabel?: string): StatusInformation => {
	return {
		label: 'PAYMENT_SCHEDULED',
		subLabel: subLabel || '',
		icon: calendarsvg,
		invertIconColor: true,
		isLast: false
	}
}
const errorInvoice = (subLabel?: string): StatusInformation => {
	return {
		label: 'ERROR',
		subLabel: subLabel || '',
		icon: alertWhite,
		invertIconColor: true,
		isLast: true,
		variant: StatusSquareVariant.ERROR
	}
}
const canceledInvoice = (subLabel?: string): StatusInformation => {
	return {
		label: 'CANCELED',
		subLabel: subLabel || '',
		icon: cancelRejectsvg,
		invertIconColor: true,
		variant: StatusSquareVariant.WARNING,
		isLast: true
	}
}
const voidedInvoice = (subLabel?: string): StatusInformation => {
	return {
		label: 'VOIDED',
		subLabel: subLabel || '',
		icon: voidedSvg,
		invertIconColor: true,
		variant: StatusSquareVariant.INACTIVE,
		isLast: false
	}
}
const inProgressInvoice = (subLabel?: string): StatusInformation => {
	return {
		label: 'IN_PROCESS',
		subLabel: subLabel || '',
		icon: inProcess,
		invertIconColor: true,
		isLast: true
	}
}
const inApprovalInvoice = (subLabel?: string): StatusInformation => {
	return {
		label: 'invoices.IN_APPROVAL',
		subLabel: subLabel || '',
		icon: aprobacion,
		invertIconColor: true,
		isLast: true
	}
}

const statuses = (invoice: Invoice, currentStatus: string, message?: string) => {
	const hasProposedPaymentDate = Boolean(invoice?.proposed_payment_date)
	const statusFunctions = {
		[Constants.LINKS.MISSING_LINK]: [pendingAsociate()],
		[PURCHASE.PAID]: [paymentScheduled(), paidInvoice()],
		[PURCHASE.PAYMENT_SCHEDULED]: [paymentScheduled()],
		[Constants.ERROR.ERROR]: [errorInvoice()],
		[PURCHASE.CANCELED]: hasProposedPaymentDate
			? [paymentScheduled(), canceledInvoice(message)]
			: [canceledInvoice(message)],
		[PURCHASE.VOIDED]: [voidedInvoice()],
		[Constants.STATUS.IN_PROCESS]: [inProgressInvoice()],
		[Constants.STATUS.IN_APPROVAL]: [inApprovalInvoice()]
	}

	return statusFunctions[currentStatus] || []
}

export const getVisualStatusInvoiceDetail = (invoice: Invoice, currentStatus: string, message?: string) => {
	return statuses(invoice, currentStatus, message)
}
