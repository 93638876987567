import React from 'react'
import { Translation } from 'react-i18next'
import { NetcurioButton, NetcurioIcons } from '@netcurio/frontend-components'
import styles from '../enrollmentDetail/enrollmentDetail.module.scss'

interface IButtonAcceptRejectProps {
	paymentDays: string
	showRejectModal: (value: boolean) => void
	approveModal: (value: boolean) => void
}

const ButtonAcceptReject = ({ paymentDays, showRejectModal, approveModal }: IButtonAcceptRejectProps) => {
	return (
		<Translation>
			{(t) => (
				<div className={styles.buttonsPosition}>
					<NetcurioButton
						variant="outlined"
						color="primary"
						endIcon={<NetcurioIcons.Check />}
						onClick={() => approveModal(true)}
						disabled={!paymentDays}
					>
						{t('approveEnrollment')}
					</NetcurioButton>
					<NetcurioButton
						variant="text"
						size={'small'}
						color="secondary"
						endIcon={<NetcurioIcons.Close />}
						onClick={() => showRejectModal(true)}
					>
						<span> {t('rejectEnrollment')} </span>
					</NetcurioButton>
				</div>
			)}
		</Translation>
	)
}
export default ButtonAcceptReject
