import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const PURCHASE_ORDERS_FOR_INVOICE: DocumentNode = gql`
	query PurchaseOrderForInvoice($id: ID!, $customer: ID!) {
		PurchaseOrderForInvoice(id: $id, customer: $customer) {
			id
			customer_reference
			customer {
				rfc
			}
			items {
				code
				name
				position
				unit_price
				unit
				pending_quantity_to_invoice
				percent_tolerance
				unlimited_tolerance
				requires_gr_confirmation
				requested_amount
			}
		}
	}
`
