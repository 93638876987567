import gql from 'graphql-tag'

export const TICEKT_CATEGORY = gql`
	query TicketCategoriesCreate($search_text: String!, $sort_field: String, $sort_order: String) {
		TicketCategoriesCreate(search_text: $search_text, sort_field: $sort_field, sort_order: $sort_order) {
			code
			description
		}
	}
`

export const CREATE_NEW_TICKET = gql`
	mutation createTicket(
		$receiver: ID!
		$category: ID!
		$subject: String!
		$description: String!
		$document_creation: String
		$comment: CommentInput
	) {
		createTicket(
			receiver: $receiver
			category: $category
			subject: $subject
			description: $description
			document_creation: $document_creation
			comment: $comment
		)
	}
`
