import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const CREATE_INVITATION: DocumentNode = gql`
	mutation createInvitation(
		$email: String!
		$role: String!
		$is_admin: Boolean!
		$enrollment_request_approver: Boolean
	) {
		createInvitation(
			email: $email
			role: $role
			is_admin: $is_admin
			enrollment_request_approver: $enrollment_request_approver
		) {
			code
			message
		}
	}
`
