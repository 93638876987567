import gql from 'graphql-tag'

export const EnrollmentInvitations = gql`
	query EnrollmentInvitations(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		EnrollmentInvitations(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			total
			list {
				id
				status {
					value
				}
				created_at
				customer {
					rfc
					name
				}
				supplier {
					rfc
					name
				}
				customer_reference
			}
		}
	}
`
