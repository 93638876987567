export enum Languages {
	EN = 'en',
	ES = 'es'
}

const getCookie = (name: string) => {
	const value = `; ${document.cookie}`
	const parts = value.split(`; ${name}=`)
	if (parts.length === 2) return parts.pop()?.split(';').shift()
	return null
}

export const useLanguageSwitcher = () => {
	const currentLanguage = getCookie('i18next') || Languages.ES

	const switchLanguage = () => {
		const newLanguage = currentLanguage.includes(Languages.ES) ? Languages.EN : Languages.ES
		const url = new URL(window.location.href)
		url.searchParams.set('lng', newLanguage)
		window.location.href = url.toString()
	}

	return {
		currentLanguage,
		languageButtonLabel: currentLanguage.includes(Languages.ES) ? 'English' : 'Español',
		switchLanguage
	}
}
