import { URLS } from '@netcurio/frontend-common'
import { FileViewerModal, Header, NetcurioIcons, useNetcurioLoader } from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ErrorModal } from '../../components/dialogModal/errorModal'
import { MainModal } from '../../components/dialogModal/mainModal'
import { CancelButton } from '../../components/HeaderButtons/CancelButton'
import { useEnvironment } from '../../hooks/useEnvironment'
import { FileViewerObject } from '../../types'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { errorModal } from '../../utilities/errorModal'
import { expiredToken } from '../../utilities/expiredToken'
import fileToBase64 from '../../utilities/fileToBase64'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import InformationContainer from './informationContainer'
import { LateralColumn } from './lateralColumn'
import { objectModalCloseTicket, objectModalReopenTicket, objectModalReviewTicket } from './modalView'
import { ADD_REVISION_DOCUMENT, CLOSE_TICKET, TICKET_DETAIL, TICKET_REOPEN, TICKET_REVIEW } from './queries'
import './style.css'
import { ModalData, ModalTypes, TicketDetail } from './types'

export const TicketDetailView = (): ReactElement => {
	const { environment } = useEnvironment()
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }
	const [modal, setModal] = useState<JSX.Element | undefined>(undefined)

	const { TICKETS_FOLDER } = Constants.FOLDER

	const { t } = useTranslation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const ticketId: string = useMemo(() => getUrlParameter('ticket'), [])
	const sender: string = useMemo(() => getUrlParameter('sender'), [])
	const history = useHistory()
	const [errorCode, setErrorCode] = useState<string>()
	const [errorMessage, setErrorMessage] = useState<string>()
	const [showModal, setShowModal] = useState<boolean>(false)
	const [modalType, setModalType] = useState<ModalTypes>()
	const [ticketDetail, setTicketDetail] = useState<TicketDetail>()
	const [additionalFile, setAdditionalFile] = useState<File | undefined>()
	const [fileViewer, setFileViewer] = useState<FileViewerObject>({
		open: false,
		titleText: t('invoiceText'),
		fileSrc: ''
	})

	const getTicketDetail = (): void => {
		showLoadingSpinner()
		client
			.query({
				query: TICKET_DETAIL,
				variables: {
					id: ticketId,
					sender: sender
				},
				fetchPolicy: 'no-cache'
			})
			.then((result) => {
				if (result.data) {
					setTicketDetail(result.data.Ticket)
					hideLoadingSpinner()
				} else {
					const errorCode = showErrorComponent(undefined)
					setModalType(ModalTypes.error)
					setErrorCode(errorCode)
					setShowModal(true)
				}
				hideLoadingSpinner()
			})
			.catch(handleError)
	}

	useEffect(() => {
		let modal: ReactElement

		if (showModal && modalType !== undefined) {
			const errorModalShow = errorCode !== undefined
			const { fillModal, acceptActionModal } = getModalData()
			if (errorModalShow) {
				modal = (
					<ErrorModal
						fillModal={fillModal}
						acceptActionModal={acceptActionModal}
						hideModal={() => {
							setShowModal(false)
							setModalType(undefined)
							setErrorCode(undefined)
							setErrorMessage(undefined)
							setModal(undefined)
						}}
						errorCode={errorCode}
					/>
				)
			} else {
				modal = (
					<MainModal
						fillModal={fillModal}
						acceptActionModal={acceptActionModal}
						hideModal={() => {
							setShowModal(false)
							setModalType(undefined)
							setErrorCode(undefined)
							setErrorMessage(undefined)
							setModal(undefined)
						}}
					/>
				)
			}
			setModal(modal)
		}
	}, [showModal])

	const handleError = (error: Error) => {
		console.error(error)
		const newErrorCode = showErrorComponent(error)
		if (!expiredToken(newErrorCode)) {
			setModalType(ModalTypes.error)
			setErrorCode(newErrorCode)
			if (error.message) setErrorMessage(error.message)
			setShowModal(true)
		}
		hideLoadingSpinner()
	}

	// Handle modal render
	const getModalData = (): ModalData => {
		switch (modalType) {
			case ModalTypes.error:
				return { fillModal: errorModal(errorCode, errorMessage) }
			case ModalTypes.reviewTicket:
				return {
					fillModal: objectModalReviewTicket(),
					acceptActionModal: reviewticket
				}
			case ModalTypes.closeTicket:
				return {
					fillModal: objectModalCloseTicket(),
					acceptActionModal: closedTicket
				}
			case ModalTypes.reopenTicket:
				return {
					fillModal: objectModalReopenTicket(),
					acceptActionModal: reopenTicket
				}
			default:
				break
		}
	}

	const reviewticket = () => {
		setModalType(undefined)
		setModal(undefined)
		setShowModal(false)
		showLoadingSpinner()
		client
			.mutate({
				mutation: TICKET_REVIEW,
				variables: {
					id: ticketId,
					sender: sender
				}
			})
			.then(() => {
				hideLoadingSpinner()
				getTicketDetail()
			})
			.catch(handleError)
	}

	const closedTicket = (button: string, reason: string) => {
		setModalType(undefined)
		setModal(undefined)
		setShowModal(false)
		showLoadingSpinner()
		client
			.mutate({
				mutation: CLOSE_TICKET,
				variables: {
					id: ticketId,
					sender: sender,
					closing_reason: reason
				}
			})
			.then(() => {
				hideLoadingSpinner()
				getTicketDetail()
			})
			.catch(handleError)
	}

	const reopenTicket = () => {
		setModalType(undefined)
		setModal(undefined)
		setShowModal(false)
		hideLoadingSpinner()
		client
			.mutate({
				mutation: TICKET_REOPEN,
				variables: {
					id: ticketId,
					sender: sender
				}
			})
			.then(() => {
				hideLoadingSpinner()
				getTicketDetail()
			})
			.catch(handleError)
	}

	const addRevisionDocument = async () => {
		showLoadingSpinner()
		let document_revision: string
		if (additionalFile) {
			await fileToBase64(additionalFile)
				.then((result: string) => {
					document_revision = result.split('base64')[1]
				})
				.catch(handleError)
		}

		client
			.mutate({
				mutation: ADD_REVISION_DOCUMENT,
				variables: {
					id: ticketId,
					sender: sender,
					document_revision
				}
			})
			.then(() => {
				hideLoadingSpinner()
				getTicketDetail()
			})
			.catch(handleError)
	}

	const actionButton = (value: string) => {
		switch (value) {
			case 'reviewTicket':
				setModalType(ModalTypes.reviewTicket)
				setShowModal(true)
				break
			case 'closeTicket':
				setModalType(ModalTypes.closeTicket)
				setShowModal(true)
				break
			case 'reopenTicket':
				setModalType(ModalTypes.reopenTicket)
				setShowModal(true)
				break
			default:
				break
		}
	}

	const openModalPDF = (value: string) => {
		let fileName = ''
		let fileSrc = ''
		switch (value) {
			case 'creation':
				fileName = `${ticketDetail?.document_creation?.toLowerCase()}.pdf`
				fileSrc = `${S3_BUCKET}${TICKETS_FOLDER}/${fileName}`
				setFileViewer((state) => ({ ...state, open: true, fileSrc }))
				break
			case 'revision':
				fileName = `${ticketDetail?.document_revision?.toLowerCase()}.pdf`
				fileSrc = `${S3_BUCKET}${TICKETS_FOLDER}/${fileName}`
				setFileViewer((state) => ({ ...state, open: true, fileSrc }))
				break
			default:
				break
		}
	}

	const closePDFModal = () => setFileViewer((state) => ({ ...state, open: false }))

	return (
		<div className="ticket-detail-container">
			{modal ?? modal}
			<Header>
				<div>
					<CancelButton
						onClick={() => history.push(URLS.TICKET_LIST)}
						translationKey="goToList"
						icon={<NetcurioIcons.ArrowBack />}
					/>
				</div>

				<div />
			</Header>
			<div className="ticket-detail-middle">
				<InformationContainer
					ticketDetail={ticketDetail}
					openModalPDF={openModalPDF}
					additionalFile={additionalFile}
					setAdditionalFile={setAdditionalFile}
					addRevisionDocument={addRevisionDocument}
					sender={sender}
				/>
				<LateralColumn
					ticketDetail={ticketDetail}
					client={client}
					handleError={handleError}
					ticketId={ticketId}
					sender={sender}
					actionButton={actionButton}
				/>
				<FileViewerModal onClose={closePDFModal} {...fileViewer} />
			</div>
		</div>
	)
}
