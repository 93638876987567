import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { parseTimestampToTimeZone, dateFormatter, URLS, Roles } from '@netcurio/frontend-common'
import { PropsTableRow } from './types'
import Formatter from '../../utilities/formatter'
import styles from './requestForQuotationList.module.scss'
import classNames from 'classnames'

export const TableRow = ({
	dataRFQ: {
		branch_office,
		created_at,
		created_by: createdBy,
		customer,
		customer_reference,
		id,
		status,
		supplier
	},
	userRole
}: PropsTableRow): ReactElement => {
	const isCustomer: boolean = userRole === Roles.CUSTOMER

	return (
		<Link
			className={classNames(styles.containerColumnList, styles.hoverColumnListRFQ)}
			to={`${URLS.RFQ_DETAIL}?rfq=${id}&customer=${customer.rfc}`}
		>
			<p
				className={classNames(
					styles.textColumnMainRFQ,
					isCustomer ? styles.fieldId : styles.fieldIdSupplier
				)}
			>
				{Formatter.id(id)}
			</p>
			<p
				className={classNames(
					styles.textColumnMainRFQ,
					isCustomer ? styles.fieldCustomerReference : styles.fieldCustomerReferenceSupplier
				)}
			>
				{Formatter.nullValueFormatting(customer_reference)}
			</p>
			<p
				className={classNames(
					styles.textColumnMainRFQ,
					isCustomer ? styles.fieldStatus : styles.fieldStatusSupplier
				)}
			>
				{status.value}
			</p>
			<p
				className={classNames(
					styles.textColumnMainRFQ,
					isCustomer ? styles.fieldBranchOffice : styles.fieldBranchOfficeSupplier
				)}
			>
				{branch_office}
			</p>
			<p
				className={classNames(
					styles.textColumnMainRFQ,
					isCustomer ? styles.fieldCustomer : styles.fieldSupplier
				)}
			>
				{isCustomer ? supplier.name : customer.name}
			</p>
			<p
				className={classNames(
					styles.textColumnMainRFQ,
					isCustomer ? styles.fieldDate : styles.fieldDateSupplier
				)}
			>
				{dateFormatter.format(parseTimestampToTimeZone(created_at))}
			</p>
			{isCustomer && createdBy && (
				<p className={classNames(styles.textColumnMainRFQ, styles.fieldCreatedBy)}>
					{createdBy?.name + ' ' + createdBy?.lastname}
				</p>
			)}
		</Link>
	)
}
