import { NetcurioButton, NetcurioIcons } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface CancelButtonProps {
	onClick: () => void
	icon?: React.ReactNode
	translationKey?: string
}

export const CancelButton = ({
	onClick,
	icon = <NetcurioIcons.Close />,
	translationKey = 'cancelButton'
}: CancelButtonProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioButton variant="outlined" color="error" size={'small'} onClick={onClick} endIcon={icon}>
			{t(translationKey)}
		</NetcurioButton>
	)
}
