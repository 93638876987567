import { getCurrentUser } from '@netcurio/frontend-common'
import { InvoiceResponse, UploadCFDIPayload, UploadCFDIResponse, UploadParams } from '../types/FileUpload'
import Constants from './constants'
import fileToBase64 from './fileToBase64'

const uploadFiles = async (
	endpoint: string,
	signal: AbortSignal,
	body: BodyInit
): Promise<InvoiceResponse> => {
	return fetch(endpoint, {
		body,
		signal,
		method: 'POST',
		headers: { 'Content-Type': 'application/json; charset=UTF-8' }
	})
		.then((res: Response) => res.json())
		.catch((error) => error)
}

export const uploadCFDI = async (
	endpoint: string,
	files: Array<File>,
	token: string,
	uploadParams: UploadParams,
	controller: AbortController
): Promise<UploadCFDIResponse> => {
	const currentUser = getCurrentUser()

	const { SUCCESSFUL, ERROR, UNKNOWN_ERROR, INVALID_VERSION, INVALID_SIGNATURE } =
		Constants.UPLOAD_FILES.UPLOAD_FILES_RESPONSE
	const { expectedCFDIType, extraRequestParams } = uploadParams

	const data: UploadCFDIPayload = {
		token,
		expectedCFDIType,
		currentCompany: currentUser.company.rfc,
		file1: await fileToBase64(files[0]),
		file2: await fileToBase64(files[1]),
		...extraRequestParams
	}

	const response: InvoiceResponse = await uploadFiles(endpoint, controller.signal, JSON.stringify(data))

	if (response.UUID) {
		return { [SUCCESSFUL]: response.UUID }
	} else if (response.code) {
		let errorCode = response.code.toString()
		if (response.code === 'INVALID_XML') {
			if (response.message && response.message.includes('no longer valid')) {
				errorCode = INVALID_VERSION
			}
			if (response.message && response.message.includes('The CFDI signature could not be verified')) {
				errorCode = INVALID_SIGNATURE
			}
		} else if (response.code === 'CUSTOMER_RULE_EXCEPTION') {
			errorCode = response.message
		}
		return { [ERROR]: errorCode }
	} else return { [UNKNOWN_ERROR]: 'UNKNOWN_EXCEPTION' }
}
