import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow
} from '@netcurio/frontend-components'
import { Roles } from '@netcurio/frontend-common'
import { InvoiceRow } from './InvoiceRow'
import { Invoice } from '../../../../types'
import invoices from '@netcurio/frontend-assets/src/icons/invoice.svg'
import classNames from 'classnames'
import styles from '../consumptionDetail.module.scss'

interface InvoicesTableProps {
	userRole?: string
	hasInvoice?: boolean
	dataInvoices?: Array<Invoice>
	setShowNewInvoiceModal(open: boolean): void
	openInvoiceDetail(uuid: string): void
}

export const InvoicesTable: FC<InvoicesTableProps> = ({
	userRole,
	dataInvoices,
	setShowNewInvoiceModal,
	openInvoiceDetail,
	hasInvoice
}) => {
	const { t } = useTranslation()
	const [showInvoicesTable, setShowInvoicesTable] = useState<boolean>(false)
	const showNewInvoiceButton = userRole === Roles.SUPPLIER
	const disabledNewInvoiceButton = userRole === Roles.SUPPLIER || hasInvoice

	return (
		<NetcurioGrid item width="100%" height="100%" display="flex" flexDirection="column">
			<NetcurioGrid
				className={classNames(
					styles.containerButtonHeaderInvoices,
					disabledNewInvoiceButton
						? styles.bgDarkBlueBarPressed
						: styles.grInvoiceDeliverBillsOrderDetails,
					showInvoicesTable
						? styles.headerInvoicesBorderActive
						: styles.headerInvoicesBorderDisabled
				)}
				onClick={() => {
					if (disabledNewInvoiceButton) {
						setShowInvoicesTable(!showInvoicesTable)
					}
				}}
			>
				<img className={styles.imgHeaderInvoicesDetailsClick} src={invoices} alt="icon invoices" />
				<p className={styles.textInvoicesOrderDetails}>{t('invoicesText')}</p>
			</NetcurioGrid>
			{showInvoicesTable && (
				<NetcurioGrid
					item
					height="100%"
					width="100%"
					display="flex"
					flexDirection="column"
					alignItems="center"
					className={styles.containerDataTableDisplay}
				>
					<p className={styles.textHeaderDetailTable}>{t('textInvoicesHeader')}</p>
					{showNewInvoiceButton && (
						<NetcurioGrid item marginBottom="1rem">
							<NetcurioButton
								color="secondary"
								startIcon={<NetcurioIcons.ControlPointOutlined />}
								variant="outlined"
								disabled={!disabledNewInvoiceButton}
								onClick={() => setShowNewInvoiceModal(true)}
							>
								{t('newInvoice')}
							</NetcurioButton>
						</NetcurioGrid>
					)}
					<NetcurioGrid item width="100%" height="100%">
						<NetcurioTableContainer minHeight="100%">
							<NetcurioTable stickyHeader size="small">
								<NetcurioTableHead>
									<NetcurioTableRow isDetailHeader>
										<NetcurioTableCell
											align="center"
											className={styles.tableCellInThirds}
										>
											<span>{t('serieFolio')}</span>
										</NetcurioTableCell>
										<NetcurioTableCell
											align="center"
											className={styles.tableCellInThirds}
										>
											<span>{t('dateText')}</span>
										</NetcurioTableCell>
										<NetcurioTableCell
											align="center"
											className={styles.tableCellInThirds}
										>
											<span>{t('total_amount')}</span>
										</NetcurioTableCell>
									</NetcurioTableRow>
								</NetcurioTableHead>
								<NetcurioTableBody>
									{dataInvoices &&
										dataInvoices?.map((invoice: Invoice) => (
											<InvoiceRow
												key={invoice.uuid}
												invoice={invoice}
												openInvoiceDetail={openInvoiceDetail}
											/>
										))}
								</NetcurioTableBody>
							</NetcurioTable>
						</NetcurioTableContainer>
					</NetcurioGrid>
				</NetcurioGrid>
			)}
		</NetcurioGrid>
	)
}
