import { validateEmail, validateRFC, validateZipCode } from '@netcurio/frontend-common'
import { useNetcurioLoader } from '@netcurio/frontend-components'
import React, { createContext, useState } from 'react'
import { connection } from '../../utilities/connection'
import { showErrorComponent } from '../../utilities/errorCode'
import * as queries from '../../views/login/queries'

interface RegisterCompanyInputs {
	rfc?: string
	companyName?: string
	address?: string
	tokenState?: string
	zipCode?: string
	industry?: string
	creationDate?: string
	userFirstName?: string
	userLastName?: string
	email?: string
}
interface FormErrors {
	rfcError?: object
	companyNameError?: object
	addressError?: object
	tokenStateError?: object
	zipCodeError?: object
	industryError?: object
	userFirstNameError?: object
	userLastNameError?: object
	emailError?: object
}
interface ServerErrorMessageProps {
	code?: string
	message?: string
}

interface ShowRegisterCompanyProps {
	topMessage?: string
	isOpenRegisterCompanyModal?: boolean
	errorsForm?: FormErrors
	serverErrorMessage?: ServerErrorMessageProps
	companyForm?: RegisterCompanyInputs
	validateForm?(): void
	registerCompany?(): void
	closeModal?(): void
	setcompanyForm?(newValue: Record<string, any>): void
	setIsOpenRegisterCompanyModal?(newValue: boolean): void
	setTopMessage?(newValue: string): void
}

/**
 *	@param	topMessage <string> This getter stores a message when the company is registered correctly.
 *	@param	isOpenRegisterCompanyModal <boolean>: This getter determines whether is shown/not shown the RegisterCompanyModal.
 *	@param	errorsForm <object>: This object (getter) stores all the possible errors of the form when verify.
 *	@param	serverErrorMessage <object>: This object (getter) stores a code and a message to show if there´s a problem while registering the company.
 *	@param	companyForm <object>: This object (getter) stores all the value inputs from the form.
 *	@param	validateForm <function>: This function is called to validate the inputs form
 *	@param	registerCompany <function>: This function is called to register a Company
 *	@param	setcompanyForm <function>: This function is called set the values from the form.
 *	@param	setIsOpenRegisterCompanyModal <function>: This sets the value to shown/not shown the RegisterCompanyModal.
 *	@param	setTopMessage <function>: This function is called set a message when the company is registered correctly.
 */

export const ShowRegisterCompanyContext = createContext<ShowRegisterCompanyProps>({
	topMessage: '',
	isOpenRegisterCompanyModal: false,
	errorsForm: {},
	serverErrorMessage: {},
	companyForm: {}
})

export const ShowRegisterCompanyProvider = ({
	children
}: React.PropsWithChildren<ShowRegisterCompanyProps>) => {
	const client = connection()
	const date = new Date()
	const objectErrorForm: FormErrors = {
		rfcError: { message: ' ', isValid: false },
		companyNameError: { message: ' ', isValid: false },
		addressError: { message: ' ', isValid: false },
		tokenStateError: { message: ' ', isValid: false },
		zipCodeError: { message: ' ', isValid: false },
		industryError: { message: ' ', isValid: false },
		userFirstNameError: { message: ' ', isValid: false },
		userLastNameError: { message: ' ', isValid: false },
		emailError: { message: ' ', isValid: false }
	}
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [topMessage, setTopMessage] = useState<string>('')
	const [isOpenRegisterCompanyModal, setIsOpenRegisterCompanyModal] = useState<boolean>(false)
	const [errorsForm, setErrorsForm] = useState<FormErrors>(objectErrorForm)
	const [serverErrorMessage, setServerErrorMessage] = useState<ServerErrorMessageProps>({
		code: '',
		message: ''
	})
	const [companyForm, setcompanyForm] = useState<RegisterCompanyInputs>({
		rfc: '',
		companyName: '',
		address: '',
		tokenState: '',
		zipCode: '',
		industry: '',
		creationDate: `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(
			2,
			'0'
		)}/${date.getFullYear()}`,
		userFirstName: '',
		userLastName: '',
		email: ''
	})

	const validateForm = () => {
		const newErrors: FormErrors = objectErrorForm
		setServerErrorMessage({ code: '', message: '' })
		let isValidForm = true
		if (companyForm.rfc === '') {
			newErrors.rfcError = { message: 'emptyFieldError', isValid: true }
			isValidForm = false
		} else if (!validateRFC(companyForm.rfc || '')) {
			newErrors.rfcError = { message: 'wrongRfcFormat', isValid: true }
			isValidForm = false
		}
		if (companyForm.companyName === '') {
			newErrors.companyNameError = { message: 'emptyFieldError', isValid: true }
			isValidForm = false
		}
		if (companyForm.address === '') {
			newErrors.addressError = { message: 'emptyFieldError', isValid: true }
			isValidForm = false
		}
		if (companyForm.tokenState === '') {
			newErrors.tokenStateError = { message: 'emptyFieldError', isValid: true }
			isValidForm = false
		} else if (companyForm.tokenState && companyForm.tokenState.length < 3) {
			newErrors.tokenStateError = { message: 'tokenTooShort', isValid: true }
			isValidForm = false
		}
		if (companyForm.zipCode === '') {
			newErrors.zipCodeError = { message: 'emptyFieldError', isValid: true }
			isValidForm = false
		} else if (companyForm.zipCode && companyForm.zipCode.length < 3) {
			newErrors.zipCodeError = { message: 'zipCodeTooShort', isValid: true }
			isValidForm = false
		} else if (!validateZipCode(companyForm.zipCode || '')) {
			newErrors.zipCodeError = { message: 'wrongZipCodeFormat', isValid: true }
			isValidForm = false
		}
		if (companyForm.industry === '') {
			newErrors.industryError = { message: 'emptyFieldError', isValid: true }
			isValidForm = false
		}
		if (companyForm.userFirstName === '') {
			newErrors.userFirstNameError = { message: 'emptyFieldError', isValid: true }
			isValidForm = false
		}
		if (companyForm.userLastName === '') {
			newErrors.userLastNameError = { message: 'emptyFieldError', isValid: true }
			isValidForm = false
		}
		if (companyForm.email === '') {
			newErrors.emailError = { message: 'emptyFieldError', isValid: true }
			isValidForm = false
		} else if (!validateEmail(companyForm.email || '')) {
			newErrors.emailError = { message: 'wrongEmailFormat', isValid: true }
			isValidForm = false
		}
		setErrorsForm(newErrors)

		if (isValidForm) {
			registerCompany()
		}
	}

	const formatErrorMessage = (message: string) => {
		return message.split(':')[1]
	}

	const registerCompany = () => {
		const newCompany = {
			rfc: companyForm.rfc,
			name: companyForm.companyName,
			address_line_1: companyForm.address,
			state_code: companyForm.tokenState,
			postal_code: companyForm.zipCode,
			industry_description: companyForm.industry,
			name_user: companyForm.userFirstName,
			last_name_user: companyForm.userLastName,
			email: companyForm.email
		}
		showLoadingSpinner()
		client
			.mutate({
				mutation: queries.REGISTER_NEW_COMPANY,
				variables: newCompany
			})
			.then(() => {
				hideLoadingSpinner()
				setTopMessage('companyRegister')
				setServerErrorMessage({ code: '', message: '' })
				setIsOpenRegisterCompanyModal(false)
				setcompanyForm({
					rfc: '',
					companyName: '',
					address: '',
					tokenState: '',
					zipCode: '',
					industry: '',
					creationDate: ``,
					userFirstName: '',
					userLastName: '',
					email: ''
				})
			})
			.catch((error) => {
				hideLoadingSpinner()
				console.error(error)
				setServerErrorMessage({
					code: showErrorComponent(error),
					message: formatErrorMessage(error.message)
				})
			})
	}

	const closeModal = () => {
		setcompanyForm({
			rfc: '',
			companyName: '',
			address: '',
			tokenState: '',
			zipCode: '',
			industry: '',
			creationDate: `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(
				2,
				'0'
			)}/${date.getFullYear()}`,
			userFirstName: '',
			userLastName: '',
			email: ''
		})
		setIsOpenRegisterCompanyModal(false)
		setErrorsForm(objectErrorForm)
		if (serverErrorMessage?.message?.includes(serverErrorMessage.message)) {
			setServerErrorMessage({ code: '', message: '' })
		}
	}

	return (
		<ShowRegisterCompanyContext.Provider
			value={{
				topMessage,
				isOpenRegisterCompanyModal,
				errorsForm,
				serverErrorMessage,
				companyForm,
				validateForm,
				registerCompany,
				setcompanyForm,
				setIsOpenRegisterCompanyModal,
				setTopMessage,
				closeModal
			}}
		>
			{children}
		</ShowRegisterCompanyContext.Provider>
	)
}
