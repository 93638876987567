import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'

interface DefaultCompanyBlockModalProps {
	open: boolean
	onAccept(): void
}

export const DefaultCompanyBlockModal: FC<DefaultCompanyBlockModalProps> = ({
	open,
	onAccept
}): ReactElement => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			open={open}
			actionButtons={
				<>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('closeText')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('defaultCompanyBlock')}</p>
		</NetcurioDialog>
	)
}
