import React, { ReactElement, useEffect, useState } from 'react'
import { t } from 'i18next'
import Formatter from '../../../../utilities/formatter'
import { parseTimestampToTimeZone, dateFormatter } from '@netcurio/frontend-common'
import { PurchaseOrder, PurchaseOrderItem } from '../../../../types'
import logoNetcurio from '@netcurio/frontend-assets/src/images/netcurio-logo.png'
import { TableRow } from './tableRow'
import classNames from 'classnames'
import styles from './pageManager.module.scss'

interface IPageStructure {
	informationPurchaseOrder: PurchaseOrder
	informationTable: Array<PurchaseOrderItem>
	firstPage: boolean
	logoLoad(page: number): void
	page: number
	totalPages: number
}
export const PageStructure = ({
	informationPurchaseOrder,
	firstPage,
	logoLoad,
	page,
	totalPages,
	informationTable
}: IPageStructure): ReactElement => {
	const [showHeaderInfo, setShowHeaderInfo] = useState<boolean>(true)
	useEffect(() => {
		if (!firstPage) {
			setShowHeaderInfo(false)
		}
	}, [])
	const printingDate = parseTimestampToTimeZone(new Date())
	const {
		id,
		customer_reference,
		status,
		created_at,
		supplier,
		customer,
		branch_office,
		delivery_address_line_1,
		delivery_address_line_2,
		delivery_address_postal_code,
		delivery_address_country,
		delivery_address_state,
		currency
	} = informationPurchaseOrder
	const statusName = status ? status.key : '',
		supplierName = supplier ? supplier.name : '',
		customerName = customer ? customer.name : '',
		supplierRFC = supplier ? supplier.rfc : ''
	return (
		<div className={styles.poPdfContainer}>
			<header className={styles.poPdfHeader}>
				<img
					className={styles.poPdfNetcurioLogo}
					src={logoNetcurio}
					alt="Logo Netcurio"
					onLoad={() => logoLoad(page)}
				/>
				<div>
					<p className={styles.poPdfPageContainer}>
						{t('page')}
						<span id={'poPdfPageCount'}>{totalPages ? totalPages : ''}</span>
					</p>
					<p className={styles.poPdfPrintDate}>{dateFormatter.format(printingDate)}</p>
				</div>
			</header>
			<div className={styles.poTitle}>
				<p>{t('id_PO') + ' ' + Formatter.id(id)}</p>
				<p>{t('customerRefPoPdf') + ' ' + Formatter.nullValueFormatting(customer_reference)}</p>
			</div>
			{showHeaderInfo && (
				<div className={styles.poData}>
					<div className={styles.poPdfInformationContainer}>
						<div className={styles.purchaseOrderInformation}>
							<p className={styles.poPdfTitlesInfo}>{t('statusPoPdf')}</p>
							<p className={styles.poPdfInfo}>{t(statusName)}</p>
						</div>
						<div className={classNames(styles.purchaseOrderInformation, styles.pdfInfoCustomer)}>
							<p className={styles.poPdfTitlesInfo}>{t('customerPoPdf')}</p>
							<p className={styles.poPdfInfoCustomer}>{customerName}</p>
						</div>
						<div
							className={classNames(
								styles.purchaseOrderInformation,
								styles.poPdfSupplierContainer
							)}
						>
							<p className={styles.poPdfTitlesInfo}>{t('supplierPoPdf')}</p>
							<p className={styles.poPdfInfo}>{supplierName}</p>
						</div>
						<div
							className={classNames(
								styles.purchaseOrderInformation,
								styles.poPdfDeliveryAddressContainer
							)}
						>
							<p className={styles.poPdfTitlesInfo}>{t('deliveryAddressPoPdf')}</p>
							<p className={styles.pdfPoDeliveryAddress}>
								{delivery_address_line_1 +
									' ' +
									(delivery_address_line_2 !== null ? delivery_address_line_2 : '') +
									' ' +
									delivery_address_state +
									' ' +
									delivery_address_postal_code +
									' ' +
									delivery_address_country}
							</p>
						</div>
					</div>
					<div className={styles.poPdfInformationContainer}>
						<div className={styles.purchaseOrderInformation}>
							<p className={styles.poPdfTitlesInfo}>{t('creationDatePoPdf')}</p>
							<p className={styles.poPdfInfo}>
								{dateFormatter.format(parseTimestampToTimeZone(created_at))}
							</p>
						</div>
						<div className={classNames(styles.purchaseOrderInformation, styles.pdfBranchOffice)}>
							<p className={styles.poPdfTitlesInfo}>{t('branchOfficePoPdf')}</p>
							<p className={styles.poPdfInfoBranchOffice}>{branch_office}</p>
						</div>
						<div
							className={classNames(
								styles.purchaseOrderInformation,
								styles.poPdfSupplierContainer
							)}
						>
							<p className={styles.poPdfTitlesInfo}>{t('supplierRfc')}</p>
							<p className={styles.poPdfInfo}>{supplierRFC}</p>
						</div>
					</div>
				</div>
			)}
			<div className={styles.poPdfColumnsHeaders}>
				<p className={styles.positionHeader}>{t('posPoPdf')}</p>
				<p className={styles.descriptionHeader}>{t('descriptionCodePoPdf')}</p>
				<p className={styles.storageLocationHeader}>{t('storageLocation')}</p>
				<p className={styles.poPdfQuantitiesHeader}>{t('quantitiesHeader')}</p>
				<p className={styles.requiredDateHeader}>{t('dateRequiredPoPdf')}</p>
				<p className={styles.deliveredDateHeader}>{t('proposedDeliveryDatePoPdf')}</p>
				<p className={styles.ivaHeader}>{t('taxPoPdf')}</p>
				<p className={styles.unitPriceHeader}>{t('unitPricePoPdf')}</p>
				<p className={styles.netTotalHeader}>{t('netValue')}</p>
			</div>
			<div
				className={classNames(
					styles.poPdfRowsContainer,
					firstPage ? styles.poPdfRowsContainerFirstPage : styles.poPdfRowsContainerNextPage
				)}
				id={'poPdfRowsContainer' + page}
			>
				{Object.keys(informationTable).map((key) => (
					<TableRow key={key} item={informationTable[key]} currency={currency} />
				))}
			</div>
			<footer className={styles.poPdfFooter} />
		</div>
	)
}
