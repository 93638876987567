import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Company } from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioIcons,
	NetcurioCheckbox,
	NetcurioFormControlLabel,
	NetcurioDatePicker,
	NetcurioSelect,
	NetcurioMenuItem,
	NetcurioAutocomplete
} from '@netcurio/frontend-components'
import { StatusFilter } from '../../../types'
import dayjs from 'dayjs'
import headerStyles from '../headerStyles.module.scss'
import styles from './reportDetail.module.scss'
import { useDebounce } from '../../../utilities/useDebounce'

interface Props {
	title: string
	description: string
	filterInfo: {
		[key: string]: string
	}
	filterQuery: (searchText: string) => Promise<CompanySearch[]>
	downloadReport: (
		selectedCompany: Company,
		selectedStatus: string,
		startDate?: Date | Date[] | string,
		endDate?: Date | Date[] | string
	) => void
	hideFilter?: boolean
	dateFilterText?: string
	statusFilterText?: string
	statusFilter?: StatusFilter[]
}
interface CompanySearch {
	name: string
	rfc: string
}

export default function ReportDetail({
	title,
	description,
	filterInfo,
	filterQuery,
	downloadReport,
	hideFilter = false,
	statusFilterText = '',
	statusFilter = [],
	dateFilterText = ''
}: Props) {
	const { t } = useTranslation()
	const [selectedStatus, setSelectedStatus] = useState<string>('')
	const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState<boolean>(true)
	const [areAllCompaniesChecked, setAreAllCompaniesChecked] = useState<boolean>(false)
	const [areAllStatusChecked, setAreAllStatusChecked] = useState<boolean>(false)
	const [filterStartDate, setFilterStartDate] = useState<dayjs.Dayjs | null>(null)
	const [filterEndDate, setFilterEndDate] = useState<dayjs.Dayjs | null>(null)
	const [errorStartDate, setErrorStartDate] = useState<boolean>(true)
	const [errorEndDate, setErrorEndDate] = useState<boolean>(true)
	const [selectedCompany, setSelectedCompany] = useState<CompanySearch | undefined>(null)
	const [currentCompanyList, setCurrentCompanyList] = useState<CompanySearch[]>([])
	const [searchCompany, setSearchCompany] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const debouncedCompanyInput = useDebounce<string>(searchCompany, 500)

	useEffect(() => {
		if (debouncedCompanyInput.length >= 3) {
			setLoading(true)
			suggestCompanies()
		}
	}, [debouncedCompanyInput])

	async function suggestCompanies() {
		setCurrentCompanyList(await filterQuery(debouncedCompanyInput))
		setLoading(false)
	}

	function toggleCheckbox(event, checked: boolean) {
		setAreAllCompaniesChecked(checked)
		setIsDownloadButtonDisabled(!checked)
		setSelectedCompany(undefined)
		setSearchCompany('')
	}
	function toggleCheckboxStatus() {
		setAreAllStatusChecked(!areAllStatusChecked)
		setSelectedStatus('')
	}

	function removeSelectedStatus() {
		setSelectedStatus('')
	}

	const validateCompanyValue = (option: CompanySearch, value) => option.rfc === value.rfc

	const getCompanyLabel = (company) => `${company.name} - ${company.rfc}`

	const selectCompany = (company: CompanySearch) => {
		setSelectedCompany(company)
		setIsDownloadButtonDisabled(!isDownloadButtonDisabled)
	}

	const getSuggestion = (searchText: string) => {
		setSearchCompany(searchText)
	}

	return (
		<div className={styles.reportDetailMainContainer}>
			<div className={headerStyles.reportsTitle}>
				<p className={styles.titleHeader}>{t('reportsText')}</p>
			</div>
			<div className={styles.reportDetailBodyContainer}>
				<div className={styles.reportDetailBodyTitle}>{t(title)}</div>
				<div className={styles.reportsBodyText}>{t(description)}</div>
				{hideFilter && <div className={styles.reportsBodyText}>{t('downloadCatalog')}</div>}
				{!hideFilter && (
					<div>
						<div className={styles.reportsBodyFilterText}>{t(filterInfo.description)}</div>
						<div className={styles.titleFilter}>{t(filterInfo.name)}</div>
						<div className={styles.autocompleteSelectorContainer}>
							<NetcurioAutocomplete
								label={t(filterInfo.placeholder)}
								fullWidth
								height="smaller"
								size="small"
								variant="outlined"
								options={currentCompanyList}
								getOptionLabel={getCompanyLabel}
								isOptionEqualToValue={validateCompanyValue}
								value={selectedCompany}
								onSelectValue={selectCompany}
								onInputValueChange={getSuggestion}
								loading={loading}
								minLength={3}
								disabled={!!areAllCompaniesChecked}
								freeSolo
							/>
						</div>
						<div className={styles.checkboxLabel}>
							<NetcurioFormControlLabel
								sx={{
									'& .MuiTypography-root': {
										fontSize: '1rem',
										fontWeight: '300'
									}
								}}
								control={
									<NetcurioCheckbox
										checked={areAllCompaniesChecked}
										onChange={toggleCheckbox}
									/>
								}
								label={t(filterInfo.checkbox)}
							/>
						</div>
					</div>
				)}
				{statusFilterText && statusFilter && (
					<div>
						<div className={styles.reportsBodyFilterText}>{t(statusFilterText)}</div>
						<div className={styles.titleFilter}>{t('status_text')}</div>
						<div className={styles.autocompleteSelectorContainer}>
							<NetcurioSelect
								displayEmpty
								minWidth="100%"
								size="small"
								value={selectedStatus}
								disabled={areAllStatusChecked}
								onChange={(e) => {
									setSelectedStatus(e.target.value)
								}}
								clearFunction={removeSelectedStatus}
							>
								<NetcurioMenuItem value="">
									<em className={styles.notItalic}>{t('statusFilterPlaceholder')}</em>
								</NetcurioMenuItem>
								{statusFilter.map((status, index) => (
									<NetcurioMenuItem key={index} value={status.value}>
										{status.label}
									</NetcurioMenuItem>
								))}
							</NetcurioSelect>
						</div>
						<div className={styles.checkboxLabel}>
							<NetcurioFormControlLabel
								sx={{
									'& .MuiTypography-root': {
										fontSize: '1rem',
										fontWeight: '300'
									}
								}}
								control={
									<NetcurioCheckbox
										checked={areAllStatusChecked}
										onChange={toggleCheckboxStatus}
									/>
								}
								label={t('statusFilterCheckbox')}
							/>
						</div>
					</div>
				)}
				{dateFilterText && (
					<>
						<div className={styles.reportsBodyFilterText}>
							<span>{t('reportDateFilter')}</span>
							<span>{t(dateFilterText)}</span>
						</div>
						<div className={styles.reportsDateFilterContainer}>
							<div className={styles.datePickerContainerL}>
								<NetcurioDatePicker
									height="smaller"
									value={filterStartDate}
									onChange={(newDate, error) => {
										setFilterStartDate(newDate)
										setErrorStartDate(!!error.validationError)
									}}
									label={t('initDate')}
									format="DD/MM/YYYY"
									fullWidth={true}
								/>
							</div>
							<div className={styles.datePickerContainerL}>
								<NetcurioDatePicker
									height="smaller"
									value={filterEndDate}
									onChange={(newDate, error) => {
										setFilterEndDate(newDate)
										setErrorEndDate(!!error.validationError)
									}}
									label={t('finalDate')}
									format="DD/MM/YYYY"
									fullWidth={true}
								/>
							</div>
						</div>
					</>
				)}
				<div className={styles.buttonDownloadSection}>
					<NetcurioButton
						variant="outlined"
						sx={{ width: '200px' }}
						disabled={isDownloadButtonDisabled && !hideFilter}
						endIcon={<NetcurioIcons.Download />}
						onClick={() =>
							downloadReport(
								selectedCompany,
								selectedStatus,
								errorStartDate ? undefined : filterStartDate.toDate(),
								errorEndDate ? undefined : filterEndDate.toDate()
							)
						}
					>
						{t('downloadButton')}
					</NetcurioButton>
				</div>
			</div>
		</div>
	)
}
