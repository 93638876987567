import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const CONSUMPTION_DETAIL: DocumentNode = gql`
	query Consumption($id: ID!, $customer: ID!) {
		Consumption(id: $id, customer: $customer) {
			id
			customer {
				rfc
				name
			}
			customer_reference
			branch_office
			branch_address_line_1
			branch_address_line_2
			branch_address_state
			branch_address_postal_code
			branch_address_country
			created_by {
				name
				lastname
			}
			created_at
			items {
				position
				supplier {
					rfc
					name
				}
				code
				description
				unit
				currency
				tax
				unit_price
				quantity
				storage_location
				net_value
				pending_quantity_to_invoice
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
			invoices {
				serie
				folio
				uuid
				total
				date
				status {
					key
					value
				}
			}
		}
	}
`
