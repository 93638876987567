import React from 'react'
import { AddButton } from '../../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../../components/HeaderButtons/ExportButton'

export const UserListTabButtons = ({
	actionHeaderButton
}: {
	actionHeaderButton: (action: string) => void
}) => {
	return (
		<>
			<div>
				<AddButton translationKey="newUser" onClick={() => actionHeaderButton('newUser')} />
			</div>
			<div>
				<ExportButton onClick={() => actionHeaderButton('exportListUsers')} />
			</div>
		</>
	)
}
