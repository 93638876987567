import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const PRODUCTS: DocumentNode = gql`
	query Products(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		Products(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			total
			list {
				id
				supplier {
					rfc
				}
				description
				currency
				unit_price
				unit
				is_active
			}
		}
	}
`
