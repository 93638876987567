import React, { FC } from 'react'
import { NetcurioTableCell, NetcurioTableRow } from '@netcurio/frontend-components'
import { dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import { GoodReceipt } from '../../../../../types'
import styels from '../../purchaseOrderDetail.module.scss'

interface GoodsReceiptRowProps {
	goodReceipt: GoodReceipt
	showGrDetail: (position: number, idGoodReceive: string) => void
	position: number
}

export const GoodsReceiptRow: FC<GoodsReceiptRowProps> = ({ goodReceipt, showGrDetail, position }) => {
	return (
		<NetcurioTableRow isDetailRow onClick={() => showGrDetail(position, goodReceipt?.id)}>
			<NetcurioTableCell align="center">
				<p className={styels.texTableRow}>{goodReceipt?.id}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p className={styels.texTableRow}>
					{dateFormatter.format(parseTimestampToTimeZone(goodReceipt?.created_at))}
				</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p className={styels.texTableRow}>
					{goodReceipt?.created_by
						? `${goodReceipt?.created_by?.name} ${goodReceipt?.created_by?.lastname}`
						: ''}
				</p>
			</NetcurioTableCell>
		</NetcurioTableRow>
	)
}
