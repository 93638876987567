import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { ReactNode } from 'react'
import { NetcurioAlert, Severity } from '../NetcurioAlert/NetcurioAlert'
import styles from './NetcurioDialog.module.scss'

interface NetcurioDialogProps {
	open: boolean
	onClose?: () => void
	titleText?: string
	headerTitleSeverity?: Severity
	actionButtons: ReactNode
	children: ReactNode
	minWidth?: string
	maxWidth?: string
	contentFontSize?: string
}

/**
 * NetcurioDialog
 * @param open <boolean>: If `true`, the component is shown.
 * @param onClose <void>: Callback fired when the component requests to be closed.
 * @param titleText <string>: Dialog text title
 * @param headerTitleSeverity <string>: If set, title will be wrapped in an Alert with specified severity
 * @param actionButtons <ReactNode>: Action buttons for the dialog
 * @param minWidth <string>: Set to override default 600px min-width
 * @param children: <ReactNode>: Body of the dialog
 * @param maxWidth  <string>: Set to override default maxWidth
 * @param contentFontSize  <string>: Set to override default content FontSize
 @returns component
 */
export const NetcurioDialog = ({
	open,
	onClose,
	titleText,
	children,
	actionButtons,
	headerTitleSeverity,
	minWidth,
	maxWidth,
	contentFontSize
}: NetcurioDialogProps) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				'& .MuiDialog-paper': {
					maxWidth: maxWidth ?? 'none'
				}
			}}
		>
			<div className={styles.topBorder}> </div>
			{headerTitleSeverity ? (
				<NetcurioAlert severity={headerTitleSeverity}>{titleText ?? ''}</NetcurioAlert>
			) : (
				<div className={styles.titleTextDialog}>{titleText}</div>
			)}
			<DialogContent
				className={contentFontSize ? '' : styles.dialogContent}
				sx={{
					fontSize: contentFontSize ?? '1.6rem',
					minWidth: minWidth ?? '60rem',
					paddingBottom: '1.6rem'
				}}
			>
				{children}
			</DialogContent>
			<DialogActions sx={{ padding: '0 1.6rem 1.6rem 1.6rem' }}>{actionButtons}</DialogActions>
		</Dialog>
	)
}
