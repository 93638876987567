import { fillModal } from '../../components/dialogModal/fillModal'

export function objectCancelCreditMemoRequest() {
	const cancelRequestCreditMemo = new fillModal(
		'cancelModal',
		'titleCreditMemoDetailsExit',
		'cancelCreditMemoDetail',
		'comeback',
		'cancelText',
		'comeBackTextModal',
		''
	)
	return [cancelRequestCreditMemo]
}
