import { ProductListDataTable, StyleMainTable } from './types'
import Constants from '../../../../../utilities/constants'
import { objectStatusFilterArray } from './tableParameterArray'
import { Modal } from '@netcurio/frontend-common'
import styles from '../../../adminConsole.module.scss'

export const userListValues: ProductListDataTable = {
	fieldList: 'id',
	actualFilterData: 'id',
	initialSort: 'id',
	actualField: 'id',
	orderList: Constants.ORDER.ASC,
	limitRows: undefined,
	rowsActual: undefined,
	totalRows: undefined,
	dataFilters: {},
	dataFiltersArray: [],
	dataProducts: {},
	dataStatusFilter: '',
	textHeader: styles.headerTableDefault,
	fillStatusInputFilter: objectStatusFilterArray(),
	numericFilter: 'display-none',
	textFilter: 'display-none',
	statusFilter: 'display-none',
	minAmount: '',
	maxAmount: '',
	filterValue: undefined,
	filterContainerBar: false,
	deleteRange: false,
	minRange: undefined,
	maxRange: undefined,
	dataCreditMemo: {}
}

export const styleMainTableValues: StyleMainTable = {
	mainContainerHeight: undefined,
	mainTableHeight: undefined,
	activeHeight: true
}

export const modalValues: Modal = {
	errorCode: undefined
}
