import { gql } from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const QUOTATION: DocumentNode = gql`
	query Quotation($id: ID!, $supplier: ID!) {
		Quotation(id: $id, supplier: $supplier) {
			id
			total
			currency
			expiration_date
			hasExpired
			supplier {
				rfc
			}
			items {
				position
				status {
					key
				}
				product_code
				product_description
				quantity
				unit
				tax
				unit_price
				net_value
				rejected_reason {
					key
					value
				}
				delivery_proposal_date
				request_position {
					position
					product_description
					quantity
					unit
					required_delivery_date
				}
				product_ean
			}
			purchaseOrders {
				id
				customer {
					rfc
				}
			}
			incoterm {
				code
				description
			}
			payment_term {
				code
				description
			}
		}
	}
`
