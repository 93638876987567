import { PurchaseOrder } from '../../../types'

export const defaultValuesPurchaseOrder: PurchaseOrder = {
	id: '',
	customer: {},
	supplier: {},
	items: [],
	status: {
		value: '',
		key: ''
	},
	created_at: new Date(),
	updated_at: new Date(),
	delivery_address_country_code: '',
	delivery_address_state_code: '',
	goodsReceipts: [],
	invoices: [],
	comments: [],
	type: {
		value: '',
		key: ''
	}
}
