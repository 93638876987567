import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioTextField, NetcurioTooltip } from '@netcurio/frontend-components'
import { parseTimestampToTimeZone, dateFormatter, Status } from '@netcurio/frontend-common'
import { QuotationItem } from '../types/QuotationItem'
import Constants from '../../../utilities/constants'
import Formatter from '../../../utilities/formatter'
import classNames from 'classnames'
import styles from './Quotation.module.scss'

interface QuotationDetailRowProps {
	quotationItem: QuotationItem
}

export const QuotationDetailRow: FC<QuotationDetailRowProps> = ({
	quotationItem: {
		status,
		rejected_reason,
		product_code,
		product_description,
		quantity,
		unit,
		unit_price,
		tax,
		net_value,
		delivery_proposal_date
	}
}) => {
	const { t } = useTranslation()

	return (
		<div className={styles.quotationDetailRowContainer}>
			{(status as Status).key === Constants.STATUS.REJECTED ? (
				<div className={styles.rejectedReasonContainer}>
					<NetcurioTextField
						label={t('rejectedReason')}
						disabled
						value={rejected_reason ? (rejected_reason as Status).value : Constants.SYMBOL.HYPHEN}
					/>
				</div>
			) : (
				<div className={styles.rowQuotationDetailInfoPosition}>
					<NetcurioTooltip title={<label>{product_description}</label>} placement="right">
						<div className={classNames(styles.onlyReadField, styles.rowDetailCodeDescription)}>
							<div className={styles.rowQuotationDetailCodeDescriptionSpan}>
								{Formatter.codeDescription(product_code, product_description)}
							</div>
						</div>
					</NetcurioTooltip>
					<div className={classNames(styles.onlyReadField, styles.rowQuotationDetailQuantity)}>
						<span>{quantity}</span>
					</div>
					<div className={classNames(styles.onlyReadField, styles.rowQuotationDetailUnit)}>
						<span>{unit}</span>
					</div>
					<div className={classNames(styles.onlyReadField, styles.rowQuotationDetailUnitPrice)}>
						<span>{Formatter.currency.format(unit_price)}</span>
					</div>
					<div className={classNames(styles.onlyReadField, styles.rowQuotationDetailTax)}>
						<span>{Formatter.percent.format(tax)}</span>
					</div>
					<div className={classNames(styles.onlyReadField, styles.rowQuotationDetailNetValue)}>
						<span>{Formatter.currency.format(net_value)}</span>
					</div>
					<div
						className={classNames(
							styles.onlyReadField,
							styles.rowQuotationDetailDeliveryProposalDate
						)}
					>
						<span>{dateFormatter.format(parseTimestampToTimeZone(delivery_proposal_date))}</span>
					</div>
				</div>
			)}
		</div>
	)
}
