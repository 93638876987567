import { useNetcurioLoader } from '@netcurio/frontend-components'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { connection } from '../../../../utilities/connection'
import { showErrorComponent } from '../../../../utilities/errorCode'
import { expiredToken } from '../../../../utilities/expiredToken'
import { getUrlParameter } from '../../../../utilities/getUrlParameter'
import { REJECT_REQUEST_FOR_QUOTATION } from '../../utilities/queries'

interface RejectRequestForQuotationProps {
	rejectRequest: boolean
	closeMainModal(): void
	addNewComment(): void
	sendErrorCode(error: string): void
	resetBtnValue(): void
	resetDataPage(): void
}
/**
 * RejectRequestForQuotation
 * @description Helps the main modal to activate some actions(rejectRequestForQuotation) when the user is trying to reject a RFQ
 * @param rejectRequest <boolean>: If `true`, the function "rejectRequestForQuotation" is triggered.
 * @param addNewComment <void>: Callback fired when the component requests to be closed.
 * @param sendErrorCode <void>: Callback fired when the request fails.
 * @param closeMainModal <void>: Callback fired when the component requests to add a new comment into CommentSection.
 * @param resetBtnValue <void>: Callback fired to reset setRejectRFQ state in RejectRFQModal component.
 * @param resetDataPage <void>: Callback for reset the page
 * @return component <ReactNode>
 **/
export const RejectRequestForQuotation = ({
	rejectRequest,
	closeMainModal,
	addNewComment,
	sendErrorCode,
	resetBtnValue,
	resetDataPage
}: RejectRequestForQuotationProps) => {
	const client = connection()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	useEffect(() => {
		if (rejectRequest) {
			rejectRequestForQuotation()
			resetBtnValue()
		}
	}, [rejectRequest])

	const rejectRequestForQuotation = () => {
		showLoadingSpinner()
		const RFQ = getUrlParameter('rfq')
		const customer = getUrlParameter('customer')
		client
			.mutate({
				mutation: REJECT_REQUEST_FOR_QUOTATION,
				variables: {
					id: RFQ,
					customer: customer
				}
			})
			.then(() => {
				addNewComment()
				window.sessionStorage.setItem('rejectRFQ', 'true')
				resetDataPage()
				closeMainModal()
			})
			.catch((error) => {
				console.error(error)
				const idCode = showErrorComponent(error)
				if (!expiredToken(idCode)) {
					sendErrorCode(t('errorCodeText', { idCode }))
				}
				hideLoadingSpinner()
			})
	}

	return <p>{t('questionRejectRFQ')}</p>
}
