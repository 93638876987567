import styles from './DetailField.module.scss'
import { NetcurioTooltip } from '@netcurio/frontend-components'
import React, { ReactElement } from 'react'

interface DetailField {
	title: string
	information: string
	tooltip?: string
}

export const DetailField = ({ tooltip, title, information = '' }: DetailField): ReactElement => {
	return (
		<NetcurioTooltip title={tooltip} placement={'top'}>
			<div className={styles.invoiceDetailField}>
				<p className={styles.invoiceDetailTitle}>{title}</p>
				<p className={styles.invoiceDetailInformation}>{information}</p>
			</div>
		</NetcurioTooltip>
	)
}
