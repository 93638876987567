import ACTIONS_USER_STATUS from './actionUserStatus'
import AUTH from './authentication'
import COLORS from './colors'
import { default as CREDIT_MEMO_STATUS, default as GRAPH_BARS_COLORS } from './creditMemoStatus'
import DOCUMENT_TYPE from './documentType'
import ENROLLMENT_ADMIN_STATUS from './enrollmentAdminStatus'
import ENROLLMENT_STATUS from './enrollmentStatus'
import ERROR from './error'
import UPLOAD_FILES from './files'
import FOLDER from './folder'
import INVITATION_STATUS from './invitationStatus'
import INVOICE from './invoice'
import KEY_MAIN_SCREENS from './keyMainScreens'
import LANGUAGES from './languages'
import LINKS from './links'
import LISTS from './list'
import LIST_PROPERTIES from './listProperties'
import MIME_TYPES from './mimeTypes'
import ORDER from './order'
import PAYMENT_COMPLEMENT_STATUS from './paymentComplementStatus'
import PRODUCT_STATUS from './productStatus'
import QUOTATION from './quotation'
import REGEX from './regex'
import { RELATION_KEY_URL } from './relationKeyUrl'
import REPORTS from './reports'
import REQUEST_FOR_CREDIT_MEMO_STATUS from './requestForCreditMemo'
import { default as ADMIN, default as USER_SETTINGS } from './settingsTabs'
import SIZE from './size'
import { default as PurchaseOrderStatus, default as STATUS } from './status'
import SYMBOL from './symbols'
import TEXT_MAIN_SCREENS from './textMainScreens'
import TICKET_REVIEW from './ticketReview'
import TICKET_STATUS from './ticketStatus'
import TOOLTIP from './toolTip'
import USER from './user'
import USER_STATUS from './userStatus'
import USER_TYPES from './userTypes'

export default {
	PAYMENT_COMPLEMENT_STATUS,
	TOOLTIP,
	PRODUCT_STATUS,
	CREDIT_MEMO_STATUS,
	REQUEST_FOR_CREDIT_MEMO_STATUS,
	REGEX,
	LIST_PROPERTIES,
	USER_STATUS,
	ENROLLMENT_STATUS,
	TICKET_STATUS,
	TICKET_REVIEW,
	ENROLLMENT_ADMIN_STATUS,
	ACTIONS_USER_STATUS,
	USER_TYPES,
	LINKS,
	COLORS,
	FOLDER,
	USER,
	SIZE,
	ERROR,
	LISTS,
	ORDER,
	SYMBOL,
	AUTH,
	QUOTATION,
	REPORTS,
	MIME_TYPES,
	LANGUAGES,
	USER_SETTINGS,
	ADMIN,
	DOCUMENT_TYPE,
	TEXT_MAIN_SCREENS,
	KEY_MAIN_SCREENS,
	RELATION_KEY_URL,
	GRAPH_BARS_COLORS,
	INVITATION_STATUS,
	UPLOAD_FILES,
	STATUS,
	PurchaseOrderStatus,
	DETAIL: 'DETAIL',
	INVOICE
}
