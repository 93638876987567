export class fillModal {
	private actionModal: string
	private textTitle: string
	private textQuestion: string
	private actionAccept: string
	private textAction: string
	private textCancel: string
	private contentTitle: string

	constructor(
		actionModal: string,
		textTitle: string,
		textQuestion: string,
		actionAccept: string,
		textAction: string,
		textCancel: string,
		contentTitle: string
	) {
		this.actionModal = actionModal
		this.textTitle = textTitle
		this.textQuestion = textQuestion
		this.actionAccept = actionAccept
		this.textAction = textAction
		this.textCancel = textCancel
		this.contentTitle = contentTitle
	}

	getActionModal() {
		return this.actionModal
	}

	getTitle() {
		return this.textTitle
	}

	getQuestion() {
		return this.textQuestion
	}

	getActionAccept() {
		return this.actionAccept
	}

	getTextAction() {
		return this.textAction
	}

	getTextCancel() {
		return this.textCancel
	}

	getContentTitle() {
		return this.contentTitle
	}
}
