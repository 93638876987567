import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'

interface CancelAssociationProps {
	onClose(): void
	onAccept(): void
	open: boolean
}

export const CancelAssociationModal: FC<CancelAssociationProps> = ({
	onClose,
	onAccept,
	open
}): ReactElement => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			titleText={t('cancelAssociation')}
			open={open}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('questionCancelAssociation')}</p>
		</NetcurioDialog>
	)
}
