import { gql } from 'graphql-tag'

export const INVITE = gql`
	query Invitation($uuid: ID!) {
		Invitation(uuid: $uuid) {
			uuid
			companies {
				rfc
				name
			}
			email
		}
	}
`

export const ACTIVATE_USER = gql`
	mutation activateUser(
		$email: ID!
		$name: String!
		$lastname: String!
		$phone_number: String!
		$time_zone: String!
	) {
		activateUser(
			email: $email
			name: $name
			lastname: $lastname
			phone_number: $phone_number
			time_zone: $time_zone
		) {
			code
			message
		}
	}
`
