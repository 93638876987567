import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const GOODS_RECEIPTS: DocumentNode = gql`
	query GoodsReceipts(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		GoodsReceipts(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			list {
				id
				customer {
					rfc
				}
				customer_reference
				created_at
				created_by {
					email
					name
					lastname
				}
				purchase_order {
					id
					supplier {
						rfc
						name
					}
					customer {
						rfc
					}
				}
			}
			total
		}
	}
`
