import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const PURCHASE_ORDERS_FOR_GR: DocumentNode = gql`
	query PurchaseOrders(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		PurchaseOrders(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			list {
				id
				status {
					key
					value
				}
				branch_office
				supplier {
					name
					rfc
				}
				customer {
					rfc
				}
				delivery_address_line_1
				delivery_address_line_2
				delivery_address_country
				delivery_address_state
				delivery_address_postal_code
				total
				items {
					position
					name
					confirmed_amount
					pending_delivery
					delivered_quantity
					unit
					code
				}
			}
			total
		}
	}
`

export const ITEM_PENDING_GOOD_RECEIPT: DocumentNode = gql`
	query ItemsPendingForGoodsReceipt($purchase_order: ID!, $customer: ID!) {
		ItemsPendingForGoodsReceipt(purchase_order: $purchase_order, customer: $customer) {
			position
			name
			code
			confirmed_amount
			pending_delivery
			unit
			storage_location
			requires_confirmation
		}
	}
`

export const CREATE_GOOD_RECEIPT: DocumentNode = gql`
	mutation createGoodsReceipt($purchase_order: ID, $items: [GoodsReceiptItemInput!]!) {
		createGoodsReceipt(purchase_order: $purchase_order, items: $items)
	}
`
