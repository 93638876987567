import { tableFillHeader } from '../../../../../utilities/tableFillHeader'
import { tableFillSelectionFilter } from '@netcurio/frontend-common'
import Constants from '../../../../../utilities/constants'
import styles from '../../../adminConsole.module.scss'

export function objectHeaderArray(): Array<tableFillHeader> {
	const idColumn: tableFillHeader = new tableFillHeader(
		'idProduct',
		styles.productRowStandard,
		'',
		'id',
		'text'
	)
	const descriptionColumn: tableFillHeader = new tableFillHeader(
		'descriptionProduct',
		styles.productRowxLarge,
		'',
		'description',
		'text'
	)
	const currencyColumn: tableFillHeader = new tableFillHeader(
		'currencyProduct',
		styles.productRowSmall,
		'',
		'currency',
		'text'
	)
	const unitPriceColumn: tableFillHeader = new tableFillHeader(
		'unitPrice',
		styles.productRowStandard,
		'',
		'unit_price',
		'numeric'
	)
	const measureColumn: tableFillHeader = new tableFillHeader(
		'unitMeasure',
		styles.productRowSmall,
		'',
		'unit',
		'text'
	)
	const statusColumn: tableFillHeader = new tableFillHeader(
		'statusHeader',
		styles.productRowSmall,
		'',
		'is_active',
		'status'
	)
	return [idColumn, descriptionColumn, unitPriceColumn, currencyColumn, measureColumn, statusColumn]
}

export function objectStatusFilterArray(): Array<tableFillSelectionFilter> {
	const activeStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.PRODUCT_STATUS.ACTIVE,
		'active-status-button'
	)
	const inactiveStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.PRODUCT_STATUS.INACTIVE,
		'inactive-status-button'
	)

	return [activeStatusOption, inactiveStatusOption]
}
