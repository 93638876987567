import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Filter, URLS } from '@netcurio/frontend-common'
import { Order } from '../../../../types'
import { UsersListTab } from './usersListTab/usersListTab'
import Constants from '../../../../utilities/constants'
import UserDetailTab from './userDetail/userDetailTab'

interface Props {
	chooseMainHeaderButton: (button: string) => void
	companyName: string
	setDataFilterArray: (dataFilter: Filter[], orderFilter: Order) => void
}

export default function UserSelectionScreen({
	chooseMainHeaderButton,
	companyName,
	setDataFilterArray
}: Props) {
	const { path } = useRouteMatch()
	useEffect(() => {
		if (window.location.pathname === URLS.ADMINISTRATION + '/' + Constants.ADMIN.ADMIN_TABS.USER_TAB)
			chooseMainHeaderButton('usersListTab')
		else chooseMainHeaderButton('userDetailTab')
	}, [window.location.pathname])

	return (
		<Switch>
			<Route exact path={path}>
				<UsersListTab setDataFilterArray={setDataFilterArray} />
			</Route>
			<Route path={`${path}/:email`}>
				<UserDetailTab companyName={companyName} />
			</Route>
		</Switch>
	)
}
