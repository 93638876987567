import { Filter, Modal, User, tableFillSelectionFilter } from '@netcurio/frontend-common'
import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	ResultNotFound,
	TagsFilter,
	TextInputFilter,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import { Dayjs } from 'dayjs'
import React, { FC, KeyboardEvent, useEffect, useMemo, useState } from 'react'
import { HeaderTable } from '../../../../../components/HeaderTable/HeaderTable'
import { connection } from '../../../../../utilities/connection'
import Constants from '../../../../../utilities/constants'
import { showErrorComponent } from '../../../../../utilities/errorCode'
import { expiredToken } from '../../../../../utilities/expiredToken'
import listHelper from '../../../../../utilities/listHelper'
import { tableFillHeader } from '../../../../../utilities/tableFillHeader'
import styles from '../../../adminConsole.module.scss'
import { ErrorModal } from '../Modals/ErrorModal/ErrorModal'
import {
	modalDefaultValues,
	styleMainTableDefaultValues,
	userListDefaultValues
} from './defaultValuesUserList'
import {
	fillFieldsHeaderTable,
	objectStatusFilterArray,
	objectTypeFilterArray
} from './fillFieldsHeaderTable'
import * as queries from './queries'
import { TableRow } from './tableRow'
import {
	DataUser,
	FilterToApply,
	ResultQueryUsers,
	StyleMainTable,
	UpdateFilter,
	UserList,
	UsersListTabProps
} from './types'

const fillFields = fillFieldsHeaderTable()

export const UsersListTab: FC<UsersListTabProps> = ({ setDataFilterArray }) => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const [userList, setUserList] = useState<UserList>(userListDefaultValues)
	const [resultNotFound, setResultNotFound] = useState<boolean>(false)
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [stopPagination, setStopPagination] = useState<boolean>(true)
	const [modal, setModal] = useState<Modal>(modalDefaultValues)
	const [styleMainTable, setStyleMainTable] = useState<StyleMainTable>(styleMainTableDefaultValues)
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const heightRow = 40
	let isMounted = true

	useEffect(() => {
		if (isMounted) {
			showLoadingSpinner()
			setShowBarLoader(true)
			const finalRowsHeight: number =
				window.innerHeight - Constants.USER_SETTINGS.ADMIN_HEIGHT_TABLES.ROWS_INFO_HEIGHT
			const numberRows: number = Math.round(finalRowsHeight / 44) + 1
			setUserList((prevState) => ({
				...prevState,
				limitRows: numberRows,
				rowsActual: numberRows
			}))
			const finalHeight: number =
				window.innerHeight - Constants.USER_SETTINGS.ADMIN_HEIGHT_TABLES.TABLE_HEIGHT
			const mainHeight: number =
				window.innerHeight - Constants.USER_SETTINGS.ADMIN_HEIGHT_TABLES.CONTAINER_HEIGHT
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: finalHeight,
				mainContainerHeight: mainHeight
			}))
		}
		return () => {
			isMounted = false
		}
	}, [])

	useEffect(() => {
		if (userList.limitRows !== undefined && isMounted) {
			filteringUsers(0, userList.initialSort, '', userList.initialSort, '')
		}
	}, [modal.errorCode, userList.limitRows])

	useEffect(() => {
		if (activePagination && stopPagination && isMounted) {
			setActivePagination(false)
			setStopPagination(false)
			filteringUsers(userList.rowsActual, '', '', '', undefined, undefined, undefined, 'none')
		}
	}, [activePagination, stopPagination])

	useEffect(() => {
		if (userList.totalRows && userList.rowsActual) {
			window.document
				.getElementsByClassName('tableInformation')[0]
				.addEventListener('scroll', handlePagination)
		}
		return () => {
			const element: Element = window.document.getElementsByClassName('tableInformation')[0]
			if (element) element.removeEventListener('scroll', handlePagination)
		}
	}, [userList.totalRows, userList.rowsActual])

	useEffect(() => {
		if (userList.deleteRange && userList.actualField === 'created_at') {
			setUserList((prevState) => ({
				...prevState,
				deleteRange: false
			}))
		}
		if (userList.filtersOfTypeStatus.status.dataFilter === 'return') {
			if (userList.fillStatusInputFilter.length !== objectStatusFilterArray().length) {
				setUserList((prevState) => ({
					...prevState,
					fillStatusInputFilter: objectStatusFilterArray()
				}))
			}
			if (userList.filterValue !== undefined) {
				setUserList((prevState) => ({
					...prevState,
					filterValue: undefined
				}))
			}
		} else if (
			userList.filtersOfTypeStatus.status.dataFilter !== '' &&
			userList.filtersOfTypeStatus.status.dataFilter
		) {
			if (
				!userList.fillStatusInputFilter.some((selectionFilter) => {
					return (
						selectionFilter.selectedParameter === userList.filtersOfTypeStatus.status.dataFilter
					)
				})
			) {
				setUserList((prevState) => {
					const newFillStatusInputFilter = prevState.fillStatusInputFilter
					newFillStatusInputFilter.push(
						new tableFillSelectionFilter(userList.filtersOfTypeStatus.status.dataFilter)
					)
					return {
						...prevState,
						fillStatusInputFilter: newFillStatusInputFilter
					}
				})
			}
		}

		if (userList.dataStatusFilter === 'return') {
			if (userList.fillTypeInputFilter.length !== objectTypeFilterArray().length) {
				setUserList((prevState) => ({
					...prevState,
					fillTypeInputFilter: objectTypeFilterArray()
				}))
			}
			if (userList.filterValue !== undefined) {
				setUserList((prevState) => ({
					...prevState,
					filterValue: undefined
				}))
			}
		}

		if (userList.filterContainerBar && styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				activeHeight: false,
				mainTableHeight: prevState.mainTableHeight - heightRow,
				mainContainerHeight: prevState.mainContainerHeight - heightRow
			}))
		} else if (!userList.filterContainerBar && !styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				mainTableHeight: prevState.mainTableHeight + heightRow,
				activeHeight: true,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))
		}
		setResultNotFound(userList.totalRows < 1)
	}, [
		userList.deleteRange,
		userList.filtersOfTypeStatus,
		userList.dataStatusFilter,
		userList.filterContainerBar,
		userList.totalRows
	])

	const handlePagination = () => {
		listHelper.handlePagination(
			'tableInformation',
			userList.totalRows,
			userList.rowsActual,
			filteringUsers,
			setActivePagination,
			setShowBarLoader
		)
	}

	const filteringUsers = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter = '',
		initRange?: number | Dayjs,
		finalRange?: number | Dayjs,
		filterRemove = ''
	): void => {
		let fieldListData: string = userList.fieldList
		let orderListData: string = userList.orderList
		let columnFilterActual: string = userList.actualFilterData
		let currentList: DataUser = { ...userList.dataUsers }

		if (sortOrder !== '') {
			orderListData = sortOrder
			setUserList((prevState) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortField) {
			setUserList((prevState) => ({
				...prevState,
				fieldList: sortField
			}))
			fieldListData = sortField
		}
		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setUserList((prevState) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}

		let typeFilterActual: string
		let initRangeActual: number | Dayjs
		let finalRangeActual: number | Dayjs
		const valueFilterActual: string = valueFilter

		switch (columnFilterActual) {
			case 'email':
			case 'name_lastname':
			case 'created_by':
				typeFilterActual = 'wildcard'
				break
			case 'status':
				typeFilterActual = 'exact_match'
				break
			case 'created_at':
				typeFilterActual = 'date'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
			case 'is_admin':
				typeFilterActual = 'boolean'
				break
		}

		if (skip > 0) {
			setUserList((prevState) => ({
				...prevState,
				rowsActual: userList.rowsActual + userList.limitRows
			}))
		} else {
			setUserList((prevState) => ({
				...prevState,
				rowsActual: prevState.limitRows,
				dataUsers: {}
			}))
			currentList = {}
		}

		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual || undefined,
			initRangeActual || undefined,
			finalRangeActual || undefined,
			columnFilterActual,
			filterRemove || undefined,
			sortField
		)

		setDataFilterArray(filterToApply, {
			sortField: fieldListData,
			sortOrder: orderListData
		})
		getUsers(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number | Dayjs,
		finalRangeActual: number | Dayjs,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		if (columnFilterActual === 'is_admin') {
			valueFilterActual = valueFilterActual === Constants.USER_TYPES.ADMINISTRATOR ? 'true' : 'false'
		}
		const resultFiltersToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			userList.dataFilters,
			userList.filtersOfTypeStatus
		) as FilterToApply

		const objUpdate: UpdateFilter = resultFiltersToApply.objectForStateUpdate
		setUserList((prevState) => ({
			...prevState,
			dataFilters: objUpdate.dataFilters,
			dataFiltersArray: objUpdate.dataFiltersArray,
			deleteRange: objUpdate.deleteRange,
			filterContainerBar: objUpdate.filterContainerBar
		}))

		if (objUpdate.filtersOfTypeStatus) {
			setUserList((prevState) => ({
				...prevState,
				filtersOfTypeStatus: objUpdate.filtersOfTypeStatus
			}))
		}
		if (objUpdate.dataStatusFilter) {
			setUserList((prevState) => ({
				...prevState,
				dataStatusFilter: objUpdate.dataStatusFilter
			}))
		}
		return resultFiltersToApply.filterToApply
	}

	const getUsers = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: DataUser
	) => {
		const copyFilterToApply: Array<Filter> = [...filterToApply]
		client
			.query({
				query: queries.USERS,
				variables: {
					limit: userList.limitRows,
					skip: skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyFilterToApply
				}
			})
			.then((result: ApolloQueryResult<ResultQueryUsers>) => {
				let interCount: number = skip
				const data: DataUser = { ...currentList }
				const obj: Array<User> = result.data.Users.list || []
				for (const keyName in obj) {
					interCount++
					data[interCount] = obj[keyName]
				}

				if (isMounted) {
					setUserList((prevState) => ({
						...prevState,
						totalRows: result.data.Users.total,
						dataUsers: data,
						dataStatusFilter: '',
						filtersOfTypeStatus: {
							...prevState.filtersOfTypeStatus,
							status: {
								...prevState.filtersOfTypeStatus.status,
								dataFilter: prevState.filtersOfTypeStatus.status.dataFilter || ''
							}
						}
					}))
				}
				setStopPagination(true)
				hideLoadingSpinner()
				setShowBarLoader(false)
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		const errorCode: string = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setUserList((prevState) => ({
				...prevState,
				dataUsers: {},
				filtersOfTypeStatus: {
					status: {
						...prevState.filtersOfTypeStatus.status,
						dataFilter: ''
					}
				},
				dataStatusFilter: ''
			}))
			setStopPagination(true)
			setModal({
				errorCode: errorCode
			})
		}
		hideLoadingSpinner()
		setShowBarLoader(false)
	}

	const showFilters = (field: string, fieldStyle: string) => {
		const filterActual: string = fieldStyle + 'Filter'
		let classActualField = 'display-none'
		let activeFilter: string
		switch (filterActual) {
			case 'dateFilter':
				classActualField = 'user-' + fieldStyle + '-filter'
				activeFilter = userList.dateFilter
				break
			case 'statusFilter':
				classActualField = 'user-' + fieldStyle + '-filter'
				activeFilter = userList.statusFilter
				break
			case 'typeFilter':
				classActualField = 'user-' + fieldStyle + '-filter'
				activeFilter = userList.typeFilter
				break
			case 'textFilter':
				classActualField = 'display-block'
				activeFilter = userList.textFilter
				break
		}

		if (activeFilter === 'display-none') {
			if (styleMainTable.mainTableHeight > window.innerHeight - 222) {
				setStyleMainTable((prevState) => ({
					...prevState,
					mainTableHeight: prevState.mainTableHeight - heightRow,
					mainContainerHeight: prevState.mainContainerHeight - heightRow
				}))
			}
			deleteDateRange()
			setUserList((prevState) => ({
				...prevState,
				initDate: undefined,
				finalDate: undefined,
				filterValue: '',
				dateFilter: 'display-none',
				statusFilter: 'display-none',
				textFilter: 'display-none',
				typeFilter: 'display-none',
				[filterActual]: classActualField,
				actualField: field,
				initDateErrorText: '',
				finalDateErrorText: '',
				textHeader: styles.textHeaderListAdmin
			}))
		} else if (userList.actualField === field) {
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + heightRow,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))
			deleteDateRange()
			setUserList((prevState) => ({
				...prevState,
				filterValue: '',
				[filterActual]: 'display-none',
				textHeader: styles.headerTableDefault
			}))
		} else {
			setUserList((prevState) => ({ ...prevState, actualField: field, filterValue: '' }))
		}
	}

	const deleteDateRange = () => {
		setUserList((prevState) => ({
			...prevState,
			initRange: undefined,
			finalRange: undefined
		}))
	}
	const closeFilterContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringUsers, userList.initialSort)
		deleteDateRange()
		setUserList((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) => {
		if (indexObject === 'created_at') {
			deleteDateRange()
		}
		listHelper.deleteFilterActive(
			indexObject,
			userList.dataFilters,
			closeFilterContainerBar,
			filteringUsers
		)
		setUserList((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		filterValue: string,
		objectRange?: { [key: string]: Dayjs }
	) => {
		showLoadingSpinner()
		let initRange: Dayjs = userList.initRange
		let finalRange: Dayjs = userList.finalRange

		if (objectRange) {
			if (objectRange.initRange) initRange = objectRange.initRange
			if (objectRange.finalRange) finalRange = objectRange.finalRange
		}

		if (sortField !== '') {
			filteringUsers(0, sortField, order, fieldFilter, undefined, undefined, undefined, undefined)
		} else {
			filteringUsers(0, sortField, order, fieldFilter, filterValue, initRange, finalRange, undefined)
		}
		setUserList((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const selectionFilter = (status: string, position: number) => {
		if (userList.filtersOfTypeStatus.status.numSelectOptions < 5) {
			if (userList.actualField === 'is_admin') {
				callDataList('', '', userList.actualField, status)
				const booleanOptions = objectTypeFilterArray().filter(
					(option) => option.getSelectedParameter() !== status
				)
				setUserList((prevState) => ({ ...prevState, fillTypeInputFilter: booleanOptions }))
			} else {
				callDataList('', '', userList.actualField, status)
				userList.fillStatusInputFilter.splice(position, 1)
			}
		}
	}

	const closeFilter = () => {
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: prevState.mainTableHeight + heightRow,
			mainContainerHeight: prevState.mainContainerHeight + heightRow
		}))
		deleteDateRange()
		setUserList((prevState) => ({
			...prevState,
			dateFilter: 'display-none',
			statusFilter: 'display-none',
			textFilter: 'display-none',
			typeFilter: 'display-none',
			textHeader: styles.headerTableDefault,
			filterValue: undefined
		}))
	}

	const handleDateRangeFilterChange = (evt: Dayjs, field: string) => {
		const evtValue: Dayjs = evt
		const dateActual = field + 'Date'
		const rangeActual = field + 'Range'
		if (evtValue !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', userList.actualField, undefined, {
					[rangeActual]: evtValue
				})
				setUserList((prevState) => ({
					...prevState,
					[dateActual]: evtValue,
					[rangeActual]: evtValue,
					[dateActual + 'StyleErrorText']: ''
				}))
				emptyFilterField()
			}
		}
	}

	const handleTextFilterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		const value = evt.target.value
		setUserList((prevState) => ({
			...prevState,
			filterValue: value
		}))
	}
	const emptyFilterField = () => {
		setUserList((prevState) => ({
			...prevState,
			filterValue: '',
			finalDate: undefined,
			initDate: undefined
		}))
	}

	const handleFilterKeyDown = (evt: KeyboardEvent<HTMLInputElement> | string) => {
		listHelper.handleFilterKeyDown(
			typeof evt === 'object' ? { ...evt, target: evt.target as HTMLInputElement } : evt,
			callDataList,
			emptyFilterField,
			userList.actualField,
			userList.filterValue
		)
	}

	return (
		<div className="tableInformationWhiteStyle margins-table">
			<div className={styles.headerMainTableUsersList}>
				{fillFields.map((item: tableFillHeader) => (
					<HeaderTable
						key={item.name}
						dataMainHeaderTable={item}
						textHeader={userList.textHeader}
						showFilters={showFilters}
					/>
				))}
			</div>
			<TagsFilter
				showTagsFilter={
					userList.actualField === 'is_admin' ? userList.typeFilter : userList.statusFilter
				}
				actualField={userList.actualField}
				callDataList={callDataList}
				selectionFilter={selectionFilter}
				closeFilter={closeFilter}
				fillInputFilter={
					userList.actualField === 'is_admin'
						? userList.fillTypeInputFilter
						: userList.fillStatusInputFilter
				}
			/>
			<DateInputFilter
				showDateFilter={userList.dateFilter}
				actualField={userList.actualField}
				initDate={userList.initDate}
				finalDate={userList.finalDate}
				callDataList={callDataList}
				initReferenceText="init"
				finReferenceText="final"
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			<TextInputFilter
				showTextFilter={userList.textFilter}
				actualField={userList.actualField}
				valueFilter={userList.filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			{userList.filterContainerBar ? (
				<ActiveFiltersBar
					closeFilterContainerBar={closeFilterContainerBar}
					dataFiltersArray={userList.dataFiltersArray}
					deleteFilterActive={deleteFilterActive}
					objectType={Constants.LISTS.PRODUCTS}
					booleanNames={{
						true: Constants.USER_TYPES.ADMINISTRATOR,
						false: Constants.USER_TYPES.STANDARD
					}}
				/>
			) : null}
			<div
				className="tableInformationWhiteStyle"
				style={{ height: styleMainTable.mainContainerHeight }}
			>
				<div className="tableInformation" style={{ height: styleMainTable.mainContainerHeight }}>
					<ResultNotFound showNotFound={resultNotFound} />
					{Object.keys(userList.dataUsers).map((key: string) => (
						<TableRow key={`product-${key}`} dataUser={userList.dataUsers[key]} />
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
			<ErrorModal open={!!modal?.errorCode} errorCode={modal?.errorCode} />
		</div>
	)
}
