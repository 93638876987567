import gql from 'graphql-tag'

export const CREATE_RFQ = gql`
	mutation createRequestForQuotation(
		$branch_office: String!
		$supplier: String!
		$delivery_address_line_1: String!
		$delivery_address_line_2: String
		$delivery_address_country_code: String!
		$delivery_address_country: String!
		$delivery_address_state: String!
		$delivery_address_state_code: String!
		$delivery_address_postal_code: String!
		$items: [RequestForQuotationItemInput!]!
		$expiration_date: Date
		$comment: CommentInput
		$branch_office_id: String
	) {
		createRequestForQuotation(
			branch_office: $branch_office
			supplier: $supplier
			delivery_address_line_1: $delivery_address_line_1
			delivery_address_line_2: $delivery_address_line_2
			delivery_address_country_code: $delivery_address_country_code
			delivery_address_country: $delivery_address_country
			delivery_address_state: $delivery_address_state
			delivery_address_state_code: $delivery_address_state_code
			delivery_address_postal_code: $delivery_address_postal_code
			items: $items
			expiration_date: $expiration_date
			comment: $comment
			branch_office_id: $branch_office_id
		)
	}
`
export const PRODUCT_NAME_CODE = gql`
	query ProductsByNameOrCode($searchText: String!, $supplier: ID!) {
		ProductsByNameOrCode(searchText: $searchText, supplier: $supplier) {
			id
			supplier {
				rfc
			}
			description
			unit_price
			unit
			tax
			ean
			currency
		}
	}
`
