import { Roles } from '@netcurio/frontend-common'
import { tableFillHeader } from '../../../../../utilities/tableFillHeader'
import styles from '../../../adminConsole.module.scss'

export function objectHeaderArray(userRole: Roles): Array<tableFillHeader> {
	const rfc_field = userRole === 'customer' ? 'supplier_rfc' : 'customer_rfc'
	const name_field = userRole === 'customer' ? 'supplier' : 'customer'

	const rfcColumn: tableFillHeader = new tableFillHeader(
		'companyRFC',
		styles.tableHeaderLargeSize,
		'',
		rfc_field,
		'text'
	)

	const customerReference: tableFillHeader = new tableFillHeader(
		'trackInformationTitle.customer_reference',
		styles.tableHeaderLargeSize,
		'',
		'customer_reference',
		'text'
	)

	const nameColumn: tableFillHeader = new tableFillHeader(
		'companySocialNameText',
		styles.tableHeaderxLargeSize,
		'',
		name_field,
		'text'
	)
	const registrationColumn: tableFillHeader = new tableFillHeader(
		'registrationTitle',
		styles.tableHeaderLargeSize,
		'',
		'created_at',
		'date'
	)
	return [nameColumn, rfcColumn, customerReference, registrationColumn]
}
