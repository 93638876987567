import { ItemQuotation, RequestForQuotation, StateRequestForQuotationDetail } from '../types'
import { QuotationItem } from '../types/QuotationItem'
import Constants from '../../../utilities/constants'

const informationDataRFQDefault: RequestForQuotation = {
	id: '',
	status: {
		key: '',
		value: ''
	},
	created_at: new Date(),
	updated_at: new Date(),
	expiration_date: new Date(),
	date_rejected: new Date(),
	date_closed: 0,
	date_quoted: 0,
	created_by: {},
	supplier: {},
	customer: {},
	branch_office: '',
	delivery_address_line_1: '',
	delivery_address_line_2: '',
	delivery_address_postal_code: '',
	delivery_address_state: '',
	delivery_address_country: '',
	items: [],
	quotations: [],
	comments: [],
	modified_by_supplier: {},
	customer_reference: '',
	setting_validate_catalogue: true
}

const dataRFQDefault: StateRequestForQuotationDetail = {
	showRejectRFQMessage: false,
	showClosedRFQMessage: false,
	showQuotationDetail: false,
	showNewQuotationComponent: false,
	cancelQuotationModal: false,
	createQuotationOpen: false,
	cleanReasons: false,
	resetDataQuotation: false,
	buttonContainer: true,
	showList: true,
	showButtonsNewQuotation: false,
	disabledNewQuotationButton: true,
	showNewQuotation: false,
	existMessagePending: false,
	showTrackInformation: true,
	showMainTableRFQ: true,
	closeRFQModal: false,
	goToList: false,
	pendingCommentModal: false,
	rejectRFQModal: false,
	openPDFModal: false,
	quotationClosedForClient: false,
	dateErrorRequired: false,
	quotationSaveDataModal: false,
	saveDiffPrices: false,
	differentCurrenciesModal: false,
	currencyQuotationRequired: false
}

const defaultNewLineQuotationItem: QuotationItem = {
	position: undefined,
	product_description: undefined,
	status: undefined,
	request_position: undefined,
	rejected_reason: undefined,
	delivery_proposal_date: undefined
}

const defaultItemRFQ: ItemQuotation = {
	itemsQ: [],
	netTotal: 0,
	rejectReason: undefined,
	status: Constants.STATUS.CONFIRMED,
	currencyPosition: '',
	sameCurrencyList: true,
	unitPrice: ''
}

export { informationDataRFQDefault, dataRFQDefault, defaultNewLineQuotationItem, defaultItemRFQ }
