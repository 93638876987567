import React from 'react'
import { NetcurioButton, NetcurioDialog, Severity } from '@netcurio/frontend-components'

interface ErrorModalProps {
	open: boolean
	onAccept: () => void
	title?: string
	bodyText: string
	textButton: string
	showInput?: boolean
	headerTitleSeverity?: Severity
}

export const ErrorModal = ({
	open,
	onAccept,
	title,
	bodyText,
	textButton,
	headerTitleSeverity
}: ErrorModalProps) => {
	return (
		<NetcurioDialog
			open={open}
			titleText={title}
			headerTitleSeverity={headerTitleSeverity}
			actionButtons={
				<NetcurioButton variant="contained" onClick={onAccept}>
					{textButton}
				</NetcurioButton>
			}
		>
			<p>{bodyText}</p>
		</NetcurioDialog>
	)
}
