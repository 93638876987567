import { getCurrentUser, URLS } from '@netcurio/frontend-common'
import { Auth } from 'aws-amplify'
import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { beforeUnloadListener } from '../../utilities/beforeUnloadListener'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { redirectToPage } from '../../utilities/redirectHelper'
import UserInformation from '../../utilities/userInformation'
import * as queries from '../../views/login/queries'

interface ErrorModalProps {
	fillModal?: Array<{
		getActionModal: () => string
		getActionAccept: () => string
		getTextAction: () => string
		getContentTitle?: () => string
		getQuestion: () => string
		getTitle: () => string
	}>
	errorCode?: string
	errorByActionClient?: boolean
	acceptActionModal?: (action: string) => void
	hideModal?: (action: string) => void
}

const defaultProps: ErrorModalProps = {
	fillModal: [
		{
			getActionModal: () => {
				console.warn('getActionModal is missing on errorModal')
				return ''
			},
			getActionAccept: () => {
				console.warn('getActionAccept is missing on errorModal')
				return ''
			},
			getTextAction: () => 'getTextAction is missing on errorModal',
			getContentTitle: () => 'getContentTitle is missing on errorModal',
			getQuestion: () => 'getQuestion is missing on errorModal',
			getTitle: () => 'getTitle is missing on errorModal'
		}
	],
	errorCode: '',
	errorByActionClient: false,
	acceptActionModal: () => console.warn('acceptActionModal is missing on errorModal'),
	hideModal: () => console.warn('hideModal is missing on errorModal')
}

export const ErrorModal = ({
	fillModal,
	errorCode,
	errorByActionClient,
	acceptActionModal,
	hideModal
}: ErrorModalProps = defaultProps) => {
	const { t } = useTranslation()
	const [state, setState] = useState({
		colorAccept: 'accept-button'
	})
	const { colorAccept } = state

	const boxQuestionResponseRef = useRef<HTMLDivElement>(null)
	const history = useHistory()

	const eventMouseOption = (optionButton: 'cancel' | 'confirm', event: 'over' | 'leave' | 'down') => {
		if (event === 'over') {
			if (optionButton === 'cancel') {
				setState((prev) => ({
					...prev,
					colorCancel: ' colorCancelOver '
				}))
			} else if (optionButton === 'confirm') {
				setState((prev) => ({
					...prev,
					colorAccept: 'colorAcceptOver '
				}))
			}
		} else if (event === 'leave') {
			if (optionButton === 'cancel') {
				setState((prev) => ({
					...prev,
					colorCancel: 'cancel-button'
				}))
			} else if (optionButton === 'confirm') {
				setState((prev) => ({
					...prev,
					colorAccept: 'accept-button'
				}))
			}
		}
	}

	const updateCompaniesUser = () => {
		const client = connection()
		return new Promise(function (resolve) {
			client
				.mutate({
					mutation: queries.LOGIN_USER
				})
				.then((result) => {
					UserInformation.setCurrentUser(result.data.loginUser, undefined)
					resolve(result)
				})
				.catch((error) => {
					console.error(error)
					resolve(error)
				})
		})
	}

	const acceptAction = () => {
		beforeUnloadListener('remove')
		if (errorCode === Constants.USER.USER_BLOCKED || errorCode === Constants.USER.USER_UNSUBSCRIBED) {
			const companiesUnblocked = UserInformation.getCompaniesUnblocked()
			if (companiesUnblocked.length === 1) {
				Auth.signOut()
					.then(() => {
						location.href = URLS.LOGIN
						localStorage.clear()
					})
					.catch((err) => console.error(err))
			} else {
				updateCompaniesUser()
					.then((result) => {
						const user = getCurrentUser()
						const companiesUpdated = (result as any).data.loginUser.companies.filter(
							(item: any) => item.status.key !== 'BLOCKED'
						)
						if (companiesUpdated.length >= 1) {
							location.replace(URLS.SELECT_COMPANY_AT_LOGIN)
						} else if (companiesUpdated.length === 0) {
							Auth.signOut()
								.then(() => {
									location.href = URLS.LOGIN
									localStorage.clear()
								})
								.catch((err) => console.error(err))
						} else {
							if (user) UserInformation.setCurrentUser(user, companiesUpdated[0])
							history.push(
								companiesUpdated[0].homepage
									? URLS[Constants.RELATION_KEY_URL[companiesUpdated[0].homepage]]
									: URLS.PO_LIST
							)
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
		} else if (hideModal && !errorByActionClient) {
			if (fillModal) {
				hideModal(fillModal[0].getActionAccept())
			}
		} else if (acceptActionModal === undefined) {
			redirectToPage()
		} else {
			if (fillModal) {
				acceptActionModal(fillModal[0].getActionAccept())
			}
		}
	}

	useEffect(() => {
		if (boxQuestionResponseRef.current) {
			boxQuestionResponseRef.current.style.height = '200px'
		}
	}, [])

	const titleHeight = fillModal ? fillModal[0].getTitle() : 'box-title-error-modal'
	return (
		<div id="modal" className={`modal-position displayFlex`}>
			<div className="body-modal">
				<div className={`box-title-modal ${titleHeight}`}>
					<Trans>
						<p className="text-title-modal">{fillModal?.[0].getTitle()}</p>
					</Trans>
				</div>
				<div className="box-question-response-modal" ref={boxQuestionResponseRef}>
					<div>
						<Trans>
							<p className="content-title-modal">{fillModal?.[0].getContentTitle?.()}</p>
						</Trans>
						<p className="box-question-modal box-error-modal gray-color-text-general-info">
							{!errorByActionClient
								? t(fillModal ? fillModal[0].getQuestion() : '', {
										idCode: errorCode
									})
								: t(fillModal ? fillModal[0].getQuestion() : '')}
						</p>
					</div>
					<div className="box-buttons-error">
						<div className="box-confirm">
							<button
								className={`button-modal ${colorAccept}`}
								onClick={() => acceptAction()}
								onMouseOver={() => eventMouseOption('confirm', 'over')}
								onMouseLeave={() => eventMouseOption('confirm', 'leave')}
								onMouseDown={() => eventMouseOption('confirm', 'down')}
							>
								<Trans>
									<p className="text-button">{fillModal?.[0].getTextAction()}</p>
								</Trans>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
