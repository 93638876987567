import arrowDown from '@netcurio/frontend-assets/src/icons/arrow-down-reports.svg'
import arrowUp from '@netcurio/frontend-assets/src/icons/arrow-up-reports.svg'
import creditMemoSvg from '@netcurio/frontend-assets/src/icons/nc.svg'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Translation } from 'react-i18next'
import { CreditMemo } from '../../types'
import Constants from '../../utilities/constants'
import CreditMemoListModule from './creditMemoDetail/creditMemoListModule'
import styles from './requestForCreditMemoDetail.module.scss'

export function ContainerAssociationDocument({
	creditMemos,
	showCreditMemoDetail,
	statusRFCM,
	readOnly
}: {
	creditMemos: CreditMemo[]
	showCreditMemoDetail: (creditMemo: CreditMemo) => void
	statusRFCM: string
	readOnly?: boolean
}) {
	const [showList, setShowList] = useState<boolean>(false)
	const [disabled, setDisabled] = useState<boolean>(false)
	const [disabledStyle, setDisabledStyle] = useState<string>(styles.containerCollapsedCreditMemo)
	useEffect(() => {
		if (creditMemos && creditMemos.length > 0) {
			setShowList(true)
		}
	}, [creditMemos])
	useEffect(() => {
		const disabled = statusRFCM === Constants.REQUEST_FOR_CREDIT_MEMO_STATUS.REJECTED
		setDisabled(disabled)
		if (disabled) setDisabledStyle(styles.containerCollapsedCreditMemoDisabled)
	}, [statusRFCM])

	function showListCreditNote() {
		if (showList) {
			return (
				<CreditMemoListModule
					creditMemos={creditMemos}
					showCreditMemoDetail={showCreditMemoDetail}
					statusRFCM={statusRFCM}
					readOnly={readOnly}
				/>
			)
		}
	}

	return (
		<Translation>
			{(t) => (
				<div>
					<div
						className={classNames(disabledStyle, {
							[styles.rfcmAssociationDocumentOpenContainer]: showList
						})}
						onClick={() => {
							if (!disabled) setShowList(!showList)
						}}
					>
						<div className={styles.titleContainerPaymentComplements}>
							<img
								alt={'credit memo'}
								src={creditMemoSvg}
								className={styles.iconPaymentComplement}
							/>
							<p className={styles.textTitlePaymentComplements}>{t('creditMemoText')}</p>
							<img
								alt={'credit memo'}
								src={showList ? arrowUp : arrowDown}
								className={styles.iconArrowDownRFCMDetail}
							/>
						</div>
					</div>
					{showListCreditNote()}
				</div>
			)}
		</Translation>
	)
}
