import { gql } from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const UPDATE_DEFAULT_COMPANY: DocumentNode = gql`
	mutation updateDefaultCompany($default_company: String!) {
		updateDefaultCompany(default_company: $default_company) {
			code
			message
		}
	}
`
