import React, { Suspense } from 'react'
import App from './app'
import { useEnvironment } from './hooks/useEnvironment'
import { initializeAmplify } from './utilities/initialize-amplify'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

const queryClient = new QueryClient()

const AppWrapper = () => {
	const { environment, isSuccess } = useEnvironment()

	if (isSuccess) {
		const region = environment?.COGNITO_REGION || ''
		const poolid = environment?.COGNITO_USERPOOLID || ''
		const clientid = environment?.COGNITO_CLIENTID || ''
		initializeAmplify(region, poolid, clientid)
	}

	return <App />
}

export const Bootstrap = () => {
	const { t } = useTranslation()
	return (
		<Suspense fallback={<h1>{t('LOADING')}...</h1>}>
			<QueryClientProvider client={queryClient}>
				<AppWrapper />
			</QueryClientProvider>
		</Suspense>
	)
}
