import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow,
	NetcurioTextField,
	NetcurioTooltip
} from '@netcurio/frontend-components'
import styles from './BatchesModal.module.scss'

interface ListBatchesProps {
	batchesInfo: Array<any>
	unit: string
}
export const ListBatches = ({ batchesInfo, unit }: ListBatchesProps) => {
	const { t } = useTranslation()
	return (
		<NetcurioTableContainer>
			<NetcurioTable size="small">
				<NetcurioTableHead>
					<NetcurioTableRow isDetailHeader>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={`${t('quantity')} - ${t('unitMeasure')}`} placement="top">
								<p className={styles.tableTitle}>
									{t('quantity')} - {t('unitMeasure')}
								</p>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('batchText')} placement="top">
								<p className={styles.tableTitle}> {t('batchText')}</p>
							</NetcurioTooltip>
						</NetcurioTableCell>
					</NetcurioTableRow>
				</NetcurioTableHead>
				<NetcurioTableBody>
					{batchesInfo.map((key) => (
						<NetcurioTableRow key={key.id}>
							<NetcurioTableCell align="center">
								<div className={styles.containerTableCell}>
									<NetcurioTextField
										size="small"
										fullWidth
										value={`${key.quantity} ${unit}`}
										placeholder={t('quantity')}
										disabled
									/>
								</div>
							</NetcurioTableCell>
							<NetcurioTableCell align="center">
								<div className={styles.containerTableCell}>
									<NetcurioTextField
										fullWidth
										size="small"
										value={key.batch}
										placeholder={t('batchText')}
										disabled
									/>
								</div>
							</NetcurioTableCell>
						</NetcurioTableRow>
					))}
				</NetcurioTableBody>
			</NetcurioTable>
		</NetcurioTableContainer>
	)
}
