import { AUTH, RELATION_KEY_URL, URLS, getCompany, getLanguage } from '@netcurio/frontend-common'
import {
	AdapterDayjs,
	LocalizationProvider,
	NetcurioLoaderSpinnerContextProvider,
	NetcurioSnackbarProvider,
	NetcurioTheme
} from '@netcurio/frontend-components'
import React, { useCallback, useState } from 'react'
import { render } from 'react-dom'
import { ErrorModal } from './components/dialogModal/errorModal'
import { useAuth } from './hooks/useAuth'
import { useStorageListener } from './hooks/useStorageListener'
import { modalCompanyChange } from './utilities/modalExternCompanyChange'
import { validationToRedirection } from './utilities/redirectHelper'
import HomeView from './views/homeView'
import MainView from './views/mainView'

const App = () => {
	const [state, setState] = useState({
		homeView: false,
		mainView: true
	})

	const { mainView } = state

	const homeView = () => {
		validationToRedirection(false)
			.then(() => {
				setState({ homeView: true, mainView: false })
				return <HomeView />
			})
			.catch((error: Error) => {
				console.error(error)
			})
		return null
	}

	const renderView = () => {
		if (mainView) {
			return <MainView homeView={homeView} />
		}
		return <HomeView />
	}

	const acceptActionModal = useCallback(() => {
		const company = getCompany()
		const homepage = company.homepage
		location.href = homepage ? URLS[RELATION_KEY_URL[homepage]] : URLS.PO_LIST
	}, [])

	const showModal = useCallback(() => {
		const parentModal = document.getElementById('parentModal')
		const element = (
			<ErrorModal
				fillModal={modalCompanyChange()}
				acceptActionModal={acceptActionModal}
				errorCode={''}
				errorByActionClient={true}
			/>
		)
		if (parentModal) {
			render(element, parentModal)
		}
	}, [acceptActionModal])

	useAuth({
		onTokenExpired: () => {
			setState({ homeView: false, mainView: true })
		}
	})
	useStorageListener(AUTH.NETCURIO_USER, showModal)

	return (
		<NetcurioTheme>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getLanguage()}>
				<NetcurioSnackbarProvider>
					<NetcurioLoaderSpinnerContextProvider>
						{renderView()}
					</NetcurioLoaderSpinnerContextProvider>
				</NetcurioSnackbarProvider>
			</LocalizationProvider>
		</NetcurioTheme>
	)
}

export default App
