export const beforeUnloadListener = (action: string) => {
	if (action === 'remove') window.removeEventListener('beforeunload', functionOnBeforeUnload)
	else window.addEventListener('beforeunload', functionOnBeforeUnload)
}

const functionOnBeforeUnload = (event: BeforeUnloadEvent) => {
	event.preventDefault()
	// Included for legacy support, e.g. Chrome/Edge < 119
	event.returnValue = 'Message not readable'
}
