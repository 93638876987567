import gql from 'graphql-tag'
export const INVOICE_COMMENTS = gql`
	query Invoice($uuid: ID!) {
		Invoice(uuid: $uuid) {
			uuid
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`
