import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const CREDIT_MEMOS: DocumentNode = gql`
	query CreditMemos(
		$limit: Int
		$skip: Int
		$sort_field: String
		$sort_order: String
		$filter: [FilterInput]
	) {
		CreditMemos(
			limit: $limit
			skip: $skip
			sort_field: $sort_field
			sort_order: $sort_order
			filter: $filter
		) {
			list {
				uuid
				serie
				folio
				sender {
					name
					rfc
				}
				receiver {
					name
					rfc
				}
				date
				total
				invoice_reference
				invoice {
					uuid
					serie
					folio
				}
				status {
					key
					value
				}
			}
			total
		}
	}
`
