export default {
	MAIN_COMPONENTS_HEIGHT: 106,
	HEADERS_HEIGHT: 97,
	WIDTH_TRACK_INFORMATION_SHORT_STATUS: 'width-short-status',
	WIDTH_TRACK_INFORMATION_LONG_STATUS_INVOICES: 'width-long-status-invoices',
	WIDTH_TRACK_INFORMATION_LONG_STATUS_PO: 'width-long-status',
	WIDTH_TRACK_INFORMATION_COMPLETE_STATUS: 'width-complete-status',
	HEIGHT_HEADER: 55,
	HEIGHT_TRACK_GRS_PC: 263,
	ERROR_MESSAGE_HEIGHT: 32,
	NEW_RFQ_SCROLL_HEIGHTS: {
		NEW_RFQ: 595,
		NEW_RFQ_MAX_NUMBER_OF_HEADER_ERRORS: 2
	},
	NEW_PURCHASE_ORDER_SCROLL_HEIGHTS: {
		PO_QUOTATION: 590,
		NEW_PO: 680
	},
	LIMIT_SIZE_FILES: 4.5,
	SIZE_MB: 1024
}
