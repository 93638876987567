import { gql } from 'graphql-tag'

export default {
	CUSTOMERS_BY_NAME_OR_CODE: gql`
		query CustomersByNameOrCode($search_text: String!, $sort_field: String, $sort_order: String) {
			CustomersByNameOrCode(
				search_text: $search_text
				sort_field: $sort_field
				sort_order: $sort_order
			) {
				rfc
				name
			}
		}
	`
}
