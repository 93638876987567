import React, { ChangeEvent, FC, useState } from 'react'
import {
	NetcurioIcons,
	NetcurioNumericField,
	NetcurioTableCell,
	NetcurioTableRow,
	NetcurioTextField,
	NetcurioTooltip,
	NetcurioIconButton
} from '@netcurio/frontend-components'
import { RegisterBatch } from '../../../../../types'
import { useTranslation } from 'react-i18next'
import styles from '../../NewGoodsReceipt/tableEntriesRecord.module.scss'

interface ItemsBatchProps {
	unit: string
	batchItem: RegisterBatch
	itemPosition: number
	handleItemFieldChange(input: string, value: string, position: number): void
	removeItem(position: number): void
	pendingDelivery: number
}

export const ItemsBatch: FC<ItemsBatchProps> = ({
	unit,
	batchItem,
	itemPosition,
	handleItemFieldChange,
	removeItem,
	pendingDelivery
}) => {
	const { t } = useTranslation()
	const [quantity, setQuantity] = useState<string>(batchItem.quantity ? batchItem.quantity.toString() : '')
	const [batch, setBatch] = useState<string>(batchItem.batch)

	const changeValueAmount = (evt: ChangeEvent<HTMLInputElement>) => {
		const quantityValue: string = evt.target.value
		handleItemFieldChange('quantity', quantityValue, itemPosition)
		setQuantity(quantityValue)
	}

	const changeValueBatch = (evt: ChangeEvent<HTMLInputElement>) => {
		const batchValue: string = evt.target.value
		handleItemFieldChange('batch', batchValue, itemPosition)
		setBatch(batchValue)
	}

	const deleteItem = () => {
		setQuantity('')
		setBatch('')
		removeItem(itemPosition)
	}

	return (
		<NetcurioTableRow>
			<NetcurioTableCell align="center">
				<NetcurioTooltip title={<label>{t('quantity')}</label>} placement="top">
					<div className={styles.containerTableCell}>
						<NetcurioNumericField
							size="small"
							fullWidth
							decimalScale={2}
							suffix={` ${unit}`}
							value={quantity}
							onChange={changeValueAmount}
							maxLimit={pendingDelivery}
							minLimit={0}
							error={batchItem.isValidQuantity}
							placeholder={t('quantity')}
							label={t('quantity')}
						/>
					</div>
				</NetcurioTooltip>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<div className={styles.containerTableCell}>
					<NetcurioTextField
						fullWidth
						size="small"
						value={batch}
						onChange={changeValueBatch}
						error={batchItem.isValidBatch}
						placeholder={t('batchText')}
						label={t('batchText')}
					/>
				</div>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<NetcurioIconButton onClick={deleteItem}>
					<NetcurioIcons.Close className={styles.registerBatchModalCloseBatchModalIcon} />
				</NetcurioIconButton>
			</NetcurioTableCell>
		</NetcurioTableRow>
	)
}
