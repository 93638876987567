import { Company, Status, User } from '@netcurio/frontend-common'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { fillModal } from '../../components/dialogModal/fillModal'

export enum ModalTypes {
	error,
	reviewTicket,
	closeTicket,
	reopenTicket
}

export interface ModalData {
	fillModal: fillModal[]

	acceptActionModal?(button?: string, motive?: string): void
}

export interface TicketDetail {
	id: string
	sender: Company
	receiver: Company
	category: {
		code: string
		description: string
	}
	status: Status
	created_by: User
	updated_by: User
	created_at: Date
	updated_at: Date
	date_review: Date
	date_close: Date
	subject: string
	description: string
	closing_reason: string
	document_creation: string
	document_revision: string
	can_review?: boolean
	can_create?: boolean
}

export interface InformationContainerProps {
	ticketDetail: TicketDetail
	additionalFile: File | undefined
	sender: string

	openModalPDF(value: string): void

	setAdditionalFile(additionalFile: File | undefined): void

	addRevisionDocument(): void
}

export interface DocumentSectionProps {
	tableHeight: string
	ticketDetail: TicketDetail
	additionalFile: File | undefined

	openModalPDF(value: string): void

	setAdditionalFile(additionalFile: File | undefined): void

	addRevisionDocument(): void
}

export interface LateralColumnProps {
	ticketDetail: TicketDetail
	client: DefaultClient<NormalizedCacheObject>
	ticketId: string
	sender: string

	handleError(error: Error): void

	actionButton(value: string): void
}
export interface ReviewButtonsProps {
	ticketDetail: TicketDetail
	actionButton(value: string): void
}
