import Grid, { GridProps } from '@mui/material/Grid'
import { ThemeOptions } from '@mui/material/styles'
import { FC } from 'react'

declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xxl: true
	}
}

export const NetcurioGridThemeOptions: ThemeOptions = {
	breakpoints: {
		keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
		values: {
			xs: 0,
			sm: 600,
			md: 905,
			lg: 1240,
			xl: 1440,
			xxl: 2000
		}
	}
}

export const NetcurioGrid: FC<GridProps> = ({ children, ...rest }) => {
	return <Grid {...rest}>{children}</Grid>
}

NetcurioGrid.displayName = 'NetcurioGrid'
