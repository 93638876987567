import dayjs from 'dayjs'
import { PurchaseOrder, PurchaseOrderItem } from '../../../../types'

export const defaultValuesItemPo: PurchaseOrderItem = {
	code: '',
	name: '',
	product: undefined,
	position: undefined,
	net_value: 0,
	unit_price: undefined,
	unit: '',
	status: '',
	storage_location_id: '',
	storage_location: '',
	reference_price: undefined,
	requested_amount: 1,
	confirmed_amount: 0,
	delivery_proposal_date: undefined,
	required_delivery_date: undefined,
	tax: undefined,
	rejected_reason: {
		key: '',
		value: ''
	},
	pending_delivery: undefined,
	delivered_quantity: undefined,
	price_change_reason: '',
	ean: '',
	purchase_order: '',
	customer_reference: '',
	supplier_reference: '',
	can_be_deleted: false,
	deleted: false,
	percent_tolerance: undefined,
	pending_quantity_to_invoice: undefined,
	receivedAmount: ''
}

export const defaultValuesPurchaseOrder: PurchaseOrder = {
	id: '',
	customer: {},
	supplier: {},
	items: [defaultValuesItemPo],
	total: 0,
	status: {
		value: '',
		key: ''
	},
	created_at: dayjs(new Date()),
	updated_at: dayjs(new Date()),
	delivery_address_country_code: '',
	delivery_address_state_code: '',
	goodsReceipts: [],
	invoices: [],
	comments: [],
	type: {
		value: '',
		key: ''
	}
}
