import React, { FC, useState } from 'react'
import classNames from 'classnames'
import { PURCHASE, Comment } from '@netcurio/frontend-common'
import { useTranslation } from 'react-i18next'
import { TotalContainerBar } from '@netcurio/frontend-components/src'
import {
	CommentSectionSize,
	CommentSectionVariant,
	CommentsSection,
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons
} from '@netcurio/frontend-components'
import Formatter from '../../utilities/formatter'
import styles from './purchaseOrderEdit.module.scss'
import { PurchaseOrder } from '../../types'

interface TotalRelationContainerProps {
	partialConfirmation: boolean
	informationPurchaseOrder: PurchaseOrder
	addNewComment(newCommentData: Comment): void
	commentsCommentSection: Array<Comment>
	confirmOrder(): void
	pendingMessageHandler(pendingMessage: string): void
	disabledConfirmOrderButton: boolean
	rejectOrder(): void
}

export const TotalRelationContainer: FC<TotalRelationContainerProps> = ({
	partialConfirmation,
	informationPurchaseOrder,
	addNewComment,
	commentsCommentSection,
	confirmOrder,
	pendingMessageHandler,
	disabledConfirmOrderButton,
	rejectOrder
}) => {
	const { t } = useTranslation()
	const POType = informationPurchaseOrder.type ? informationPurchaseOrder.type.key : ''
	const [isCommentsSectionExtended, setIsCommentsSectionExtended] = useState<boolean>(false)

	const handleCommentsSizeChange = (commentsSectionSize: CommentSectionSize) => {
		setIsCommentsSectionExtended(commentsSectionSize === CommentSectionSize.Extended)
	}

	return (
		<>
			{POType !== PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT && !isCommentsSectionExtended && (
				<NetcurioGrid item marginBottom="1rem">
					<TotalContainerBar
						title={'totalText'}
						fields={{
							currencyText: informationPurchaseOrder.currency
						}}
						total={{
							totalDotText: Formatter.currency.format(informationPurchaseOrder.total)
						}}
					/>
				</NetcurioGrid>
			)}

			<NetcurioGrid
				item
				className={classNames(styles.commentSection, {
					[styles.grownSection]: isCommentsSectionExtended
				})}
			>
				<CommentsSection
					variant={CommentSectionVariant.WithBody}
					comments={commentsCommentSection}
					onAddComment={addNewComment}
					onSizeChange={handleCommentsSizeChange}
					onChange={pendingMessageHandler}
				/>
				{!isCommentsSectionExtended && (
					<NetcurioGrid
						container
						width="100%"
						marginTop="0.5rem"
						display="flex"
						justifyContent="flex-end"
					>
						<NetcurioGrid item width="20rem" marginY="0.5rem">
							<NetcurioButton
								variant="outlined"
								color="primary"
								endIcon={<NetcurioIcons.Check />}
								onClick={confirmOrder}
								fullWidth
								disabled={disabledConfirmOrderButton}
							>
								{t(partialConfirmation ? 'partialConfirmOrder' : 'confirmOrder')}
							</NetcurioButton>
						</NetcurioGrid>
						<NetcurioGrid item width="20rem" marginY="0.5rem">
							<NetcurioButton
								variant="text"
								color="secondary"
								fullWidth
								endIcon={<NetcurioIcons.Close />}
								onClick={rejectOrder}
							>
								{t('rejectOrder')}
							</NetcurioButton>
						</NetcurioGrid>
					</NetcurioGrid>
				)}
			</NetcurioGrid>
		</>
	)
}
