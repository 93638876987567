import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface CancelNewRequestForCreditMemoModalProps {
	openModal: boolean
	onCloseModal(): void
	cancel(): void
}
/**
 * CancelNewRequestForCreditMemoModal
 * @description Helps to display a modal when the user is trying to reject a RFQ
 * @param openModal <boolean>: If `true`, the component is shown
 * @param onCloseModal <boolean>: If `false`, the component is hide
 * @param cancel <void>: Callback fired when the user cancels the action the modal
 * @return component <ReactNode>
 **/
export const CancelNewRequestForCreditMemoModal = ({
	openModal,
	onCloseModal,
	cancel
}: CancelNewRequestForCreditMemoModalProps) => {
	const { t } = useTranslation()

	const triggerCancel = () => {
		cancel()
	}

	const triggerClose = () => {
		onCloseModal()
	}
	return (
		<NetcurioDialog
			titleText={t('titleCreditMemoDetailsExit')}
			open={openModal}
			actionButtons={
				<>
					<NetcurioButton onClick={triggerClose}>{t('comeBackTextModal')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={triggerCancel}>
						{t('cancelButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('cancelRFCMQuestion')}</p>
		</NetcurioDialog>
	)
}
