import { useEffect, useMemo, useState } from 'react'
import { CompanySettings } from '../types/CompanySettings'
import { connection } from '../utilities/connection'
import { COMPANY_SETTINGS } from '../views/invoices/graphql'

interface Props {
	rfc: string | null
	errorHandler?: (error: Error) => void
}

export const useCompanySettings = ({ rfc, errorHandler }: Props) => {
	const [companySettings, setCompanySettings] = useState<CompanySettings | null>(null)
	const client = useMemo(() => connection(), [])

	useEffect(() => {
		if (rfc) {
			client
				.query({
					query: COMPANY_SETTINGS,
					variables: {
						rfc
					}
				})
				.then((result) => {
					setCompanySettings(result.data.CompanySettings)
				})
				.catch(errorHandler)
		}
	}, [client, rfc, errorHandler])

	return { companySettings }
}
