import React, { ReactElement, createContext, useState } from 'react'
import { CommentsSectionProps } from './CommentsSection'

enum CommentSectionSize {
	Minimized,
	Intermediate,
	Extended
}

enum CommentSectionVariant {
	Simple,
	WithBody,
	ReadOnly
}

interface CommentsSectionContextValue extends Partial<CommentsSectionProps> {
	showInput?: boolean
	setShowInput?(newValue: boolean): void
	onExtendBody?(): void
	onMinimizeBody?(): void
	commentSectionSize?: CommentSectionStateSize
	setCommentSectionSize?: React.Dispatch<React.SetStateAction<CommentSectionStateSize>>
	variant?: CommentSectionVariant
}

const CommentsSectionContext = createContext<CommentsSectionContextValue>({
	commentSectionSize: {
		currentSize: CommentSectionSize.Intermediate,
		previousSize: CommentSectionSize.Intermediate
	}
})

export interface CommentSectionStateSize {
	currentSize: CommentSectionSize
	/*	 We need to save the previousSize to toggle between the resizing correctly	 */
	previousSize: CommentSectionSize
}

export const CommentsSectionContextProvider = ({
	children,
	...props
}: React.PropsWithChildren<CommentsSectionContextValue>): ReactElement => {
	const [showInput, setShowInput] = useState<boolean>(false)

	const [commentSectionSize, setCommentSectionSize] = useState<CommentSectionStateSize>({
		currentSize: CommentSectionSize.Intermediate,
		previousSize: CommentSectionSize.Intermediate
	})

	const handleExtendBody = (): void => {
		const nextSize =
			commentSectionSize.currentSize === CommentSectionSize.Intermediate
				? CommentSectionSize.Extended
				: CommentSectionSize.Intermediate

		setCommentSectionSize((previousState) => ({
			currentSize: nextSize,
			previousSize: previousState.currentSize
		}))

		if (props.onSizeChange) props.onSizeChange(nextSize)
	}

	const handleMinimizeBody = (): void => {
		let nextSize: CommentSectionSize
		let previousSize: CommentSectionSize

		if (commentSectionSize.currentSize !== CommentSectionSize.Minimized) {
			nextSize = CommentSectionSize.Minimized
			previousSize = commentSectionSize.currentSize
		} else {
			nextSize = commentSectionSize.previousSize
			previousSize = CommentSectionSize.Minimized
		}

		setCommentSectionSize(() => ({
			currentSize: nextSize,
			previousSize: previousSize
		}))

		if (props.onSizeChange) props.onSizeChange(nextSize)
	}

	return (
		<CommentsSectionContext.Provider
			value={{
				...props,
				showInput,
				setShowInput,
				onExtendBody: handleExtendBody,
				onMinimizeBody: handleMinimizeBody,
				commentSectionSize,
				setCommentSectionSize
			}}
		>
			{children}
		</CommentsSectionContext.Provider>
	)
}

export { CommentSectionSize, CommentSectionVariant, CommentsSectionContext }
