import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const CREATE_CONSUMPTION_COMMENT: DocumentNode = gql`
	mutation createConsumptionComment($consumption: ID!, $customer: ID!, $text: String!) {
		createConsumptionComment(consumption: $consumption, customer: $customer, text: $text) {
			code
			message
		}
	}
`
