import { gql } from 'graphql-tag'

export const REQUEST_FOR_CREDIT_MEMO = gql`
	query RequestForCreditMemo($id: ID!, $customer: ID!) {
		RequestForCreditMemo(id: $id, customer: $customer) {
			id
			customer {
				rfc
				name
			}
			supplier {
				rfc
				name
			}
			status {
				key
				value
			}
			invoice {
				uuid
				serie
				folio
				currency
				total
			}
			request_reason
			rejected_reason
			items {
				position
				concept_description
				amount
				net_amount
				tax
			}
			created_by {
				name
				lastname
				email
			}
			modified_by_customer {
				name
				lastname
				email
			}
			modified_by_supplier {
				name
				lastname
				email
			}
			created_at
			updated_at
			date_accepted
			date_rejected
			date_closed
			customer_reference
			user_reference
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
			credit_memos {
				uuid
				status {
					key
					value
				}
				sender {
					name
					rfc
				}
				receiver {
					name
					rfc
				}
				uploaded_by
				serie
				folio
				payment_method
				currency
				subtotal
				iva
				total
				invoice {
					uuid
					serie
					folio
				}
				date
				created_at
				updated_at
				comments {
					uuid
					text
					created_by {
						email
						name
						lastname
					}
					created_at
				}
				type
				error
				modified_by_customer
				modified_by_supplier
				date_voided
				date_accounting
				items {
					position
					concept
					quantity
					net_amount
					tax
					unit_price
					reference_position
					unit
					request_for_credit_memo_item {
						concept_description
						net_amount
						tax
					}
				}
				request_for_credit_memo {
					id
					customer {
						rfc
					}
					customer_reference
				}
			}
		}
	}
`

export const ACCEPT_CREDIT_MEMO_REQUEST = gql`
	mutation acceptRequestForCreditMemo($id: ID!, $customer: ID!) {
		acceptRequestForCreditMemo(id: $id, customer: $customer) {
			code
			message
		}
	}
`

export const REJECT_CREDIT_MEMO_REQUEST = gql`
	mutation RejectRequestForCreditMemo($id: ID!, $customer: ID!, $rejected_reason: String!) {
		rejectRequestForCreditMemo(id: $id, customer: $customer, rejected_reason: $rejected_reason) {
			code
			message
		}
	}
`
export const RFCM_COMMENTS = gql`
	query RequestForCreditMemo($id: ID!, $customer: ID!) {
		RequestForCreditMemo(id: $id, customer: $customer) {
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`

export const CREATE_RFCM_COMMENT = gql`
	mutation createRFCMComment($requestForCreditMemo: ID!, $customer: ID!, $text: String!) {
		createRFCMComment(requestForCreditMemo: $requestForCreditMemo, customer: $customer, text: $text) {
			code
			message
		}
	}
`
