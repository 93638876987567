import { Comment, URLS } from '@netcurio/frontend-common'
import { FileViewerModal } from '@netcurio/frontend-components'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useEnvironment } from '../../../hooks/useEnvironment'
import Constants from '../../../utilities/constants'
import { RequestForQuotation, StateRequestForQuotationDetail } from '../types'
import { AlertClosedRFQModal } from './AlertClosedRFQModal'
import { CancelAddNewCommentModal } from './CancelAddNewCommentModal'
import { CancelNewRFQModal } from './CancelNewRFQModal'
import { CloseRFQModal } from './CloseRFQModal/CloseRFQModal'
import { ErrorRFQModal } from './ErrorRFQModal'
import { RejectRequestForQuotationModal } from './RejectRequestForQuotationModal/RejectRequestForQuotationModal'
import { SaveQuotationModal } from './SaveQuotationModal'

interface ViewAllModalRFQProps {
	dataRFQ: StateRequestForQuotationDetail
	setDataRFQ: Dispatch<SetStateAction<any>>
	cancelQuotation(): void
	addNewComment(newCommentData: Comment): void
	commentPending: { text: string }
	informationRFQ: RequestForQuotation
	errorCodeModal: string
	saveQuotation(): void
	resetDataPage(): void
}

export const ViewAllModalsRFQ: FC<ViewAllModalRFQProps> = ({
	dataRFQ,
	setDataRFQ,
	cancelQuotation,
	addNewComment,
	commentPending,
	informationRFQ,
	errorCodeModal,
	saveQuotation,
	resetDataPage
}) => {
	const { t } = useTranslation()
	const history = useHistory()
	const { environment } = useEnvironment()
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }

	return (
		<>
			<CancelNewRFQModal
				openModal={dataRFQ.cancelQuotationModal}
				onCloseModal={() =>
					setDataRFQ((prevState) => ({ ...prevState, cancelQuotationModal: false }))
				}
				onAcceptModal={cancelQuotation}
			/>
			<CancelAddNewCommentModal
				openModal={dataRFQ.pendingCommentModal}
				onCloseModal={() => setDataRFQ((prevState) => ({ ...prevState, pendingCommentModal: false }))}
				onAcceptModal={() => history.push(URLS.RFQ)}
			/>
			<RejectRequestForQuotationModal
				openModal={dataRFQ.rejectRFQModal}
				addNewComment={() => addNewComment(commentPending)}
				onCloseModal={() => setDataRFQ((prevState) => ({ ...prevState, rejectRFQModal: false }))}
				resetDataPage={resetDataPage}
			/>
			<CloseRFQModal
				openModal={dataRFQ.closeRFQModal}
				addNewComment={() => addNewComment(commentPending)}
				onCloseModal={() => setDataRFQ((prevState) => ({ ...prevState, closeRFQModal: false }))}
				resetDataPage={resetDataPage}
			/>
			<FileViewerModal
				open={dataRFQ.openPDFModal}
				onClose={() => setDataRFQ((prevState) => ({ ...prevState, openPDFModal: false }))}
				titleText={t('pdfAttachmentText')}
				fileSrc={
					informationRFQ.attachment
						? `${S3_BUCKET}${Constants.FOLDER.RFQ}/${informationRFQ.attachment}.pdf`
						: ''
				}
			/>
			<ErrorRFQModal open={!!errorCodeModal} errorCode={errorCodeModal} />
			<AlertClosedRFQModal
				open={dataRFQ.quotationClosedForClient}
				accept={() =>
					setDataRFQ((prevState) => ({
						...prevState,
						quotationClosedForClient: false
					}))
				}
				bodyText={t('messageQuotationClosed')}
				textButton={t('acceptTextButton')}
			/>
			<SaveQuotationModal
				openModal={dataRFQ.quotationSaveDataModal}
				closeModal={() =>
					setDataRFQ((prevState) => ({
						...prevState,
						quotationSaveDataModal: false
					}))
				}
				onAcceptModal={saveQuotation}
				saveDiffPrices={dataRFQ.saveDiffPrices}
			/>
		</>
	)
}
