import React from 'react'
import { Translation } from 'react-i18next'
import { NetcurioButton, NetcurioIcons } from '@netcurio/frontend-components'
import styles from './enrollmentDetail.module.scss'

interface IFileInformation {
	[index: string]: {
		column: string
		uuid?: string
	}
}

interface IEnrollmentDocumentsProps {
	enrollmentFiles: IFileInformation
	setUUIDFile: (uuid: string) => void
	hasDocuments: () => boolean
}

const EnrollmentFiles = ({
	enrollmentFiles,
	setUUIDFile,
	hasDocuments
}: IEnrollmentDocumentsProps): JSX.Element => {
	return (
		<Translation>
			{(t) => (
				<>
					<div className={styles.headerContainerEnrollmentDetails}>
						<div className={styles.displayFlex}>
							<p className={styles.titleHeaderEnrollmentDetails}>{t('fileUploadTitle')}</p>
						</div>
					</div>
					<div className={`${styles.containerTrackEnrollment} ${styles.grayColorText}`}>
						{Object.keys(enrollmentFiles).map(
							(key) =>
								enrollmentFiles[key].uuid && (
									<div className={styles.enrollmentDocumentsRow} key={key}>
										<span>{t(`${key}NewCompany`)}</span>
										<NetcurioButton
											variant={'outlined'}
											size={'small'}
											sx={{
												height: '3rem',
												marginTop: '0.5rem',
												border: 'solid 0.1rem'
											}}
											onClick={() => setUUIDFile(enrollmentFiles[key].uuid)}
											endIcon={<NetcurioIcons.RemoveRedEyeOutlined />}
										>
											<p>{t('viewDocument')}</p>
										</NetcurioButton>
									</div>
								)
						)}
						{!hasDocuments() && (
							<p className={styles.enrollmentNoDocuments}>{t('enrollmentNoDocuments')}</p>
						)}
					</div>
				</>
			)}
		</Translation>
	)
}
export default EnrollmentFiles
