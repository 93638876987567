import React from 'react'
import { Translation } from 'react-i18next'
import { SquareInfo } from './squareInfo'
import { RelatedCompanyInformation } from './relatedCompanyDetail'
import { parseTimestampToTimeZone, dateFormatter } from '@netcurio/frontend-common'

interface ITrackInformationProps {
	companyInformation: RelatedCompanyInformation
}

const TrackInformation = ({ companyInformation }: ITrackInformationProps) => {
	return (
		<Translation>
			{(t) => (
				<>
					<SquareInfo title={t('rfcNewCompany')} text={companyInformation?.rfc} />
					<SquareInfo
						title={t('addressInputNewCompany')}
						text={companyInformation?.address_line_1}
					/>
					<SquareInfo title={t('tokenNewCompany')} text={companyInformation?.state_code} />
					<SquareInfo title={t('countryTokenLabel')} text={companyInformation?.country_code} />
					<SquareInfo title={t('zipcodeNewCompany')} text={companyInformation?.postal_code} />
					<SquareInfo
						title={t('industryNewCompany')}
						text={companyInformation?.industry_description}
					/>
					<SquareInfo
						title={t('registrationTitleTooltip')}
						text={dateFormatter.format(parseTimestampToTimeZone(companyInformation?.created_at))}
					/>
					<SquareInfo
						title={t('payment_days_COMPANIES')}
						text={companyInformation?.payment_days + ''}
						id={'payment-days'}
						tooltip={t('paymentDaysTooltip')}
					/>
				</>
			)}
		</Translation>
	)
}
export default TrackInformation
