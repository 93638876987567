import React, { FC } from 'react'
import styles from '../requestForQuotation.module.scss'
import { InformationRFQItem } from '../types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { RowTable } from './RowTable'

interface MainTableRFQDetailProps {
	informationRFQItems: Array<InformationRFQItem>
}

export const MainTableRFQDetail: FC<MainTableRFQDetailProps> = ({ informationRFQItems }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.mainTableRFQ}>
			<div className={styles.stickyTableHeaderTop}>
				<div className={styles.separatorGray} />
				<div className={styles.displayBlock}>
					<div className={styles.headerMainTableTitle}>
						<span>{t('productsText')}</span>
					</div>
					<div className={styles.headerMainTableRFQDetail}>
						<p className={classNames(styles.textHeaderMainTableRFQ, styles.smallHeader)}>
							{t('positionText')}
						</p>
						<p className={classNames(styles.textHeaderMainTableRFQ, styles.bigHeaderRFQ)}>
							{t('productText')}
						</p>
						<p className={classNames(styles.textHeaderMainTableRFQ, styles.smallHeader)}>
							{t('amountUMText')}
						</p>
						<p className={classNames(styles.textHeaderMainTableRFQ, styles.smallHeader)}>
							{t('requiredDeliveryDateText')}
						</p>
					</div>
				</div>
			</div>
			{informationRFQItems.map((itemRFQ: InformationRFQItem, key: number) => (
				<RowTable key={itemRFQ.position + '-' + key} informationItem={itemRFQ} />
			))}
		</div>
	)
}
