import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'

interface ChangeUserRoleModalProps {
	open: boolean
	close: () => void
	accept: () => void
	isChangeToAdmin: boolean
}
/**
 * ChangeUserRoleModal
 * @description Helps to display a message when user wants to change type or role for another user´s account
 * @param open <boolean>: tells if the dialog is open or not
 * @param close <function>: this function handles any action on the parent when close action is triggered
 * @param unblock <function>: this function handles any action on the parent when unblocking action is triggered
 * @param isChangeToAdmin <boolean>: this is true when the user is to be changed to an Admin, not the other way around
 * @returns Modal component
 */
export const ChangeUserRoleModal = ({
	open,
	close,
	accept,
	isChangeToAdmin
}: ChangeUserRoleModalProps): ReactElement => {
	const { t } = useTranslation()

	const handleClose = () => {
		close()
	}

	const handleAccept = () => {
		accept()
	}
	return (
		<>
			<NetcurioDialog
				open={open}
				onClose={handleClose}
				titleText={t('changeUserType')}
				actionButtons={
					<>
						<NetcurioButton variant="text" onClick={handleClose}>
							{t('cancelButton')}
						</NetcurioButton>
						<NetcurioButton variant="contained" onClick={handleAccept}>
							{t('saveButton')}
						</NetcurioButton>
					</>
				}
			>
				<p>
					{isChangeToAdmin
						? t('changeUserRoleToAdminConfirmation')
						: t('changeUserRoleToStandardConfirmation')}
				</p>
			</NetcurioDialog>
		</>
	)
}
