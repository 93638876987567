import cancelIcon from '@netcurio/frontend-assets/src/icons/cancel-red.svg'
import cancelSolidRed from '@netcurio/frontend-assets/src/icons/cancel-solid-red.svg'
import checkSolidGreen from '@netcurio/frontend-assets/src/icons/check-solid-green.svg'
import checkSolidYellow from '@netcurio/frontend-assets/src/icons/check-solid-yellow.svg'
import checkBlue from '@netcurio/frontend-assets/src/icons/check_blue.svg'
import { PURCHASE, allowDecimals, dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import {
	NetcurioAutocomplete,
	NetcurioCheckbox,
	NetcurioDatePicker,
	NetcurioTextField,
	NetcurioTooltip,
	PricesInformation
} from '@netcurio/frontend-components'
import dayjs, { Dayjs } from 'dayjs'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConditionalRender } from '../../components/ConditionalRender/ConditionalRender'
import Constants from '../../utilities/constants'
import Formatter from '../../utilities/formatter'
import './styles.scss'

export const TableRow = ({
	confirmedAllItemsProps,
	informationTable,
	dateCopyAllItemsProps,
	positionKey,
	purchaseOrderType,
	setReasonItem,
	setRowSelected,
	changeConfirmAmount,
	changeRequiredDateProps,
	fillConsecutiveDate,
	selectedRow,
	allReasons
}): ReactElement => {
	const { t } = useTranslation()
	const [confirmedAllItems, setConfirmedAllItems] = useState<boolean>(false)
	const [checkConfirm, setCheckConfirm] = useState(checkBlue)
	const [checkCancel, setCheckCancel] = useState(cancelIcon)
	const [valueAmountConfirm, setValueAmountConfirm] = useState<number | string>('')
	const [valueRequiredDate, setValueRequiredDate] = useState<Dayjs | undefined>(undefined)
	const [fillRejectField, setFillRejectField] = useState<boolean>(false)
	const [selectedReason, setSelectedReason] = useState(undefined)
	const [checkDate, setCheckDate] = useState<boolean>(false)
	const [placeholderNA, setPlaceholderNA] = useState<string>('')
	const [isRejectedPosition, setIsRejectedPosition] = useState<boolean>(false)
	const [imgCheckPosition, setImgCheckPosition] = useState<string>('')

	useEffect(() => {
		if (confirmedAllItemsProps) {
			if (!confirmedAllItems) {
				let confirmCheckSolidGreen = ''
				if (informationTable.required_delivery_date) {
					confirmCheckSolidGreen = checkSolidGreen
				}
				setImgCheckPosition(confirmCheckSolidGreen)
				setCheckConfirm(checkSolidGreen)
				setCheckCancel(cancelIcon)
				setValueAmountConfirm(informationTable.requested_amount)
				setValueRequiredDate(
					informationTable.delivery_proposal_date
						? dayjs(informationTable.delivery_proposal_date)
						: undefined
				)
				setConfirmedAllItems(true)
				setCheckDate(true)
				setIsRejectedPosition(false)
				setFillRejectField(false)
				setSelectedReason(undefined)
			}
		} else {
			setCheckDate(false)
			if (confirmedAllItems) {
				setCheckConfirm(checkBlue)
				setCheckCancel(cancelIcon)
				setValueAmountConfirm('')
				setValueRequiredDate(undefined)
				setConfirmedAllItems(false)
				setFillRejectField(false)
				setSelectedReason(undefined)
			}
		}
		if (dateCopyAllItemsProps) {
			if (informationTable.delivery_proposal_date) {
				setCheckDate(true)
			} else if (valueRequiredDate) {
				setValueRequiredDate(undefined)
				setCheckDate(false)
			}
			if (
				informationTable.delivery_proposal_date &&
				valueRequiredDate !== informationTable.delivery_proposal_date
			) {
				setValueRequiredDate(informationTable.delivery_proposal_date)
				setCheckDate(true)
			}
		} else if (!informationTable.delivery_proposal_date && valueRequiredDate && checkDate) {
			setValueRequiredDate(undefined)
			setCheckDate(false)
		}
	}, [
		confirmedAllItemsProps,
		dateCopyAllItemsProps,
		informationTable,
		valueRequiredDate,
		selectedReason,
		confirmedAllItems,
		checkDate
	])

	useEffect(() => {
		if (!valueAmountConfirm && !valueRequiredDate) {
			setImgCheckPosition('')
		} else if (valueAmountConfirm < informationTable.requested_amount && !valueRequiredDate) {
			setImgCheckPosition(checkSolidYellow)
		} else if (valueAmountConfirm >= informationTable.requested_amount && valueRequiredDate) {
			setImgCheckPosition(checkSolidGreen)
		}
	}, [valueRequiredDate, valueAmountConfirm])

	const checkColorConfirmAmount = (amount, checkChange, reason) => {
		const choiceReason = reason === '' ? selectedReason : reason
		setIsRejectedPosition((!valueAmountConfirm || valueAmountConfirm === 0) && !valueRequiredDate)
		if (amount < informationTable.requested_amount) {
			if (choiceReason && checkChange === 'imgCheckPosition') {
				setCheckConfirm(checkSolidYellow)
				setCheckCancel(cancelIcon)
				setFillRejectField(true)
			} else {
				setCheckConfirm(checkSolidYellow)
				setFillRejectField(true)
				setCheckCancel(cancelIcon)
			}
		} else {
			if (checkChange === 'imgCheckPosition') {
				setFillRejectField(false)
				setSelectedReason(undefined)
				setCheckConfirm(checkSolidGreen)
				setCheckCancel(cancelIcon)
			} else if (checkChange === 'checkConfirm') {
				setFillRejectField(false)
				setCheckConfirm(checkSolidGreen)
				setCheckCancel(cancelIcon)
				selectReason(undefined)
			}
			setReasonItem(undefined, positionKey)
		}
	}

	const setRejectedAmount = () => {
		setImgCheckPosition(checkSolidYellow)
		setCheckConfirm(checkBlue)
		setCheckCancel(cancelSolidRed)
		setValueAmountConfirm('')
		setIsRejectedPosition(true)
		setFillRejectField(true)
		setPlaceholderNA('NAtext')
		if (selectedReason !== undefined) {
			setImgCheckPosition(cancelSolidRed)
		}
		setRowSelected(positionKey)
		changeConfirmAmount(Constants.STATUS.REJECTED, positionKey)
	}

	const setConfirmedAmount = () => {
		setCheckConfirm(checkSolidGreen)
		setCheckCancel(cancelIcon)
		setFillRejectField(false)
		setIsRejectedPosition(false)
		setValueAmountConfirm(informationTable.requested_amount)
		setSelectedReason(undefined)
		setRowSelected(positionKey)
		setReasonItem(undefined, positionKey)
		changeConfirmAmount(informationTable.requested_amount, positionKey)
	}

	const handleDateChange = (evt: Dayjs) => {
		setValueRequiredDate(evt)
		changeRequiredDateProps(evt, positionKey as number)
	}

	const changeConfirmedAmount = (evt) => {
		if (evt.target.value === '.') {
			evt.target.value = '0.'
		}
		if (allowDecimals(evt.target.value)) {
			setIsRejectedPosition(evt.target.value === '' || parseFloat(evt.target.value) === 0)
			if (parseFloat(evt.target.value) <= informationTable.requested_amount) {
				try {
					checkColorConfirmAmount(evt.target.value, 'imgCheckPosition', '')
				} catch {
					checkColorConfirmAmount(evt.target.value, 'checkConfirm', '')
				}
				setValueAmountConfirm(evt.target.value)
				changeConfirmAmount(evt.target.value, positionKey)
			} else if (evt.target.value === '') {
				setCheckConfirm(checkBlue)
				setCheckCancel(cancelIcon)
				setFillRejectField(false)
				setValueAmountConfirm('')
				setSelectedReason(undefined)
				setPlaceholderNA('')
				setReasonItem(undefined, positionKey)
				changeConfirmAmount(evt.target.value, positionKey)
			}
		}
	}

	const selectReason = (reason) => {
		if (valueAmountConfirm > 0) {
			try {
				checkColorConfirmAmount(valueAmountConfirm, 'imgCheckPosition', reason)
			} catch {
				checkColorConfirmAmount(valueAmountConfirm, 'checkConfirm', '')
			}
		} else if (checkCancel === cancelSolidRed || imgCheckPosition === cancelSolidRed) {
			setImgCheckPosition(checkSolidYellow)
			setCheckConfirm(checkBlue)
			setCheckCancel(cancelSolidRed)
		}
		if (!reason && checkCancel === cancelSolidRed) {
			setImgCheckPosition(cancelSolidRed)
		}
		setSelectedReason(reason)
		setReasonItem(reason, positionKey)
	}

	const dateCopyAllItems = () => {
		if (valueRequiredDate) {
			fillConsecutiveDate(valueRequiredDate, positionKey, checkDate)
			setCheckDate(!checkDate)
		}
	}

	const getReasonLabel = (reason) => `${reason.value}`

	const showInputConfirmDate = () => {
		if (isRejectedPosition) {
			return (
				<p className={'text-column-main-table-orderDetails reason-table proposalNaRowEditPO'}>
					{t('NAtext')}
				</p>
			)
		} else {
			return (
				<div className={'containerProposalDeliverDate'}>
					<NetcurioDatePicker
						height="smaller"
						label={t('dateProposalDeliverText')}
						format={'DD/MM/YYYY'}
						value={valueRequiredDate}
						onChange={(evt) => handleDateChange(evt)}
						minDate={dayjs(new Date())}
					/>
					<NetcurioTooltip title={t('dateToAll')}>
						<div className={'checkbox-align display-block'}>
							<NetcurioCheckbox checked={checkDate} onChange={() => dateCopyAllItems()} />
						</div>
					</NetcurioTooltip>
				</div>
			)
		}
	}

	const getRowStatusVisibility = () => {
		if (
			imgCheckPosition !== checkSolidGreen &&
			imgCheckPosition !== checkSolidYellow &&
			imgCheckPosition !== cancelSolidRed
		) {
			return 'hide-container'
		} else {
			return 'imgCheckColumnPurchaseOrderEdit'
		}
	}

	const showProductPrices = (net_value, reference_price, unit_price, price_change_reason, tax) => {
		if (purchaseOrderType.key !== PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT) {
			return (
				<div>
					<div className={'text-in-line'}>
						<p className={'multiline-info'}>{t('itemNetValue')}</p>
						<p className={'multiline-info'}>{Formatter.currency.format(net_value)}</p>
					</div>
					<div className={'display-content'}>
						<PricesInformation
							referencePrice={reference_price}
							unitPrice={unit_price}
							priceChangeReason={price_change_reason}
						/>
					</div>
					<NetcurioTooltip title={t('information')} placement={'right'}>
						<div className={'text-in-line width-IVA'}>
							<p className={'multiline-info'}>{t('ivaText')}</p>
							<p className={'multiline-info'}>{Formatter.percent.format(tax)}</p>
						</div>
					</NetcurioTooltip>
				</div>
			)
		}
	}
	let styleHeightRow
	if (purchaseOrderType.key === PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT) {
		styleHeightRow = 'min-height-edition'
	}
	return (
		<div
			id={'container-column-purchase-orderDetail' + positionKey}
			className={
				styleHeightRow +
				`${
					positionKey !== selectedRow ? ' mainContainerRowEditPO' : ''
				} container-column-purchase-orderDetail gray-color-text-general-info row-edit` +
				positionKey
			}
		>
			<div
				id={'leftBoxBlueEdit' + positionKey}
				className={`${positionKey === selectedRow ? 'leftBoxBlueEdit' : ''}`}
			/>
			<div
				id={'rightBoxBlueEdit' + positionKey}
				className={`${positionKey === selectedRow ? 'rightBoxBlueEdit' : ''}`}
			/>
			<p className={'text-column-main-table-orderDetails positionRowEditPO'}>
				{informationTable.position}
			</p>
			<div className={'text-column-main-table-orderDetails text-align-left product-row-edit-po'}>
				<div className={'text-in-line tooltip-description'}>
					<p className={'multiline-info'}>{t('itemDescription')}</p>
					<p className={'multiline-info itemDescriptionOverflow'}>{informationTable.name}</p>
					<div className={'tooltip-text-description'}>
						<p className={'tooltip-text'}>{informationTable.name}</p>
					</div>
				</div>
				<div className={'text-in-line'}>
					<p className={'multiline-info'}>{t('itemCode')}</p>
					<p className={'multiline-info'}>{informationTable.code}</p>
				</div>
				{showProductPrices(
					informationTable.net_value,
					informationTable.reference_price,
					informationTable.unit_price,
					informationTable.price_change_reason,
					informationTable.tax
				)}
			</div>
			<p className={'text-column-main-table-orderDetails storageRowEditPO'}>
				{t(Formatter.nullValueFormattingToNA(informationTable.storage_location))}
			</p>
			<p className={'text-column-main-table-orderDetails requestedRowEditPO'}>
				{informationTable.requested_amount + ' ' + informationTable.unit?.toUpperCase()}
			</p>
			<div className={'text-column-main-table-orderDetails confirm-row-edit-po'}>
				<div className={'confirm-amount'}>
					<div className={'input-container'}>
						<NetcurioTextField
							value={valueAmountConfirm}
							onChange={(evt) => changeConfirmedAmount(evt)}
							onFocus={() => setRowSelected(positionKey)}
							placeholder={t(placeholderNA)}
						/>
					</div>
					<div className={'buttons-container'}>
						<ConditionalRender condition={selectedRow === positionKey}>
							<NetcurioTooltip title={t('confirmButtonText')} placement={'top'}>
								<img
									className={'buttons-table'}
									src={checkConfirm}
									alt=""
									onClick={() => setConfirmedAmount()}
								/>
							</NetcurioTooltip>
							<NetcurioTooltip title={t('rejectButtonText')} placement={'top'}>
								<img
									className={'buttons-table'}
									src={checkCancel}
									alt=""
									onClick={() => setRejectedAmount()}
								/>
							</NetcurioTooltip>
						</ConditionalRender>
					</div>
				</div>
				<ConditionalRender condition={fillRejectField}>
					<NetcurioAutocomplete
						label={t('reasonSelectorItem')}
						options={allReasons}
						variant="outlined"
						height="smaller"
						value={selectedReason}
						onSelectValue={(e) => selectReason(e)}
						getOptionLabel={getReasonLabel}
						error={!selectedReason}
					/>
				</ConditionalRender>
			</div>
			<p className={'text-column-main-table-orderDetails requiredRowEditPO'}>
				{informationTable.required_delivery_date === null
					? t('NAtext')
					: dateFormatter.format(parseTimestampToTimeZone(informationTable.required_delivery_date))}
			</p>
			{showInputConfirmDate()}
			<div
				className={
					'imgCheckColumnPurchaseOrderEdit container-img-check-column-purchase-orderEdit ' +
					getRowStatusVisibility()
				}
			>
				<img
					src={imgCheckPosition}
					className={'imgCheckSizePOEdit status-row' + positionKey}
					alt="check"
				/>
			</div>
		</div>
	)
}
